import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useTheme } from 'styled-components';
import { toast } from 'react-toastify';

import { Modal } from '@mui/material';
import AlertModal from 'components/AlertModal';
import ConfirmModal from 'components/ConfirmModal';
import { AddCircleOutlineOutlined, DeleteOutline } from '@mui/icons-material';

import Form from './Form';
import * as services from '../../services';

const ModalFaixa = ({
  acoes,
  registro,
  handleClose,
  open,
  disabled = false,
  refetch,
}) => {
  const theme = useTheme();
  const [acaoDisciplinarModal, setAcaoDisciplinarModal] = useState({
    create: null,
    remove: null,
    error: null,
  });

  const { data: _acoesDisciplinares = [], refetch: refetchDisciplinares } =
    useQuery(['acoes-disciplinares'], () => services.getAcoesDisciplinares(), {
      refetchOnWindowFocus: false,
    });

  const { data: _modulosCapacitacao = [] } = useQuery(
    ['modulos_capacitacao'],
    () => services.getModulosCapacitacao(),
    { refetchOnWindowFocus: false },
  );

  const acoesDisciplinares = _acoesDisciplinares
    .filter(item => item.ativo)
    .map(item => ({
      text: item.nome,
      value: item.id,
      id: item.id,
      removable: !!item.is_editavel,
      is_reincidencia: item.is_reincidencia,
    }));

  const handleAddDisciplinar = async nome => {
    try {
      const req = await services.createAcaoDisciplinar(nome);
      if (req.success) {
        toast.success(req.message);
        refetchDisciplinares();
      } else toast.error(req.message);
      setAcaoDisciplinarModal(state => ({ ...state, create: null }));
    } catch (error) {
      console.warn('Erro ao salvar.', error);
    }
  };

  const handleDeleteDisciplinar = async id => {
    try {
      const req = await services.desactivateAcaoDisciplinar(id);

      if (req.success) {
        toast.success(req.message);
        refetchDisciplinares();
      } else if (req.status === 406) {
        setAcaoDisciplinarModal(state => ({ ...state, error: null }));
      } else {
        toast.error(req.message);
      }
      setAcaoDisciplinarModal(state => ({ ...state, remove: null }));
    } catch (error) {
      console.warn('Erro ao salvar.', error);
    }
  };

  const modulosCapacitacao = _modulosCapacitacao.map(modulo => ({
    id: modulo.id,
    text: modulo.titulo,
    value: modulo.id,
  }));

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Form
          acoes={acoes}
          refetch={refetch}
          setAcaoDisciplinarModal={setAcaoDisciplinarModal}
          handleClose={handleClose}
          registro={registro}
          acoesDisciplinares={acoesDisciplinares}
          modulosCapacitacao={modulosCapacitacao}
          disabled={disabled}
        />
      </Modal>
      {!!acaoDisciplinarModal.create && (
        <ConfirmModal
          open={!!acaoDisciplinarModal.create}
          handleClose={() =>
            setAcaoDisciplinarModal(state => ({ ...state, create: null }))
          }
          title={`Tem certeza que deseja salvar "${acaoDisciplinarModal.create}"?`}
          titleIcon={
            <AddCircleOutlineOutlined
              sx={{ color: theme.palette.semantics.feedback.success.natural }}
              fontSize="medium"
            />
          }
          buttonText="Confirmar"
          onClick={() => handleAddDisciplinar(acaoDisciplinarModal.create)}
        />
      )}
      {!!acaoDisciplinarModal?.remove && (
        <ConfirmModal
          open={!!acaoDisciplinarModal?.remove}
          handleClose={() =>
            setAcaoDisciplinarModal(state => ({ ...state, remove: null }))
          }
          title="Tem certeza que deseja desativar esse registro?"
          titleIcon={
            <DeleteOutline
              sx={{ color: theme.palette.semantics.feedback.attention.natural }}
              fontSize="medium"
            />
          }
          buttonText="Confirmar"
          onClick={() => handleDeleteDisciplinar(acaoDisciplinarModal.remove)}
        />
      )}
      {!!acaoDisciplinarModal?.error && (
        <AlertModal
          open={!!acaoDisciplinarModal?.error}
          handleClose={() =>
            setAcaoDisciplinarModal(state => ({ ...state, error: null }))
          }
          backButtonText="Cancelar"
          title="Não é possível excluir esta ação"
          subtitle="Existe uma ou mais faixas que utilizam esta ação, desative a faixa para poder excluir a ação."
          buttonText="Entendi"
          onClick={() =>
            setAcaoDisciplinarModal(state => ({ ...state, error: null }))
          }
        />
      )}
    </div>
  );
};

export default ModalFaixa;
