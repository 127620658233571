import React from 'react';
import KeyboardDoubleArrowLeftOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowLeftOutlined';
import KeyboardDoubleArrowRightOutlinedIcon from '@mui/icons-material/KeyboardDoubleArrowRightOutlined';
import {
  addWeeks,
  startOfWeek,
  endOfWeek,
  addMonths,
  startOfMonth,
  endOfMonth,
  addYears,
  startOfYear,
  endOfYear,
  format,
  subMonths,
  endOfDay,
  startOfDay,
  subDays,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';

import * as S from './styled';
import { useTheme } from 'styled-components';

export const PeriodPicker = ({
  period = 'week',
  periodAdd = null,
  initialDate,
  finalDate = new Date().getUTCDate(),
  minDate,
  maxDate,
  onChange,
}) => {
  const theme = useTheme();

  const fns = {
    week: {
      addPeriod: addWeeks,
      startOf: startOfWeek,
      endOf: endOfWeek,
      mask: 'dd/MM/yyyy',
    },
    // Primeiro ao ultimo dia do mes
    month: {
      addPeriod: addMonths,
      startOf: startOfMonth,
      endOf: endOfMonth,
      mask: 'dd/MM/yyyy',
    },
    // Ultimos 30 dias
    lastMonth: {
      addPeriod: addMonths,
      startOf: date => startOfDay(subDays(date, 30)),
      endOf: endOfDay,
      mask: 'MMM yyyy',
    },
    // Janeiro a dezembro do ano
    year: {
      addPeriod: addYears,
      startOf: startOfYear,
      endOf: endOfYear,
      mask: 'MMM yyyy',
    },
    // Últimos 12 meses
    lastYear: {
      addPeriod: addYears,
      startOf: date => startOfMonth(subMonths(date, 11)),
      endOf: endOfMonth,
      mask: 'MMM yyyy',
    },
  };

  const changeBack = () => {
    const _initialDate = fns[period].startOf(
      fns[periodAdd ?? period].addPeriod(finalDate, -1),
    );
    const _finalDate = fns[period].endOf(
      fns[periodAdd ?? period].addPeriod(finalDate, -1),
    );
    if (onChange instanceof Function)
      onChange({ initialDate: _initialDate, finalDate: _finalDate });
  };

  const changeNext = () => {
    const _initialDate = fns[period].startOf(
      fns[periodAdd ?? period].addPeriod(finalDate, 1),
    );
    const _finalDate = fns[period].endOf(
      fns[periodAdd ?? period].addPeriod(finalDate, 1),
    );
    if (onChange instanceof Function)
      onChange({ initialDate: _initialDate, finalDate: _finalDate });
  };

  const isMin =
    minDate && minDate > fns[periodAdd ?? period].addPeriod(initialDate, -1);
  const isMax =
    maxDate && maxDate < fns[periodAdd ?? period].addPeriod(finalDate, 1);

  return (
    <S.Container>
      <S.DateBox>
        <KeyboardDoubleArrowLeftOutlinedIcon
          htmlColor={
            isMin
              ? theme.palette.words.subtitle.light
              : theme.palette.words.subtitle.natural
          }
          onClick={isMin ? undefined : () => changeBack()}
          className={isMin ? 'arrow null' : 'arrow'}
          title="arrow-back-tasks"
        />
        <div>
          {initialDate
            ? format(initialDate, fns[period].mask, { locale: ptBR })
            : '-'}
        </div>
        <div>-</div>
        <div>
          {finalDate
            ? format(finalDate, fns[period].mask, {
                locale: ptBR,
              })
            : '-'}
        </div>

        <KeyboardDoubleArrowRightOutlinedIcon
          htmlColor={
            isMax
              ? theme.palette.words.subtitle.light
              : theme.palette.words.subtitle.natural
          }
          onClick={isMax ? undefined : () => changeNext()}
          className={isMax ? 'arrow null' : 'arrow'}
          title="arrow-next-tasks"
        />
      </S.DateBox>
    </S.Container>
  );
};
