import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

// Components
import InfoCard from 'components/Cards/InfoCard';
import DefaultButton from 'components/Buttons/Default';
import GhostButton from 'components/Buttons/Ghost';
import Table from 'components/TableLocal';
import ConfirmModal from 'components/ConfirmModal';
import { Grid, IconButton, SvgIcon } from '@mui/material';
import { SaveAlt, Close, CheckCircleOutline } from '@mui/icons-material';
import { toast } from 'react-toastify';
import * as XLSX from 'xlsx';

// Image
import { ReactComponent as ProhibitedIco } from 'images/icons/prohibited.svg';
import { ReactComponent as WarningIco } from 'images/icons/warning.svg';
import { ReactComponent as DeleteIco } from 'images/icons/delete.svg';
import csvDownload from 'images/csv_download.png';
import csvUpload from 'images/csv_upload.png';

// styled
import * as S from './styled';
import { useTheme } from 'styled-components';

// Service
import { createMany, checkUpload } from '../services';

// constants
import { templateFile, text } from './constans';
import { columnsCreateMany } from '../constants';

const sortBy = [
  {
    id: 'placa',
    desc: true,
  },
];

const AddMotoristas = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [etapa, setEtapa] = useState(2);
  const [downloading, setDownloading] = useState(false);
  const [loadingFile, setLoadingFile] = useState(false);

  const [fileLoaded, setFileLoaded] = useState(null);
  const [dataErros, setDataErros] = useState([]);
  const [checkedData, setCheckedData] = useState(null);
  const [selectedData, setSelectedData] = useState(null);
  const [saving, setSaving] = useState(false);

  const [openConfirmCancel, setOpenConfirmCancel] = useState(false);
  const [openConfirmSave, setOpenConfirmSave] = useState(false);

  // Estado downloading indica que o arquivo está sendo baixado
  // Atualiza para etapa 2 (upload) com delay
  const handleDownload = () => {
    setDownloading(true);
    setTimeout(() => {
      setDownloading(false);
      setEtapa(2);
    }, 2000);
  };

  // Carrega arquivo em fileLoaded
  // Atualiza para etapa 3 e chama handleReadLoadedFile
  const handleLoadFile = e => {
    setLoadingFile(true);
    const [file] = e.target.files;
    setFileLoaded(file);
    setEtapa(3);
    handleReadLoadedFile(file);
  };

  // Lê arquivo e registra json em data
  const handleReadLoadedFile = file => {
    const reader = new FileReader();
    reader.onload = evt => {
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, {
        type: 'binary',
        cellDates: true,
      });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      const data = XLSX.utils.sheet_to_json(ws, {
        raw: false,
        cellDates: true,
      });

      if (data.length < 1) toast.warning('Não há dados na planilha carregada!');
      else {
        handleChekData(data);
        setLoadingFile(false);
      }
    };
    reader.readAsBinaryString(file);
  };

  // Chamada no X do arquivo ou em "REFAZER"
  const handleCancelFile = () => {
    setFileLoaded(null);
    setEtapa(2);
    setCheckedData(null);
    setDataErros([]);
    setOpenConfirmCancel(false);
  };

  const removerCaracteresEspeciais = texto => {
    return texto
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .replace(/[^a-zA-Z0-9\s]/g, '');
  };

  // Chamada quando carrega uma planilha modelo com algum dado
  const handleChekData = async data => {
    const res = await checkUpload(data);
    if (res.data.success) {
      const tmp = res.data.data.map(item => {
        item.funcao = removerCaracteresEspeciais(item.funcao || '');
        item.matricula = removerCaracteresEspeciais(item.matricula || '');
        item.nome = removerCaracteresEspeciais(item.nome || '');
        return item;
      });

      setCheckedData(tmp);
      setTimeout(() => {
        setEtapa(4);
        if (res.data.alerta_faixa) toast.warning(res.data.message);
      }, 3000);
    } else if (res.data.errors) {
      setDataErros(res.data.errors);
    }
  };

  // Função final. Só é chamada após as validações pelo botao SALVAR
  const handleSave = async () => {
    setSaving(true);
    // Salva apenas marcados
    const saveData = checkedData.filter(item => selectedData.includes(item.id));

    const res = await createMany(saveData);
    if (res.status === 200) {
      toast.success(res.data.message);
      navigate('/motoristas');
    } else if (res.data?.message) {
      toast.error(res.data.message);
    }
    setSaving(false);
    setOpenConfirmSave(false);
  };

  // Download e carregamento do arquivo
  const renderEtapa1_2 = () => {
    return (
      <S.StyledCard>
        <Grid container>
          <Grid item xl={5} md={5} xs={12}>
            <S.Content>
              <img src={csvDownload} alt="csv_download" />
              <S.TextContent>
                <h2>1. Baixar modelo</h2>

                <h3>{text.baixarModelo}</h3>

                <DefaultButton
                  startIcon={<SaveAlt />}
                  onClick={handleDownload}
                  href={templateFile}
                  loading={downloading}
                >
                  BAIXAR MODELO
                </DefaultButton>
              </S.TextContent>
            </S.Content>
          </Grid>

          {/* Divider vertical só aparece em telas maiores */}
          <Grid
            item
            xl={1}
            md={1}
            justifyContent="center"
            display={{ xs: 'none', md: 'flex', xl: 'flex' }}
          >
            <S.DividerVertical />
          </Grid>

          {/* Divider horizontal só aparece em telas menores */}
          <Grid
            item
            xs={12}
            justifyContent="center"
            display={{ xs: 'flex', md: 'none', xl: 'none' }}
          >
            <S.DividerHorizontal />
          </Grid>

          <Grid item xl={5} md={5} xs={12}>
            <S.Content>
              <img src={csvUpload} alt="csv_upload" />
              <S.TextContent>
                <h2>2. Fazer upload</h2>

                <h3>{text.fazerUpload}</h3>

                <label htmlFor="contained-button-file">
                  <S.InputFile
                    accept=".xlsx, .xls"
                    id="contained-button-file"
                    type="file"
                    onChange={handleLoadFile}
                  />
                  <DefaultButton
                    startIcon={<SaveAlt sx={{ transform: 'rotate(180deg)' }} />}
                    component="span"
                    disabled={etapa < 2}
                  >
                    FAZER UPLOAD
                  </DefaultButton>
                </label>
              </S.TextContent>
            </S.Content>
          </Grid>
        </Grid>
      </S.StyledCard>
    );
  };

  // Após carregar arquivo
  // Mostra erros a serem corrigidos
  const renderEtapa3 = () => {
    return (
      <S.StyledCard>
        <S.UploadContent>
          <img src={csvUpload} alt="csv_upload" />

          {dataErros.length > 0 && (
            <SvgIcon
              component={ProhibitedIco}
              htmlColor="red"
              style={{ fontSize: '32px', margin: '10px' }}
            />
          )}

          <h2>
            {loadingFile
              ? 'Carregando...'
              : dataErros.length > 0
              ? 'Erro no carregamento '
              : ''}
          </h2>

          {etapa < 4 && <h3>{text.sendoEnviado}</h3>}
          {fileLoaded && (
            <S.FileLoaded>
              <p>{fileLoaded.name}</p>
              <IconButton
                size="small"
                onClick={() => setOpenConfirmCancel(true)}
              >
                <Close
                  fontSize="15px"
                  htmlColor={theme.palette.words.subtitle.natural}
                  style={{ strokeWidth: '1.5px' }}
                />
              </IconButton>
            </S.FileLoaded>
          )}

          {dataErros.length > 0 && (
            <S.ErrorContainer>
              <S.ErrorHeader>
                <WarningIco
                  strokeWidth="1.5"
                  stroke="red"
                  fill="none"
                  fontSize="medium"
                />
                <h1>{text.contemErros}</h1>
              </S.ErrorHeader>

              <Grid container spacing={2}>
                {dataErros.map(msgErro => (
                  <Grid item xs={6} md={6} xl={6}>
                    <p>{msgErro}</p>
                  </Grid>
                ))}
              </Grid>
            </S.ErrorContainer>
          )}

          {checkedData && !dataErros.length > 0 && (
            <S.SuccessContainer>
              <CheckCircleOutline
                htmlColor={theme.palette.semantics.feedback.success.natural}
                fontSize="medium"
              />
              <h3>{text.successo}</h3>
            </S.SuccessContainer>
          )}
        </S.UploadContent>
      </S.StyledCard>
    );
  };

  // Etapa 4: exibe dados validados para adicionar
  const renderTable = () => {
    return (
      <Table
        columns={columnsCreateMany}
        data={checkedData}
        sortBy={sortBy}
        permitIsSortedOccur
        setSelectedData={setSelectedData}
        selectedDefault={checkedData.map(i => i.id)} // Seleciona tudo por padrao
      />
    );
  };

  // Componentes da página
  const renderMain = () => (
    <S.Main>
      <S.TitleWrapper>
        <h1>Adicionar em Massa - Motoristas</h1>
        <div>
          {etapa > 2 && (
            <GhostButton
              customcolor={
                theme?.palette?.semantics?.feedback?.attention?.natural
              }
              size="medium"
              onClick={() => setOpenConfirmCancel(true)}
            >
              Refazer
            </GhostButton>
          )}
          {checkedData && etapa === 4 && (
            <GhostButton
              size="medium"
              sx={{ marginLeft: '15px' }}
              onClick={() => setOpenConfirmSave(true)}
              loading={saving}
            >
              Salvar e avançar
            </GhostButton>
          )}
        </div>
      </S.TitleWrapper>

      <div style={{ padding: '15px 0px' }}>
        <InfoCard
          message={
            etapa < 4
              ? 'Adicione seus motoristas de uma só vez! Baixe nosso modelo de planilha, preencha os dados e faça o upload do arquivo.'
              : 'Revise todos os dados que serão inseridos. Após clicar em "salvar e avançar", todos os veículos serão cadastrados como "ativos".'
          }
        />
      </div>

      {etapa < 3 && renderEtapa1_2()}
      {etapa === 3 && renderEtapa3()}
      {etapa === 4 && renderTable()}
    </S.Main>
  );

  return (
    <>
      {renderMain()}

      <ConfirmModal
        open={openConfirmCancel}
        handleClose={() => setOpenConfirmCancel(false)}
        title="Deseja excluir arquivo carregado?"
        titleIcon={<DeleteIco />}
        subtitle="Você deverá refazer o carregamento da planiha."
        buttonText="Excluir"
        onClick={handleCancelFile}
        isNegative
      />

      <ConfirmModal
        open={openConfirmSave}
        handleClose={() => setOpenConfirmSave(false)}
        title="Deseja confirmar adição de motoristas?"
        subtitle={
          selectedData?.length === checkedData?.length
            ? 'Tem certeza que deseja criar acesso à Plataforma Onisys para os motoristas selecionados?'
            : 'Você realmente deseja descartar os motoristas não selecionados?'
        }
        buttonText="Confirmar"
        onClick={handleSave}
      />
    </>
  );
};

export default AddMotoristas;
