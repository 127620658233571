import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  .title {
    margin-bottom: 1rem;
    font-size: 24px;
    font-weight: 800;
    color: #4b5166;
    span {
      color: #f64e60;
    }
  }
  .subtitle {
    text-align: end;
    margin-bottom: 1rem;
    font-size: 16px;
    line-height: 32px;
    font-weight: 500;
    color: #939aab;
  }

  .card-wrapper {
    width: 100%;
  }

  .card {
    width: 100%;
    height: 100%;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 4px;
    border: 1px solid #939aab4d;
  }

  .flex {
    width: 100%;
    margin-top: 1rem;
    display: flex;
    gap: 1rem;
  }
`;
