import React, { useState, forwardRef, useEffect } from 'react';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Tooltip, SvgIcon } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { useTheme } from 'styled-components';
import * as S from './styled';
import TextField from '@mui/material/TextField';
import InputMask from 'react-input-mask';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import axios from 'axios';
import Skeleton from '@mui/material/Skeleton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { addNewCliente } from '../../services';
import { toast } from 'react-toastify';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import Avatar from '@mui/material/Avatar';

/*
data: lista de objetos com {value: valor do id correspondente,
                            name: string do nome do objeto selecionado},
value: data[].value
label: Descrição acima do select (não obrigatório)
hasClear:
*/

const SelectInput = forwardRef(
  (
    {
      value = '',
      handleChange,
      data,
      placeholder,
      clearLabel,
      label,
      required,
      markselected,
      tooltip,
      disabled,
      color = null,
      colorDisabled = null,
      refetch = () => {},
      onAddNew,
      ...props
    },
    ref,
  ) => {
    const theme = useTheme();
    const [isAddingNew, setIsAddingNew] = useState(false);
    const [newCnpj, setNewCnpj] = useState('');
    const [open, setOpen] = useState(false);
    const [empresa, setEmpresa] = useState(null);
    const [onfocus, setOnfocus] = useState(false);
    const [sizeCnpj, setSizeCnpj] = useState(0);
    const [localSearch, setLocalSearch] = useState('');
    const [filteredData, setFilteredData] = useState([]);

    const handleAddNewClick = event => {
      setIsAddingNew(true);
    };

    useEffect(() => {
      setFilteredData(data);
    }, [open]);

    const handleSaveEnterprise = async data => {
      try {
        if (!empresa?.razao_social && !empresa?.cnpj)
          return toast.success('faltam cnpj e razao social!');

        const dataSend = {
          id: null,
          nome: empresa?.razao_social,
          cnpj: empresa?.cnpj,
          ativo: true,
        };
        const res = await addNewCliente(dataSend);
        if (res.success) {
          toast.success('Empresa salva com sucesso');
          setOpen(false);
          setIsAddingNew(false);
          setOnfocus(false);
          setNewCnpj('');
          setEmpresa(null);
          setSizeCnpj(0);
        }
      } catch (error) {
        console.error('Erro ao salvar empresa:', error.message);
        toast.error('Erro ao salvar empresa');
      }
    };

    const validateCNPJ = cnpj => {
      // Remove caracteres não numéricos
      cnpj = cnpj.replace(/[^\d]+/g, '');

      if (cnpj.length !== 14) return false;

      // Elimina CNPJs inválidos conhecidos
      if (/^(\d)\1{13}$/.test(cnpj)) return false;

      // Validação dos dígitos verificadores
      let tamanho = cnpj.length - 2;
      let numeros = cnpj.substring(0, tamanho);
      let digitos = cnpj.substring(tamanho);
      let soma = 0;
      let pos = tamanho - 7;

      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }

      let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(0)) return false;

      tamanho += 1;
      numeros = cnpj.substring(0, tamanho);
      soma = 0;
      pos = tamanho - 7;

      for (let i = tamanho; i >= 1; i--) {
        soma += numeros.charAt(tamanho - i) * pos--;
        if (pos < 2) pos = 9;
      }

      resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11);
      if (resultado != digitos.charAt(1)) return false;

      return true;
    };

    const handleSearch = async value => {
      setNewCnpj(value);
      const cleanedValue = value.replace(/[^\d]+/g, '');

      setSizeCnpj(cleanedValue.length);
      if (!validateCNPJ(cleanedValue)) {
        console.error('CNPJ inválido');
        return;
      }

      try {
        const response = await axios.get(
          `https://brasilapi.com.br/api/cnpj/v1/${cleanedValue}`,
        );
        if (!response.status === 200) {
          throw new Error('Erro na requisição');
        }
        setEmpresa(response?.data || '');
      } catch (error) {
        console.error('Erro ao buscar CNPJ:', error.message);
      }
    };

    const handleLocalSearch = async value => {
      setOnfocus(true);
      setLocalSearch(value);

      const filtered = data.filter(item =>
        item.name.toLowerCase().includes(value.toLowerCase()),
      );

      setFilteredData(filtered);
    };

    return (
      <S.Container>
        {label && (
          <S.Label color={color}>
            {label}
            <S.Required active={required}>*</S.Required>
            {tooltip && (
              <Tooltip title={tooltip} placement="top">
                <SvgIcon fontSize="small" component={InfoOutlinedIcon} />
              </Tooltip>
            )}
          </S.Label>
        )}
        <Select
          ref={ref}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => {
            if (onfocus) return;
            setOpen(false);
          }}
          color="secondary"
          disabled={disabled}
          value={value}
          onChange={handleChange}
          displayEmpty
          fullWidth
          sx={{
            background:
              markselected && Boolean(value)
                ? `${theme.palette.system.divider}AA`
                : 'transparent',
            '& .MuiSelect-select': {
              display: 'flex',
              alignItems: 'center',
            },
            '.MuiOutlinedInput-notchedOutline': {
              border: disabled
                ? 'none'
                : `2px solid ${theme.palette.brand.primary.light}`,
            },
            '.MuiOutlinedInput-notchedOutline.error': {
              border: `2px solid ${theme.palette.semantics.feedback.attention.natural}`,
            },
            '.Mui-disabled': {
              backgroundColor:
                colorDisabled || theme.palette.semantics.feedback.unknown.light,
              borderRadius: '5px',
            },
          }}
          {...props}
        >
          {clearLabel && (
            <MenuItem
              value=""
              key="undefined"
              children={value ? clearLabel : placeholder}
            />
          )}
          <>
            <MenuItem
              onKeyDown={e => e.stopPropagation()}
              sx={{
                backgroundColor: 'transparent',
                border: 'none',
                '&:hover': {
                  backgroundColor: 'transparent',
                },
                '&.Mui-selected': {
                  backgroundColor: 'transparent',
                },
                '&.Mui-focusVisible': {
                  backgroundColor: 'transparent',
                },
                padding: 0, // Remove o padding
                margin: 0, // Remove a margem
                minHeight: 0, // Remove altura mínima
                '& .MuiListItemText-root': {
                  margin: 0, // Remove margem de texto
                },
              }}
              disableRipple
            >
              <TextField
                size="small"
                placeholder="Buscar"
                fullWidth
                onChange={e => handleLocalSearch(e.target.value)}
                value={localSearch}
                sx={{
                  transform: 'translateY(-8px)',
                  backgroundColor: '#EDF2F9',
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      border: 'none',
                    },
                    '&:hover fieldset': {
                      border: 'none',
                    },
                    '&.Mui-focused fieldset': {
                      border: 'none',
                    },
                  },
                }}
              />
            </MenuItem>
          </>
          {isAddingNew ? (
            <>
              <S.SearchEnterprise>
                <IconButton
                  sx={{ marginRight: 2 }}
                  onClick={() => setIsAddingNew(false)}
                >
                  <CloseIcon />
                </IconButton>
                <InputMask
                  mask="99.999.999/9999-99"
                  onChange={e => handleSearch(e.target.value)}
                  value={newCnpj}
                  style={{ height: 40 }}
                >
                  {props => (
                    <TextField
                      {...props}
                      sx={{ width: 372 }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <SearchIcon />
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Digite o CNPJ"
                      size="small"
                    />
                  )}
                </InputMask>
              </S.SearchEnterprise>
              {sizeCnpj > 5 && empresa === null ? (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    marginTop: 10,
                  }}
                >
                  {[1, 2, 4].map(() => (
                    <Skeleton
                      variant="rectangular"
                      width={372}
                      height={15}
                      sx={{ marginTop: 1 }}
                    />
                  ))}
                </div>
              ) : (
                <S.NameEnterprise onClick={() => handleSaveEnterprise()}>
                  {empresa?.razao_social && (
                    <>
                      <div>
                        <AddCircleOutlineIcon sx={{ color: '#ff8040' }} />
                      </div>
                      <div>
                        <div className="title">
                          {empresa?.razao_social || ''}
                        </div>
                        <div className="subtitle">{empresa?.cnpj || ''}</div>
                      </div>
                    </>
                  )}
                </S.NameEnterprise>
              )}
            </>
          ) : (
            <MenuItem
              onFocus={() => setOnfocus(true)}
              onClick={handleAddNewClick}
            >
              <S.ButtonAdd>
                <AddCircleOutlineOutlinedIcon sx={{ marginRight: 1 }} />
                <div>ADICIONAR NOVA EMPRESA</div>
              </S.ButtonAdd>
            </MenuItem>
          )}

          {filteredData.map(item => (
            <MenuItem
              onFocus={() => setOnfocus(false)}
              value={item.value}
              key={item.value}
              style={{
                display: 'flex',
                alignItems: 'center',
                border: 'none',
              }}
              disabled={!!item.disabled}
            >
              {item.image && (
                <Avatar
                  src={item.image}
                  alt={item.name}
                  sx={{
                    width: 38,
                    height: 38,
                    marginRight: '10px',
                    border: `2px solid ${theme.palette.brand.secondary.natural}`,
                  }}
                />
              )}
              {item.name}
            </MenuItem>
          ))}
        </Select>
      </S.Container>
    );
  },
);

export default SelectInput;
