import produce from 'immer';

const INITIAL_STATE = {
  status: '',
  color: '',
  background: '',
  totalNotifications: 0,
  openPush: [],
  profile_photo: null,
  alert: {
    show: false,
    message: '',
  },
  openModalInfo: false,
  showMsgInfo: true,
  fullScreenMode: false,
  allIds: '',
  toggleSnackbar: false,
  downloadSnackbar: null,
  toggleAlertModal: false,
  downloadAlertModal: null,
};

export default function header(state = INITIAL_STATE, action) {
  return produce(state, draft => {
    switch (action.type) {
      case '@evaluations/CHANGE_HEADER_STATUS': {
        draft.status = action.payload.status;
        draft.color = action.payload.color;
        draft.background = action.payload.background;
        break;
      }

      case '@evaluations/CHANGE_NOTIFICATIONS_COUNT': {
        draft.totalNotifications = action.payload.totalNotifications;
        draft.openPush = action.payload.openPush;
        draft.allIds = action.payload.allIds;
        break;
      }

      case '@evaluations/SET_ALERT': {
        draft.alert.message = action.payload.message;
        draft.alert.show = true;
        break;
      }

      case '@evaluations/CLOSE_ALERT': {
        draft.alert.message = '';
        draft.alert.show = false;
        break;
      }

      case '@evaluations/UPDATE_PROFILE_PHOTO': {
        draft.profile_photo = action.payload.profile_photo;
        break;
      }

      case '@evaluations/OPEN_MODAL_INFO': {
        draft.openModalInfo = true;
        break;
      }
      case '@evaluations/CLOSE_MODAL_INFO': {
        draft.openModalInfo = false;
        break;
      }
      case '@evaluations/SHOW_MSG_INFO': {
        draft.showMsgInfo = true;
        break;
      }
      case '@evaluations/HIDE_MSG_INFO': {
        draft.showMsgInfo = false;
        break;
      }

      case '@evaluations/FULL_SCREEN_MODE': {
        draft.fullScreenMode = true;
        break;
      }
      case '@evaluations/EXIT_FULL_SCREEN_MODE': {
        draft.fullScreenMode = false;
        break;
      }
      case '@evaluations/TOGGLE_SNACKBAR_OPEN': {
        draft.toggleSnackbar = true;
        draft.downloadSnackbar = action.payload;
        break;
      }

      case '@evaluations/TOGGLE_SNACKBAR_CLOSE': {
        draft.toggleSnackbar = false;
        draft.downloadSnackbar = null;
        break;
      }

      case '@evaluations/TOGGLE_ALERT_MODAL_OPEN': {
        draft.toggleAlertModal = true;
        draft.downloadAlertModal = action.payload;
        break;
      }

      case '@evaluations/TOGGLE_ALERT_MODAL_CLOSE': {
        draft.toggleAlertModal = false;
        draft.downloadAlertModal = null;
        break;
      }

      default:
    }
  });
}
