import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

import {
  Divider,
  Grid,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Modal,
  Icon as IconM,
} from '@mui/material';
import {
  SaveAlt,
  FindInPageOutlined,
  CalendarTodayOutlined,
  OpenInFull,
  ControlPointDuplicateRounded,
} from '@mui/icons-material';
import { toast } from 'react-toastify';

import { useReactToPrint } from 'react-to-print';
import TextInput from 'components/Inputs/TextField';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import Loading from 'components/Loading';
import ImageModal from 'components/ImageModal';
import Icon from 'components/Icons';
import DataHour from 'components/Inputs/DataHour';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';

import { addDays } from 'date-fns';
import { formatNewDate, formatNewHour } from 'utils/dates';
import { useTheme } from 'styled-components';
import { ModalEvidencia } from './Evidence';
import * as S from './styled';

import DropZone from '../../../../components/Inputs/DropZoneLayout';

import * as request from '../services';

const CaeDetail = params => {
  const theme = useTheme();
  const routeParams = useParams();
  const id = params.imprimir
    ? params.id
    : params.create
    ? null
    : routeParams.id;
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });

  const [cae, setCae] = useState(null);
  const [filiais, setFiliais] = useState([]);
  const [allUsersBase, setAllUsersBase] = useState(null);
  const [imagemName, setImagemName] = useState(null);
  const [expandedImage, setExpandedImage] = useState(false);

  const [openModal, setOpenModal] = useState(null);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [modoEdicao, setModoEdicao] = useState(false);
  const [approveModal, setApproveModal] = useState(false);
  const [disabled, setDisabled] = useState(false);

  const navigate = useNavigate();
  const classes = S.useStyles();
  const componentRef = useRef();
  const inicialRef = useRef(null);

  const [question, setQuestion] = useState(null);
  const [printMode, setPrintMode] = useState(false);

  const changeMonitoring = value => {
    if (value.length >= 1024) return;
    setCae({ ...cae, data_ocorrencia: value });
  };

  const fetchCae = async () => {
    const res = await request.getCae(id);
    if (res.data.success) {
      setAllUsersBase(res.data.users.map(i => i.id_do_usuario));
      setCae(res.data.cae);
      setDisabled(res?.data?.cae?.status === 'FINALIZADO');
    } else {
      if (
        res.data.code === 400 ||
        res.data.code === 402 ||
        res.data.code === 403 ||
        res.data.code === 500
      ) {
        toast.error(res.data.message);
        return false;
      }
      const errors = res.data.posto?.errors;
      errors?.forEach(i => {
        toast.error(i);
      });
      return false;
    }
  };

  // Configura body de uma nova CAE
  // Deve receber de params os dados fixos
  const configNewCae = () => {
    const obj = {
      status: 'ABERTO',
      operacao: { id: 1, nome: 'Raízen' },
      nome_motorista: null,
      placa: null,
      id_da_filial: null,
      caminhao: null,
      observacao_cae: null,
      imagem_cae: null,
      data_ocorrencia: new Date(),
      data_limite: addDays(new Date(), params.questao.prazo_bloqueio),
      titulo: params.questao && params.questao.abreviacao,
      posto: params.posto,
      questao: params.questao,
      id_do_aprovador: user.id,
      usuario: user,
    };
    setCae(obj);
  };

  const fetchFiliais = async () => {
    if (!user.id_da_distribuidora) {
      const res = await request.requestFilials();
      setFiliais(res.data.data);
    }
  };

  // Carregamento inicial da página
  useEffect(() => {
    fetchFiliais();
  }, []);

  useEffect(() => {
    if (filiais) {
      if (id) fetchCae();
      else configNewCae();
    }
  }, [filiais]);

  useEffect(() => {
    setQuestion(params.questao);
  }, [params.questao]);

  useEffect(() => {
    if (printMode) handlePrint();
  }, [printMode]);

  useEffect(() => {
    if (allUsersBase) {
      setModoEdicao(allUsersBase.includes(user.id));
    }
  }, [allUsersBase]);

  useEffect(() => {
    if (componentRef?.current !== undefined && params.imprimir)
      setPrintMode(true);
  }, [componentRef?.current, params.imprimir]);

  const handleChange = (key, value) => {
    setCae(prev => {
      return { ...prev, [key]: value };
    });
  };

  const handleCreateCae = async () => {
    const res = await request.createCae(cae);
    if (!res.data.success) {
      if (
        res.data.code === 400 ||
        res.data.code === 402 ||
        res.data.code === 403 ||
        res.data.code === 500
      ) {
        toast.error(res.data.message);
      } else {
        const errors = res.data.posto?.errors;
        errors?.forEach(i => {
          toast.error(i);
        });
      }
    } else {
      toast.success(res.data.message);
      setQuestion(null);
      params.setCloseModalCae(true);
    }
  };

  const handleUpdateCae = async () => {
    const res = await request.updateCae(id, cae);
    if (!res.data.success) {
      if (
        res.data.code === 400 ||
        res.data.code === 402 ||
        res.data.code === 403 ||
        res.data.code === 500
      ) {
        toast.error(res.data.message);
      } else {
        const errors = res.data.posto?.errors;
        errors?.forEach(i => {
          toast.error(i);
        });
      }
    } else {
      toast.success(res.data.message);
      setCae(res.data.cae);
    }
  };

  const handleModalAprovar = infrac => {
    setApproveModal(true);
  };

  const handleAprovarCar = () => {
    cae.status = 'FINALIZADO';
    cae.id_do_aprovador = user.id;
    handleUpdateCae();
  };

  const handleDeleteImagem = () => {
    // deleteFileFromStorage(posto.arquivo_croqui) TODO: deve excluir a imagem do storage?
    setCae({ ...cae, imagem_cae: null });
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => setLoadingPdf(true),
    onAfterPrint: () => {
      setPrintMode(false);
      setLoadingPdf(false);
      if (params.imprimir) params.setCancelPrint(null);
    },
    documentTitle: `cae${cae?.id}`,
    pageStyle: `
        @page {
          size: auto;
          margin: 5mm;
        }
      `,
  });

  const handleCancelPrint = () => {
    setPrintMode(false);
    setLoadingPdf(false);
    if (params.imprimir) params.setCancelPrint(null);
  };

  //  ------------------   RENDERIZACAO --------------------------------
  const renderHeader = () => {
    return (
      id && (
        <S.SpacedRow modalImprimir={params.imprimir}>
          {loadingPdf ? (
            <h1 marginTop="20px">Carregando impressão...</h1>
          ) : (
            <h1>Comunicação de Atendimento de Emergência</h1>
          )}

          <S.SpacedRow>
            {/* <GhostButton  //TODO: Verificar se é necessário histórico de CAE
                        startIcon={<HistoricoIcon />}
                        size="medium"
                        onClick={() => navigate(`/logs/cae/${id}`)}
                        sx={{ marginLeft: "20px" }}
                    >
                        HISTÓRICO DE ALTERAÇÕES
                    </GhostButton> */}

            {!loadingPdf && (
              <GhostButton
                startIcon={<FindInPageOutlined />}
                size="medium"
                onClick={() => navigate(`/layout/${cae.posto.id}`)}
                sx={{ marginLeft: '20px' }}
              >
                VER LAYOUT VINCULADO
              </GhostButton>
            )}

            <GhostButton
              startIcon={<SaveAlt />}
              size="medium"
              onClick={() => setPrintMode(true)}
              style={{ marginLeft: '10px' }}
              loading={loadingPdf}
            >
              IMPRIMIR
            </GhostButton>

            {loadingPdf && (
              <GhostButton
                // startIcon={<FindInPageOutlined />}
                size="medium"
                onClick={() => handleCancelPrint()}
                sx={{ marginLeft: '20px' }}
              >
                CANCELAR IMPRESSÃO
              </GhostButton>
            )}
          </S.SpacedRow>
        </S.SpacedRow>
      )
    );
  };

  // Lado Esquerdo do detalhe da CAE
  // Não editável
  const renderDriverInfo = () => {
    return (
      <S.ColumnWrapper id={id}>
        <Grid container columnSpacing={2}>
          <Grid item xs={12} sm={12} display="flex" justifyContent="center">
            <S.ImageContainer file={!!cae.imagem_cae}>
              {cae.imagem_cae && (
                <>
                  <S.IconDivClose onClick={() => handleDeleteImagem()}>
                    <Icon
                      name="close"
                      size={25}
                      color={theme.palette.brand.secondary.natural}
                    />
                  </S.IconDivClose>
                  <S.ExpandImage onClick={() => setExpandedImage(true)}>
                    <OpenInFull
                      sx={{
                        color: theme.palette.brand.secondary.natural,
                        fontSize: 40,
                      }}
                    />
                  </S.ExpandImage>
                </>
              )}
              <DropZone
                disabled={!modoEdicao && user.id_da_distribuidora}
                width="100%"
                height="500px"
                fileUrl={cae.imagem_cae ? cae.imagem_cae : ''}
                setFileUrl={fileUrl => setCae({ ...cae, imagem_cae: fileUrl })}
                setFileName={setImagemName}
                id_layout={cae.posto.id}
                base={cae.posto.base}
                onClick={() => setOpenModal(true)}
              />
            </S.ImageContainer>
          </Grid>

          <Divider style={{ margin: '10px 10px 10px 10px', width: '100%' }} />

          <Grid item xs={6} sm={6}>
            <TextInput
              label="Motorista"
              onChange={e => handleChange('nome_motorista', e.target.value)}
              value={cae.nome_motorista ? cae.nome_motorista : ''}
              inputProps={{ readOnly: !modoEdicao && id }}
              variant={!modoEdicao && id ? 'filled' : 'outlined'}
              disabled={disabled}
            />
          </Grid>
          <Grid item xs={6} sm={6}>
            <TextInput
              id="placa"
              label="Placa do Veículo"
              value={cae.placa ? cae.placa : ''}
              onChange={e => handleChange('placa', e.target.value)}
              inputProps={{ readOnly: !modoEdicao && id }}
              variant={!modoEdicao && id ? 'filled' : 'outlined'}
              disabled={disabled}
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <TextInput
              id="cliente"
              label="Cliente"
              value={cae?.posto?.distribuidora?.nome}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <TextInput
              id="transportadora"
              label="Transportadora"
              value={cae.usuario.filial.empresa.nome}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <TextInput
              id="filial"
              label="Filial"
              value={cae.usuario.filial.nome}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              id="aprovador"
              label="Aprovador"
              value={cae?.aprovador?.nome || 'Não aprovada'}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          {cae?.arquivo_evidencia && (
            <Grid item xs={12} sm={12}>
              <InputAttachedFile
                label="Arquivo evidência"
                fileUrl={cae?.arquivo_evidencia}
                setFileUrl={value =>
                  setCae(prev => {
                    return { ...prev, arquivo_evidencia: value };
                  })
                }
                fileDir={`/layout/${cae.posto.base.sigla}/${cae.posto.id}`}
                disabled={(!modoEdicao && id) || disabled}
              />
            </Grid>
          )}
        </Grid>
      </S.ColumnWrapper>
    );
  };

  // Lado direito detalhe do desvio
  // Dados editáveis
  const renderCaeInfo = () => {
    const severidade = [1, 2, 3, 4];

    const getStatusColor = status => {
      switch (status) {
        case 'ABERTO':
          return theme.palette.semantics.feedback.warning.natural;
        case 'PENDENTE':
          return theme.palette.semantics.feedback.warning.natural;
        case 'FINALIZADO':
          return theme.palette.semantics.feedback.success.natural;
        case 'DELETADO':
          return theme.palette.semantics.feedback.attention.natural;
      }
    };

    return (
      <S.ColumnWrapper id={id}>
        <Grid container columnSpacing={2} rowSpacing="24px">
          <Grid item xs={6} sm={6}>
            <TextInput
              id="titulo"
              label="Título do Evento"
              value={cae.questao.abreviacao}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>
          <Grid item xs={2} sm={2}>
            <TextInput
              id="codigo"
              label="Código"
              value={cae.questao.id}
              InputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <TextInput
              id="situacao"
              label="Situação Atual"
              value={cae.status}
              variant="filled"
              fillcolor={getStatusColor(cae.status)}
              inputProps={{ readOnly: true }}
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextInput
              id="reportadoem"
              label="Reportado em"
              value={
                cae.createdAt
                  ? formatNewDate(cae.createdAt)
                  : formatNewDate(new Date())
              }
              inputProps={{ readOnly: true }}
              startIcon={
                <CalendarTodayOutlined
                  sx={{ color: theme.palette.brand.primary.light }}
                />
              }
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <DataHour
              disabled={(!modoEdicao && id) || disabled}
              label="Data e hora do Ocorrido"
              value={cae.data_ocorrencia}
              onChange={value => changeMonitoring(value)}
              refs={inicialRef}
              variant={!modoEdicao && id ? 'filled' : 'outlined'}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              id="endereco"
              label="Localização / Endereço"
              value={cae.posto.endereco ? cae.posto.endereco : ''}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <h3>Severidade do Evento</h3>
            <FormControl component="fieldset">
              <RadioGroup
                row
                value={cae.questao.severidade}
                style={{ justifyContent: 'space-between', paddingTop: '15px' }}
              >
                {severidade.map(item => (
                  <FormControlLabel
                    key={`${item}`}
                    value={item}
                    control={<Radio disabled />}
                    label={item}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextInput
              id="consequencia"
              label="Consequência"
              value={cae.questao.consequencia}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} sm={6}>
            <TextInput
              id="data_limite"
              label="Data Limite Adequeação"
              value={formatNewHour(
                addDays(
                  new Date(cae.data_ocorrencia),
                  cae.questao.prazo_bloqueio,
                ),
              )}
              startIcon={
                <CalendarTodayOutlined
                  sx={{ color: theme.palette.brand.primary.light }}
                />
              }
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              label="Descrição da Ocorrência"
              placeholder="Observação da Ocorrência"
              onChange={e => handleChange('observacao_cae', e.target.value)}
              multiline
              rows={4}
              value={cae.observacao_cae}
              inputProps={{ readOnly: !modoEdicao && id }}
              variant={!modoEdicao && id ? 'filled' : 'outlined'}
              disabled={disabled}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            display={printMode ? 'none' : 'flex'}
            justifyContent="flex-end"
          >
            {id && (
              <>
                <GhostButton
                  id="btnVoltar"
                  size="medium"
                  onClick={() => navigate(-1)}
                  style={{ marginLeft: '10px' }}
                >
                  VOLTAR
                </GhostButton>

                {modoEdicao && !disabled && (
                  <>
                    <GhostButton
                      id="btnSalvar"
                      size="medium"
                      onClick={() => handleUpdateCae()}
                      style={{ marginLeft: '10px' }}
                    >
                      Salvar
                    </GhostButton>

                    {cae.status === 'ABERTO' && (
                      <DefaultButton
                        size="medium"
                        onClick={() => handleModalAprovar()}
                        style={{ marginLeft: '10px' }}
                        disabled={!!cae?.data_aprovacao}
                      >
                        Aprovar
                      </DefaultButton>
                    )}
                  </>
                )}
              </>
            )}

            {!id && (
              <>
                <GhostButton
                  id="btnCancelar"
                  size="medium"
                  customcolor={
                    theme?.palette?.semantics?.feedback?.attention?.natural
                  }
                  onClick={() => setQuestion(null)}
                  style={{ marginLeft: '10px' }}
                >
                  Cancelar
                </GhostButton>

                <GhostButton
                  id="btnSalvar"
                  size="medium"
                  onClick={() => handleCreateCae()}
                  style={{ marginLeft: '10px' }}
                  disabled={!!cae?.data_aprovacao}
                >
                  Salvar
                </GhostButton>
              </>
            )}
          </Grid>
        </Grid>
      </S.ColumnWrapper>
    );
  };

  const renderPage = () => {
    return (
      <>
        <S.Main>
          {renderHeader()}
          <Grid
            container
            spacing={id ? '20px' : '0px'}
            marginTop={id ? '10px' : '0px'}
            ref={componentRef}
          >
            {printMode && (
              <h1 style={{ marginLeft: '40px' }}>
                {' '}
                Comunicação de Atendimento de Emergência{' '}
              </h1>
            )}

            <Grid item xs={12} sm={12} md={printMode ? 12 : 6}>
              {renderDriverInfo()}
            </Grid>

            {printMode && <Grid item xs={12} height="350px" />}

            <Grid item xs={12} sm={12} md={printMode ? 12 : 6}>
              {renderCaeInfo()}
            </Grid>
          </Grid>
        </S.Main>
        <ImageModal
          open={expandedImage}
          handleClose={() => setExpandedImage(false)}
          image={cae.imagem_cae}
        />
        <ModalEvidencia
          open={approveModal}
          handleClose={() => {
            setApproveModal(false);
            fetchCae();
          }}
          cae={cae}
          cliente={cae?.posto?.id_da_distribuidora || 1}
        />
      </>
    );
  };

  return cae ? (
    <>
      {id && !params.imprimir ? (
        renderPage()
      ) : (
        <Modal
          open={!!(question || params.imprimir)}
          onClose={() => setQuestion(null)}
          aria-labelledby="simple-modal-title"
          aria-describedby="simple-modal-description"
        >
          <div
            style={{
              top: '50%',
              left: '50%',
              position: 'absolute',
              transform: `translate(-50%, -50%)`,
              width: '90%',
              height: '90%',
              backgroundColor: 'white',
              overflowY: 'scroll',
            }}
            className={classes.paper}
          >
            <Grid xs={12} sm={12}>
              {!params.imprimir && (
                <S.CaeHeader>
                  <IconM
                    sx={{ color: theme.palette.brand.secondary.natural }}
                    fontSize="medium"
                  >
                    add_circle_outline
                  </IconM>
                  <p>Criar Nova CAE</p>
                </S.CaeHeader>
              )}
              {renderPage()}
            </Grid>
          </div>
        </Modal>
      )}
    </>
  ) : (
    <Loading />
  );
};

export default CaeDetail;
