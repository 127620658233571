import api from 'services/api';

export const getTiposDesvios = async params => {
  const { data } = await api.get('/desvios/manual', { params });
  return data.data;
};

export const saveDesvioManual = async data => {
  const res = await api.post(`/desvios/manual`, data);
  return res.data;
};

export const getPlanosAcao = async () => {
  const { data } = await api.get('/plano-acao');
  return data.data;
};

export const createPlanoAcao = async titulo => {
  const res = await api.post(`/plano-acao`, {
    titulo,
  });
  return res.data;
};

export const desactivatePlanoAcao = async id => {
  const res = await api.put(`/plano-acao/${id}/desativar`);
  return { ...res.data, status: res.status };
};

export const getDriversProvider = async () => {
  const res = await api.get(`/select-drivers-provider`);
  const data = res?.data?.data || [];
  return data.map(i => ({ value: i.id, text: i.nome }));
};

export const getResponsaveisProvider = async () => {
  const res = await api.get(`/select-responsibles-provider`);
  const data = res?.data?.data || [];
  return data;
};

export const getClients = async () => {
  const res = await api.get('/select-clients');
  const data = res?.data?.data || [];
  return data.map(i => ({ value: i?.id, label: i?.nome }));
};

export const getResponsaveis = async id => {
  const res = await api.get(`/desvios/${id}/responsaveis`);
  return res?.data?.data || [];
};

export const getResponsaveisDesvio = async id => {
  const res = await api.get(`/desvios/responsibles-desvios-select`);
  return res?.data?.rows || [];
};
