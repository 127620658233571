import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { useTheme } from 'styled-components';
import { useQuery } from 'react-query';

import { FormControl, Grid, Divider } from '@mui/material';

import Select from 'components/Inputs/Select';
import GhostButton from 'components/Buttons/Ghost';
import TextInput from 'components/Inputs/TextField';
import RadioGroup from 'components/Inputs/RadioGroup';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';
import { getResponsaveisProvider } from 'pages/Desvios/components/DesvioManualModal/services';

import * as S from '../styled';
import { usePlans } from 'hooks/usePlans';
import { USUARIO_NIVEL } from 'constants';

const Config = ({
  acaoSuspensao,
  isSuspensao,
  formData,
  setFormData,
  handleOpenModal,
}) => {
  const theme = useTheme();
  const { isProvider, isOpLogistico, hasTorrePlus } = usePlans();
  const { user } = useSelector(state => state.auth.user);
  const userLevel = user.nivel;
  const userFilials = user.usuario_filiais;
  const { users } = useSelector(state => {
    return state.selects;
  });

  const handleChange = (name, value) => {
    setFormData(prev => {
      return { ...prev, [name]: value };
    });
  };

  const { data: responsaveisProvider = [] } = useQuery(
    ['responsaveis-provider'],
    () => getResponsaveisProvider(),
    {
      enabled: user.provider,
      refetchOnWindowFocus: false,
    },
  );

  // Lista de responsáveis
  const responsibles = useMemo(
    () =>
      user.provider
        ? responsaveisProvider
        : users
            .filter(i => {
              if (userLevel === USUARIO_NIVEL.ADMIN) return true;
              if (userFilials.includes(i.id_da_filial)) return true;
              return false;
            })
            .map(usr => ({ name: usr.nome, value: usr.id })),
    [users, userLevel, responsaveisProvider],
  );

  // Caso desvio proprio Op Logistico, deixa os campos liberados
  const defaultDisable =
    isProvider && (!isOpLogistico || !!acaoSuspensao.empresa);

  return (
    <S.ColumnWrapper>
      <Grid container columnSpacing={2} rowSpacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          display="flex"
          flexDirection="row"
          alignItems="center"
        >
          <div className="imageArea">
            {acaoSuspensao?.distribuidora?.logo_url && (
              <img src={acaoSuspensao?.distribuidora.logo_url} />
            )}
            {!acaoSuspensao?.distribuidora?.logo_url && (
              <span className="letters">
                {acaoSuspensao?.distribuidora?.nome[0]}
              </span>
            )}
          </div>
          {!hasTorrePlus && (
            <span className="distributorName">
              {acaoSuspensao?.distribuidora?.nome}
            </span>
          )}

          {hasTorrePlus && (
            <>
              <S.ContactInfo>
                <span
                  style={{ marginLeft: '-10%' }}
                  className="distributorName"
                >
                  {acaoSuspensao.empresa?.nome}
                </span>
              </S.ContactInfo>

              <Divider orientation="vertical" flexItem border="1px" />

              <S.ContactInfo>
                <div style={{ display: 'flex' }}>
                  <h1 style={{ fontSize: '16px' }}>Contato: </h1>
                  <p style={{ marginLeft: '2%' }}>
                    {acaoSuspensao.empresa?.nome_do_contato
                      ? acaoSuspensao.empresa.nome_do_contato
                      : 'Não informado'}
                  </p>
                </div>
                <div style={{ display: 'flex' }}>
                  <h1 style={{ fontSize: '16px' }}>Celular: </h1>
                  <p style={{ marginLeft: '2%' }}>
                    {acaoSuspensao.empresa?.telefone_do_contato
                      ? acaoSuspensao.empresa.telefone_do_contato
                      : 'Não informado'}
                  </p>
                </div>
                <div style={{ display: 'flex' }}>
                  <h1 style={{ fontSize: '16px' }}>E-mail: </h1>
                  <p>
                    {acaoSuspensao.empresa?.email_do_contato
                      ? acaoSuspensao.empresa.email_do_contato
                      : 'Não informado'}
                  </p>
                </div>
              </S.ContactInfo>
            </>
          )}
        </Grid>
        <Grid item xs={12} sm={12}>
          {isProvider && acaoSuspensao?.empresa ? (
            <TextInput
              id="responsavel"
              label="Responsável"
              value={acaoSuspensao?.responsavel?.nome}
              onChange={e => {}}
              placeholder="Responsável não definido"
              disabled
              props={{
                readOnly: true,
              }}
              variant="filled"
            />
          ) : (
            <Select
              id="id_responsavel"
              label={`${
                !isSuspensao && acaoSuspensao?.status === 'LIBERADO'
                  ? ''
                  : 'Atribuir '
              }Responsável`}
              value={formData?.id_responsavel}
              disabled={acaoSuspensao?.status === 'LIBERADO'}
              onChange={e => handleChange('id_responsavel', e.target.value)}
              placeholder="Atribuir Responsável"
              data={responsibles}
              required={acaoSuspensao?.status !== 'LIBERADO'}
            />
          )}
        </Grid>

        <Grid item xs={12} sm={12}>
          <TextInput
            label="Observações"
            placeholder="Inserir observações"
            onChange={e => handleChange('observacao', e.target.value)}
            multiline
            rows={4}
            value={formData?.observacao}
            disabled={acaoSuspensao?.status === 'LIBERADO' || defaultDisable}
          />
        </Grid>

        {isSuspensao && acaoSuspensao?.status !== 'LIBERADO' && (
          <>
            <Grid item xs={12} sm={6}>
              <FormControl>
                <p
                  style={{
                    color: theme.palette.words.subtitle.natural,
                    font: 'normal medium 14px/20px Texta',
                  }}
                >
                  Motivo da liberação <span style={{ color: 'red' }}>*</span>
                </p>
                <RadioGroup
                  disabled={defaultDisable}
                  options={[
                    {
                      value: 'Treinamento já cumprido',
                      label: 'Treinamento já cumprido',
                    },
                    {
                      value: 'Suspensão já aplicada',
                      label: 'Suspensão já aplicada',
                    },
                    { value: 'Outro Motivo', label: 'Outro Motivo' },
                  ]}
                  value={formData.tipo_motivo ?? ''}
                  onChange={e => {
                    handleChange('tipo_motivo', e.target.value);
                    if (e.target.value === 'Outro Motivo')
                      handleChange('motivo_liberacao', '');
                    else handleChange('motivo_liberacao', e.target.value);
                  }}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6} display="flex" alignItems="end">
              <div style={{ width: '100%' }}>
                <TextInput
                  disabled={
                    formData.tipo_motivo != 'Outro Motivo' || defaultDisable
                  }
                  label=""
                  placeholder="Insira o motivo"
                  value={formData.motivo_liberacao ?? ''}
                  onChange={e =>
                    handleChange('motivo_liberacao', e.target.value)
                  }
                  id="motivo_liberacao"
                  name="motivo_liberacao"
                />
              </div>
            </Grid>

            <Grid item xs={12} sm={12}>
              <InputAttachedFile
                idInput="input_file_detalhe"
                label="Arquivo Justificativa"
                inputLabel="Anexar Evidência"
                fileUrl={formData?.link_anexo}
                setFileUrl={value => handleChange('link_anexo', value)}
                fileDir={`/acoes/${acaoSuspensao?.id}`}
                disabled={defaultDisable}
              />
            </Grid>
          </>
        )}
        {(!isSuspensao || acaoSuspensao?.status === 'LIBERADO') && (
          <Grid item xs={12} sm={12}>
            <InputAttachedFile
              disabled={acaoSuspensao?.status === 'LIBERADO' || defaultDisable}
              idInput="input_file_detalhe"
              label="Anexo"
              inputLabel="Anexar Evidência"
              fileUrl={formData?.link_anexo}
              setFileUrl={value => handleChange('link_anexo', value)}
              fileDir={`/acoes-suspensoes/${acaoSuspensao?.id}`}
            />
          </Grid>
        )}
      </Grid>
      {!!acaoSuspensao?.id && !defaultDisable && (
        <S.Footer>
          <GhostButton
            id="btnSalvar"
            size="medium"
            onClick={() => handleOpenModal('SALVAR')}
          >
            Salvar
          </GhostButton>

          {!['LIBERADO', 'APLICADO'].includes(acaoSuspensao?.status) && (
            <GhostButton
              id="btnFinalizar"
              onClick={() => handleOpenModal('FINALIZAR')}
              size="medium"
              style={{ marginLeft: '10px' }}
              customcolor={
                theme?.palette?.semantics?.feedback?.attention?.natural
              }
            >
              Finalizar
            </GhostButton>
          )}
        </S.Footer>
      )}
    </S.ColumnWrapper>
  );
};

export default Config;
