import React, {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { debounce } from 'lodash';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { Collapse } from '@mui/material';
import Loading from 'components/Loading';
import { Bulk } from 'components/BulkActions';
import Bar from 'components/CriticalityBar';
import { Input } from 'components/Inputs/SearchInput';
import { ExpandLess, ExpandMore } from '@mui/icons-material';

import { usePlans } from 'hooks/usePlans';

import { requestDesviosMotoristas } from '../services';
import { List } from './components';
import * as S from './styled';
import Empty from 'components/Empty';

export const ListaAgrupada = forwardRef(
  (
    {
      setTableData,
      selectedInfractions,
      setSelectedInfractions,
      loadingLines,
      setLoadingLines,
      setIsFetching,
      setLastUpdated,
      getActions,
      getBulkActions,
      handleOpenNewTab,
    },
    ref,
  ) => {
    const { isProvider, hasTorrePlus, hasTorre } = usePlans();

    const filtersPersist = useSelector(state => {
      return state?.filter?.filters?.desvios;
    });

    const listaRef = useRef(null);
    const scrollRef = useRef(null);
    const filterDesvios = useSelector(state => state.filterDesvios);
    const [data, setData] = useState([]);
    const [total, setTotal] = useState(0);
    const [pageIndex, setPageIndex] = useState(0);
    const [query, setQuery] = useState(null);
    const [search, setSearch] = useState('');
    const [open, setOpen] = useState('');

    const { refetch, isFetching } = useQuery(
      ['desvios-motoristas', query],
      () => query && requestDesviosMotoristas(query),
      {
        refetchOnWindowFocus: false,
        onSuccess: resData => {
          if (resData?.data?.success) {
            setTotal(resData?.data.total);
            if (pageIndex === 0) setData(resData?.data.data);
            else setData(prevState => [...prevState, ...resData.data.data]);
          } else {
            setData([]);
            setTotal(0);
          }
        },
        onSettled: () => {
          const currentTime = new Date();
          setLastUpdated(currentTime);
        },
      },
    );

    useImperativeHandle(ref, () => ({
      refetchGeral: () => {
        setData([]);
        if (pageIndex === 0) refetch();
        else setPageIndex(0);
      },
      refetchLista: () =>
        !!listaRef?.current && listaRef.current.refetchLista(),
      refetchCount: () =>
        !!listaRef?.current && listaRef.current.refetchCount(),
    }));

    const initial = useRef(null);
    const prevIndex = useRef(pageIndex);
    useEffect(() => {
      if (initial.current) {
        initial.current = false;
        return;
      }

      if (pageIndex > 0 && pageIndex === prevIndex.current) {
        setPageIndex(0);
        setData([]);
        return;
      }

      setQuery({
        ...filtersPersist,
        pageIndex,
        pageSize: 20,
        status: filterDesvios.status,
        search,
      });
      prevIndex.current = pageIndex;
    }, [filtersPersist, filterDesvios.status, search, pageIndex]);

    const handleScroll = useCallback(
      debounce(() => {
        const div = scrollRef.current;
        if (
          div.scrollTop + div.clientHeight >= div.scrollHeight - 1 &&
          !isFetching
        ) {
          if (data.length < total) setPageIndex(prev => prev + 1);
        }
      }, 300),
      [isFetching],
    );

    return (
      <div>
        <S.Header>
          <Input
            local={false}
            loading={isFetching}
            placeholder="Buscar motorista"
            transparent
            value={search}
            handleChange={value => setSearch(value)}
          />
          <Bulk selected={selectedInfractions} options={getBulkActions()} />
        </S.Header>
        <S.List ref={scrollRef} onScroll={handleScroll}>
          {!isFetching && data.length === 0 ? (
            <Empty
              title="Ops! Você não tem nenhum desvio disponível."
              subtitle="Verifique os filtros aplicados!"
              image="frota.png"
              height="300px"
            />
          ) : (
            data.map(item => {
              const id = item.id_motorista || item.id_caminhao;
              const isOpen = open === id;
              return (
                <S.ListItem key={id}>
                  <S.ListItemButton
                    onClick={() => {
                      if (isOpen) {
                        setOpen('');
                        return;
                      }
                      setOpen(id);
                    }}
                  >
                    <S.Content>
                      <span className={`bold ${!item.nome && 'light'}`}>
                        {!isProvider || hasTorre || hasTorrePlus
                          ? item.nome ?? 'Motorista não identificado'
                          : item.matricula}
                      </span>
                    </S.Content>
                    <S.Content>
                      <span className="light">Placa: {item.placa}</span>
                    </S.Content>
                    <S.Content>
                      <span className="light">Pontos: {item.total_pontos}</span>
                    </S.Content>
                    <S.Content>
                      <span>
                        <span className="bold">{item.total_desvios}</span>{' '}
                        desvios cometidos
                      </span>
                      <Bar
                        tooltip
                        quantities={{
                          qtde_GRAVE: item?.total_grave || 0,
                          qtde_GRAVISSIMA: item?.total_gravissima || 0,
                          qtde_MODERADA: item?.total_moderada || 0,
                          qtde_PRIMARIA: item?.total_primaria || 0,
                        }}
                      />
                    </S.Content>
                    {isOpen ? <ExpandLess /> : <ExpandMore />}
                  </S.ListItemButton>
                  <Collapse in={isOpen} timeout="auto" unmountOnExit>
                    <div>
                      <List
                        ref={listaRef}
                        idMotorista={item.id_motorista}
                        idCaminhao={item.id_caminhao}
                        setSelectedInfractions={setSelectedInfractions}
                        loadingLines={loadingLines}
                        setLoadingLines={setLoadingLines}
                        setIsFetching={setIsFetching}
                        setTableData={setTableData}
                        getActions={getActions}
                        handleOpenNewTab={handleOpenNewTab}
                      />
                    </div>
                  </Collapse>
                </S.ListItem>
              );
            })
          )}
          {isFetching ? (
            <S.ListItemLoad>
              <Loading />
            </S.ListItemLoad>
          ) : (
            !!total && (
              <S.ListItemLoad>
                Mostrando {data.length} de {total} itens.
              </S.ListItemLoad>
            )
          )}
        </S.List>
      </div>
    );
  },
);
