// Styled
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeHeaderStatus } from 'store/modules/header/actions';
import { useLocation, useNavigate, useParams } from 'react-router-dom';

import {
  setItems,
  setGerais,
  setTitle,
  setId,
  setCapa,
  setStatus,
  setIsEditable,
} from 'store/modules/formularios/actions';

// Components
import Loading from 'components/Loading';
import Stepper from 'components/Stepper';
import GhostButton from 'components/Buttons/Ghost';
import DefaultButton from 'components/Buttons/Default';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DesktopWindowsOutlinedIcon from '@mui/icons-material/DesktopWindowsOutlined';

// Components MUI
import { Popover } from '@mui/material';
import { SaveAlt } from '@mui/icons-material';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import { Warnning } from 'components/Warnning';

// Constants
import ExportToExcel from 'utils/exportToCvs';
import { useReactToPrint } from 'react-to-print';
import { steps } from './constants';
import { initializeForm } from '../constants';
import { formatStyleHeader } from '../actions';

// Services
import * as services from './services';
import { getTurmas } from './Publico/services';

// Utils
import ModalResponse from '../components/ModalResponse';
import { ModalExcel } from '../components/ModalExcel';
import { Sidebar } from '../components/Sidebar';
import FullModal from '../components/FullModal';
import { ExportFormPdf } from './Export';
import * as S from './styled';
import { addHours, format } from 'date-fns';

let timerRoute = null;

const TemplateConfigFormulario = () => {
  const theme = useTheme();
  const printRefPdf = useRef();

  // Navigate
  const navigate = useNavigate();
  const location = useLocation();

  // Params
  const params = useParams();
  const formularios = useSelector(state => state.formularios);

  // Dispatch
  const dispatch = useDispatch();

  // Controller Page (states)
  const [turmas, setTurmas] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [trySave, setTrySave] = useState(false);
  const [changed, setChanged] = useState(false);
  const [formPrev, setFormPrev] = useState(null);
  const [disabled, setDisabled] = useState(false);
  const [finalMode, setFinalMode] = useState(null);
  const [isPublished, setIsPublished] = useState(false);
  const [currentStep, setCurrentStep] = useState(steps[0]);
  const [openModalAddAluno, setOpenModalAddAluno] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [exportablePeriod, setExportablePeriod] = useState('');
  const [typeExport, setTypeExport] = useState(null);
  const [warningView, setWarningView] = useState(!formularios.isEditable);

  // Modals
  const [fullModal, setFullModal] = useState(false);
  const [modalResponse, setModalResponse] = useState(false);
  const [modalExcel, setModalExcel] = useState(false);

  // Controll back arrow in window when modal is opened in same tab (BETA VERSION)
  useEffect(() => {
    timerRoute = setTimeout(() => {
      if (!fullModal && !modalResponse) {
        window.onpopstate = event => {
          event.preventDefault();
        };
      }
    }, 100);

    return function cleanup() {
      clearTimeout(timerRoute);
    };
  }, [fullModal, modalResponse]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const saveStep = searchParams.get('step');
    if (saveStep) setCurrentStep(steps[saveStep]);
  }, [location]);

  // ------------------------CHARGE CURRENT FORM------------------------------------//
  const {
    data: res,
    isError,
    isLoading: loadingQuery,
    refetch,
  } = useQuery([`form${params.id}`], () => services.getFomrItem(params.id), {
    refetchOnWindowFocus: false,
  });

  //  TODO: Requisicao para pegar turmas separada do formularios
  //  Isso pode ser simplificado fazendo uma unica request para
  //  todos os dados
  const fetchTurma = async () => {
    await getTurmas().then(res => {
      const result = res.data.data.filter(item => {
        if (item.ativa === true && item.n_alunos !== 0) {
          return item;
        }
      });
      setTurmas(result);
    });
  };

  useEffect(() => {
    fetchTurma();
  }, []);

  useEffect(() => {
    // Initial states
    setLoading(true);
    window.scrollTo({ top: 0 });

    // Verify route
    const pathname = location.pathname.slice(26);
    const goEdit = pathname.includes('editar/');

    // Set initial verifications
    if (loadingQuery) return null;
    if (goEdit && res.data.form.status !== 'Rascunho') setIsEdit(true);
    if (
      res.data.form.status === 'Ativo' ||
      res.data.form.status === 'Finalizado'
    )
      setIsPublished(true);

    if (!isError) {
      // TODO: Unificar dispatch (que aberração eu escrevi aqui)
      dispatch(setId(res.data.form.id));
      dispatch(
        setItems(
          res.data.form.secoes.length > 0
            ? res.data.form.secoes
            : initializeForm,
        ),
      );
      dispatch(setGerais(res.data.form));
      dispatch(setStatus(res.data.form.status));
      dispatch(setTitle(res.data.form.titulo));
      dispatch(setCapa(res.data.form.capa));
      dispatch(setIsEditable(res.data.form.id_empresa !== 4110));
      dispatch(
        changeHeaderStatus(formatStyleHeader(res.data.form.status, theme)),
      );
      setLoading(false);
    } else {
      toast.error(res.message);
      setIsEdit(false);
    }
  }, [res]);

  // --------------------CONTROLLER PAGE (Functions)--------------------------------//
  const handleBackPage = () => {
    if (currentStep.step !== 1) {
      setCurrentStep(steps[currentStep.value - 1]);
      setChanged(false);
    } else {
      isEdit ? navigate('/formularios') : navigate('/formularios/criar');
    }
  };

  const handleNext = () => {
    if (currentStep.step < steps.length) {
      setCurrentStep(steps[currentStep.value + 1]);
      setChanged(false);
    }
  };

  const changePage = () => {
    const { Component } = currentStep;
    return (
      <Component
        trySave={trySave}
        disabled={disabled}
        setChanged={setChanged}
        setTrySave={setTrySave}
        setDisabled={setDisabled}
        setCurrentStep={setCurrentStep}
        loading={loading}
        setLoading={setLoading}
        isEdit={isEdit}
        tags={res.data.tags}
        finalMode={finalMode}
        refetch={refetch}
        isPublished={isPublished}
        formPrev={formPrev}
        setFormPrev={setFormPrev}
        setModalResponse={setModalResponse}
        turmas={turmas}
        fetchTurma={fetchTurma}
        openModalAddAluno={openModalAddAluno}
        setOpenModalAddAluno={setOpenModalAddAluno}
        // Selects for Reports
        selectsGeneral={selectsGeneral}
        selectsUsers={selectsUsers}
        currentGeneral={currentGeneral}
        setCurrentGeneral={setCurrentGeneral}
        currentUser={currentUser}
        setCurrentUser={setCurrentUser}
      />
    );
  };

  const handleSave = status => {
    // This state is used for start a request in sub components
    // They have a useEffect to verify true or false and dispatch request
    setFinalMode(status);
    setTrySave(true);
  };

  // -------------------------CONTROLLER FOOTER BUTTONS----------------------------//
  const saveButton = () => {
    let valid = false;
    if (changed) {
      valid = true;
    }

    if (valid) {
      return (
        <GhostButton
          onClick={() => handleSave()}
          loading={loading}
          disabled={!formularios.isEditable}
        >
          Salvar e Avançar
        </GhostButton>
      );
    }
  };

  const nextButton = () => {
    let valid = false;

    if (!changed && currentStep.step !== 4) {
      valid = true;
    }

    if (valid) {
      return (
        <GhostButton
          onClick={() => handleNext()}
          disabled={!formularios.isEditable}
        >
          Avançar
        </GhostButton>
      );
    }
  };

  // ---------------------------------------EXPORTAÇÕES--------------------------//
  const [loadingExport, setLoadingExport] = useState(false);
  const [dataExportDaTable, setDataExportDaTable] = useState([]);

  const exportPDF = async () => {
    const start = exportablePeriod?.[0] || currentUser?.[0];
    const end = exportablePeriod?.[1] || currentUser?.[1];
    setLoadingExport(true);
    const res = await services.exportPDF(formularios.id, {
      start,
      end,
    });

    setDataExportDaTable(res.data.data);
    setLoadingExport(false);
    if (res.data.success) {
      handlePrint();
    }
  };

  const [loadingPdf, setLoadingPdf] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => printRefPdf.current,
    onBeforeGetContent: () => setLoadingExport(true),
    onAfterPrint: () => {
      setLoadingExport(false);
    },
    copyStyles: true,
    documentTitle: `formulario_id_${formularios?.id}`,
  });

  const exportExcel = async () => {
    const start = exportablePeriod[0] || currentUser[0];
    const end = exportablePeriod[1] || currentUser[1];

    setLoadingExport(true);
    const res = await services.exportExcel({
      id: formularios.id,
      start,
      end,
    });
    if (res.data.success) {
      toast.success(res.data.message);
      ExportToExcel({
        excel: res.data.data.excel,
        name: `formulario_${formularios.id}_${format(
          new Date(start),
          'dd-MM-yyyy',
        )}`,
      });
      setModalExcel(false);
      setExportablePeriod('');
    } else {
      toast.error(res.data.message);
    }
    setLoadingExport(false);
  };

  const handleVerifyExport = type => {
    // Verificaçoes se é um formulario com repetições
    setTypeExport(type);
    const hasRepet = formularios.gerais.repetir;

    if (hasRepet) {
      setModalExcel(true);
    } else {
      type === 'EXCEL' ? exportExcel() : exportPDF();
    }
  };

  // --------------------------------SELECTS REPORTS ---------------------------//
  // Selectes States
  const [selectsGeneral, setSelectsGeneral] = useState([]);
  const [currentGeneral, setCurrentGeneral] = useState(0);
  const [selectsUsers, setSelectsUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState('');

  useEffect(() => {
    if (formularios?.id && isEdit) {
      const catchPeriods = async () => {
        const res = await services.getPeriods(formularios.id);
        if (res.success) {
          updatePeriods(res);
        } else {
          toast.error(res.message);
        }
      };

      catchPeriods();
    }
  }, [formularios?.id, isEdit]);

  const updatePeriods = data => {
    // User

    const fuse = new Date().getTimezoneOffset() / 60;

    const periods = data.data.map(i => [
      addHours(new Date(i[0]), fuse),
      addHours(new Date(i[1]), fuse),
    ]);

    setSelectsUsers(periods);
    setCurrentUser(periods[periods.length - 1]);
  };
  // --------------------------------RENDER-------------------------------------------//

  return (
    <S.Container>
      {!loading && (
        <>
          {isEdit && (
            <Sidebar
              itens={steps}
              page={currentStep.step - 1 || 0}
              setPage={setCurrentStep}
              setChanged={setChanged}
            />
          )}
          <S.TemplatePage className={isEdit ? 'isEditTemplate' : ''}>
            {!isEdit && (
              <Stepper
                steps={steps.slice(0, -1)}
                currentStep={currentStep.step}
              />
            )}

            <S.HeaderPage className={isEdit ? 'isEditHeader' : ''}>
              <div className="titlePage">
                {!isEdit ? currentStep.pageTitle : currentStep.page}
              </div>
              <div className="headerButtons">
                {currentStep.step === 4 && (
                  <S.ButtonPrev onClick={() => setModalResponse(true)}>
                    <span>Ver prévia desktop</span>
                    <DesktopWindowsOutlinedIcon />
                  </S.ButtonPrev>
                )}

                {currentStep.step === 2 && (
                  <DefaultButton
                    disabled={!formularios.isEditable}
                    children="Adicionar Turma"
                    icon={<SettingsOutlinedIcon />}
                    startIcon={<AddCircleOutlineIcon />}
                    style={{ marginRight: '10px' }}
                    onClick={() => setOpenModalAddAluno(true)}
                  />
                )}

                {isEdit && (
                  <GhostButton
                    disabled={!formularios.isEditable}
                    children="Editar Campos"
                    icon={<SettingsOutlinedIcon />}
                    onClick={() => setFullModal(true)}
                  />
                )}

                {isEdit && (
                  <>
                    <GhostButton
                      children="Exportar"
                      icon={<SaveAlt />}
                      onClick={event => setAnchorEl(event.currentTarget)}
                      style={{ marginLeft: '10px' }}
                    />

                    <Popover
                      sx={{ transform: 'translate(0px, 2px)' }}
                      id={anchorEl ? 'popover' : undefined}
                      open={!!anchorEl}
                      anchorEl={anchorEl}
                      onClose={() => setAnchorEl(null)}
                      onClick={() => setAnchorEl(null)}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <S.Popup>
                        <button onClick={() => handleVerifyExport('EXCEL')}>
                          Excel
                        </button>
                        <button onClick={() => handleVerifyExport('PDF')}>
                          PDF
                        </button>
                      </S.Popup>
                    </Popover>
                  </>
                )}
              </div>
            </S.HeaderPage>

            <div style={{ marginBottom: '60px' }}>
              {!loading && changePage()}
            </div>

            {currentStep.step !== 5 && (
              <S.ControllerArea>
                <div className="statusArea">
                  {changed && 'Alterações não salvas'}
                  {!changed && 'Informações Atualizadas'}
                </div>

                <div className="buttonsArea">
                  <GhostButton
                    className="backArea"
                    customcolor={
                      theme?.palette?.semantics?.feedback?.attention?.natural
                    }
                    onClick={() => handleBackPage()}
                    disabled={!formularios.isEditable}
                  >
                    Voltar
                  </GhostButton>

                  {saveButton()}
                  {nextButton()}

                  {currentStep.step == 4 && !isEdit && (
                    <>
                      <DefaultButton onClick={() => handleSave('draft')}>
                        Salvar como rascunho
                      </DefaultButton>
                      <DefaultButton
                        onClick={() => handleSave('public')}
                        style={{ marginLeft: '10px' }}
                      >
                        Publicar
                      </DefaultButton>
                    </>
                  )}
                </div>
              </S.ControllerArea>
            )}
          </S.TemplatePage>

          {fullModal && (
            <FullModal
              open={fullModal}
              onClose={fecthAgain => {
                setFullModal(false);
                if (fecthAgain) {
                  setFormPrev(null);
                  refetch();
                }
              }}
              pathname={isEdit}
              backPath={`/formularios/configuracao/editar/${formularios.id}`}
            />
          )}

          {formPrev && modalResponse && (
            <ModalResponse
              open={modalResponse}
              onClose={() => setModalResponse(false)}
              previewWeb
              form={formPrev}
              backPath={`/formularios/configuracao/editar/${formularios.id}`}
            />
          )}
        </>
      )}

      {loading && (
        <S.LoadingBox>
          <Loading />
        </S.LoadingBox>
      )}

      <ModalExcel
        open={modalExcel}
        onClose={() => setModalExcel(false)}
        select={selectsUsers}
        defaultOption={currentUser}
        exportablePeriod={exportablePeriod}
        setExportablePeriod={setExportablePeriod}
        typeExport={typeExport}
        exportPDF={exportPDF}
        exportExcel={exportExcel}
        loading={loadingExport}
      />

      <ExportFormPdf
        form={formularios}
        data={dataExportDaTable}
        printRef={printRefPdf}
        period={exportablePeriod || currentUser}
      />
      <Warnning
        view={!formularios.isEditable}
        backgorund={theme.palette.semantics.feedback.warning.natural}
        color={theme.palette.words.text.contrast}
      >
        <div className="warning">
          Você não possui permissão para editar esse conteúdo.
        </div>
        <span className="close" onClick={() => setWarningView(false)}>
          Fechar
        </span>
      </Warnning>
    </S.Container>
  );
};

export default TemplateConfigFormulario;
