// Styleds

// React
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';

// Components
import BarGraph from 'components/Graphs/BarGraph';
import PieGraph from 'components/Graphs/PieGraph';
import LineGraph from 'components/Graphs/LineGraph';
import EmptyDataCard from 'components/Cards/EmptyDataCard';

// Components Icons
import { ReactComponent as ArrowLeft } from 'images/icons/arrows/left-double.svg';
import { ReactComponent as ArrowRight } from 'images/icons/arrows/right-double.svg';

// Services
import { formatNewDate } from 'utils/dates';
import * as services from '../services';

// Actions
import { fequencyModule } from '../actions';

// Utils
import * as S from './styled';
import { addHours, format } from 'date-fns';

let timer = null; // Timer period

export const General = ({ periods, currentTime, setCurrentTime }) => {
  // Redux
  const formularios = useSelector(state => state.formularios);
  const gerais = formularios.gerais;

  // General States
  const [historico, setHistorico] = useState([]);
  const [percentualEsperado, setPercentualEsperado] = useState([]);
  const [newYAxis, setNewYAxis] = useState(false);
  const [aderencia, setAderencia] = useState([]);
  const [ocorrencias, setOcorrencias] = useState([]);
  const [firstFetch, setFirstFetch] = useState(true);
  const [index, setIndex] = useState(0);

  // ---------------------------------------REQUESTS---------------------------------------//

  // -------------- Infos Graphs
  const {
    data,
    refetch,
    isFetching: loading,
  } = useQuery(
    [`/form-reports-${formularios.id}`, index],
    () => services.getGraphs(formularios.id, { index }),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => updateGraphs(data),
      onError: data => toast.error(data.message),
    },
  );

  const updateGraphs = res => {
    const periodo = gerais.periodo;
    const repetir = gerais.repetir;
    const handleLabel = label => {
      if (periodo === 'MES' && repetir) {
        return format(new Date(label), 'MM/yyyy');
      }

      return format(addHours(new Date(label), 3), 'dd/MM');
    };

    let data = res.data;

    // Historico
    let formatedHistory = [];
    let formatedHistory2 = [];
    let histX = data.historico.x;
    let histY = data.historico.y;
    for (let i in histX) {
      formatedHistory.push({
        x: handleLabel(histX[i]),
        Finalizações: histY[i],
        'Esperado (%)': data?.historico?.parametroPreenchimento || 0,
      });
    }
    setHistorico(formatedHistory);
    setPercentualEsperado(formatedHistory2);

    // Ocorrencias
    let formatedOcurrences = [];
    let occrX = data.ocorrencias.x;
    let occrY = data.ocorrencias.y;
    for (let i in occrX) {
      formatedOcurrences.push({
        name: handleLabel(occrX[i]),
        value: occrY[i],
      });
    }
    setOcorrencias(formatedOcurrences);

    // Aderencia
    let formatedAderencia = [
      {
        name: 'Concluidos',
        value: data.aderencia.concluidos > 0 ? data.aderencia.concluidos : 0,
      },
      {
        name: 'Pendentes de aprovação',
        value:
          data.aderencia.pendentesAssinaturas > 0
            ? data.aderencia.pendentesAssinaturas
            : 0,
      },
      {
        name: 'Iniciados',
        value: data.aderencia.iniciados > 0 ? data.aderencia.iniciados : 0,
      },
      {
        name: 'Justificados',
        value:
          data.aderencia.justificados > 0 ? data.aderencia.justificados : 0,
      },
    ];

    if (formularios?.multiplo) {
      setAderencia(formatedAderencia);
    } else {
      setAderencia([
        ...formatedAderencia,
        {
          name: 'Pendentes',
          value:
            data.aderencia.pendentesIniciar > 0
              ? data.aderencia.pendentesIniciar
              : 0,
        },
      ]);
    }

    setFirstFetch(false);
  };

  useEffect(() => {
    if (!firstFetch) {
      clearTimeout(timer);
      timer = setTimeout(() => {
        refetch();
      }, 300);
    }
  }, [currentTime]);

  // ---------------------------------------PERIODS CONTROLLER---------------------------------------//
  const total = data?.data?.index;
  const current = data?.data?.current;

  const nextPeriod = () => {
    if (current > 0) {
      setIndex(current - 1);
    }
  };

  const backPeriod = () => {
    if (current < total) {
      setIndex(current + 1);
    }
  };

  // --------------------------------------- RENDER ---------------------------------------//
  return (
    <>
      <S.InfoLine first={total === current} last={!current}>
        <div className="period">{fequencyModule(gerais)}</div>

        <div className="filters">
          {gerais?.repetir && (
            <div className="arrow back" onClick={() => backPeriod()}>
              <ArrowLeft />
            </div>
          )}
          <div className="textMedium">
            {loading && <S.Skeleton />}
            {!loading && (
              <>
                {format(
                  addHours(new Date(data?.data?.periodo[0]), 3),
                  'dd/MM/yy',
                )}{' '}
                -{' '}
                {format(
                  addHours(new Date(data?.data?.periodo[1]), 3),
                  'dd/MM/yy',
                )}
              </>
            )}
          </div>
          {gerais?.repetir && (
            <div className="arrow next" onClick={() => nextPeriod()}>
              <ArrowRight />
            </div>
          )}
        </div>
      </S.InfoLine>
      <S.Container>
        <S.TotalBox>
          {(loading || historico?.length > 0) && (
            <LineGraph
              data={historico}
              colors="critical"
              title="Histórico de Preenchimento"
              series={['Finalizações']}
              tooltip
              loading={loading}
              newYAxisMax={+data?.historico?.preenchimentoEsperado}
              doubleYAxis
              series2={['Esperado (%)']}
            />
          )}
          {historico?.length <= 0 && !loading && (
            <EmptyDataCard
              image="frota.png"
              title="Formulário não iniciado!"
              subtitle={`Retorne a partir do dia ${formatNewDate(
                gerais.data_inicio,
              )} e veja o histórico de preenchimento`}
            />
          )}
        </S.TotalBox>

        <S.MediumBox className="bar">
          <BarGraph
            data={ocorrencias}
            colors="default"
            title="Quantidade de Ocorrências"
            tooltip
            loading={loading}
          />
          <PieGraph
            data={aderencia}
            colors="critical-first"
            title={`Aderência do Conteúdo ${
              gerais.repetir ? '(Última repetição)' : ''
            }`}
            tooltip
            legend
            loading={loading}
          />
        </S.MediumBox>
      </S.Container>
    </>
  );
};
