import styled, { css } from 'styled-components';

export const Flex = styled.div`
  display: flex;
  gap: 1rem;
`;

export const Total = styled.div`
  white-space: nowrap;
  border: 1px solid #939aab4d;
  padding: 0 1rem;
  align-content: center;
  border-radius: 4px;
  font: normal normal medium 14px/16px Texta;
  color: #4b5166;

  span {
    font: normal normal 900 20px/18px Texta;
  }
`;

export const TableContainer = styled.div`
  display: flex;
  gap: 1rem;

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

export const Count = styled.p`
  ${({ theme, ...props }) => css`
    font: normal normal 900 12px/16px Texta;
    color: ${theme.palette.words.text.contrast};
    display: ${props.count ? 'block' : 'none'};
    background: ${theme.palette.brand.primary.natural};
    width: 16px;
    border-radius: 8px;
    position: absolute;
    top: -3px;
    right: -3px;
  `}
`;
