import React, { useState } from 'react';
import Modal from '@mui/material/Modal';
import FormControlLabel from '@mui/material/FormControlLabel';

import { Divider, Radio, RadioGroup, SvgIcon } from '@mui/material';
import Icon from 'components/Icons';
import FiltersGlobal from 'components/FiltersGlobal';
import { CustomizedSelect } from 'components/SectionPeriod';
import { ReactComponent as filterIco } from 'images/icons/filter.svg';

import GhostButton from 'components/Buttons/Ghost';
import { useTheme } from 'styled-components';
import * as S from './styled';

const ExportModal = ({
  open, // Modal aberto ou fechado
  title = 'Exportar', // Titulo modal
  subtitle = null, // Subtítulo modal (opcional)
  handleClose, // Funcão executada ao clicar no cancelar ou fechar
  defaultExport = 'EXCEL', // Seleção padrao (EXCEL || PDF)
  handleClick,
  radioItems = [], // Array de opções com {label: '', key: ''}
  loading,
  date = false,
  setFilterAnchorEl,
  setFilters,
  period,
  setPeriod,
  filters,
  filterProps,
}) => {
  const theme = useTheme();
  const [exportType, setExportType] = useState(defaultExport);
  const [selectedRadio, setSelectedRadio] = useState('');

  const body = (
    <S.Paper>
      <S.Header>
        <S.Title>{title}</S.Title>
        <S.CloseButton
          startIcon={
            <Icon name="close" color={theme.palette.brand.primary.natural} />
          }
          onClick={handleClose}
          variant="inherit"
          style={{ backgroundColor: theme.palette.system.transparent }}
        />
      </S.Header>

      <S.Subtitle>{subtitle}</S.Subtitle>

      <S.Action>
        <S.Selector
          onClick={() => {
            setExportType('EXCEL');
            setSelectedRadio('');
          }}
          active={exportType === 'EXCEL'}
        >
          Excel
        </S.Selector>
        <S.Selector
          onClick={() => {
            setExportType('PDF');
            setSelectedRadio('');
          }}
          active={exportType === 'PDF'}
        >
          PDF
        </S.Selector>
      </S.Action>

      <Divider />
      {/*
      {filterProps ? (
        <S.FiltersContainer>
          <FiltersGlobal
            hideRefleshButton
            customComponent={<div className="span">Filtros:</div>}
            {...filterProps}
          />
        </S.FiltersContainer>
      ) : (
        <S.FiltersContainer>
          <div className="span">Filtros:</div>
          {date && (
            <CustomizedSelect
              today={filters.finalDate}
              selectedPeriod={period}
              handleChangeDate={value =>
                setFilters(state => ({ ...state, initialDate: value }))
              }
              handleChangePeriod={value => setPeriod(value)}
            />
          )}
          <S.StyledButton
            textcolor={theme.palette.words.text.natural}
            backgroundcolor="transparent"
            startIcon={<SvgIcon component={filterIco} />}
            height="45px"
            style={{ marginTop: '3px' }}
            onClick={event => setFilterAnchorEl(event.currentTarget)}
          >
            Filtros
          </S.StyledButton>
        </S.FiltersContainer>
      )} */}

      <S.Main>
        <S.RadioContainer>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={selectedRadio}
            onChange={(_, value) => setSelectedRadio(value)}
          >
            <FormControlLabel
              sx={{
                color: theme.palette.words.title.natural,
              }}
              value=""
              control={<Radio />}
              label="Tudo"
            />
            {radioItems
              .filter(item => !item.export || item.export.includes(exportType))
              .map(item => (
                <FormControlLabel
                  sx={{
                    color: theme.palette.words.title.natural,
                  }}
                  key={item.value}
                  value={item.value}
                  control={<Radio />}
                  label={item.label}
                />
              ))}
          </RadioGroup>
        </S.RadioContainer>
      </S.Main>

      <S.Footer>
        <GhostButton
          disabled={loading}
          size="medium"
          icon={null}
          customcolor={theme?.palette?.semantics?.feedback?.attention?.natural}
          onClick={handleClose}
        >
          Cancelar
        </GhostButton>

        <GhostButton
          loading={loading}
          size="medium"
          sx={{ marginLeft: '10px' }}
          onClick={() => handleClick(exportType, selectedRadio, filters)}
        >
          Baixar
        </GhostButton>
      </S.Footer>
    </S.Paper>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      {body}
    </Modal>
  );
};

export default ExportModal;
