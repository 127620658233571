export const setHeader = (status, theme) => {
  switch (status) {
    case 'BLOQUEADO':
      return {
        status,
        color: theme.palette.semantics.feedback.attention.natural,
        background: theme.palette.semantics.feedback.attention.light,
      };
    case 'RESTRITO':
      return {
        status,
        color: theme.palette.semantics.feedback.warning.natural,
        background: theme.palette.semantics.feedback.warning.light,
      };
    case 'RASCUNHO':
      return {
        status,
        color: theme.palette.semantics.feedback.warning.natural,
        background: theme.palette.semantics.feedback.warning.light,
      };

    case 'LIBERADO':
      return {
        status,
        color: theme.palette.semantics.feedback.success.natural,
        background: theme.palette.semantics.feedback.success.light,
      };
    default:
      return {};
  }
};
