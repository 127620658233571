export const generateFileName = (pageName, filtersPersist) => {
  const formatDate = dateString => {
    const date = new Date(dateString);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();
    return `${day}-${month}-${year}`;
  };

  const today = new Date();
  const todayFormatted = formatDate(today);

  const initialDateFormatted = filtersPersist?.initialDate
    ? formatDate(filtersPersist.initialDate)
    : todayFormatted;

  const finalDateFormatted = filtersPersist?.finalDate
    ? formatDate(filtersPersist.finalDate)
    : todayFormatted;

  const fileName =
    initialDateFormatted === finalDateFormatted
      ? `${pageName}_${initialDateFormatted}`
      : `${pageName}_${initialDateFormatted}-${finalDateFormatted}`;

  return fileName;
};
