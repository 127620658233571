// React
import { useQuery } from 'react-query';
import React, { useState, useEffect } from 'react';

// components pagina

import { FatoresRisco } from '../Rastreamento/FatoresRisco';
import { RodoviasPerigosas } from './RodoviasPerigosas';
import DesviosAbertos from '../MinhasTarefas/DesviosAbertos';
import { Atalhos } from './Atalhos';

// Styles
import * as S from './styled';

// services
import { getRastreamento } from '../Rastreamento/services';
import { alertaDesvios } from '../MinhasTarefas/services';
import { formatNewTime } from 'utils/dates';

const HomeSimples = () => {
  const [infractions, setInfractions] = useState([]);
  const [updatedAt, setUpdatedAt] = useState(new Date());
  const [count, setCount] = useState(0);
  const [activeTab, setActiveTab] = useState(0);

  const { isLoading, data } = useQuery(
    ['home-rastreamento'],
    () => getRastreamento(),
    {
      refetchInterval: 60000,
      onSuccess: () => {
        setUpdatedAt(new Date());
      },
    },
  );

  const { isLoadingTarefas, data: tarefas } = useQuery(
    ['home-tarefas'],
    () => alertaDesvios(),
    {
      refetchInterval: 60000,
    },
  );

  useEffect(() => {
    if (data?.data?.length) {
      const _infractions = data.data.reduce((acc, item) => {
        return [...acc, ...item.desvios];
      }, []);
      setInfractions(_infractions);
    }
  }, [data]);

  return (
    <S.Container>
      <div className="flex">
        <div className="card-wrapper">
          <div className="title">
            Você tem {count} tratativas{' '}
            <span>{activeTab === 0 ? 'a vencer' : 'vencidas'}</span>
          </div>
          <div className="card">
            <DesviosAbertos
              data={tarefas?.data ?? []}
              handleCountTitle={(_count, _activeTab) => {
                setCount(_count);
                setActiveTab(_activeTab);
              }}
              hideTitle
            />
          </div>
        </div>
        <div className="card-wrapper">
          <div className="title">Deslocamentos acontecendo agora</div>
          <div className="card">
            <RodoviasPerigosas data={infractions} isLoading={isLoading} />
          </div>
        </div>
        <div className="card-wrapper">
          <div className="subtitle">
            Atualizado às {formatNewTime(updatedAt)}
          </div>
          <div className="card">
            <FatoresRisco data={infractions} isLoading={isLoading} />
          </div>
        </div>
      </div>
      <br />
      <div>
        <h1>Do que você precisa hoje?</h1>
        <div className="flex">
          <Atalhos />
        </div>
      </div>
    </S.Container>
  );
};

export default HomeSimples;
