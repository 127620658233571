import React, { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';

import { Icon, IconButton, Modal, Grid } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import InputMask from 'react-input-mask';
import { toast } from 'react-toastify';

import Input from 'components/Inputs/TextField';
import GhostButton from 'components/Buttons/Ghost';
import ConfirmModal from 'components/ConfirmModal';

import * as S from './styled';
import CloseIcon from '@mui/icons-material/Close';

// Service
import { ReactComponent as InfoIcon } from 'images/icons/configuracoes/infoIcon.svg';
import { updateEmpresa } from '../services';

const ModalEditar = ({
  open,
  dataEdit,
  handleClose = () => {},
  fetch = () => {},
}) => {
  const theme = useTheme();
  const [openConfirmSave, setOpenConfirmSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openMsg, setOpenMsg] = useState(true);

  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [nome, setNome] = useState('');

  const handleApply = async () => {
    if (!email || !telefone || !nome) {
      toast.error('Preencha todos os campos');
      return;
    }

    const dataObj = {
      contato_email: email,
      contato_telefone: telefone,
      contato_nome: nome,
    };
    const res = await updateEmpresa(dataEdit.id, dataObj);
    if (res.success) {
      toast.success('Empresa atualizada com sucesso');
    }
  };

  useEffect(() => {
    if (dataEdit) {
      setEmail(dataEdit?.empresa_distribuidoras[0]?.contato_email || '');
      setTelefone(dataEdit?.empresa_distribuidoras[0]?.contato_telefone || '');
      setNome(dataEdit?.nome || '');
    }
  }, [dataEdit]);

  return (
    <>
      <Modal open={open} onClose={handleClose} disableEscapeKeyDown={false}>
        <S.Container>
          <S.Header>
            <div style={{ alignItems: 'center' }}>
              <>
                <EditOutlined
                  sx={{ color: theme.palette.brand.secondary.natural }}
                  fontSize="medium"
                />
                <h2>Editar Empresa</h2>
              </>
            </div>

            <IconButton size="small" onClick={handleClose}>
              <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
                close
              </Icon>
            </IconButton>
          </S.Header>

          <S.Main>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} marginBottom={3} marginTop={3}>
                <S.Title>{dataEdit?.nome || '-'}</S.Title>
              </Grid>
              <Grid item xs={12} sm={12}>
                <S.Section>INFORMAÇÕES DE CONTATO</S.Section>
              </Grid>
              <Grid item xs={12} sm={12}>
                <S.Alert show={openMsg}>
                  <S.AlertText>
                    <div>
                      <InfoIcon />
                    </div>
                    <p>
                      Insira um contato da transportadora que auxilie na
                      tratativa dos desvios diretamente com os motoristas.
                    </p>
                  </S.AlertText>
                  <IconButton size="small" onClick={() => setOpenMsg(false)}>
                    <CloseIcon
                      sx={{
                        color: theme.palette.words.subtitle.natural,
                        height: 20,
                        width: 20,
                      }}
                    />
                  </IconButton>
                </S.Alert>
              </Grid>
              <Grid item xs={12} sm={12}>
                <Input
                  required
                  value={nome}
                  onChange={e => setNome(e.target.value)}
                  onch
                  label="Nome"
                  placeholder="Nome"
                  name="nome"
                  id="nome"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Input
                  required
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  label="E-mail"
                  placeholder="email"
                  name="email"
                  id="email"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <InputMask
                  mask="(99) 9 9999-9999"
                  value={telefone}
                  onChange={e => setTelefone(e.target.value)}
                >
                  {fieldProps => (
                    <Input
                      {...fieldProps}
                      required
                      label="Telefone"
                      placeholder="Telefone"
                      name="telefone"
                      id="telefone"
                    />
                  )}
                </InputMask>
              </Grid>
            </Grid>
          </S.Main>

          <S.Footer>
            <div />
            <div>
              <GhostButton
                customcolor={
                  theme?.palette?.semantics?.feedback?.attention?.natural
                }
                onClick={handleClose}
                size="medium"
              >
                Cancelar
              </GhostButton>

              <GhostButton
                onClick={handleApply}
                size="medium"
                sx={{ marginLeft: '20px' }}
              >
                Salvar
              </GhostButton>
            </div>
          </S.Footer>
        </S.Container>
      </Modal>

      {/* Confirmação de adição */}
      <ConfirmModal
        open={openConfirmSave}
        handleClose={() => setOpenConfirmSave(false)}
        title="Deseja adicionar motorista?"
        titleIcon={
          <Icon
            sx={{ color: theme.palette.brand.secondary.natural }}
            fontSize="medium"
          >
            add_circle_outline
          </Icon>
        }
        subtitle="Tem certeza que deseja criar um acesso para este motorista?"
        buttonText="Confirmar"
        onClick={() => handleAddMotorista(data)}
        loading={loading}
      />
    </>
  );
};

export default ModalEditar;
