import React, {
  Fragment,
  useCallback,
  useEffect,
  useState,
  useMemo,
} from 'react';
import { useForm, Controller, useFieldArray } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { useTheme } from 'styled-components';
import { toast } from 'react-toastify';

import {
  FormControl,
  FormLabel,
  Grid,
  IconButton,
  Tooltip,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/DeleteOutline';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import RemoveIcon from '@mui/icons-material/Remove';
import GhostButton from 'components/Buttons/Ghost';
import Select from 'components/Inputs/Select';
import TextInput from 'components/Inputs/TextField';
import RadioGroup from 'components/Inputs/RadioGroup';
import Loading from 'components/Loading';
import * as S from './styled';
import * as services from '../../services';
import Footer from 'components/ActionFooter';
import { Tag, TagContainer, TagLabel } from 'components/Tags';
import { GoogleMap } from 'components/GoogleMap';
import {
  MapInfoButton,
  MapInfoContent,
  MapInfoWrapper,
  RadiusControlActions,
  RadiusControlButton,
  RadiusControlTitle,
  RadiusControlWrapper,
} from 'components/GoogleMap/styled';
import PlacesAutocomplete from 'components/PlacesAutocomplete';
import { DashedButton } from 'components/Buttons/Dashed';

const lineSymbol = {
  path: 'M 0,-1 0,1',
  strokeOpacity: 1,
  scale: 4,
};

const EditCercaEletronica = ({ novo }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const params = useParams();
  const { user } = useSelector(state => state.auth);
  const isProvider = user?.user?.provider;

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: null,
      titulo: '',
      descricao: '',
      categoria: 'CERCA',
      pista_molhada: null,
      carregado: null,
      ativo: true,
      id_tipo_veiculo: null,
      id_distribuidora: null,
      violacoes: [
        {
          pontos: '',
          intervalo_tempo: '',
          limiar: '',
          plano_de_acao: '',
          id_tipo_padrao: 2,
          ativo: true,
        },
      ],
    },
  });

  const { fields, append, remove } = useFieldArray({
    name: 'violacoes',
    control,
  });

  const selects = useSelector(state => {
    return state.selects;
  });

  const { data: _tiposVeiculos = [] } = useQuery(
    ['tipos-veiculos-telemetria'],
    () => services.getTiposVeiculos(),
    { staleTime: Infinity },
  );

  const tiposVeiculos = [
    { value: null, name: 'Todos' },
    ..._tiposVeiculos.map(tipo => ({
      name: tipo.tipo,
      value: tipo.id,
    })),
  ];

  const [map, setMap] = useState(null);
  const [singlePoint, setSinglePoint] = useState(true);
  const [markers, setMarkers] = useState([]);
  const [markerRefs, setMarkerRefs] = useState([]);
  const [polyRef, setPolyRef] = useState(null);
  const [circleRef, setCircleRef] = useState(null);
  const [radius, setRadius] = useState(100);
  const [path, setPath] = useState([]);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [mapInfo, setMapInfo] = useState({
    open: true,
    content: 'Busque a localização e navegue no mapa aplicando o zoom',
  });

  const { data, error, isLoading, refetch } = useQuery(
    ['cerca-eletronica', params.id],
    () => services.getDesvio(params.id),
    {
      enabled: false,
      staleTime: Infinity,
    },
  );

  const clients = [
    { id: 0, value: null, name: isProvider ? '' : 'Todos' },
    ...selects.clients
      ?.filter(
        i => (data && data.id_distribuidora == i.id) || i.status !== 'ATIVO',
      ) // Não pode atribuir parametro para embarcadora ativa
      .map(i => ({ name: i.nome, value: i.id })),
  ] || [{ id: 0, value: null, name: 'Todos' }];

  useEffect(() => {
    if (!novo) refetch();
  }, [novo]);

  useEffect(async () => {
    if (data) {
      const _data = { ...data };
      delete _data.empresa;
      delete _data.distribuidora;
      delete _data.tipo_veiculo;
      reset(_data);
      if (data.cerca?.raio) {
        setRadius(data.cerca.raio);
        const position = {
          lat: parseFloat(data.cerca.latitude),
          lng: parseFloat(data.cerca.longitude),
        };
        const address = await getGeocodeLocation(position);
        setMarkers([
          {
            position,
            title: '',
            address,
          },
        ]);
        map?.setCenter(position);
      } else {
        const _markers = data.cerca?.rotas
          .filter(item => item.is_marcador)
          .sort((a, b) => a.pos - b.pos);
        const formattedMarkers = _markers.map((item, index) => ({
          position: {
            lat: parseFloat(item.latitude),
            lng: parseFloat(item.longitude),
          },
          title:
            index === 0
              ? 'Inicial'
              : index === _markers.length - 1
              ? 'Final'
              : '',
        }));
        formattedMarkers[0].address = await getGeocodeLocation(
          formattedMarkers[0].position,
        );
        formattedMarkers[formattedMarkers.length - 1].address =
          await getGeocodeLocation(
            formattedMarkers[formattedMarkers.length - 1].position,
          );

        setMarkers(formattedMarkers);
        map?.setCenter({
          lat: parseFloat(_markers[0]?.latitude),
          lng: parseFloat(_markers[0]?.longitude),
        });
      }
      setSinglePoint(!!data.cerca?.raio);
      map?.setZoom(13);
    }
  }, [map, data, error]);

  useEffect(() => {
    if (!map) return;
    const directionsService = new google.maps.DirectionsService();

    if (markers.length) {
      setMapInfo(state => ({
        ...state,
        content: singlePoint
          ? 'Defina o tamanho do raio'
          : markers.length > 1
          ? 'Configure os parâmetros nos campos abaixo do mapa'
          : 'Clique no próximo ponto para finalizar a cerca',
      }));
    }

    markerRefs.forEach(marker => {
      window.google.maps.event.removeListener(marker.dragListener);
      window.google.maps.event.removeListener(marker.clickListener);
      window.google.maps.event.removeListener(marker.infoListener);
      window.google.maps.event.removeListener(marker.removeListener);
      marker.ref.setMap(null);
    });
    circleRef?.setMap(null);

    markers.forEach(({ position, title }, index) => {
      // Add marker
      const icon =
        !title && !singlePoint
          ? {
              path: google.maps.SymbolPath.CIRCLE,
              strokeOpacity: 0.7,
              scale: 6,
            }
          : undefined;
      const markerRef = new window.google.maps.Marker({
        map,
        draggable: true,
        position,
        label: title,
        icon,
      });
      let markerInfowindow = new google.maps.InfoWindow({
        content: ' ',
      });

      let markerClickListener = null;
      let markerInfoListener = null;
      let markerRemoveListener = null;

      // Add marker click Listner
      markerClickListener = google.maps.event.addListener(
        markerRef,
        'click',
        function () {
          markerInfowindow.setContent(
            // eslint-disable-next-line react/no-this-in-sfc
            `<button style="border: none; background: inherit; padding: 4px; font: normal normal 14px/16px Texta;" id="btn-remover-${index}" value="${index}" type="button">DESFAZER PONTO</button>`,
          );
          markerInfowindow.open(map, this);
        },
        { once: true },
      );

      // Add infoWindow to marker
      markerInfoListener = google.maps.event.addListener(
        markerInfowindow,
        'domready',
        function () {
          const removeButton = document.getElementById(`btn-remover-${index}`);
          if (removeButton) {
            markerRemoveListener = google.maps.event.addDomListener(
              removeButton,
              'click',
              () => {
                setMarkers(_markers => {
                  const markers = [..._markers];
                  markers.splice(index, 1);
                  if (markers.length > 1)
                    markers[markers.length - 1].title = 'Final';
                  return markers ?? [];
                });
              },
            );
          }
        },
      );

      // Add marker dragEndListner
      const markerDragListener = google.maps.event.addListener(
        markerRef,
        'dragend',
        async function () {
          const position = markerRef.getPosition();
          const address = await getGeocodeLocation(position);
          setMarkers(_markers => {
            const markers = [..._markers];
            markers[index].position = {
              lat: position.lat(),
              lng: position.lng(),
            };
            markers[index].address = address;
            return markers;
          });
        },
      );

      setMarkerRefs(_markerRefs => [
        ..._markerRefs,
        {
          ref: markerRef,
          dragListener: markerDragListener,
          clickListener: markerClickListener,
          infoListener: markerInfoListener,
          removeListener: markerRemoveListener,
        },
      ]);
    });

    // Add radius overlay
    if (singlePoint && markers.length > 0) {
      const circleRef = new window.google.maps.Circle({
        map,
        strokeColor: theme.palette.brand.primary.dark,
        strokeOpacity: 0.8,
        strokeWeight: 1,
        fillColor: theme.palette.brand.primary.natural,
        fillOpacity: 0.35,
        center: markers[0].position,
        radius,
      });
      setCircleRef(circleRef);
    }

    // Add polyline between markers
    if (markers.length >= 2) {
      const _markers = [...markers];
      const origin = _markers.shift();
      const destination = _markers.pop();

      directionsService.route(
        {
          origin: origin.position,
          destination: destination.position,
          waypoints: _markers.map(marker => ({
            location: marker.position,
            stopover: false,
          })),

          travelMode: 'DRIVING',
        },
        (response, status) => {
          if (status === 'OK') {
            const path = [...response.routes[0].overview_path];
            setPath(
              path.map((position, idx) => ({
                pos: idx + 1,
                latitude: position.lat(),
                longitude: position.lng(),
                is_marcador: false,
              })),
            );

            if (polyRef) polyRef?.setMap(null);

            const _polyRef = new window.google.maps.Polyline({
              map,
              path,
              geodesic: true,
              strokeColor: theme.palette.brand.primary.dark,
              strokeOpacity: 0,
              icons: [
                {
                  icon: lineSymbol,
                  offset: '0',
                  repeat: '20px',
                },
              ],
            });
            setPolyRef(prev => {
              if (prev) prev.setMap(null);
              return _polyRef;
            });
          }
        },
      );
    }

    return () => {
      markerRefs.forEach(marker => {
        window.google.maps.event.removeListener(marker.dragListener);
        window.google.maps.event.removeListener(marker.clickListener);
        window.google.maps.event.removeListener(marker.infoListener);
        window.google.maps.event.removeListener(marker.removeListener);
        marker.ref.setMap(null);
      });
      circleRef?.setMap(null);
      polyRef?.setMap(null);
    };
  }, [map, markers, singlePoint, radius]);

  // Add marcador ao clicar
  useEffect(() => {
    const handleAddMarker = (position, address) => {
      setMarkers(_markers => {
        if (singlePoint) {
          return !_markers.length
            ? [{ position, address, title: '' }]
            : _markers;
        }
        if (!_markers.length) return [{ position, address, title: 'Inicial' }];
        if (_markers.length === 1)
          return [..._markers, { position, address, title: 'Final' }];
        let arr = [..._markers];
        arr.splice(arr.length - 1, 0, {
          position,
          address,
          title: '',
        });
        return arr;
      });
    };

    let clickListener = null;
    if (map) {
      clickListener = map.addListener(
        'click',
        async e => {
          const location = { lat: e.latLng.lat(), lng: e.latLng.lng() };
          let address = await getGeocodeLocation(location);
          handleAddMarker(location, address);
        },
        { once: true },
      );
    }

    return () =>
      clickListener && window.google.maps.event.removeListener(clickListener);
  }, [map, singlePoint]);

  // Busca descricao do ponto
  const getGeocodeLocation = useCallback(async location => {
    if (!window.google) return '';
    const geocoder = new window.google.maps.Geocoder();
    if (!geocoder) return '';
    try {
      const response = await geocoder.geocode({ location });

      if (response.results[0]) return response.results[0].formatted_address;
      return 'Endereço não encontrado.';
    } catch (e) {
      console.warn(`Geocoder failed due to: ${e}`);
    }
  }, []);

  // Altera tipo de cerca
  const handleChangeType = useCallback(
    (_, value) => {
      setSinglePoint(value === 'true');
      if (value === 'true' && markers.length) {
        setMarkers(_markers => [{ ..._markers[0], title: '' }]);
        setPath([]);
        polyRef?.setMap(null);
        if (fields.length > 1) remove(1);
      } else if (value === 'false' && markers.length) {
        setMarkers(_markers => [{ ..._markers[0], title: 'Inicial' }]);
      }
    },
    [markers, fields, polyRef],
  );

  const onSubmit = async data => {
    if (singlePoint && !markers.length) {
      toast.error('Defina um ponto no mapa.');
      return;
    }

    if (!singlePoint && markers.length < 2) {
      toast.error(
        markers.length === 1
          ? 'Defina um ponto final'
          : 'Defina um ponto inicial.',
      );
      return;
    }

    setLoadingSubmit(true);
    let success = true;
    let index = 0;
    for (const violacao of data.violacoes) {
      const payload = {
        ...data,
        id: index === 0 ? data.id ?? null : null,
        titulo: `${index !== 0 ? 'Retorno ' : ''}${data.titulo}`,
        violacoes: [violacao],
      };

      if (singlePoint) {
        payload.cerca = {
          ...payload.cerca,
          nome: data.titulo,
          descricao: '',
          latitude: markers[0].position.lat,
          longitude: markers[0].position.lng,
          raio: radius,
        };
      } else {
        const rotas =
          index === 0
            ? path
            : [...path]
                .reverse()
                .map((item, idx) => ({ ...item, pos: idx + 1 }));
        const _markers = markers.map((item, idx) => ({
          pos: index === 0 ? idx + 1 : markers.length - idx,
          latitude: item.position.lat,
          longitude: item.position.lng,
          is_marcador: true,
        }));
        payload.cerca = {
          ...payload.cerca,
          id: index === 0 ? payload.cerca?.id ?? null : null,
          nome: `${index !== 0 ? 'Retorno ' : ''}${data.titulo}`,
          descricao: '',
          latitude: null,
          longitude: null,
          raio: null,
          rotas: rotas.concat(_markers),
        };
      }

      try {
        // eslint-disable-next-line no-await-in-loop
        const req = await services.registerDesvio(payload);

        if (req.success) {
          success = true;
          toast.success(req.message);
        } else {
          success = false;
          toast.error(req.message);
        }
        setLoadingSubmit(false);
      } catch (error) {
        success = false;
        toast.error('Falha ao salvar.');
        console.warn('Erro ao salvar.', error);
        setLoadingSubmit(false);
      }
      index++;
    }
    if (success) navigate(-1);
  };

  const disableForm = useMemo(() => {
    const clientesAtivos = selects.clients
      .filter(i => i.status == 'ATIVO')
      .map(i => i.id);
    return (
      !isProvider &&
      data &&
      (!data.id_empresa || clientesAtivos.includes(data.id_distribuidora))
    );
  }, [data, isProvider]);

  if (isLoading) {
    return <Loading />;
  }

  return (
    <form
      onSubmit={handleSubmit(onSubmit, e =>
        toast.error('Preencha todos os campos obrigatórios!'),
      )}
    >
      <S.Container>
        <S.Title>
          <h1>{`${novo ? 'Adicionar' : 'Editar'} Cerca Eletrônica`}</h1>
        </S.Title>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <S.Section>Localização</S.Section>
          </Grid>

          <Grid item xs={12} sm={6}>
            {map && (
              <PlacesAutocomplete
                onChange={position => {
                  map?.setCenter(position);
                  map?.setZoom(13);
                  setMapInfo(state => ({
                    ...state,
                    content: 'Clique no mapa para definir o ponto',
                  }));
                }}
              />
            )}
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl disabled={disableForm}>
              <FormLabel>
                <p>Tipo de Cerca</p>
              </FormLabel>
              <RadioGroup
                row
                options={[
                  { value: true, label: 'Ponto único' },
                  { value: false, label: 'Ponto inicial e final' },
                ]}
                value={singlePoint}
                onChange={handleChangeType}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <GoogleMap
              mapContainerClassName="map"
              options={{
                center: {
                  lat: -13.923025,
                  lng: -56.8509357,
                },
                zoom: 4,
                zoomControl: true,
                scrollwheel: false,
              }}
              getMapInstance={mapInstance => setMap(mapInstance)}
            >
              {mapInfo.open && (
                <MapInfoWrapper>
                  <MapInfoContent>{mapInfo.content}</MapInfoContent>
                  <MapInfoButton
                    onClick={e =>
                      setMapInfo(state => ({ ...state, open: false }))
                    }
                  >
                    <CloseIcon fontSize="8px" />
                  </MapInfoButton>
                </MapInfoWrapper>
              )}
              {singlePoint && !!markers.length && (
                <RadiusControlWrapper>
                  <RadiusControlTitle>Raio</RadiusControlTitle>
                  <RadiusControlActions>
                    <RadiusControlButton
                      type="button"
                      onClick={() =>
                        setRadius(radius => (radius > 0 ? radius - 50 : 0))
                      }
                    >
                      <RemoveIcon />
                    </RadiusControlButton>
                    <p>{radius}m</p>
                    <RadiusControlButton
                      type="button"
                      onClick={() => setRadius(radius => radius + 50)}
                    >
                      <AddIcon />
                    </RadiusControlButton>
                  </RadiusControlActions>
                </RadiusControlWrapper>
              )}
            </GoogleMap>
          </Grid>

          {fields.map((field, index) => {
            const retorno = index === 1;
            const markerOrigem = retorno ? markers.length - 1 : 0;
            const markerDestino = retorno ? 0 : markers.length - 1;
            return (
              <Fragment key={field.id}>
                {index > 0 && (
                  <>
                    <Grid item xs={11} sm={11}>
                      <S.Section>Retorno</S.Section>
                    </Grid>
                    <Grid item xs={1} sm={1}>
                      <Tooltip title="Excluir retorno">
                        <IconButton size="small" onClick={() => remove(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </Tooltip>
                    </Grid>
                  </>
                )}
                <Grid item xs={12} sm={6}>
                  <TextInput
                    disabled
                    label={`Ponto ${singlePoint ? 'Único' : 'Inicial'}`}
                    placeholder="Marque o ponto no mapa"
                    value={markers[markerOrigem]?.address ?? ''}
                  />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextInput
                    disabled
                    label="Coordenada"
                    placeholder="Marque o ponto no mapa"
                    value={
                      markers[markerOrigem]
                        ? `${markers[markerOrigem]?.position.lat}, ${markers[markerOrigem]?.position.lng}`
                        : ''
                    }
                  />
                </Grid>

                {!singlePoint && (
                  <>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        disabled
                        label="Ponto Final"
                        placeholder="Marque o ponto no mapa"
                        value={markers[markerDestino]?.address ?? ''}
                      />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <TextInput
                        disabled
                        label="Coordenada"
                        placeholder="Marque o ponto no mapa"
                        value={
                          markers[markerDestino]
                            ? `${markers[markerDestino]?.position.lat}, ${markers[markerDestino]?.position.lng}`
                            : ''
                        }
                      />
                    </Grid>
                  </>
                )}

                <Grid item xs={4} sm={4}>
                  <Controller
                    control={control}
                    rules={{
                      required: { value: true, message: 'Campo obrigatório.' },
                    }}
                    name={`violacoes[${index}].limiar`}
                    render={({ field }) => (
                      <TextInput
                        required
                        disabled={disableForm}
                        type="number"
                        inputProps={{ min: 0 }}
                        error={!!errors.violacoes?.[index]?.limiar}
                        message={errors.violacoes?.[index]?.limiar?.message}
                        label="Velocidade máx."
                        placeholder="Insira a velocidade"
                        {...field}
                        onChange={({ target }) =>
                          field.onChange(
                            target.value ? parseInt(target.value) : '',
                          )
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={4} sm={4}>
                  <Controller
                    rules={{
                      required: { value: true, message: 'Campo obrigatório.' },
                    }}
                    control={control}
                    name={`violacoes[${index}].intervalo_tempo`}
                    render={({ field }) => (
                      <TextInput
                        required
                        disabled={disableForm}
                        type="number"
                        inputProps={{ min: 0 }}
                        error={!!errors.violacoes?.[index]?.intervalo_tempo}
                        message={
                          errors.violacoes?.[index]?.intervalo_tempo?.message
                        }
                        label={
                          <span>
                            Intervalo de tempo (min.){' '}
                            <Tooltip
                              title="Tempo de tolerância antes que o desvio comece a ser contabilizado."
                              arrow
                            >
                              <HelpIcon sx={{ fontSize: '1rem' }} />
                            </Tooltip>
                          </span>
                        }
                        placeholder="Insira o intervalo de tempo"
                        {...field}
                        onChange={({ target }) =>
                          field.onChange(
                            target.value ? parseInt(target.value) : '',
                          )
                        }
                      />
                    )}
                  />
                </Grid>

                <Grid item xs={4} sm={4}>
                  <Controller
                    rules={{
                      required: { value: true, message: 'Campo obrigatório.' },
                    }}
                    control={control}
                    name={`violacoes[${index}].pontos`}
                    render={({ field }) => (
                      <TextInput
                        required
                        disabled={disableForm}
                        type="number"
                        inputProps={{ min: 0 }}
                        error={!!errors.violacoes?.[index]?.pontos}
                        message={errors.violacoes?.[index]?.pontos?.message}
                        label="Pontuação"
                        placeholder="Insira a pontuação"
                        {...field}
                        onChange={({ target }) =>
                          field.onChange(
                            target.value ? parseInt(target.value) : '',
                          )
                        }
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={12} sm={12}>
                  <TagLabel>
                    Criticidade
                    <span
                      style={{
                        fontSize: '20px',
                        fontWeight: 500,
                        color: theme.palette.semantics.feedback.attention.dark,
                      }}
                    >
                      *
                    </span>
                  </TagLabel>
                  <TagContainer>
                    <Controller
                      rules={{
                        required: {
                          value: true,
                          message: 'Campo obrigatório.',
                        },
                      }}
                      control={control}
                      name={`violacoes[${index}].criticidade`}
                      render={({ field: { onChange, value } }) => (
                        <>
                          <Tag
                            variant="information"
                            selected={value === 'PRIMÁRIA'}
                            onClick={() => !disableForm && onChange('PRIMÁRIA')}
                          >
                            Primária
                          </Tag>
                          <Tag
                            variant="warning"
                            selected={value === 'MODERADA'}
                            onClick={() => !disableForm && onChange('MODERADA')}
                          >
                            Moderada
                          </Tag>
                          <Tag
                            variant="attention"
                            selected={value === 'GRAVE'}
                            onClick={() => !disableForm && onChange('GRAVE')}
                          >
                            Grave
                          </Tag>
                          <Tag
                            variant="attention"
                            selected={value === 'GRAVÍSSIMA'}
                            onClick={() =>
                              !disableForm && onChange('GRAVÍSSIMA')
                            }
                          >
                            Gravíssima
                          </Tag>
                        </>
                      )}
                    />
                  </TagContainer>
                  {errors.violacoes?.[index]?.criticidade?.message && (
                    <span
                      style={{
                        color: theme.palette.semantics.feedback.attention.dark,
                        margin: '10px 0',
                      }}
                    >
                      {errors.violacoes?.[index]?.criticidade?.message}
                    </span>
                  )}
                </Grid>
              </Fragment>
            );
          })}

          {!singlePoint && fields.length === 1 && (
            <Grid item xs={12} sm={12}>
              <DashedButton
                type="button"
                disabled={disableForm}
                onClick={() =>
                  append({
                    ativo: true,
                    plano_de_acao: '',
                    id_tipo_padrao: 2,
                  })
                }
              >
                <AddIcon />
                ADICIONAR RETORNO
              </DashedButton>
            </Grid>
          )}

          <Grid item xs={12} sm={12}>
            <S.Section>Informações Complementares</S.Section>
          </Grid>

          <Grid item xs={12} sm={12}>
            <Controller
              rules={{
                required: { value: true, message: 'Campo obrigatório.' },
              }}
              control={control}
              name="titulo"
              render={({ field }) => (
                <TextInput
                  required
                  disabled={disableForm}
                  error={!!errors.titulo}
                  message={errors.titulo?.message}
                  label="Título"
                  placeholder="Insira um título"
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={isProvider ? 12 : 6}>
            <Controller
              control={control}
              name="id_tipo_veiculo"
              render={({ field: { onChange, name, ref, value } }) => (
                <Select
                  ref={ref}
                  disabled={disableForm}
                  name={name}
                  value={value}
                  handleChange={onChange}
                  error={!!errors.id_tipo_veiculo}
                  data={tiposVeiculos}
                  label="Selecione o tipo de veículo"
                />
              )}
            />
          </Grid>
          {!isProvider && (
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="id_distribuidora"
                render={({ field: { onChange, name, ref, value } }) => (
                  <Select
                    disabled={disableForm}
                    ref={ref}
                    name={name}
                    value={value}
                    handleChange={onChange}
                    error={!!errors.cliente}
                    data={clients}
                    label="Selecione o cliente"
                  />
                )}
              />
            </Grid>
          )}
          <Grid item xs={12} sm={12}>
            <TagLabel>Complemento</TagLabel>
            <TagContainer>
              <Controller
                control={control}
                name="pista_molhada"
                render={({ field: { onChange, value } }) => (
                  <>
                    <Tag
                      selected={value === false}
                      onClick={() =>
                        !disableForm && onChange(value === false ? null : false)
                      }
                    >
                      Pista Seca
                    </Tag>
                    <Tag
                      selected={value === true}
                      onClick={() =>
                        !disableForm && onChange(value === true ? null : true)
                      }
                    >
                      Pista Molhada
                    </Tag>
                  </>
                )}
              />
              <Controller
                control={control}
                name="carregado"
                render={({ field: { onChange, value } }) => (
                  <>
                    <Tag
                      selected={value === false}
                      onClick={() =>
                        !disableForm && onChange(value === false ? null : false)
                      }
                    >
                      Veículo Vazio
                    </Tag>
                    <Tag
                      selected={value === true}
                      onClick={() =>
                        !disableForm && onChange(value === true ? null : true)
                      }
                    >
                      Veículo Carregado
                    </Tag>
                  </>
                )}
              />
            </TagContainer>
          </Grid>
        </Grid>
      </S.Container>
      <Footer
        title="Informações Atualizadas"
        actions={
          <>
            <GhostButton
              customcolor={
                theme?.palette?.semantics?.feedback?.attention?.natural
              }
              onClick={() => navigate(-1)}
            >
              Voltar
            </GhostButton>
            <GhostButton
              disabled={disableForm}
              type="submit"
              loading={loadingSubmit}
            >
              Salvar
            </GhostButton>
          </>
        }
      />
    </form>
  );
};

export default EditCercaEletronica;
