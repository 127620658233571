import { Icon } from '../../../components/IconsOnisys';

export const cardsInfo = [
  {
    type: 'ALTISSIMO',
    value: '0',
    icon: <Icon name="icone_risco_altissmo" />,
    text: 'Risco altíssimo',
    loading: false,
  },
  {
    type: 'ALTO',
    value: '0',
    icon: <Icon name="icone_risco_alto" />,
    text: 'Risco alto',
    loading: false,
  },
  {
    type: 'PERIGO',
    value: '0',
    icon: <Icon name="icone_prox_vencimento" />,
    text: 'Deslocamentos perigosos',
    loading: false,
  },
];
