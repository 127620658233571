import {
  sub,
  add,
  format,
  isToday,
  isTomorrow,
  isYesterday,
  startOfWeek,
  endOfWeek,
  setISOWeek,
  differenceInCalendarWeeks,
  isValid,
  getISOWeek,
  parse,
} from 'date-fns';
import { ptBR } from 'date-fns/locale';

import { utcToZonedTime } from 'date-fns-tz';

export const formatNewDate = date => {
  try {
    if (date && date !== null) {
      const timeZone = 'GMT';
      const formattedDate = utcToZonedTime(date, timeZone);
      return format(formattedDate, 'dd/MM/yyyy');
    }
    return '';
  } catch (error) {
    return '';
  }
};

export const formatTime = (date, seconds = false) => {
  if (date && date !== null) {
    const timeZone = 'GMT';
    const formattedDate = utcToZonedTime(date, timeZone);
    if (seconds) return format(formattedDate, 'HH:mm:ss');
    return format(formattedDate, 'HH:mm');
  }
  return '';
};

export const formatNameDate = date => {
  return format(date, 'yyyy-MM-dd');
};

// Para dados ja exitentes
export const formatNewHour = (date, seconds) => {
  if (date && date !== null) {
    const timeZone = '-03';
    const formattedDate = utcToZonedTime(new Date(date), timeZone);
    return !seconds
      ? format(formattedDate, 'dd/MM/yyyy - HH:mm')
      : format(formattedDate, 'dd/MM/yyyy - HH:mm:ss');
  }
  return '';
};

export const formatNewTime = (date, seconds = false) => {
  if (date && date !== null) {
    const timeZone = '-03';
    const formattedDate = utcToZonedTime(new Date(date), timeZone);
    if (seconds) return format(formattedDate, 'HH:mm:ss');
    return format(formattedDate, 'HH:mm');
  }
  return '';
};

// Para dados ja exitentes (sem -3 hrs)
export const formatNewHourClean = (date, seconds) => {
  if (date && date !== null) {
    const timeZone = '-00';
    const formattedDate = utcToZonedTime(new Date(date), timeZone);
    return !seconds
      ? format(formattedDate, 'dd/MM/yyyy - HH:mm')
      : format(formattedDate, 'dd/MM/yyyy - HH:mm:ss');
  }
  return '';
};

// Para dados nao existentes
export const formatEmptyHour = () => {
  const formattedDate = utcToZonedTime(new Date());
  return format(formattedDate, 'dd/MM/yyyy - HH:mm');
};

// Formato de data para salvar em arquivos
export const getDatetimeString = () => {
  const date = new Date();
  return format(date, 'yyyy-MM-dd_HH-mm-ss');
};

const DaysInYear = year => {
  return (year % 4 === 0 && year % 100 > 100) || year % 400 === 0 ? 366 : 365;
};

export const getDateGivenWeek = (value, addTime = false) => {
  // Verifica se `value` está definido e é uma string válida
  if (!value || typeof value !== 'string' || !value.includes('-')) {
    console.warn(
      "Invalid 'value' format. Expected format 'YYYY-WW', received:",
      value,
    );
    return addTime ? 'Data inválida ~ Data inválida' : 'Data inválida';
  }

  // Divide o ano e a semana a partir de `value`
  const splittedWeek = value.split('-');
  const year = parseInt(splittedWeek[0], 10);
  const week = parseInt(splittedWeek[1], 10);

  // Inicializa uma data base
  let date = new Date(year, 0, 1 + (week - 1) * 7);

  // Calcula início e fim da semana
  const startWeek = startOfWeek(date, { weekStartsOn: 0 });
  const endWeek = endOfWeek(date, { weekStartsOn: 0 });

  return addTime
    ? `${format(add(startWeek, { days: 1 }), 'dd/MM/yyyy HH:mm')} ~ ${format(
        add(endWeek, { days: 1 }),
        'dd/MM/yyyy HH:mm',
      )}`
    : `${format(add(startWeek, { days: 1 }), 'dd/MM/yyyy')} ~ ${format(
        add(endWeek, { days: 1 }),
        'dd/MM/yyyy',
      )}`;
};

export const formatShortDate = date => {
  if (date && date !== null) {
    return format(new Date(date), 'dd/MM');
  }
  return '';
};

export const removeTimeZone = date => {
  if (date && date !== null) {
    const data = String(date).split('T')[0].split('-');
    const hora = String(date).split('T')[1].split('.')[0];

    const dia = data[2];
    const mes = data[1];
    const ano = data[0];

    return String(`${dia}/${mes}/${ano} ${hora}`);
  }
  return '';
};

export const weekNumber = date => {
  let week = differenceInCalendarWeeks(
    date,
    new Date(date.getFullYear(), 0, 1),
  );
  return `${date.getFullYear()}-${(week + 1).toString().padStart(2, '0')}`;
};

export const formatRelativeDate = data => {
  if (!data) {
    return '';
  }
  if (typeof data === 'string') data = new Date(data);
  if (!isValid(data)) {
    return 'Data inválida.';
  }
  // Verifica se a data é hoje
  if (isToday(data)) {
    return `Hoje às ${format(data, 'HH:mm')}`;
  }
  // Verifica se a data é ontem
  if (isYesterday(data)) {
    return `Ontem às ${format(data, 'HH:mm')}`;
  }
  // Verifica se a data é amanhã
  if (isTomorrow(data)) {
    return `Amanhã às ${format(data, 'HH:mm')}`;
  }
  // Caso contrário, retorna a data formatada normalmente
  return format(data, 'dd/MM/yyyy HH:mm');
};

export const ptMonths = [
  'Janeiro',
  'Fevereiro',
  'Março',
  'Abril',
  'Maio',
  'Junho',
  'Julho',
  'Agosto',
  'Setembro',
  'Outubro',
  'Novembro',
  'Dezembro',
];

export const ptDays = [
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sabado',
];

export const transformData = (
  data,
  periodType = 'day',
  differenceInDays = 0,
) => {
  const seenPeriods = new Set();

  const transformedData = data.map(item => {
    const formatarData = data => {
      let dataFormatada = format(data, 'MMM yyyy', { locale: ptBR });
      dataFormatada =
        dataFormatada.charAt(0).toUpperCase() + dataFormatada.slice(1);
      return dataFormatada;
    };

    const date = parse(item.month_year, 'dd/MM/yy', new Date());
    if (!isValid(date)) {
      return {
        ...item,
        name: 'Data inválida',
      };
    }

    const differenceInWeeks = Math.floor(differenceInDays / 7);
    let name;

    if (periodType === 'day') {
      if (differenceInDays > 31 && differenceInWeeks < 26) {
        const weekNumber = getISOWeek(date);
        name = `S-${weekNumber < 10 ? `0${weekNumber}` : weekNumber}`;
      } else if (differenceInWeeks >= 26) {
        name = format(date, 'MMM yyyy', { locale: ptBR });
      } else {
        name = format(date, 'dd', { locale: ptBR });
      }
    } else if (periodType === 'week') {
      if (differenceInWeeks > 26) {
        name = format(date, 'MMM yyyy', { locale: ptBR });
      } else {
        const weekNumber = getISOWeek(date);
        name = `S-${weekNumber < 10 ? `0${weekNumber}` : weekNumber}`;
      }
    } else if (periodType === 'month') {
      if (differenceInWeeks > 26) {
        name = format(date, 'MMM yyyy', { locale: ptBR });
      } else {
        name = formatarData(date);
      }
    } else {
      name =
        differenceInDays > 31
          ? formatarData(date)
          : format(date, 'dd', { locale: ptBR });
    }

    if (seenPeriods.has(name) && periodType !== 'original') {
      return {
        ...item,
        name: '',
      };
    }

    seenPeriods.add(name);
    return {
      ...item,
      name,
    };
  });

  // Retorna os dados filtrados ou os dados originais caso o filtro seja alterado
  return transformedData.filter(item => item.name);
};
