import api from 'services/api';
import qs from 'qs';

const requestDriver = async (id, query) => {
  const res = await api.get(`/drivers/${id}`, { params: query });
  return res;
};

const requestTelemetriaInfractions = async (id, query) => {
  const res = await api.get(`/telemetria/drivers/${id}`, { params: query });
  return res;
};

const requestFadigaInfractions = async (id, query) => {
  const res = await api.get(`/fadiga/drivers/${id}`, { params: query });
  return res;
};

const requestDriverForms = async (id, query) => {
  const res = await api.get(`/driver-form-history/${id}`, { params: query });
  return res;
};

const requestDriverCard = async query => {
  const res = await api.get('/drivers-card', { params: query });
  return res;
};

const requestDriverGraphs = async query => {
  const res = await api.get('/drivers-graphs', { params: query });
  return res;
};

const requestDriverBloqueio = async id => {
  const res = await api.get(`/drivers/perfil/acaosuspensao/bloqueio/${id}`);
  return res;
};

const requestDriverLastTrip = async id => {
  const res = await api.get(`/provider/motoristas/${id}/ultima-viagem`);
  return res;
};

const requestFaixaContratada = async query => {
  const res = await api.get('/faixa-contratada', { params: query });
  return res;
};

const changeDriverStatus = async id => {
  const res = await api.put(`/driver-change-status/${id}`);
  return res;
};

const changeStatusMany = async data => {
  const res = await api.put(`/driver-change-status-many`, data);
  return res;
};

const createDriver = async data => {
  const res = await api.post(`/driver`, data);
  return res;
};

const checkUpload = async data => {
  const res = await api.post(`/drivers/checkUpload`, data);
  return res;
};

const createMany = async data => {
  const res = await api.post(`/drivers/storeMany`, data);
  return res;
};

const updateDriver = async data => {
  const res = await api.put(`/driver/${data.id}`, data);
  return res;
};

const requestLogs = async id => {
  const res = await api.get(`/driver-logs/${id}`);
  return res;
};

const requestExcel = async query => {
  const res = await api.get('/excel/driver', { params: query });
  return res;
};

const requestExcelEvaluation = async query => {
  const res = await api.get('/excel/evaluationsdriver', { params: query });
  return res;
};

const requestExcelInfractions = async query => {
  const res = await api.get('/excel/infractions', { params: query });
  return res;
};

const requestExcelUnloadingEvaluation = async query => {
  const res = await api.get('/excel/unloadingevaluationsdriver', {
    params: query,
  });
  return res;
};

const requestExcelUnloadingInfraction = async query => {
  const res = await api.get('/excel/unloadingInfractions', { params: query });
  return res;
};

const requestExcelTelemetria = async query => {
  const res = await api.get('/excel/telemetriaDriver', {
    params: {
      ...query,
      categoria: ['DESVIO', 'CERCA', 'VELOCIDADE', 'JORNADA'],
    },
  });
  return res;
};

const requestExcelFadiga = async query => {
  const res = await api.get('/excel/telemetriaDriver', {
    params: { ...query, categoria: ['FADIGA'] },
  });
  return res;
};

const requestExcelPad = async query => {
  const res = await api.get('/excel/pad', { params: query });
  return res;
};

const requestExcelForms = async query => {
  const res = await api.get('/excel/formsDriver', { params: query });
  return res;
};

const requestDriverRanking = async (id, query) => {
  const res = await api.get(`/driver-ranking/${id}`, { params: query });
  return res;
};

const requestClients = async () => {
  const res = await api.get('/select-clients');
  return res;
};

const changeClient = async data => {
  const res = await api.put('/driver-change-client', data);
  return res;
};

const sendSms = async id => {
  const res = await api.put(`/send-sms/${id}`);
  return res;
};

const sendMessage = async id => {
  const res = await api.put(`/send-message/${id}`);
  return res;
};

const storePhone = async data => {
  const res = await api.put(`/driver-store-phone/${data.id}`, data);
  return res;
};

const getFormResponse = async (id, histId, alunoId, alunoTipo) => {
  const res = await api.get(
    `/my-form/${id}?${qs.stringify({
      selects: true,
      histId,
      alunoId,
      alunoTipo,
    })}`,
  );
  return res.data;
};

const requestDriversExports = async (id, params) => {
  const res = await api.get(`/drivers/perfil/pdf/${id}`, { params });
  return res?.data || [];
};

const requestDriversExportsExcel = async (id, params) => {
  const res = await api.get(`/excel/perfil_motorista`, { params });
  return res?.data || [];
};

export {
  requestDriver,
  requestDriverForms,
  requestDriverGraphs,
  requestTelemetriaInfractions,
  requestFadigaInfractions,
  requestFaixaContratada,
  requestDriverLastTrip,
  requestDriverCard,
  requestDriverBloqueio,
  changeDriverStatus,
  changeStatusMany,
  createDriver,
  checkUpload,
  createMany,
  updateDriver,
  requestLogs,
  requestExcel,
  requestExcelEvaluation,
  requestExcelInfractions,
  requestExcelTelemetria,
  requestExcelFadiga,
  requestExcelUnloadingEvaluation,
  requestExcelUnloadingInfraction,
  requestExcelPad,
  requestExcelForms,
  requestDriverRanking,
  requestClients,
  changeClient,
  sendSms,
  sendMessage,
  storePhone,
  getFormResponse,
  requestDriversExports,
  requestDriversExportsExcel,
};
