import {
  getResponsaveis,
  getTiposDesvios,
  getCriticidade,
  getDesviosTiposPadrao,
  getDesviosTipos,
  getVeiculos,
  getTecnologias,
} from 'constants/_SERVICES/provider';

// pages
import DesviosProvider from 'pages/Provider/Desvios';
import DesvioProvider from 'pages/Provider/Desvios/Detalhe';
import LogsDesvioProvider from 'pages/Provider/Desvios/Historico';
import DesviosConfiguracoes from 'pages/DesviosConfiguracoes';
import EditConfig from 'pages/DesviosConfiguracoes/Detalhe';
import Historico from 'pages/Historico';
import TratativaDesvios from 'pages/Desvios/Estatisticas/TratativasDesvios';
import DesempenhoGeral from 'pages/Desvios/Estatisticas/DesempenhoGeral';
import { DetalheDeslocamento } from 'pages/Desvios/Deslocamentos/Detalhe';
import Deslocamentos from 'pages/Desvios/Deslocamentos';

import DesviosUnificados from 'pages/Desvios/Listagem';
import DetalheDesvio from 'pages/Desvios/Detalhe';

export default {
  '/desvio': {
    date: true,
    pageFilterName: 'filterDesvioProvider',
    filters: [
      {
        name: 'tipo',
        placeholder: 'Filtrar por Tipo',
        section: 'Desvio',
        mode: 'multiple',
        data: async () => getTiposDesvios(),
      },
      {
        name: 'criticidade',
        placeholder: 'Filtrar por Criticidade',
        section: 'Desvio',
        mode: 'multiple',
        data: async () => getCriticidade(),
      },
    ],
    page: <DesviosProvider />,
  },

  '/desvio/:id': {
    defaults: [],
    back: -1,
    title: 'Desvio Direção',
    id: true,
    status: true,
    page: <DesvioProvider />,
  },

  '/logs/desvio/:id': {
    defaults: [],
    back: -1,
    title: 'Histórico do Desvio',
    id: true,
    page: <LogsDesvioProvider />,
  },

  '/desvios/configuracoes': {
    back: -1,
    // defaults: ['empresas'],
    page: <DesviosConfiguracoes />,
    // pageFilterName: 'filterDesviosConfiguracoesProvider',
    // filters: [
    //   {
    //     name: 'desvio',
    //     placeholder: 'Filtrar por Desvio',
    //     mode: 'single',
    //     data: async () => getDesviosTiposPadrao(),
    //   },
    //   {
    //     name: 'veiculo',
    //     placeholder: 'Filtrar por Tipo de Veículo',
    //     mode: 'single',
    //     data: async () => getVeiculos(true),
    //   },
    // ],
  },

  '/logs/desvios/configuracoes/:id': {
    back: -1,
    title: 'Histórico da Configuração de Desvio',
    id: true,
    defaults: [],
    page: (
      <Historico
        back={-1}
        url="/desvios/configuracoes/logs/:id"
        formatReturn={data =>
          data.map(log => ({
            ...log,
            data: log.data ?? log.createdAt,
            evento: log.evento?.evento ?? '',
          }))
        }
      />
    ),
  },

  '/desvios/configuracoes/criar': {
    back: -1,
    title: 'Adicionar Grupo',
    defaults: [],
    page: <EditConfig novo />,
  },

  '/desvios/desempenho-geral': {
    back: -1,
    title: 'Desempenho Geral',
    defaults: [],
    page: <DesempenhoGeral />,
  },

  '/desvios/configuracoes/:id': {
    back: -1,
    title: 'Editar Grupo',
    defaults: [],
    page: <EditConfig />,
  },

  '/desvios': {
    pageFilterName: 'filterDesvios',
    back: -1,
    title: 'Desvios',
    page: <DesviosUnificados />,
  },

  '/desvios/tratativa-desvios': {
    back: -1,
    date: false,
    title: 'Tratativas de desvios',
    defaults: [],
    page: <TratativaDesvios />,
  },

  '/desvios/deslocamentos': {
    back: -1,
    date: false,
    title: 'Deslocamentos',
    defaults: [],
    page: <Deslocamentos />,
  },

  '/desvios/deslocamentos/:id': {
    back: -1,
    title: 'Deslocamento',
    subTitle: 'Deslocamentos',
    id: true,
    page: <DetalheDeslocamento />,
  },

  '/desvios/:id': {
    back: -1,
    title: 'Desvio',
    id: true,
    status: true,
    defaults: [],
    page: <DetalheDesvio />,
  },

  '/logs/desvios/:id': {
    back: -1,
    title: 'Histórico do Desvio',
    id: true,
    defaults: [],
    page: (
      <Historico
        back="/desvios/:id"
        url="/desvios/:id/logs"
        formatReturn={data =>
          data.map(log => ({
            ...log,
            data: log.data ?? log.createdAt,
            evento: log.evento?.evento ?? '',
          }))
        }
      />
    ),
  },
};
