import React, { useState, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

import { Modal, IconButton, Icon, Grid } from '@mui/material';

import InfoCard from 'components/Cards/InfoCard';
import GhostButton from 'components/Buttons/Ghost';
import * as S from './styled';
import TextField from 'components/Inputs/_withController/TextField';
import { SettingsOutlined } from '@mui/icons-material';
import { useQuery } from 'react-query';
import { getDecaimento, saveDecaimento } from '../../services';

const defaultValues = {
  decaimento: '',
  expiracao: '',
};

const DecaimentoModal = ({ open, handleClose }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  const { isFetching, refetch } = useQuery(
    ['acoes-suspensoes-decaimento'],
    () => getDecaimento(),
    {
      refetchOnWindowFocus: false,
      onSuccess: res => {
        const { data } = res?.data || null;
        reset({ ...defaultValues, ...data });
      },
    },
  );

  const onSubmit = async data => {
    try {
      setLoading(true);
      const req = await saveDecaimento({
        ...data,
        decaimento: Number(data.decaimento),
        expiracao: Number(data.expiracao),
      });
      if (req?.success) {
        toast.success(req?.message);
        refetch();
        handleClose();
      } else toast.error(req?.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.warn('Erro ao salvar.', error);
    }
  };

  return (
    <Modal open={open}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.Paper>
          <S.Header>
            <S.Title>
              <SettingsOutlined
                sx={{ color: theme.palette.words.text.light }}
              />
              <h1>Configurar decaimento de pontos</h1>
            </S.Title>

            <IconButton size="small" onClick={handleClose}>
              <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
                close
              </Icon>
            </IconButton>
          </S.Header>

          <InfoCard message="A pontuação pode ser configurada para decair e expirar a partir da data do desvio, configure conforme a necessidade de sua empresa." />

          <Grid container rowSpacing={0} columnSpacing={2} marginY={3}>
            <Grid item xs={6} sm={6}>
              <TextField
                required
                control={control}
                name="decaimento"
                type="number"
                inputProps={{ min: 0 }}
                errors={errors}
                label="Decaimento (dias)"
                placeholder="Insira o decaimento"
                rules={{
                  required: { value: true, message: 'Campo obrigatório.' },
                }}
              />
            </Grid>

            <Grid item xs={6} sm={6}>
              <TextField
                required
                control={control}
                name="expiracao"
                type="number"
                inputProps={{ min: 0 }}
                errors={errors}
                label="Prazo de expiração (dias)"
                placeholder="Insira o prazo de expiração"
                rules={{
                  required: { value: true, message: 'Campo obrigatório.' },
                }}
              />
            </Grid>
          </Grid>

          <S.Footer>
            <GhostButton
              customcolor={
                theme?.palette?.semantics?.feedback?.attention?.natural
              }
              size="medium"
              onClick={handleClose}
            >
              Cancelar
            </GhostButton>

            <GhostButton
              type="submit"
              size="medium"
              style={{ marginLeft: '10px' }}
              loading={loading || isFetching}
            >
              Salvar configurações
            </GhostButton>
          </S.Footer>
        </S.Paper>
      </form>
    </Modal>
  );
};

export default DecaimentoModal;
