// Styles
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import React, { useState, useEffect, useRef } from 'react';

// Components
import Loading from 'components/Loading';
import TableLocal from 'components/TableLocal';
import GhostButton from 'components/Buttons/Ghost';

// Components MUI
import Badge from '@mui/material/Badge';
import Avatar from '@mui/material/Avatar';
import { Divider, Modal } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import Filters from '../Filters';
import * as S from './style.js';

// Utils
import { columnsAluno } from './columns';
import {
  getMotoristas,
  createTurmaAluno,
  inativateGestor,
  inativateDriver,
} from './services';

const Alunos = ({
  openModalAluno,
  behaviorCloseModal,
  id_turma,
  fetchData,
  tipoUser,
}) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);

  // tabelas
  const [selectedClass, setSelectedClass] = useState([]);
  const [selectedRow, setSelectedRow] = useState('');

  const [dataUsuario, setDataUsuario] = useState([]);
  const [dataUsuarioBk, setDataUsuarioBk] = useState([]);

  const [totalFilters, setTotalFilters] = useState(0);
  const [actions, setActions] = useState([]);

  const fetchUsers = async () => {
    setLoading(true);
    const res = await getMotoristas(tipoUser);
    if (res.data.success) {
      const motoristas = res.data.drivers;
      const { users } = res.data;
      motoristas.map(item => {
        Object.assign(item, {
          filial_name: item.filial.nome,
          turmas: item.turmas,
          conteudos: item.conteudos,
        });
        return item;
      });
      users.map(item => {
        Object.assign(item, {
          filial_name: item.filial.nome,
          turmas: item.turmas,
          conteudos: item.conteudos,
        });
        return item;
      });
      setDataUsuario(users.concat(motoristas));
      setDataUsuarioBk(users.concat(motoristas));
    } else {
      toast.error(res.data.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    fetchUsers();
  }, [openModalAluno]);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getAcron = str => {
    const matches = str.match(/\b(\w)/g);
    const acronym = matches.join('');
    return acronym.toUpperCase();
  };

  const formatTable = arr => {
    arr.forEach(i => {
      if (i.id === 'id') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph
                fontweight="bold"
                color={theme.palette.brand.primary.natural}
              >
                {row.original.id}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'nome') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <div className="container-img-name">
                <Avatar
                  alt={getAcron(row.original.nome)}
                  src={row.original.foto}
                  sx={{ marginRight: 1 }}
                />
                <S.Paragraph
                  fontweight="bold"
                  color={theme.palette.brand.primary.natural}
                >
                  {row.original.nome}
                </S.Paragraph>
              </div>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'tipo') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph
                fontweight="bold"
                color={theme.palette.brand.primary.natural}
              >
                {row.original.tipo}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'funcao') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph
                fontweight="bold"
                color={theme.palette.brand.primary.natural}
              >
                {row.original.funcao}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'filial_name') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph
                fontweight="bold"
                color={theme.palette.brand.primary.natural}
              >
                {row.original.filial_name}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'ativo') {
        i.Cell = function ({ row }) {
          let colorText = '';
          let colorBg = '';

          switch (row.original.status) {
            case 'ATIVO':
              colorText = theme.palette.semantics.feedback.success.natural;
              colorBg = theme.palette.semantics.feedback.success.light;
              break;
            default:
              colorText = theme.palette.semantics.feedback.attention.natural;
              colorBg = theme.palette.semantics.feedback.attention.light;
              break;
          }

          return (
            <S.ColDriver>
              <S.Paragraph className="status" color={colorText} bg={colorBg}>
                {row.original.status}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'grupos') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph
                fontweight="bold"
                color={theme.palette.brand.primary.natural}
              >
                {row.original.turmas}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      }
    });
    return arr;
  };
  const bulkActions = [
    {
      title: 'Adicionar Alunos',
      function: () => AdicionarALuno(),
    },
  ];

  const sortBy = [];

  const findTypeUser = id => {
    const result = dataUsuario.find(item => {
      return Number(item.id) === Number(id);
    });
    return result?.tipo;
  };

  const AdicionarALuno = async () => {
    const alunos = [];
    selectedRow.map(id => {
      const type = findTypeUser(id);
      alunos.push({
        id_usuario: type === 'Usuário' ? Number(id) : null,
        id_motorista: type === 'Motorista' ? Number(id) : null,
      });
    });
    const res = await createTurmaAluno({
      id_turma: Number(id_turma),
      alunos,
    });
    if (res.data.success) {
      toast.success(res.data.message);
      behaviorCloseModal();
      fetchData();
    } else {
      toast.error(res.data.message);
    }
  };
  const openFilter = Boolean(anchorEl);

  const id = openFilter ? 'simple-popover' : undefined;

  const handleSetExcluir = async id => {
    const data = dataUsuario.find(item => Number(item.id) === Number(id));
    const status = !data.status ? 'ATIVO' : 'INATIVO';
    if (data.tipo === 'Usuário') {
      const res = await inativateGestor(id, { status });
      if (res.data.success) {
        toast.success(res.data.message);
        fetchUsers();
      } else {
        toast.error(res.data.message);
      }
    } else if (data.tipo === 'Motorista') {
      const res = await inativateDriver(id);
      if (res.data.success) {
        toast.success(res.data.message);
        fetchUsers();
      } else {
        toast.error(res.data.message);
      }
    }
  };

  return (
    <Modal
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      open={openModalAluno}
      onClose={behaviorCloseModal}
      disableEscapeKeyDown={false}
    >
      <S.Container>
        {loading && <Loading />}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            marginBottom: '10px',
          }}
        >
          <div className="leftHeader">
            <PersonAddAltOutlinedIcon
              htmlColor={theme.palette.brand.secondary.natural}
            />
            <h1>Selecionar usuários</h1>
          </div>
          <IconButton onClick={() => behaviorCloseModal()}>
            <CloseOutlinedIcon />
          </IconButton>
        </div>
        <Divider />
        <div className="table-container">
          {!loading && (
            <div className="filter-container">
              <IconButton
                color="error"
                aria-describedby={id}
                onClick={handleClick}
              >
                <Badge color="error" badgeContent={totalFilters}>
                  <FilterAltOutlinedIcon color="disabled" />
                </Badge>
              </IconButton>
              <label
                style={{
                  color: theme.palette.semantics.feedback.unknown.natural,
                }}
                onClick={handleClick}
              >
                Filtros
              </label>

              <Filters
                id={id}
                open={openFilter}
                anchorEl={anchorEl}
                onClose={handleClose}
                data={dataUsuario}
                data_bk={dataUsuarioBk}
                tipoUser={tipoUser}
                setUsersFilters={data => setDataUsuario(data)}
              />
            </div>
          )}
          <div
            style={{
              opacity: loading ? 0 : 1,
              pointerEvents: loading ? 'none' : 'all',
            }}
          >
            <TableLocal
              columns={formatTable(columnsAluno)}
              pageSizes={4}
              data={dataUsuario}
              heightEmpty="200px"
              sortBy={sortBy}
              permitIsSortedOccur
              tableType
              actions={actions}
              setSelectedData={id => {
                setSelectedRow(id);
                setActions([
                  {
                    name: 'excluir',
                    action: id => handleSetExcluir(id),
                    text: 'Excluir',
                  },
                  {
                    name: 'inserir',
                    action: async id => {
                      const type = findTypeUser(id);
                      const res = await createTurmaAluno({
                        id_turma: Number(id_turma),
                        alunos: [
                          {
                            id_usuario: type === 'Usuário' ? Number(id) : null,
                            id_motorista:
                              type === 'Motorista' ? Number(id) : null,
                          },
                        ],
                      });
                      if (res.data.success) {
                        toast.success(res.data.message);
                        behaviorCloseModal();
                        fetchData();
                      } else {
                        toast.error(res.data.message);
                      }
                    },
                    text: 'Inserir',
                  },
                ]);
              }}
              setSelectedRow={val => {
                setSelectedClass(val);
              }}
              bulkActions={bulkActions}
              empty={{
                image: '',
                title: 'Seus alunos aparecerão aqui!',
                subtitle: '',
              }}
              search={{
                inputLabel: 'Buscar Cliente',
                keys: ['nome', 'filial_name'],
              }}
            />
          </div>
        </div>
        <div className="button-container">
          <GhostButton
            customcolor={
              theme?.palette?.semantics?.feedback?.attention?.natural
            }
            onClick={() => behaviorCloseModal()}
          >
            Cancelar
          </GhostButton>
          <div style={{ marginRight: 10 }} />
          <GhostButton onClick={AdicionarALuno}>Salvar</GhostButton>
        </div>
      </S.Container>
    </Modal>
  );
};

export default Alunos;
