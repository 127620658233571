import React, { useMemo } from 'react';
import { SingleTable } from '../../Components/SingleTable';
import { columns } from './constants';
import { useQuery } from 'react-query';
import { requestRanking } from 'pages/Provider/RankingEmpresas/services';
import { subDays } from 'date-fns';
import { usePlans } from 'hooks/usePlans';

const today = new Date();
const last30 = subDays(new Date(), 30);

const query = {
  initialDate: last30,
  finalDate: today,
  tipo: 'empresa',
};

export const MenosSeguros = () => {
  const { planosAtivos } = usePlans();
  const { isFetching, data: resData } = useQuery(
    ['empresas-menos-seguras', query],
    () => query && requestRanking(query),
    {
      refetchOnWindowFocus: false,
    },
  );

  const data = useMemo(() => {
    const _data = resData?.data?.data.geral ?? [];
    return _data.slice(-5).reverse();
  }, [resData]);

  return (
    <SingleTable
      title="Empresas menos seguras"
      subtitle="Últimos 30 dias"
      data={data}
      columns={columns.filter(
        col =>
          !col.plans ||
          planosAtivos.some(({ id_do_plano }) =>
            col.plans.includes(id_do_plano),
          ),
      )}
      link={{
        to: '/empresas/ranking?order=DESC',
        children: 'Ver todos',
        justify: 'right',
      }}
      loading={isFetching}
    />
  );
};
