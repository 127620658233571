/* eslint-disable import/order */

// Styles
import * as S from './styled';

// React
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect, useRef, useCallback } from 'react';

// Components
import { DefaultTable } from 'components/_Table/templates/default';
import { Icon } from 'components/IconsOnisys';
import ConfirmModal from 'components/ConfirmModal';
import Ghost from 'components/Buttons/Ghost';
import Dialog from '@mui/material/Dialog';

// Utils
import { trackEvent } from 'utils/mixpanel';

// Services
import { requestEmpresas, acceptInvite, rejectInvite } from './services';

// Constants
import { columns } from './constants';

const sortBy = {
  id: 'nome',
  order: 'ASC',
};

const Empresas = () => {
  const navigate = useNavigate();
  const filter = useSelector(state => state.filterProvider);
  const filterEmpresa = useSelector(state => state.filterEmpresaProvider);
  const { user } = useSelector(state => state?.auth?.user);
  const [confirmModal, setConfirmModal] = useState(false);
  const [confirmModalReject, setConfirmModalReject] = useState(false);

  const [currentItem, setCurrentItem] = useState(null);

  const [query, setQuery] = useState(null);

  const {
    isLoading: loading,
    data,
    refetch: fetchData,
  } = useQuery(['empresas-provider', query], () => requestEmpresas(query), {
    refetchOnWindowFocus: false,
  });

  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setQuery({ ...filter, ...filterEmpresa });
  }, [filter, filterEmpresa]);

  const handleVer = id => {
    trackEvent(user, 'EMPRESA: VER PERFIL');
    if (id) navigate(`/empresas/${id}`);
    else toast.error('Não foi possível acessar os detalhes desta empresa.');
  };

  const formatRows = rows =>
    rows.map(row => ({
      ...row,
      ativo: !!row.empresa_distribuidoras[0]?.ativo,
      convite: row.empresa_distribuidoras[0]?.convite,
      n_motoristas_ativos: row.n_motoristas_ativos || 0,
      data_vinculo: row?.empresa_distribuidoras[0]?.data_vinculo || '-',
    }));

  const handleReject = async () => {
    const res = await rejectInvite(currentItem.id);
    if (res?.status === 200) {
      toast.success('Convite rejeitado com sucesso!');
      setConfirmModalReject(false);
      fetchData();
    } else {
      toast.error('Ocorreu um erro ao rejeitado o convite');
    }
  };

  const handleConfirm = async () => {
    const res = await acceptInvite(currentItem.id);
    if (res?.status === 200) {
      toast.success('Convite aceito com sucesso!');
      setConfirmModal(false);
      fetchData();
    } else {
      toast.error('Ocorreu um erro ao aceitar o convite');
    }
  };

  const handleOpenModal = item => {
    setConfirmModal(true);
    setCurrentItem(item);
  };

  const formatColumns = useCallback(
    col =>
      col.map(i => {
        if (i.id === 'convite') {
          return {
            ...i,
            children: (_, item) => {
              return (
                <>
                  {item?.empresa_distribuidoras &&
                  item?.empresa_distribuidoras[0].convite === 'PENDENTE' ? (
                    <S.InvitationContainer
                      onClick={() => handleOpenModal(item)}
                    >
                      <Icon name="icone_alerta" style={{ marginRight: 5 }} />
                      <span>
                        {' '}
                        Essa empresa enviou um convite de vínculo, clique aqui
                        para ver.
                      </span>
                    </S.InvitationContainer>
                  ) : (
                    ''
                  )}
                </>
              );
            },
          };
        }
        return i;
      }),
    [],
  );

  const renderHeader = () => {
    return (
      <>
        <S.TitleWrapper>
          <h1>Gestão de empresas</h1>
        </S.TitleWrapper>
      </>
    );
  };

  const renderTable = () => {
    const actions = [{ title: 'Ver perfil', function: handleVer }];

    return (
      <DefaultTable
        data={formatRows(data?.data?.rows || [])}
        columns={formatColumns(columns)}
        loading={loading}
        local
        notClickable={[789]}
        sortBy={sortBy}
        placeholder="Buscar Empresa..."
        actions={actions}
        searchKeys={['nome', 'cnpj']}
      />
    );
  };

  return (
    <S.Main>
      {renderHeader()}
      {renderTable()}

      <Dialog open={confirmModalReject}>
        <S.ContentDialogReject>
          <div className="title">Tem certeza que deseja rejeitar?</div>
          <div className="content">
            Se você rejeitar, essa empresa não aparecerá na sua lista e a
            solicitação será excluída. Deseja prosseguir?
          </div>
          <div className="container-btn">
            <Ghost
              style={{ marginRight: 5 }}
              onClick={() => {
                setConfirmModalReject(false);
                setConfirmModal(true);
              }}
            >
              VOLTAR
            </Ghost>
            <Ghost customcolor="#f64e60" onClick={() => handleReject()}>
              CONTINUAR
            </Ghost>
          </div>
        </S.ContentDialogReject>
      </Dialog>

      <ConfirmModal
        open={confirmModal}
        onClick={handleConfirm}
        handleClose={() => setConfirmModal(false)}
        title={
          <div style={{ color: '#939aab', fontSize: 16 }}>
            {currentItem?.data_convite || ''}
          </div>
        }
        content={
          <S.ContentDialogConfirm>
            <div className="title-dialog">
              Essa empresa marcou você como um cliente dela
            </div>
            <div className="container-info-empresa">
              <div className="title-empresa">{currentItem?.nome || '-'}</div>
              <div className="cnpj-empresa">
                CNPJ: {currentItem?.cnpj || '-'}
              </div>
              <div className="cnpj-empresa">
                Motoristas: {currentItem?.n_motoristas_ativos || 0}
              </div>
            </div>
            <div className="footer-text">
              Caso você aceite, ela aparecerá na sua lista de empresas, desvios,
              estatísticas motoristas, frota, etc.
            </div>
          </S.ContentDialogConfirm>
        }
        customButton={
          <Ghost
            customcolor="#f64e60"
            onClick={() => {
              setConfirmModal(false);
              setConfirmModalReject(true);
            }}
          >
            Rejeitar
          </Ghost>
        }
        buttonText="Aceitar"
        hideButtonCancel
      />
    </S.Main>
  );
};

export default Empresas;
