import React, { useState } from 'react';
import { SvgIcon } from '@mui/material';
import { ReactComponent as info } from 'images/icons/toast/info.svg';
import { useTheme } from 'styled-components';
import * as S from './styled';

/*
Card Toast informativo
Some ao clicar, mas não é desmontado
*/
const InfoCard = ({
  message,
  variant = 'success',
  button = null,
  onClick = null,
}) => {
  const theme = useTheme();
  const [active, setActive] = useState(true);
  const handleClick = () => {
    if (onClick instanceof Function) onClick();
    setActive(false);
  };

  return (
    <S.StyledToast onClick={handleClick} active={active} variant={variant}>
      {active && (
        <>
          <SvgIcon
            htmlColor={theme.palette.semantics.feedback[variant].natural}
            component={info}
          />
          <h2>{message}</h2>
          {button && <S.EditButton>{button}</S.EditButton>}
        </>
      )}
    </S.StyledToast>
  );
};

export default InfoCard;
