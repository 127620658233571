import React, {
  useEffect,
  useState,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { HeaderlessTable } from 'components/_Table/templates/headerless';
import { usePlans } from 'hooks/usePlans';

import { columns, columnsEmbarcador } from '../../../columns';
import { requestDesvios, requestCount } from '../../../services';

export const List = forwardRef(
  (
    {
      idMotorista,
      idCaminhao,
      setSelectedInfractions,
      loadingLines,
      setLoadingLines,
      setIsFetching,
      setTableData,
      getActions,
      handleOpenNewTab,
    },
    ref,
  ) => {
    const { isOpLogistico, isProvider, hasTorre, hasTorrePlus } = usePlans();

    const filtersPersist = useSelector(state => {
      return state?.filter?.filters?.desvios;
    });

    const filterDesvios = useSelector(state => state.filterDesvios);

    const [resetTable, setResetTable] = useState(false);
    const [query, setQuery] = useState(null);

    const {
      refetch,
      isFetching,
      data: resData,
    } = useQuery(['desvios', query], () => query && requestDesvios(query), {
      refetchOnWindowFocus: false,
      refetchInterval: hasTorrePlus || hasTorre ? 60000 : false,
      onSuccess: resData => {
        setTableData(resData?.data?.data || []);
        setLoadingLines([]);
        resetTable && setResetTable(false);
      },
      onError: () => {
        setTableData([]);
      },
    });

    const initial = useRef(null);

    // Atualiza totalizador apenas quando muda filtros
    const {
      refetch: refetchCount,
      isFetching: loadingCount,
      data: resCount,
    } = useQuery(['count-desvios', query], () => requestCount(query), {
      refetchOnWindowFocus: false,
      refetchInterval: hasTorrePlus || hasTorre ? 60000 : false,
      staleTime: 0,
      enabled: !initial.current,
    });

    useImperativeHandle(ref, () => ({
      refetchLista: () => refetch(),
      refetchCount: () => refetchCount(),
    }));

    useEffect(() => {
      if (initial.current) {
        initial.current = false;
        return;
      }

      setResetTable(true);
    }, [filtersPersist, filterDesvios.status]);

    useEffect(() => {
      setIsFetching(isFetching);
    }, [isFetching]);

    // Atualiza as colunas de acordo com o status selecionado
    const handleColumns = () => {
      let _columns = isProvider && !isOpLogistico ? columnsEmbarcador : columns;
      if (isProvider && hasTorrePlus)
        _columns = [
          {
            header: 'Motorista/Transp',
            id: 'motorista_nome',
            type: 'string',
            tooltip: true,
            sort: true,
            conditional: [
              {
                condition: (_, item) => !!item.id_motorista,
                style: theme => ({
                  color: theme?.palette?.words.title.natural,
                }),
              },
              {
                condition: (_, item) => !item.id_motorista,
                style: theme => ({
                  color: theme?.palette?.words.subtitle.light,
                }),
              },
            ],
            subRow: {
              value: (_, item) => item?.empresa_nome,
            },
          },
          ..._columns.slice(1),
        ];

      if (filterDesvios.status === 'FINALIZADO')
        _columns[4] = {
          header: 'Data Conclusão',
          id: 'data_encerramento',
          type: 'date',
          sort: true,
        };
      else if (filterDesvios.status === 'DELETADO')
        _columns[4] = {
          header: 'Data Exclusão',
          id: 'data_exclusao',
          type: 'date',
          sort: true,
        };

      return _columns;
    };

    return (
      <HeaderlessTable
        data={resData?.data?.data || []}
        columns={handleColumns()}
        setSelectedRows={
          ['ABERTO', 'PENDENTE'].includes(filterDesvios.status) &&
          (!isProvider || isOpLogistico || hasTorrePlus)
            ? setSelectedInfractions
            : null
        }
        loading={isFetching || loadingCount}
        loadingCounter={loadingCount}
        pageCount={resCount?.data?.total || 0}
        visualizedKey="visto"
        local={false}
        actions={getActions()}
        onClickRow={id => {
          if (handleOpenNewTab instanceof Function) handleOpenNewTab(id);
        }}
        reset={resetTable}
        loadingSelection={loadingLines}
        setQuery={q =>
          setQuery({
            ...query,
            ...filtersPersist,
            ...q,
            status: filterDesvios.status,
            motorista: idMotorista ?? undefined,
            caminhao: idCaminhao ?? undefined,
          })
        }
        sortBy={{ id: 'data_desvio', order: 'DESC' }}
        empty={{
          title: 'Ops! Você não tem nenhum desvio disponível.',
          description: 'Verifique os filtros aplicados!',
        }}
      />
    );
  },
);
