/* eslint-disable import/order */

// Styles
import * as S from './styled';
import colors from 'styles/colors';
import { useTheme } from 'styled-components';

// Components
import Tag from '../../../Tag';
import GhostButton from 'components/Buttons/Ghost';

// Components MUI
import CloseIcon from '@mui/icons-material/Close';

// Constants
import formater from './constants';
import * as services from '../../services';
import { useState } from 'react';
import { toast } from 'react-toastify';

const img =
  'https://firebasestorage.googleapis.com/v0/b/onisysweb.appspot.com/o/capacitacao%2Finfo%2Fcapas%2F1673467756358%2F2023-01-11_17-09-19_Capa_Conteudo_Capacitacao.jpg?alt=media&token=3ca2cd3b-f1c8-438a-a277-88580f7cbdbd';

export const FinishModal = ({
  open,
  handleClose,
  status,
  conteudo,
  confirm,
  setCurentContent,
  setCurrentStatus,
  falseBack,
}) => {
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  const defineStatus = () => {
    switch (status?.toLowerCase()) {
      case 'aprovado':
        return formater[0];
      case 'concluido':
        return formater[2];
      default:
        return formater[1];
    }
  };

  let { title, Icon } = defineStatus();
  let tags = conteudo?.tags?.slice(0, 3)?.map(item => item?.tag);

  const resetNewTry = async () => {
    setLoading(true);
    let res = await services.tryNewTest(conteudo?.id);
    setLoading(false);

    if (res.success) {
      toast.success(res.message);
      // console.log(res.data);
      falseBack && falseBack();
      setCurrentStatus && setCurrentStatus(null);
      setCurentContent &&
        setCurentContent({
          ...res.data,
          midias: conteudo?.midias?.map(item => ({
            ...item,
            completedMidia: true,
          })),
        });
    } else {
      toast.error(res.message);
    }
    handleClose();
  };

  return (
    <S.ModalMUI open={open} onClose={handleClose}>
      <S.Container>
        <S.Header>
          <div className="titleArea">
            <Icon />
            <div className="title">{title}</div>
          </div>
          <CloseIcon
            onClick={
              confirm
                ? () => {
                    handleClose();
                    confirm();
                  }
                : () => handleClose()
            }
            htmlColor={colors.greySubtitle}
            style={{
              cursor: 'pointer',
            }}
          />
        </S.Header>
        <S.Infos>
          <div className="desc">{conteudo?.descricao}</div>
        </S.Infos>

        <S.TagsArea>
          {tags?.map((item, index) => (
            <Tag key={index} children={item} active />
          ))}
        </S.TagsArea>

        <S.Details>
          <div className="imageArea">
            <img src={conteudo?.imagem_capa || img} alt="capa" />
          </div>
          <div className="title">{conteudo?.titulo}</div>
        </S.Details>
        <S.Buttons>
          <GhostButton
            children="Fechar"
            customcolor={
              theme?.palette?.semantics?.feedback?.attention?.natural
            }
            onClick={
              confirm
                ? () => {
                    handleClose();
                    confirm();
                  }
                : () => handleClose()
            }
          />
          {status !== 'APROVADO' &&
            conteudo?.tentativas > conteudo?.tentativa_atual && (
              <GhostButton
                children="Refazer teste"
                onClick={() => resetNewTry()}
                loading={loading}
              />
            )}
        </S.Buttons>
      </S.Container>
    </S.ModalMUI>
  );
};
