import React, { useEffect, useState } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  ReferenceLine, // Importação do ReferenceLine
} from 'recharts';
import { startOfMonth, subYears } from 'date-fns';
import Tabs from 'components/Tabs';

// components custom
import { PeriodPicker } from 'pages/Estatisticas/Components/PeriodPicker';
import { CustomizedSelect } from '../SectionPeriod';
// styles
import * as S from './styled';

const CustomTick = props => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#939aab"
        transform="rotate(0)"
      >
        <tspan x={0} dy="20">
          {payload.value}
        </tspan>
      </text>
    </g>
  );
};

export const GraficoHitorico = ({
  data = [],
  handleDate,
  title = '',
  filter = 'EXCLUSAO',
  changeFilter = () => {},
}) => {
  const [finalDate, setFinalDate] = useState(new Date());
  const [initialDate, setIntitalDate] = useState(
    startOfMonth(subYears(new Date(), 1)),
  );

  const formatYAxis = tickItem => {
    return `${tickItem} min`;
  };

  const findMaxAverageMinutes = data => {
    return data.reduce(
      (max, entry) => (entry.quantidade > max.quantidade ? entry : max),
      data[0],
    );
  };

  // Função para calcular a média dos valores
  const calculateAverage = data => {
    const total = data.reduce((sum, entry) => sum + entry.quantidade, 0);
    return total / data.length || 0;
  };

  const average = calculateAverage(data);

  return (
    <S.Container>
      <div className="container-filters">
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'flex-start',
          }}
        >
          <div className="title">{title}</div>
          <div className="flex-gap">
            <PeriodPicker
              finalDate={finalDate}
              initialDate={initialDate}
              period="lastYear"
              periodAdd="lastYear"
              onChange={date => {
                setFinalDate(date.finalDate);
                setIntitalDate(date.initialDate);
                handleDate(date);
              }}
            />
          </div>
        </div>
        <div>
          <Tabs
            value={filter}
            onChange={(_, value) => {
              changeFilter(value);
            }}
            items={[
              {
                value: 'EXCLUSAO',
                label: 'EXCLUSÃO DE DESVIO',
              },
              {
                value: 'ALTERAÇÃO DE OPERAÇÃO',
                label: 'ALTERAÇÃO DE OPERAÇÃO',
              },
            ]}
          />
        </div>
      </div>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart
          data={data}
          margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="month_year"
            height={70}
            tick={<CustomTick />}
            interval={0}
          />
          <YAxis
            tickFormatter={formatYAxis}
            width={80}
            domain={[0, findMaxAverageMinutes(data)]}
          />
          <Tooltip />
          <Line
            type="linear"
            dataKey="quantidade"
            stroke="#0C12F2"
            dot={props => {
              const { index } = props;
              const isLastDot = index === data.length - 1;
              return (
                <circle
                  cx={props.cx}
                  cy={props.cy}
                  r={5}
                  fill={isLastDot ? 'none' : '#0C12F2'}
                  stroke={isLastDot ? '#0C12F2' : 'none'}
                  strokeWidth={isLastDot ? 2 : 0}
                />
              );
            }}
          />
          <ReferenceLine
            y={average}
            stroke="red"
            strokeDasharray="3 3"
            label={{
              value: `Prazo: ${average.toFixed(2)} min`,
              fill: 'red',
              position: 'insideBottomRight',
            }}
          />
        </LineChart>
      </ResponsiveContainer>
    </S.Container>
  );
};
