import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  transition: transform 0.5s ease;
  width: 100%;
`;
const Header = styled.div`
  display: flex;
  height: 3.125rem;
  width: 100%;
  flex-direction: row;
  .counter-task {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-weight: 900;
    background-color: #f64e60;
    width: 32px;
    height: 32px;
    border-radius: 100%;
  }
  .buttonHeader {
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 48px;
    background: #4b5166;
    border-radius: 0.25rem;
    opacity: 0.2;
    margin-right: 1rem;
    font-weight: 900;
    font-size: 1rem;
    font-style: normal;
    color: #fff;
    transition: opacity 0.3s ease;
    span {
      margin-left: 0.625rem;
    }
    @media (min-width: 768px) and (max-width: 900px) {
      font-size: 0.72rem;
    }
    @media (min-width: 2560) {
      font-size: 5rem;
    }
  }
  .buttonHeader.active {
    opacity: 1;
  }
  .buttonHeader:hover {
    opacity: 1;
  }
  .buttonFullScreen {
    display: flex;
    min-width: 48px;
    height: 48px;
    background: #ff8040;
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    cursor: default;
  }
`;

const Content = styled.div`
  width: 100%;
`;

export { Container, Header, Content };
