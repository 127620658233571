import React from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';

// components custom
import { Skeleton } from '@mui/material';

// styles
import * as S from './styled';
import Tabs from 'components/FiltersGlobal/Tabs';

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <div
        style={{
          backgroundColor: '#fff',
          padding: '10px',
          border: '1px solid #ccc',
        }}
      >
        {payload[0].payload.key !== label && <p>{payload[0].payload.key}</p>}
        <p>{label}</p>
        <p
          style={{ color: payload[0].color || '#000' }}
        >{`${payload[0].name} : ${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

const CustomTick = props => {
  const { x, y, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#939aab"
        transform="rotate(0)"
      >
        <tspan x={0} dy="20">
          {payload.value}
        </tspan>
      </text>
    </g>
  );
};

const tabsDates = [
  { value: 'day', label: 'D' },
  { value: 'week', label: 'S' },
  { value: 'month', label: 'M' },
];

export const History = ({
  titulo = '',
  data = [],
  loading,
  handlePeriodFilter,
  period = 'day',
  interval = 'auto',
}) => {
  return (
    <S.Container print={print}>
      <div className="container-filters">
        <div className="graphtitle">{titulo}</div>
        <div className="titletop">
          <Tabs
            tab={period}
            tabs={tabsDates}
            handleApplay={handlePeriodFilter}
          />
        </div>
      </div>
      {loading ? (
        <Skeleton
          style={{ margin: '1rem' }}
          width="98%"
          height={174}
          animation="wave"
          variant="rounded"
        />
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            height={300}
            data={data}
            margin={{ top: 10, right: 40, left: 20, bottom: 5 }}
          >
            {data.length === 0 && (
              <text
                x="50%"
                y="50%"
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize={22}
                fill="#999"
              >
                Nenhum dado encontrado, tente usar outros filtros.
              </text>
            )}
            <CartesianGrid vertical={false} />
            <XAxis
              opacity={0.5}
              padding={{ left: 0, right: 0 }}
              dataKey="name"
              height={70}
              tick={<CustomTick />}
              tickLine={false}
              interval={interval}
            />

            {data.length !== 0 && (
              <YAxis
                opacity={0.5}
                padding={{ left: 0, right: 0 }}
                tickLine={false}
                minTickGap={-20}
              />
            )}
            <Tooltip content={<CustomTooltip />} />

            <Line
              type="linear"
              dataKey="x"
              name="Valor"
              stroke="#0C12F2"
              dot={{ r: 0 }}
            />
          </LineChart>
        </ResponsiveContainer>
      )}
    </S.Container>
  );
};
