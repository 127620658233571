import styled from 'styled-components';

export const Main = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 2px solid ${props => props.color};
  border-radius: 4px;
  margin-bottom: 16px;
  padding: 20px;
  overflow: hidden;

  .sub-container {
    width: 100%;
    height: 102px;
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
  .risk {
    text-align: center;
    font-size: 11px;
    font-family: Texta;
    font-style: normal;
    font-weight: 500;
    letter-spacing: 0px;
    color: #939aab;
    white-space: nowrap;
  }
  .criticidade {
    border: 1px solid ${props => props.color};
    color: ${props => props.color};
    padding: 2px 10px 2px 10px;
    text-align: center;
    border-radius: 4px;
    margin: 8px 0;
  }
  .info {
    text-align: center;
    font-size: 14px;
    font-weight: 900;
    color: #0c12f2;
  }
  .space {
    margin-right: 15px;
  }
  .fullwidth {
    width: 100%;
  }
  .container-user {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    .points {
      text-align: left;
      font-size: 16px;
      font-weight: 500;
      font-style: normal;
      letter-spacing: 0px;
      color: #4b5166;
    }
  }
  .vehicle {
    text-align: left;
    font-size: 24px;
    font-weight: 400;
    font-style: normal;
    color: #4b5166;

    .placa {
      font-weight: 900;
    }

    .empresa {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .driver-name {
    text-align: left;
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 0px;
    font-style: normal;
    color: #4b5166;
    opacity: 1;
  }
  .vehicle-name {
    display: inline-block;
    text-align: left;
    font-size: 18px;
    font-weight: 450;
    letter-spacing: 0px;
    font-style: normal;
    color: #4b5166;
    opacity: 1;
    margin-left: 1%;
  }
  .driver-footer {
    text-align: left;
    font-weight: 500;
    font-size: 11px;
    letter-spacing: 0px;
    color: #939aab;
  }
  .driver-descrition {
    text-align: left;
    letter-spacing: 0px;
    color: #4b5166;
    opacity: 1;
  }

  .driver-descrition span {
    font: normal normal 900 16px/24px Texta;
    color: #4b5166;
  }

  .last-location {
    text-align: left;
    font-size: 11px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #939aab;
    opacity: 1;
    white-space: nowrap;
  }

  .total-desvio {
    min-width: 10rem;
    text-align: left;
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0px;
    color: #4b5166;
    white-space: nowrap;
  }
  .total-desvio span {
    font-size: 32px;
    font-weight: 900;
    letter-spacing: 0px;
    color: #4b5166;
  }
  .details {
    height: 499px;
    width: 100%;
    transition: height 0.5s ease;
    overflow: scroll;
    padding-right: 10px;
  }

  .table-header {
    background-color: #edf2f9;
    height: 50px;
    color: #939aab;
    padding: 10px;
    font: normal normal 900 13px/14px Texta;
  }
  .status-pendente {
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #4b5166;
  }
  .input-text {
    border: 1px solid #939aab4d;
    border-radius: 4px;
    background: #f9fbfd;
    width: 100%;
    height: 90px;
    font-size: 16px;
    letter-spacing: 0px;
    color: #939aab;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 10px;
  }
  .tratado {
    display: flex;
    justify-content: flex-start;
    font: normal normal medium 14px/24px Texta;
    letter-spacing: 0px;
    color: #ff8040;
  }
  .tag-tratado {
    background: #4b5166;
    text-align: center;
    font: normal normal medium 14px/24px Texta;
    letter-spacing: 0px;
    border-radius: 12px;
    color: #ffffff;
    padding-left: 20px;
    padding-right: 20px;
    margin-left: 20px;
  }
  .justify-container {
    width: 100%;
    height: 15%;
    font-size: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #4b5166;
  }
`;
