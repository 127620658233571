import React from 'react';
import * as S from './styled';
import { Item } from './Item';
import { Skeleton } from '@mui/material';

export const RodoviasPerigosas = ({ data, isLoading = false }) => {
  const dataComEndereco = data.filter(item => item.endereco);

  const rodovias = dataComEndereco.reduce((acc, curr) => {
    const item = acc.find(i => i.endereco === curr.endereco);
    if (item) item.value += 1;
    else acc.push({ endereco: curr.endereco, value: 1 });
    return acc;
  }, []);

  rodovias.sort((a, b) => b.value - a.value);

  return (
    <S.Container>
      <S.Title>Rodovias mais perigosas</S.Title>
      <S.ContainerList>
        {isLoading
          ? [1, 2, 3, 4, 5].map((item, index) => (
              <Skeleton key={item?.endereco} width="100%" animation="wave">
                <Item index={index + 2} text="" />
              </Skeleton>
            ))
          : rodovias
              .slice(0, 4)
              .map((item, index) => (
                <Item
                  index={index}
                  key={item?.endereco}
                  text={item?.endereco || ''}
                  infractions={item?.value}
                />
              ))}
      </S.ContainerList>
    </S.Container>
  );
};
