/* eslint-disable import/order */

// Styles
import * as S from './styled';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { useTheme } from 'styled-components';

// React
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';

// Components
import Loading from 'components/Loading';
import ModalAddFrota from './ModalAddFrota';
import Card from 'components/Cards/Indicador';
import InfoCard from 'components/Cards/InfoCard';
import ConfirmModal from 'components/ConfirmModal';
import ExcelModal from 'components/ExcelModalNew';

// Components MUI
import { Grid } from '@mui/material';
import { EditOutlined, DeleteOutline } from '@mui/icons-material';
import FiltersGlobal from 'components/FiltersGlobal';

// Utils
import { trackEvent } from 'utils/mixpanel';
import { usePlans } from 'hooks/usePlans';
import { generateFileName } from 'utils/generateFileName';

// Constants
import { fields, cardsFrotaInit, columnsFrota2 } from './constants';

// Services
import { changeStatus, changeStatusMany, getTrucks } from './services';
import { DefaultTable } from 'components/_Table/templates/default';
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';

const Frota = () => {
  const theme = useTheme();
  const { departamento, cargas } = useFetchMultipleWithCache();

  const navigate = useNavigate();
  const { hasFadiga, hasTelemetria, hasMonitoramento, hasTorrePlus } =
    usePlans();

  // Redux e hooks
  const filterFrota = useSelector(state => state.filter?.filters?.frota);
  const user = useSelector(state => state.auth?.user?.user);
  const selects = useSelector(state => state.selects);

  const optionsEmpresa = selects.empresas;

  const filtersPersist = useSelector(state => {
    return state?.filter?.filters?.frota;
  });

  // Data
  const [data, setData] = useState([]);
  const [triggerUpdate, setTriggerUpdate] = useState(true);
  const [loading, setLoading] = useState(false);

  // Edit modal
  const [dataEdit, setdataEdit] = useState(null);

  // Seleção tabela frota
  const [selectedData, setSelectedData] = useState(null);
  const [idConfirmChangeStatus, setIdConfirmChangeStatus] = useState(false);
  const [idsConfirmDesativar, setIdsConfirmDesativar] = useState(false);
  const [idsConfirmAtivar, setIdsConfirmAtivar] = useState(false);
  const [loadingLines, setLoadingLines] = useState([]);

  // Cards
  const [cardsFrota, setCardsFrota] = useState(
    cardsFrotaInit.filter(cf => {
      if (
        hasMonitoramento ||
        (!['posicaoInadequada', 'canalIncorreto'].includes(cf.name) &&
          !hasMonitoramento)
      ) {
        return cf;
      }
    }),
  );

  // Excel Fields
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelFields, setExcelFields] = useState(fields);

  // ---------------------  FROTA ---------------------

  const [trucks, setTrucks] = useState([]);

  useEffect(() => {
    const getProviderTrucks = async () => {
      setLoading(true);
      const res = await getTrucks();
      setLoading(false);
      setTrucks(res.data.data);
      setTriggerUpdate(false);
    };
    triggerUpdate && getProviderTrucks();
  }, [triggerUpdate]);

  // Aplica filtros em frota
  useEffect(() => {
    // Cria newData com trucks filtrado

    const newData = trucks.filter(truck => {
      const res =
        (!filterFrota.empresa ||
          filterFrota.empresa.length === 0 ||
          filterFrota.empresa.some(c => c == truck.empresa?.id)) &&
        (!filterFrota.departamento ||
          filterFrota.departamento.length === 0 ||
          filterFrota.departamento.some(c => c == truck.departamento)) &&
        (!filterFrota.propriedade ||
          filterFrota.propriedade.length === 0 ||
          filterFrota.propriedade.some(c => c === truck.propriedade)) &&
        (!filterFrota.carga ||
          filterFrota.carga.length === 0 ||
          filterFrota.carga.some(c => c === truck.id_da_carga));
      return res;
    });

    // Atualiza valores dos cards com base nos dados filtrados
    // Armazena dados de newData filtrados pelo card em dataCardSeleted caso algum esteja selecionado
    let dataCardSeleted;
    const newCardsFrota = cardsFrota.map(card => {
      if (card.name === 'ativas') {
        trackEvent(user, 'FROTA: VISUALIZAR MOTORISTAS ATIVOS');
        const cardData = newData.filter(item => item.status === 'ATIVO');
        card.value = cardData.length;
        if (card.selected) dataCardSeleted = cardData;
      } else if (card.name === 'posicaoInadequada') {
        const cardData = newData.filter(item => item.pos_camera === 'NAO');
        card.value = cardData.length;
        if (card.selected) dataCardSeleted = cardData;
      } else if (card.name === 'canalIncorreto') {
        const cardData = newData.filter(item => item.dvr === 'NAO');
        card.value = cardData.length;
        if (card.selected) dataCardSeleted = cardData;
      }
      return card;
    });
    setCardsFrota(newCardsFrota);

    // Atualiza data com newData ou dataCardSeleted se algum card for selecionado
    if (dataCardSeleted) setData(dataCardSeleted);
    else setData(newData);
  }, [
    filterFrota,
    cardsFrota[0].selected,
    cardsFrota[1]?.selected || null,
    cardsFrota[2]?.selected || null,
    trucks,
  ]);

  // Ativa / inativa card clicado. Inativa os demais
  // Seleciona / limpa dados filtrados do card em dataCard
  const handleClickCards = name => {
    trackEvent(user, 'FROTA: DISPONIBILIDADE POR PERÍODO');

    const newCards = cardsFrota.map(card => {
      if (name === card.name) {
        if (card.selected !== undefined) {
          card.selected = !card.selected;
        }
      } else if (card.selected) card.selected = false;
      return card;
    });

    setCardsFrota(newCards);
  };

  const handleEditar = id => {
    trackEvent(user, 'FROTA: EDITAR VEÍCULO');

    const truckEdit = data?.find(truck => String(truck.id) === String(id));
    if (truckEdit) setdataEdit(truckEdit);
  };

  const handleHistorico = id => {
    navigate(`/logs/frota/${id}`);
  };

  const handleSetDesativarAtivar = truckId => {
    const truckEdit = data?.find(truck => String(truck.id) === String(truckId));
    setIdConfirmChangeStatus({ id: truckId, status: truckEdit.status });
  };

  const formatTable = data => {
    if (hasTorrePlus) {
      data = data.filter(item => item?.id !== 'modelo');
    } else {
      data = data.filter(item => item?.id !== 'empresa.nome');
    }
    return data;
  };

  // Finaliza edição de status
  // setTriggerUpdate(true) força atualização dos dados armazenados
  const handleDesativarAtivar = async truckId => {
    setLoadingLines([truckId]);
    const res = await changeStatus(truckId);
    if (res.data?.success) {
      toast.success(res.data.message);
      setTriggerUpdate(true);
    } else if (res.data?.message) toast.error(res.data.message);
    setIdConfirmChangeStatus(null);
    setLoadingLines([]);
  };

  const handleDesativarAtivarMassa = async (newStatus, truckIds) => {
    const data = { newStatus, truckIds };
    setLoadingLines(truckIds);
    const res = await changeStatusMany(data);
    if (res.data?.success) {
      toast.success(res.data.message);
      setTriggerUpdate(true);
    } else if (res.data?.message) toast.error(res.data.message);
    setIdsConfirmDesativar(null);
    setIdsConfirmAtivar(null);
    setLoadingLines([]);
  };

  // ---------------------  RENDER: FROTA  ---------------------
  const filtersProvider = [
    {
      filterName: 'empresa',
      label: 'Empresa',
      options:
        optionsEmpresa?.map(item => {
          return {
            value: item.id,
            label: item.nome,
          };
        }) || [],
    },
    {
      filterName: 'departamento',
      label: 'Departamento',
      options: departamento?.data || [],
    },
    {
      filterName: 'propriedade',
      label: 'propriedade',
      options: [
        { label: 'Próprio', value: 'PRÓPRIO' },
        { label: 'Agregado', value: 'AGREGADO' },
      ],
    },
    {
      filterName: 'carga',
      label: 'carga',
      options: cargas?.data || [],
    },
  ];

  const renderTop = () => {
    return (
      <>
        <div style={{ marginTop: 15 }} />
        <FiltersGlobal
          data={filtersProvider}
          hideRefleshButton
          customComponent={<h1>Veículos</h1>}
          persistDate
          handleExport={() => setOpenExcelModal(true)}
        />
        <div style={{ padding: '0px 0px' }}>
          <InfoCard message="Gerencie sua frota. Você pode adicionar, editar, ativar e inativar caminhões." />
        </div>
      </>
    );
  };

  const renderCards = () => {
    return (
      <Grid container spacing={2} marginBottom="25px">
        {cardsFrota?.map(card => {
          return (
            <Grid item key={card.name} xs={12} md={4} xl={4}>
              <Card
                value={card.value}
                icon={card.icon}
                text={card.text}
                handleClick={() => handleClickCards(card.name)}
                selected={card.selected}
                round={false}
              />
            </Grid>
          );
        })}
      </Grid>
    );
  };

  // ---------------------  RENDER: FROTA ---------------------

  const renderTableFrota = () => {
    const actions = [
      { title: 'Ver detalhes', function: handleEditar },
      {
        title: 'Histórico alterações',
        function: handleHistorico,
      },
      {
        title: 'Ativar/Desativar',
        function: handleSetDesativarAtivar,
      },
    ];

    const bulkActions = [
      {
        title: 'Desativar',
        function: () => {
          trackEvent(user, 'FROTA: DESATIVAR TODOS OS VEÍCULOS');
          setIdsConfirmDesativar(selectedData);
        },
      },
      {
        title: 'Ativar',
        function: () => {
          trackEvent(user, 'FROTA: ATIVAR TODOS OS VEÍCULOS');
          setIdsConfirmAtivar(selectedData);
        },
      },
    ];

    // ------------------------------ EXCEL ------------------------------//
    // const handleRequestExcel = async () => {
    //   setLoadingExcel(true);

    //   const newFields = excelFields.filter(item => item.selected === true);
    //   const formatedDate = formatNameDate(new Date());

    //   const newQuery = { ...filter, ...filterFrota, excelFields: newFields };

    // const res = await requestExcel(newQuery);
    // if (res.data && res.data?.data?.excel)
    //   ExportToExcel({
    //     excel: res.data.data.excel,
    //     name: `frota_${formatedDate}`,
    //   });
    // else if (res.data.message) toast.error(res.data?.message);

    //   setExcelFields(resetExcelFields(fields));
    //   setLoadingExcel(false);
    //   setOpenExcelModal(false);
    // };

    // ------------------------------ RENDER ------------------------------//
    return (
      <DefaultTable
        data={data || []}
        columns={formatTable(
          columnsFrota2.filter(cf => {
            if (
              hasMonitoramento ||
              (!['pos_camera', 'dvr'].includes(cf.id) && !hasMonitoramento)
            ) {
              return cf;
            }
          }),
        )}
        actions={actions}
        bulk={bulkActions}
        searchKeys={['placa', 'frota', 'modelo']}
        loading={loading}
        placeholder="Buscar por Placa, Frota ou Modelo"
        setSelectedRows={setSelectedData}
        sortBy={{ id: 'placa', order: 'ASC' }}
        loadingSelection={loadingLines}
        searchEvent={search =>
          trackEvent(user, 'Busca Gestão de Frotas', null, search)
        }
      />
    );
  };

  const renderModalEditFrota = () => {
    return (
      dataEdit && (
        <ModalAddFrota
          open={Boolean(dataEdit)}
          dataEdit={dataEdit}
          handleClose={() => setdataEdit(null)}
          setTriggerUpdate={setTriggerUpdate}
        />
      )
    );
  };

  // ---------------------  RENDER: DISPONIBILIDADE ---------------------

  const checkFilds = excelFields => {
    let tmp = excelFields;

    if (!hasTelemetria) {
      tmp = tmp.filter(item => item.value !== 'telemtria_ultima_posicao');
    }
    if (!hasMonitoramento) {
      tmp = tmp.filter(item => item.value !== 'fadiga_ultimo_evento');
    }
    if (!hasFadiga) {
      tmp = tmp.filter(item => item.value !== 'camera_ultimo_video');
    }
    return tmp;
  };

  // Componentes da página
  const renderMain = () => (
    <>
      <S.Main>
        {renderTop()}
        {renderCards()}
        {renderTableFrota()}
      </S.Main>

      {renderModalEditFrota()}

      {openExcelModal && (
        <ExcelModal
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          excelFields={checkFilds(excelFields)}
          query={{
            ...filtersPersist,
          }}
          file_name={generateFileName(
            window.location.pathname.split('/').pop(),
            filtersPersist,
          )}
          route="/excel-provider/frota"
          filtersPersis={filtersPersist}
          collumns={filtersProvider}
          isManualDownload
        />
      )}

      {
        /* Confirmação de ativacao / desativacao */
        idConfirmChangeStatus && (
          <ConfirmModal
            open={Boolean(idConfirmChangeStatus)}
            handleClose={() => setIdConfirmChangeStatus(null)}
            title={
              idConfirmChangeStatus.status === 'ATIVO'
                ? 'Deseja desativar este veículo?'
                : 'Deseja reativar este veículo?'
            }
            titleIcon={
              idConfirmChangeStatus.status === 'ATIVO' ? (
                <DeleteOutline
                  sx={{
                    color: theme.palette.semantics.feedback.attention.natural,
                  }}
                  fontSize="medium"
                />
              ) : (
                <EditOutlined
                  sx={{ color: theme.palette.brand.secondary.natural }}
                  fontSize="medium"
                />
              )
            }
            subtitle="Veículos inativos não geram avaliações e não são contabilizados na disponibilidade!"
            buttonText="Confirmar"
            onClick={() => {
              trackEvent(
                user,
                idConfirmChangeStatus.status === 'ATIVO'
                  ? 'FROTA: DESATIVA VEÍCULO'
                  : 'FROTA: ATIVAR VEÍCULO',
              );
              handleDesativarAtivar(idConfirmChangeStatus.id);
            }}
            loading={loading}
          />
        )
      }

      {
        /* Confirmação de ativacao em massa */
        idsConfirmAtivar && (
          <ConfirmModal
            open={Boolean(idsConfirmAtivar)}
            handleClose={() => setIdsConfirmAtivar(null)}
            title={`Deseja reativar ${idsConfirmAtivar.length} veículos?`}
            titleIcon={
              <EditOutlined
                sx={{ color: theme.palette.brand.secondary.natural }}
                fontSize="medium"
              />
            }
            subtitle="Todos os veículos marcados serão reativados!"
            buttonText="Confirmar"
            onClick={() =>
              handleDesativarAtivarMassa('ATIVO', idsConfirmAtivar)
            }
            loading={loading}
          />
        )
      }

      {
        /* Confirmação de desativacao em massa */
        idsConfirmDesativar && (
          <ConfirmModal
            open={Boolean(idsConfirmDesativar)}
            handleClose={() => setIdsConfirmDesativar(null)}
            title={`Deseja desativar ${idsConfirmDesativar.length} veículos?`}
            titleIcon={
              <DeleteOutline
                sx={{
                  color: theme.palette.semantics.feedback.attention.natural,
                }}
                fontSize="medium"
              />
            }
            subtitle="Todos os veículos marcados serão desativados!
          Veículos inativos não geram avaliações e não são contabilizados na disponibilidade."
            buttonText="Confirmar"
            onClick={() =>
              handleDesativarAtivarMassa('INATIVO', idsConfirmDesativar)
            }
            loading={loading}
          />
        )
      }
    </>
  );

  return loading ? <Loading /> : renderMain();
};

export default Frota;
