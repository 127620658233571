import * as React from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import {
  ListItemContainer,
  ItemContainer,
  ContainerPushNotification,
} from '../styled';
import { ReactComponent as ListIcon } from 'images/notifiations/list.svg';
import onisysIcon from 'images/Onisys_Simbolo.png';
import { ReactComponent as IconClose } from 'images/notifiations/iconclose.svg';
import { useNavigate } from 'react-router-dom';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

export const ShowNotification = ({
  open = false,
  handleRead = () => {},
  handleClose = () => {},
  data = [],
}) => {
  const navigate = useNavigate();

  const handleListItemClick = (event, item) => {
    handleClose();
    navigate(item.link);
  };

  return (
    <ContainerPushNotification>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
            padding: '15px',
          }}
        >
          <img src={onisysIcon} alt="onisys" height={50} width={53} />
          <div
            style={{
              color: '#4B5166',
              fontWeight: '900',
              fontStyle: 'normal',
              fontSize: '20px',
            }}
          >
            Enquanto você estava fora
          </div>
          <div style={{ transform: 'translateY(-25px)' }}>
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: theme => theme.palette.grey[500],
              }}
            >
              <IconClose />
            </IconButton>
          </div>
        </div>
        {/*
        </IconButton> */}
        <DialogContent sx={{ width: '400px', minHeight: '323px' }}>
          <List component="nav" aria-label="main mailbox folders">
            {data.map((item, index) => (
              <>
                <ListItem
                  key={index}
                  sx={{ padding: 0, height: 69, alignItems: 'flex-start' }}
                  // selected={!item?.visualizado}
                  onClick={event => handleListItemClick(event, item)}
                >
                  <ListItemContainer>
                    <ItemContainer>
                      <div className="title">
                        <div>{item?.descricao}</div>

                        <div className="group">
                          <ListIcon style={{ marginTop: 5 }} />
                          <span>{item?.total}</span>
                        </div>
                      </div>
                      <div className="footer">
                        <div className="time">{item?.time}</div>
                        <div
                          className="link"
                          onClick={event => handleListItemClick(event, item)}
                        >
                          {item.linkText}
                        </div>
                      </div>{' '}
                    </ItemContainer>
                  </ListItemContainer>
                </ListItem>
                <Divider />
              </>
            ))}
          </List>
        </DialogContent>
        <DialogActions sx={{ borderBottom: '6px solid #ff8040' }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <div
              style={{
                color: '#939aab',
                fontSize: '14px',
                fontWeight: 'normal',
                fontStyle: 'normal',
                cursor: 'pointer',
              }}
              onClick={() => {
                handleClose();
                handleRead();
              }}
            >
              Marcar tudo como lido
            </div>
            <div
              style={{
                color: '#ff8040',
                fontSize: '14px',
                fontWeight: 'normal',
                fontStyle: 'normal',
                textDecoration: 'underline',
                cursor: 'pointer',
              }}
              onClick={() => {
                handleClose();
                handleRead();
              }}
            >
              Ignorar
            </div>
          </div>
        </DialogActions>
      </BootstrapDialog>
    </ContainerPushNotification>
  );
};
