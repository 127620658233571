import styled, { css } from 'styled-components';

export const Container = styled.div`
  align-items: center;
  flex-direction: column;
  .circle {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #939aab;
    margin-right: 5px;
  }
  .circle2 {
    width: 10px;
    height: 10px;
    border-radius: 100%;
    background-color: #4b5166;
    margin-right: 5px;
  }
  .text {
    margin-right: 10px;
    font-size: 13px;
  }
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Legenda = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: 0px;
  margin-top: 15px;
  margin-bottom: 15px;
  .title-radar {
    font-size: 24px;
    font-weight: 900;
    font-style: normal;
    letter-spacing: 0px;
    color: #4b5166;
  }
`;

export const ContainerGraph = styled.div`
  overflow-x: auto;
  overflow-y: hidden;

  &::-webkit-scrollbar-thumb {
    background: #4b5166;
    opacity: 0.1;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #4b5160;
  }
`;

export const TooltipContainer = styled.div`
  background-color: #ffffff;
  padding: 10px;
  border: 1px solid #cccccc;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  font-size: 12px;
  .title {
    font-weight: bold;
  }
`;
