import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';

const ButtonApply = styled(Button)`
  && {
    ${({ theme }) => css`
      height: 32px;
      background: ${theme.palette.secondary.main};
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 16px;
      gap: 4px;
      font-family: 'Texta';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      color: ${theme.palette.words.text.contrast};
      text-transform: none;
      margin-right: 8px;
    `}
  }
`;

const DefaultPopover = ({
  children,
  onClick = () => {},
  style = {},
  startIcon = <></>,
}) => (
  <ButtonApply
    startIcon={startIcon}
    onClick={() => onClick()}
    style={{ ...style }}
  >
    {children}
  </ButtonApply>
);

export { DefaultPopover };
