import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  min-width: 100%;
  flex-direction: column;
  .item-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 5px;
    justify-content: space-between;
    .description {
      font: normal normal 900 24px/30px Texta;
      letter-spacing: 0px;
      color: #4b5166;
    }
  }
  .desvios-list {
    background: rgba(75, 81, 102, 0.1);
    border-radius: 12px;
    color: '#4B5166';
    height: 24px;
    margin-left: 20px;
    margin-top: 5px;
    font-weight: bold;
    padding-left: 15px;
    padding-right: 15px;
  }
  .container-actions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    margin-top: 40px;
    .icon {
      background: #edf2f9;
      border-radius: 50%;
      width: 33px;
      height: 33px;
    }
    .btn-add {
      background: #edf2f9;
      border: 1px solid #939aab4d;
      border-radius: 4px;
      text-align: center;
      padding-left: 20px;
      padding-right: 20px;
      padding-top: 5px;
      padding-bottom: 5px;
      margin-left: 20px;
      color: #4b5166;
      font-size: 16px;
      cursor: pointer;
      user-select: none;
    }
    .btn-add:hover {
      opacity: 0.8;
    }
  }
`;
