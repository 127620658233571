import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';

// Components
import Loading from 'components/Loading';
import Stepper from 'components/Stepper';
import GhostButton from 'components/Buttons/Ghost';
import * as S from './styled';

// Constants
import { steps } from './constants';

// Services
import * as services from './services';

const CriacaoAvaliacao = () => {
  // Navigate
  const navigate = useNavigate();
  const theme = useTheme();

  // Avaliação (states)
  const [evalId, setEvalId] = useState(null);
  const [evaluation, setEvaluation] = useState(null);
  const [initialDate, setInitialDate] = useState(null);
  const [finalDate, setFinalDate] = useState(null);

  // Controller Page (states)
  const [loading, setLoading] = useState(false);
  const [changed, setChanged] = useState(false);
  const [trySave, setTrySave] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [recharge, setRecharge] = useState(false);
  const [currentStep, setCurrentStep] = useState(steps[0]);
  const [saveImg, setSaveImg] = useState(false);

  // --------------------CONTROLLER PAGE (Functions)--------------------------------//
  const handleBackPage = () => {
    if (currentStep.step !== 1) {
      setCurrentStep(steps[currentStep.value - 1]);
      setChanged(false);
    } else {
      navigate(-1);
    }
  };

  const handleNext = () => {
    if (currentStep.step < steps.length) {
      setCurrentStep(steps[currentStep.value + 1]);
      setChanged(false);
    }
  };

  const changePage = () => {
    const { Component } = currentStep;
    return (
      <Component
        trySave={trySave}
        disabled={disabled}
        setEvalId={setEvalId}
        setChanged={setChanged}
        setTrySave={setTrySave}
        evaluation={evaluation}
        setDisabled={setDisabled}
        setRecharge={setRecharge}
        setEvaluation={setEvaluation}
        setCurrentStep={setCurrentStep}
        saveImg={saveImg}
        setSaveImg={setSaveImg}
        initialDate={initialDate}
        finalDate={finalDate}
      />
    );
  };

  const handleSave = () => {
    // This state is used for start a request in sub components
    // They have a useEffect to verify true or false and dispatch request
    setTrySave(true);
  };

  // -----------------------------REQUEST-----------------------------------//
  // This request is necessary after first page, for render new values

  useEffect(() => {
    if (recharge) {
      setLoading(true);
      getEvaluation();
    }
  }, [recharge]);

  const getEvaluation = async () => {
    if (evalId) {
      const res = await services.requestEvaluation(evalId);
      setRecharge(false);
      setChanged(false);

      if (res.success === false) {
        toast.error(res.message);
        return;
      }

      const ev = res.data.evaluation;
      const dataEval = res.data.evaluation.hora_inicial;
      setEvaluation(res.data.evaluation);

      if (ev.status === 'ASSINADA' || ev.status === 'PREENCHIDA') {
        toast.warning(
          'Avaliação assinada por motorista e/ou avaliador não pode ser modificada',
        );
        setDisabled(true);
      } else {
        setDisabled(false);
      }

      const day = new Date(dataEval).getDay();

      // Inicio da semana da avaliaçao
      const beforeDate = new Date(dataEval).setDate(
        new Date(dataEval).getDate() - day,
      );
      setInitialDate(new Date(beforeDate).toDateString());

      // Fim da semana da avaliaçao
      const afterDate = new Date(beforeDate).setDate(
        new Date(beforeDate).getDate() + 6,
      );
      setFinalDate(new Date(afterDate).toDateString());

      setLoading(false);
    }
  };

  // -------------------------CONTROLLER FOOTER BUTTONS----------------------------//
  const saveButton = () => {
    let valid = false;
    if (currentStep.step == 3 && changed) {
      valid = true;
    } else if (currentStep.step == 1 && !evaluation) {
      valid = true;
    } else if (currentStep.step == 1 && evaluation && changed) {
      valid = true;
    } else {
      valid = false;
    }

    if (valid) {
      return (
        <GhostButton onClick={() => handleSave()}>Salvar e Avançar</GhostButton>
      );
    }
  };

  const nextButton = () => {
    let valid = false;
    if (currentStep.step == 1 && evaluation && !changed) {
      valid = true;
    } else if (currentStep.step === 3 && !changed) {
      valid = true;
    } else if (currentStep.step == 2) {
      valid = true;
    } else {
      valid = false;
    }

    if (valid) {
      return <GhostButton onClick={() => handleNext()}>Avançar</GhostButton>;
    }
  };

  return (
    <S.Container>
      <Stepper steps={steps} currentStep={currentStep.step} />

      <S.HeaderPage>
        <div className="titlePage">{currentStep.title}</div>
      </S.HeaderPage>

      <div style={{ marginBottom: '60px' }}>
        {loading && (
          <S.LoadingBox>
            <Loading />
          </S.LoadingBox>
        )}
        {!loading && evaluation && currentStep.step !== 1 && changePage()}
        {!loading && currentStep.step === 1 && changePage()}
      </div>

      <S.ControllerArea>
        <div className="statusArea">
          {changed && 'Alterações não salvas'}
          {!changed && 'Informações Atualizadas'}
        </div>

        <div className="buttonsArea">
          <GhostButton
            className="backArea"
            customcolor={theme.palette.semantics.feedback.attention.dark}
            onClick={() => handleBackPage()}
          >
            Voltar
          </GhostButton>

          {saveButton()}
          {nextButton()}

          {currentStep.step == 4 && (
            <GhostButton
              onClick={() =>
                navigate(`/avaliacoes-descarga/${evaluation.id}`, {
                  replace: true,
                })
              }
            >
              Finalizar
            </GhostButton>
          )}
        </div>
      </S.ControllerArea>
    </S.Container>
  );
};

export default CriacaoAvaliacao;
