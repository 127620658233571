import React from 'react';
import { useQuery } from 'react-query';
import { useTheme } from 'styled-components';

import Loading from 'components/Loading';
import { getCriticidadeColor } from 'pages/Desvios/Listagem/constants';

import * as S from './styled';
import { requestEmpresasInfractions } from '../../services';

export const ListaDesvios = ({ id, filters }) => {
  const theme = useTheme();
  const { isFetching, data } = useQuery(
    ['empresas-maiores-desvios', id, filters],
    () => filters && requestEmpresasInfractions(id, filters),
    {
      refetchOnWindowFocus: false,
      enabled: !!id,
    },
  );

  return (
    <S.Container>
      <S.Header>
        <S.Title>Desvios mais cometidos</S.Title>
      </S.Header>
      {isFetching ? (
        <S.BoxLoading>
          <Loading />
        </S.BoxLoading>
      ) : (
        <S.List>
          {data?.data?.data?.map((item, idx) => (
            <li key={idx}>
              <S.StyledCard>
                <p className="title">{item.titulo}</p>
                <S.Criticity>
                  <S.Circle
                    color={getCriticidadeColor(item.criticidade, theme)}
                  />
                  <p>{item.criticidade}</p>
                </S.Criticity>
                <p>Qtde. de desvios: {item.x}</p>
              </S.StyledCard>
            </li>
          ))}
        </S.List>
      )}
    </S.Container>
  );
};
