import styled, { css } from 'styled-components';
import colors from 'styles/colors';

export const Container = styled.div`
  width: 100%;
`;

export const InfoLine = styled.div`
  ${({ theme, ...props }) => css`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0;
    width: 100%;

    .period {
      .date {
        font-size: 14px;
      }
    }

    .filters {
      display: flex;
      align-items: center;
      justify-content: center;

      .arrow {
        display: flex;
        align-items: center;

        &.next {
          opacity: ${props.last ? 0.4 : 1};
          cursor: ${props.last ? 'not-allowed' : 'pointer'};
        }

        &.back {
          opacity: ${props.first ? 0.4 : 1};
          cursor: ${props.first ? 'not-allowed' : 'pointer'};
        }

        span {
          font-size: 14px;
          font-weight: 600;
          color: ${theme.palette.words.subtitle.natural};
        }
      }

      .textMedium {
        background: white;
        font-size: 14px;
        font-weight: 600;
        color: ${theme.palette.words.title.natural};
        width: 165px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0 10px;
        padding: 0.5rem;
        border: 2px solid ${theme.palette.system.border};
        border-radius: 4px;
      }
    }
  `}
`;

export const TotalBox = styled.div`
  grid-area: line;
  width: 100%;
`;

export const MediumBox = styled.div`
  width: 100%;
  display: flex;
  gap: 40px;
  margin-top: 40px;

  @media (max-width: 1327px) {
    flex-direction: column;
  }
`;

export const LoadingBox = styled.div``;

export const Skeleton = styled.div`
  border-radius: 50px;
  border: 1px solid ${colors.greyBorder};
  transition: all ease 0.2s;
  height: 20px;
  width: 100%;
  background-image: linear-gradient(
    to right,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-size: 80rem 14rem;
  animation: placeholderShimmer 3s linear infinite forwards;

  @keyframes placeholderShimmer {
    0% {
      background-position: -40rem 0;
    }
    100% {
      background-position: 40rem 0;
    }
  }
`;
