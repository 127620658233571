import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

import Tabs from 'components/FiltersGlobal/Tabs';
import * as S from './styled';
import Skeleton from '@mui/material/Skeleton';

const CustomBarChart = ({
  title = '',
  data = [],
  tabs = [],
  loading = false,
  handleTabs = () => {},
}) => {
  // Dados mockados quando não houver dados
  const mockData = [
    { name: '', value: 400 },
    { name: '', value: 300 },
    { name: '', value: 200 },
    { name: '', value: 100 },
  ];

  const isEmpty = data?.length === 0;

  const CustomTick = ({ x, y, payload, width }) => {
    const maxCharsPerLine = 26;
    const lineHeight = 20;
    const spacingFromTop = 15;

    const splitText = text => {
      let lines = [];
      let startIndex = 0;

      while (startIndex < text.length) {
        let endIndex = Math.min(text.length, startIndex + maxCharsPerLine);
        if (endIndex < text.length && text[endIndex] !== ' ') {
          let lastSpaceIndex = text.lastIndexOf(' ', endIndex);
          if (lastSpaceIndex > startIndex) {
            endIndex = lastSpaceIndex;
          }
        }

        let line = text.substring(startIndex, endIndex);
        lines.push(line);

        if (lines.length === 2 && endIndex < text.length) {
          lines[1] = `${line.trim().slice(0, -3)}...`;
          break;
        }

        startIndex = endIndex + (text[endIndex] === ' ' ? 1 : 0);
      }

      return lines;
    };

    const lines = splitText(payload.value);

    return (
      <g transform={`translate(${x},${y})`}>
        {lines.map((line, index) => (
          <text
            x={0}
            y={spacingFromTop + index * lineHeight} // Ajusta o espaçamento vertical para cada linha.
            textAnchor="middle"
            fill="#666"
            key={`tick-${index}`}
          >
            {line}
          </text>
        ))}
      </g>
    );
  };

  // Calculo da largura dinâmica baseada no tamanho do nome
  const getChartWidth = () => {
    const nameLength = 26;
    const minWidth = 400;
    const dynamicWidth = Math.max(
      minWidth,
      nameLength * 15 * (data.length / 2),
    );
    return dynamicWidth;
  };

  const renderGraph = () => {
    return (
      <div style={{ overflowX: 'scroll', overflowY: 'hidden' }}>
        <div style={{ minWidth: `${getChartWidth()}px`, height: '300px' }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart
              data={isEmpty ? mockData : data}
              margin={{ top: 20, right: 30, left: 0, bottom: 20 }}
            >
              <CartesianGrid
                stroke="#E0E6EE"
                horizontal
                vertical={false}
                strokeDasharray="0"
              />
              <XAxis
                dataKey="name"
                axisLine={{ stroke: '#E0E6EE' }}
                tickLine={false}
                tick={<CustomTick />}
                interval={0}
              />
              <YAxis
                tick={{ fill: '#AAB2C0', fontSize: 14 }}
                axisLine={{ stroke: '#E0E6EE' }}
                tickLine={false}
              />
              <Tooltip
                contentStyle={{
                  backgroundColor: '#FFFFFF',
                  borderRadius: '10px',
                  border: 'none',
                }}
                cursor={{ fill: 'rgba(0, 0, 0, 0.05)' }}
              />
              <Bar
                dataKey="value"
                fill={isEmpty ? 'rgba(92, 94, 112, 0.3)' : '#5C5E70'}
                barSize={24}
                radius={[4, 4, 0, 0]}
              />
            </BarChart>
          </ResponsiveContainer>
        </div>
      </div>
    );
  };

  return (
    <S.StyledCard>
      <h2 style={{ marginBottom: '10px' }}>{title}</h2>

      {isEmpty && (
        <p
          style={{
            color: '#AAB2C0',
            textAlign: 'center',
            transform: 'translateY(130px)',
            fontWeight: 'bold',
          }}
        >
          Não há dados disponíveis. Use os filtros.
        </p>
      )}

      {(!loading || !isEmpty) && (
        <div>
          <Tabs tabs={tabs} handleApplay={val => handleTabs(val)} />
        </div>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '400px',
        }}
      >
        {loading ? (
          <Skeleton
            width="100%"
            height={310}
            style={{ transform: 'translateY(-10px)' }}
          />
        ) : (
          renderGraph()
        )}
      </div>
    </S.StyledCard>
  );
};

export default CustomBarChart;
