import styled, { css } from 'styled-components';

export const Header = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  margin: 1rem 0;
  justify-content: space-between;
`;

export const List = styled.div`
  width: 100%;
  max-height: 50vh;
  overflow-y: auto;
`;

export const ListItem = styled.div`
  width: 100%;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: ${({ theme }) => theme.palette.system.divider};
  margin-bottom: 0.5rem;
`;

export const ListItemLoad = styled.div`
  width: 100%;
  height: 60px;
  margin-bottom: 0.5rem;
  position: relative;
`;

export const ListItemButton = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  padding: 1rem;
  cursor: pointer;
`;

export const Content = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  width: 100%;
  color: ${({ theme }) => theme.palette.words.title.natural};
  font-size: 18px;
  font-weight: 500;

  .light {
    color: ${({ theme }) => theme.palette.words.subtitle.light};
  }

  .subtitle {
    color: ${({ theme }) => theme.palette.words.subtitle.natural};
  }

  .bold {
    font-weight: 800;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: end;
`;
