/* eslint-disable import/order */

// Styles
import * as S from './styled';

// React
import ReactPlayer from 'react-player';
import { useEffect, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

// Components
import Loading from '../Loading';

// Assets
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

// Icons
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SimCardAlertOutlinedIcon from '@mui/icons-material/SimCardAlertOutlined';
import PlayCircleFilledOutlinedIcon from '@mui/icons-material/PlayCircleFilledOutlined';

// PDF
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

export const MediaView = ({
  item,
  unlockNext,
  canCall,
  setCanCall,
  free = true,
}) => {
  const [typeTag, setTypeTag] = useState('');
  const [play, setPlay] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (item?.tipo === 'PDF') setTypeTag('pdf');
    if (item?.tipo === 'VÍDEO' || item?.tipo === 'VÍDEO_UPLOADING')
      setTypeTag('player');
    if (item?.tipo === 'IMAGEM') setTypeTag('img');
    if (item?.tipo === 'TEXTO') setTypeTag('text');

    setPageNumber(1);
    setError(false);
  }, [item]);

  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    let container = document.getElementById('content-over-scroll-box');
    container && container.classList.remove('block');
    if (numPages === 1 && canCall) {
      setCanCall(false);
      unlockNext(true);
    }
  };

  const handleNext = () => {
    if (numPages === pageNumber + 1 && canCall) {
      setCanCall(false);
      unlockNext(true);
    }

    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };

  const handlePrev = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  useEffect(() => {
    let container = document.getElementById('ContainerPDF');
    container && container.scrollIntoView({ behavior: 'smooth' });
  }, [pageNumber]);

  return (
    <S.Cotainer id="container-midia-scroll">
      <S.Title>{item?.titulo}</S.Title>
      <S.Description>{item?.descricao}</S.Description>

      {typeTag === 'pdf' && (
        <>
          <S.ContainerPDF id="ContainerPDF">
            {error && (
              <div className="error">
                <SimCardAlertOutlinedIcon />
                <span>Erro ao carregar o PDF, avançe para próxima etapa</span>
              </div>
            )}
            <S.Button
              onClick={() => handlePrev()}
              className="left"
              view={pageNumber > 1}
            >
              <ArrowBackIosNewIcon />
            </S.Button>
            <Document
              file={{
                url: item?.link,
              }}
              onLoadSuccess={onDocumentLoadSuccess}
              onError={
                canCall
                  ? () => {
                      setCanCall(false);
                      unlockNext(true);
                      setError(true);
                    }
                  : undefined
              }
              onLoadError={
                canCall
                  ? () => {
                      setCanCall(false);
                      unlockNext(true);
                      setError(true);
                    }
                  : undefined
              }
              loading={
                <S.BoxLoading>
                  <Loading />
                </S.BoxLoading>
              }
            >
              <Page
                pageNumber={pageNumber}
                width={800}
                renderTextLayer={false}
                renderAnnotationLayer={false}
              />
            </Document>

            <S.Button
              onClick={() => handleNext()}
              className="right"
              view={pageNumber < numPages}
            >
              <ArrowForwardIosIcon />
            </S.Button>
          </S.ContainerPDF>
          <S.Controls>
            <S.Button
              onClick={() => handlePrev()}
              className="left"
              view={pageNumber > 1}
            >
              <ArrowBackIosNewIcon />
            </S.Button>
            <p>
              Página {pageNumber} de {numPages}
            </p>
            <S.Button
              onClick={() => handleNext()}
              className="right"
              view={pageNumber < numPages}
            >
              <ArrowForwardIosIcon />
            </S.Button>
          </S.Controls>
        </>
      )}

      {typeTag === 'img' && <S.MediaImage src={item?.link} />}
      {typeTag === 'text' && (
        <S.MediaText dangerouslySetInnerHTML={{ __html: item?.link }} />
      )}
      {typeTag === 'player' && (
        <S.ContainerMedia play={play} free={free}>
          {!free && (
            <div className="cap" onClick={() => setPlay(!play)}>
              <PlayCircleFilledOutlinedIcon />
            </div>
          )}
          {error && (
            <div className="error">
              <SimCardAlertOutlinedIcon />
              <span>Erro ao carregar o vídeo, avance para próxima etapa</span>
            </div>
          )}

          <ReactPlayer
            width="100%"
            height="500px"
            controls={free}
            playing={play}
            url={item?.video?.link_hd ?? item?.link}
            config={{
              youtube: {
                playerVars: {
                  controls: free ? 1 : 0,
                  modestbranding: 1,
                  rel: 0,
                  fs: 0,
                  showinfo: 0,
                  autohide: 1,
                  disablekb: free ? 0 : 1,
                  cc_load_policy: 1,
                  iv_load_policy: 3,
                },
              },
              vimeo: {
                playerOptions: {
                  controls: free ? 1 : 0,
                  keyboard: free ? 1 : 0,
                },
              },
            }}
            onEnded={
              canCall
                ? () => {
                    setCanCall(false);
                    unlockNext(true);
                  }
                : undefined
            }
            onError={
              canCall
                ? () => {
                    setCanCall(false);
                    setError(true);
                    unlockNext(true);
                  }
                : undefined
            }
            className="react-player"
          />
        </S.ContainerMedia>
      )}
    </S.Cotainer>
  );
};
