import React, { useEffect, useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { useTheme } from 'styled-components';
import * as S from './styled';

/*
data: lista de objetos com
    {
        id: valor do id correspondente,
        value: string do nome do objeto selecionado
    }

fieldName: nome da variável armazenada. Substitui a necessidade de:
    > handleChange={(e) => handleChange(e.target.value, 'name')}
    por:
        > handleChange={handleChange}
        > fieldName: 'name'

value: array com ids selecionados. Ex: '[1, 3]'

*/

const Criticidades = {
  PRIMÁRIA: '#1BC5BD',
  GRAVÍSSIMA: '#f64e60',
  MODERADA: '#0C12F2',
  GRAVE: '#FFA801',
};

const SelectMultiple = ({
  value,
  handleChange,
  data,
  fieldName,
  label,
  selectAll,
  disabled,
  required,
  placeholder,
  width = '289px',
  error,
  message,
  ...props
}) => {
  const theme = useTheme();
  const [backupItems, setBackupItems] = useState([]);
  const [search, setSearch] = useState('');
  const [names, setNames] = useState([]);
  const [ids, setIds] = useState([]);

  const handleSelect = id => {
    if (ids.includes(id)) {
      const updateSelect = [];
      for (const i in ids) {
        if (ids[i] !== id) {
          updateSelect.push(ids[i]);
        }
      }
      formtedItems(updateSelect);
    } else {
      const updateSelect = [...ids, id];
      formtedItems(updateSelect);
    }
  };

  const clearSelection = () => {
    setNames([]);
    setIds([]);
    handleChange('', fieldName);
  };

  const selectAllOptions = () => {
    const __IDS = [];
    const __NAMES = [];

    for (const i in data) {
      __IDS.push(data[i].id);
      __NAMES.push(data[i].value);
    }

    setNames(__NAMES);
    setIds(__IDS);
    const idsString = __IDS.join(',');
    handleChange(idsString || '', fieldName);
  };

  useEffect(() => {
    setBackupItems(data);
  }, [data]);

  useEffect(() => {
    let items = [];
    if (value) {
      items = value;
    }

    for (const i in items) {
      if (Number(items[i])) {
        items[i] = Number(items[i]);
      }
    }

    const __IDS = [];
    const __NAMES = [];

    for (const i in data) {
      if (items.includes(data[i].id)) {
        __IDS.push(data[i].id);
        __NAMES.push(data[i].value);
      }
    }

    setNames(__NAMES);
    setIds(__IDS);
  }, [value]);

  useEffect(() => {
    const searchedParams = [];
    for (const i in data) {
      const name = data[i].value.toLowerCase();
      if (name.includes(search)) {
        searchedParams.push(data[i]);
      }
    }
    if (searchedParams.length === 0) {
      setBackupItems(data);
    } else {
      setBackupItems(searchedParams);
    }
    setBackupItems(searchedParams);
  }, [search]);

  const formtedItems = items => {
    const __IDS = [];
    const __NAMES = [];

    for (const i in data) {
      if (items.includes(data[i].id)) {
        __IDS.push(data[i].id);
        __NAMES.push(data[i].value);
      }
    }

    setNames(__NAMES);
    setIds(__IDS);
    handleChange(__IDS, fieldName);
  };

  const ITEM_HEIGHT = 60;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        minHeight: 250,
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  return (
    <div>
      <FormControl sx={{ width }}>
        {label && (
          <S.Label>
            {label}
            {required && <S.Required>*</S.Required>}
          </S.Label>
        )}
        {placeholder && (
          <InputLabel
            style={{ background: 'white', padding: '0 4px' }}
            id="demo-multiple-checkbox"
          >
            {placeholder}
          </InputLabel>
        )}
        <Select
          id="demo-multiple-checkbox"
          multiple
          error={error}
          value={names}
          displayEmpty
          input={<S.Input />}
          renderValue={selected => selected.join(', ')}
          MenuProps={MenuProps}
          disabled={disabled}
        >
          <S.SearchBox>
            <S.SearchInput
              type="text"
              placeholder="Buscar..."
              onChange={e => setSearch(e.target.value.toLowerCase())}
            />
            <SearchOutlinedIcon
              className="searchIcon"
              htmlColor={theme.palette.semantics.feedback.unknown.natural}
            />
          </S.SearchBox>
          <S.LineBox>
            <>
              {!selectAll && (
                <S.ClearSelection onClick={() => clearSelection()}>
                  Limpar Seleção
                </S.ClearSelection>
              )}

              {selectAll && ids.length > 0 && (
                <S.ClearSelection onClick={() => clearSelection()}>
                  Limpar Seleção
                </S.ClearSelection>
              )}

              {selectAll && !ids.length > 0 && (
                <S.ClearSelection onClick={() => selectAllOptions()}>
                  Selecionar Todos
                </S.ClearSelection>
              )}
            </>
          </S.LineBox>

          <S.OptionsArea>
            {backupItems.length > 0 &&
              backupItems.map(item => (
                <FormControlLabel
                  key={`${item.id}-${item.value}`}
                  control={<Checkbox checked={!!ids.includes(item.id)} />}
                  onChange={() => handleSelect(item.id)}
                  label={
                    <S.OptionItem color={Criticidades[item.criticidade]}>
                      <span className="person-type">{item.criticidade}</span>
                      <span>{item.value}</span>
                    </S.OptionItem>
                  }
                  value="start"
                />
              ))}
            {backupItems.length === 0 && (
              <S.EmptyOptions>Nenhuma opção disponível</S.EmptyOptions>
            )}
          </S.OptionsArea>
        </Select>
        {message && (
          <span
            style={{
              color: theme.palette.semantics.feedback.attention.dark,
              margin: '10px 0',
            }}
          >
            {message}
          </span>
        )}
      </FormControl>
    </div>
  );
};

export default SelectMultiple;
