import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { subDays } from 'date-fns';
import { useReactToPrint } from 'react-to-print';
import { useSelector } from 'react-redux';

import { Divider } from '@mui/material';

import { RoundedTabs } from 'components/RoundedTabs';
import { toast } from 'react-toastify';

import { Header } from './views/Header';
import { Desvios } from './views/Desvios';
import { Formularios } from './views/Formularios';
import { Estatisticas } from './views/Estatisticas';
import { AcoesSuspensoes } from './views/AcoesSuspensoes';
import { AvaliacoesDirecao } from './views/AvaliacoesDirecao';
import { AvaliacoesDescarga } from './views/AvaliacoesDescarga';
import { AcidentesMultas } from './views/AcidentesMultas';
import ExcelModal from 'components/ExcelModalNew';
import { PDF } from './views/PDF';
import ExportModal from 'components/ExportModal';

import { tabs } from './constants';
import * as S from './styled';
import { usePlans } from 'hooks/usePlans';

// services
import * as API from '../services';
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';
import { generateFileName } from 'utils/generateFileName';

export const PerfilMotorista = () => {
  const params = useParams();
  const componentRef = useRef();
  const promiseResolveRef = useRef(null);
  const navigate = useNavigate();
  const { user } = useSelector(state => state?.auth?.user);
  const [openExcelModal, setOpenExcelModal] = useState(false);

  if (+user?.nivel === 4) {
    toast.error(
      'Você não tem permissão para visualizar o perfil deste motorista',
    );
    navigate('/motoristas');
  }

  const { planosAtivos } = usePlans();
  const [tab, setTab] = useState(tabs[0]);
  const [printTabs, setPrintTabs] = useState(tabs.map(item => item.value));
  const [loadingPrint, setLoadingPrint] = useState(false);

  const [openExport, setOpenExport] = useState(false);
  const [dataDriversExports, setDataDriversExports] = useState([]);
  const [driverBloqueio, setDriverBloqueio] = useState();
  const [lastTrip, setLastTrip] = useState();
  const [selectedRadio, setSelectedRadio] = useState('');

  const { clients } = useFetchMultipleWithCache();

  const [filtersPage, setFiltersPage] = useState({
    finalDate: new Date(),
    initialDate: subDays(new Date(), 30),
  });

  const fetchBloqueio = async () => {
    const bloqueioResponse = await API.requestDriverBloqueio(params.id);

    setDriverBloqueio(bloqueioResponse?.data?.data);
  };

  const fetchUltimaviagem = async () => {
    const lastTripResponse = await API.requestDriverLastTrip(params.id);
    setLastTrip(lastTripResponse?.data?.ultima_viagem);
  };

  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
    onBeforeGetContent: () => {
      return new Promise(resolve => {
        promiseResolveRef.current = resolve;
        setLoadingPrint(true);
      });
    },
    onAfterPrint: () => {
      promiseResolveRef.current = null;
      setLoadingPrint(false);
    },
  });

  // ----------------------------------- Excel --------------------------//
  const keys = {
    avaliacoes: 'DIRECAO',
    avaliacoes_descarga: 'DESCARGA',
    desvios: 'DESVIOS',
    formularios: 'FORMULARIOS',
    acoes: 'ACOES',
    estatisticas: 'ESTATISTICAS',
    acidentes_multas: 'ACIDENTES_MULTAS',
  };

  const handleExport = (exportType, selectedRadio, filters) => {
    if (exportType === 'EXCEL') {
      // handleRequestExcel({
      //   ...filters,
      //   page: keys[`${selectedRadio}`] || 'todos',
      // });
      setSelectedRadio(selectedRadio);
      setOpenExcelModal(true);
      setOpenExport(false);
    } else if (exportType === 'PDF') {
      setPrintTabs(
        selectedRadio ? [selectedRadio] : tabs.map(item => item.value),
      );
      handlePrint();
    }
  };

  // const handleRequestExcel = useCallback(
  //   async query => {
  //     setLoadingPrint(true);
  //     const newQuery = {
  //       ...query,
  //       id_motorista: params.id,
  //     };

  //     const res = await API.requestDriversExportsExcel(params.id, newQuery);
  //     if (res.data.link) {
  //       downloadFromLink(res.data.link);
  //       toast.success(res.message);
  //     } else if (res.message) toast.error(res.message);

  //     setLoadingPrint(false);
  //   },
  //   [params.id],
  // );

  // ----------------------------------- Use efffects --------------------------//

  useEffect(() => {
    if (loadingPrint && promiseResolveRef.current) {
      // Resolves the Promise, letting `react-to-print` know that the DOM updates are completed
      promiseResolveRef.current();
    }
  }, [loadingPrint]);

  const fetchDriversExports = async () => {
    const paramsFilters = {
      page: 'todos',
      ...filtersPage,
    };

    const res = await API.requestDriversExports(params.id, paramsFilters);
    setDataDriversExports(res.data);
  };

  useEffect(() => {
    fetchDriversExports();
  }, [filtersPage, openExport]);

  useEffect(() => {
    fetchBloqueio();
    fetchUltimaviagem();
  }, []);

  const fieldsTransporter = [
    {
      filterName: 'client',
      label: 'cliente',
      options: clients.data,
    },
  ];

  return (
    <>
      <Header
        id={params.id}
        motoristaBloqueado={
          driverBloqueio && driverBloqueio.some(e => e.motorista_bloqueado)
        }
      />
      {openExcelModal && (
        <ExcelModal
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          query={{
            ...filtersPage,
            page: keys[`${selectedRadio}`] || 'todos',
            id_motorista: params.id,
          }}
          file_name={generateFileName(
            window.location.pathname.split('/').pop(),
            filtersPage,
          )}
          route="/excel/perfil_motorista"
          filtersPersis={filtersPage}
          collumns={fieldsTransporter}
        />
      )}
      <S.TabContainer>
        <div className="flex-1">
          <RoundedTabs
            items={tabs.filter(
              tab =>
                !tab.plans ||
                planosAtivos.some(plan => tab.plans.includes(plan.id_do_plano)),
            )}
            value={tab.value}
            onChange={value => {
              setTab(tabs.find(_tab => _tab.value === value));
            }}
          />
          <Divider />
        </div>
      </S.TabContainer>
      <S.Content>
        {tab.value === 'estatisticas' && (
          <Estatisticas
            filters={filtersPage}
            setFilters={setFiltersPage}
            motoristaBloqueado={
              driverBloqueio && driverBloqueio.some(e => e.motorista_bloqueado)
            }
            driverBloqueio={driverBloqueio}
            lastTrip={lastTrip}
            setOpenExport={setOpenExport}
          />
        )}
        {tab.value === 'avaliacoes' && (
          <AvaliacoesDirecao setOpenExport={setOpenExport} />
        )}
        {tab.value === 'avaliacoes_descarga' && (
          <AvaliacoesDescarga setOpenExport={setOpenExport} />
        )}
        {tab.value === 'desvios' && <Desvios setOpenExport={setOpenExport} />}
        {tab.value === 'formularios' && (
          <Formularios setOpenExport={setOpenExport} />
        )}
        {tab.value === 'acoes' && <AcoesSuspensoes />}
        {tab.value === 'acidentes_multas' && (
          <AcidentesMultas setOpenExport={setOpenExport} />
        )}
      </S.Content>
      <ExportModal
        date
        open={openExport}
        handleClose={() => setOpenExport(false)}
        radioItems={tabs.filter(
          tab =>
            !tab.plans ||
            planosAtivos.some(plan => tab.plans.includes(plan.id_do_plano)),
        )}
        handleClick={handleExport}
        filters={filtersPage}
        filterProps={{
          hideExportButton: true,
          data: fieldsTransporter,
          handleFilters: _filter =>
            setFiltersPage(state => ({
              ...state,
              ..._filter,
            })),
        }}
      />
      <PDF
        id={params.id}
        ref={componentRef}
        filters={{
          ...filtersPage,
          filial: filtersPage.filial ? filtersPage.filial.split(',') : null,
        }}
        printTabs={printTabs}
        dataDriversExports={dataDriversExports}
      />
    </>
  );
};
