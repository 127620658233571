import { getISOWeek, format } from 'date-fns';
import { ptBR } from 'date-fns/locale';

// Função para formatar o nome baseado no período
export const formatarNome = (item, periodo) => {
  let { dia, mes, ano, period } = item;
  if (period) {
    const splitPeriod = period.split('/');
    dia = splitPeriod[0];
    mes = splitPeriod[1];
    ano = splitPeriod[2];
  }
  if (ano === undefined || mes === undefined) {
    return '';
  }
  if (!dia) dia = 15; // item com apenas mes e ano

  // period = dd/mm/YY
  switch (periodo) {
    case 'day':
      return `${String(dia).padStart(2, '0')}/${String(mes).padStart(2, '0')}`;
    case 'month':
      const date = new Date(ano, mes - 1, dia);
      const formatDate = format(date, 'MMM yyyy', { locale: ptBR });
      return formatDate.charAt(0).toUpperCase() + formatDate.slice(1);
    case 'week':
      const data = new Date(ano, mes - 1, dia);
      const numeroSemana = getISOWeek(data);
      return `S-${String(numeroSemana).padStart(2, '0')}-${String(
        ano,
      ).substring(2)}`;
    default:
      throw new Error('Período inválido');
  }
};

// Função para definir o intervalo baseado no período
export const getInterval = (periodDate, difference) => {
  if (periodDate === 'week' && difference > 24 * 7) return 4;
  if (periodDate === 'day' && difference > 31 && difference < 24 * 7) return 7;
  if (periodDate === 'day' && difference >= 24 * 7) return 30;
  return 'auto';
};

// Função para definir o agrupamento baseado no período
export const getGroup = (periodDate, difference) => {
  if (periodDate === 'week' && difference > 24 * 7) return 'month';
  if (periodDate === 'day' && difference > 31 && difference < 24 * 7)
    return 'week';
  if (periodDate === 'day' && difference >= 24 * 7) return 'month';
  return periodDate;
};
