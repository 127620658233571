import api from 'services/api';

// Get all templates
export const getTemplates = async query => {
  const res = await api.get('/certificado/template', { params: query });
  return res.data;
};

// Get template by ID
export const getTemplateById = async id => {
  const res = await api.get(`/certificado/template/${id}`);
  return res.data;
};

// Create new template
export const createTemplate = async data => {
  const res = await api.post('/certificado/template', data);
  return res.data;
};

// Update template
export const updateTemplate = async (id, data) => {
  const res = await api.put(`/certificado/template/${id}`, data);
  return res.data;
};

// Delete template
export const deleteTemplate = async id => {
  const res = await api.delete(`/certificado/template/${id}`);
  return res.data;
};
