import styled from 'styled-components';

export const Container = styled.div`
  padding: 1rem;
`;

export const ContainerList = styled.div`
  width: 100%;
`;

export const Title = styled.h1`
  font-size: 24px;
  font-weight: 900;
  letter-spacing: 0px;
  color: #4b5166;
`;
