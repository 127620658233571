// Styled
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { changeHeaderStatus } from 'store/modules/header/actions';
import { useLocation, useParams } from 'react-router-dom';

import {
  setItems,
  setGerais,
  setTitle,
  setId,
  setCapa,
  setStatus,
  setIsEditable,
} from 'store/modules/formularios/actions';

// Components
import Loading from 'components/Loading';
import GhostButton from 'components/Buttons/Ghost';

// Components MUI
import { Divider, Popover } from '@mui/material';
import { SaveAlt } from '@mui/icons-material';

// Constants
import ExportToExcel from 'utils/exportToCvs';
import { useReactToPrint } from 'react-to-print';
import { itensBar } from './constants';
import { initializeForm } from '../constants';
import { formatStyleHeader } from '../actions';

// Services
import * as services from '../Config/services';

// Utils
import { ModalExcel } from '../components/ModalExcel';
import { Sidebar } from '../components/Sidebar';
import { ExportFormPdf } from '../Config/Export';
import * as S from './styled';
import { addHours, format } from 'date-fns';

const TemplateReportsFormulario = () => {
  const theme = useTheme();
  const printRefPdf = useRef();

  // Navigate
  const location = useLocation();

  // Params
  const params = useParams();
  const formularios = useSelector(state => state.formularios);

  // Dispatch
  const dispatch = useDispatch();

  // Controller Page (states)
  const [loading, setLoading] = useState(true);
  const [currentStep, setCurrentStep] = useState(itensBar[0]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [exportablePeriod, setExportablePeriod] = useState('');
  const [typeExport, setTypeExport] = useState(null);

  // Modals
  const [modalExcel, setModalExcel] = useState(false);

  // Selectes States
  const [selectsGeneral, setSelectsGeneral] = useState([]);
  const [currentGeneral, setCurrentGeneral] = useState(0);
  const [selectsUsers, setSelectsUsers] = useState([]);
  const [currentUser, setCurrentUser] = useState('');

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const myTab = searchParams.get('tab');
    if (myTab) {
      setCurrentStep(itensBar[+myTab] || itensBar[0]);
      searchParams.delete('tab');
      searchParams.delete('step');
      searchParams.set('reset', 1);
      location.search = '';
      window.history.replaceState(
        {},
        {},
        `/formularios/estatisticas/${params.id}`,
      );
    }
  }, [location]);

  // ------------------------CHARGE CURRENT FORM------------------------------------//
  const {
    data: res,
    isError,
    isLoading: loadingQuery,
  } = useQuery([`form${params.id}`], () => services.getFomrItem(params.id), {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    // Initial states
    setLoading(true);
    window.scrollTo({ top: 0 });

    // Set initial verifications
    if (loadingQuery) return null;

    if (!isError) {
      // TODO: Unificar dispatch (que aberração eu escrevi aqui)
      dispatch(setId(res.data.form.id));
      dispatch(
        setItems(
          res.data.form.secoes.length > 0
            ? res.data.form.secoes
            : initializeForm,
        ),
      );
      dispatch(setGerais(res.data.form));
      dispatch(setStatus(res.data.form.status));
      dispatch(setTitle(res.data.form.titulo));
      dispatch(setCapa(res.data.form.capa));
      dispatch(setIsEditable(res.data.form.id_empresa !== 4110));
      dispatch(
        changeHeaderStatus(formatStyleHeader(res.data.form.status, theme)),
      );
      setLoading(false);
    } else {
      toast.error(res.message);
    }
  }, [res]);

  const changePage = () => {
    const { Component } = currentStep;

    return (
      <S.Content>
        <Component
          periods={selectsGeneral}
          currentTime={currentGeneral}
          setCurrentTime={setCurrentGeneral}
          select={selectsUsers}
          currentSelect={currentUser}
          setCurrentSelect={setCurrentUser}
        />
      </S.Content>
    );
  };

  // ---------------------------------------EXPORTAÇÕES--------------------------//
  const [loadingExport, setLoadingExport] = useState(false);
  const [dataExportDaTable, setDataExportDaTable] = useState([]);

  const exportPDF = async () => {
    const start = exportablePeriod?.[0] || currentUser?.[0];
    const end = exportablePeriod?.[1] || currentUser?.[1];
    setLoadingExport(true);
    const res = await services.exportPDF(formularios.id, {
      start,
      end,
    });

    setDataExportDaTable(res.data.data);
    setLoadingExport(false);
    if (res.data.success) {
      handlePrint();
    }
  };

  const [loadingPdf, setLoadingPdf] = useState(false);

  const handlePrint = useReactToPrint({
    content: () => printRefPdf.current,
    onBeforeGetContent: () => setLoadingExport(true),
    onAfterPrint: () => {
      setLoadingExport(false);
    },
    copyStyles: true,
    documentTitle: `formulario_id_${formularios?.id}`,
  });

  const exportExcel = async () => {
    const start = exportablePeriod[0] || currentUser[0];
    const end = exportablePeriod[1] || currentUser[1];

    setLoadingExport(true);
    const res = await services.exportExcel({
      id: formularios.id,
      start,
      end,
    });
    if (res.data.success) {
      toast.success(res.data.message);
      ExportToExcel({
        excel: res.data.data.excel,
        name: `formulario_${formularios.id}_${format(
          new Date(start),
          'dd-MM-yyyy',
        )}`,
      });
      setModalExcel(false);
      setExportablePeriod('');
    } else {
      toast.error(res.data.message);
    }
    setLoadingExport(false);
  };

  const handleVerifyExport = type => {
    // Verificaçoes se é um formulario com repetições
    setTypeExport(type);
    const hasRepet = formularios.gerais.repetir;

    if (hasRepet) {
      setModalExcel(true);
    } else {
      type === 'EXCEL' ? exportExcel() : exportPDF();
    }
  };

  // --------------------------------SELECTS REPORTS ---------------------------//

  useEffect(() => {
    if (formularios?.id) {
      const catchPeriods = async () => {
        const res = await services.getPeriods(formularios.id);

        if (res.success) {
          updatePeriods(res);
        } else {
          toast.error(res.message);
        }
      };

      catchPeriods();
    }
  }, [formularios?.id]);

  const updatePeriods = data => {
    // User

    const fuse = new Date().getTimezoneOffset() / 60;

    const periods = data.data.map(i => [
      addHours(new Date(i[0]), fuse),
      addHours(new Date(i[1]), fuse),
    ]);
    setCurrentUser(periods[periods.length - 1]);
    setSelectsUsers(periods);
  };
  // --------------------------------RENDER-------------------------------------------//

  return (
    <S.Container>
      {!loading && (
        <>
          <Sidebar
            itens={itensBar}
            page={currentStep.id || 0}
            setPage={setCurrentStep}
          />

          <S.TemplatePage className="isEditTemplate">
            <S.HeaderPage className="isEditHeader">
              <div className="titlePage">{currentStep.page}</div>
              <div className="headerButtons">
                <GhostButton
                  children="Exportar"
                  icon={<SaveAlt />}
                  onClick={event => setAnchorEl(event.currentTarget)}
                  style={{ marginLeft: '10px' }}
                />

                <Popover
                  sx={{ transform: 'translate(0px, 2px)' }}
                  id={anchorEl ? 'popover' : undefined}
                  open={!!anchorEl}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  onClick={() => setAnchorEl(null)}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                >
                  <S.Popup>
                    <button onClick={() => handleVerifyExport('EXCEL')}>
                      Excel
                    </button>
                    <button onClick={() => handleVerifyExport('PDF')}>
                      PDF
                    </button>
                  </S.Popup>
                </Popover>
              </div>
            </S.HeaderPage>

            <br />
            <Divider />

            <div style={{ marginBottom: '60px' }}>
              {!loading && changePage()}
            </div>
          </S.TemplatePage>
        </>
      )}

      {loading && (
        <S.LoadingBox>
          <Loading />
        </S.LoadingBox>
      )}

      <ModalExcel
        open={modalExcel}
        onClose={() => setModalExcel(false)}
        select={selectsUsers}
        defaultOption={currentUser}
        exportablePeriod={exportablePeriod}
        setExportablePeriod={setExportablePeriod}
        typeExport={typeExport}
        exportPDF={exportPDF}
        exportExcel={exportExcel}
        loading={loadingExport}
      />

      <ExportFormPdf
        form={formularios}
        data={dataExportDaTable}
        printRef={printRefPdf}
        period={exportablePeriod || currentUser}
      />
    </S.Container>
  );
};

export default TemplateReportsFormulario;
