import React, { useEffect, useState } from 'react';
import * as S from './styled';
import { useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { startOfMonth, subMonths } from 'date-fns';
import { usePlans } from 'hooks/usePlans';

import WarningAmberRounded from '@mui/icons-material/WarningAmberRounded';
import { formatNewDate } from 'utils/dates';
import FiltersGlobal from 'components/FiltersGlobal';

// components custom
import Cards from 'pages/Home/FatorRisco/Cards/Card';
import { HistoryDrivers } from './HistoryDrivers';
import { GraphRDesvio } from './GraphRDesvios';
import GraphRadar from './RadarGraph';

// icons custom
import { ReactComponent as LikeIcon } from 'images/icons/profile_drivers/like.svg';
import { ReactComponent as DSLike } from 'images/icons/profile_drivers/dislike.svg';
import { ReactComponent as IAcoesSuspensoes } from 'images/icons/icone-acoes-suspensoes.svg';

// services
import * as API from './services';
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';
import { Skeleton } from '@mui/material';

export const Estatisticas = ({
  filters,
  setFilters,
  motoristaBloqueado,
  driverBloqueio,
  lastTrip,
  print = false,
  setOpenExport,
}) => {
  const { hasMonitoramento, hasTelemetria } = usePlans();

  const tabsLabels = [];
  const params = useParams();
  const [cardsForms, setCardsForms] = useState([]);
  const [cardCapacitacao, setCardCapacitacao] = useState([]);

  const [radarDesviosTab, setRadarDesviosTab] = useState(
    hasMonitoramento ? 'ptsHr' : 'ptsKm',
  );
  const [historicoTab, setHistoricoTab] = useState(
    hasMonitoramento ? 'ptsHr' : 'ptsKm',
  );

  const [historyDates, setHistoryDates] = useState({
    finalDate: new Date(),
    initialDate: startOfMonth(subMonths(new Date(), 11)),
  });

  // Os primeiros cinco cards relacionados a Avaliação de direção e telemetria
  const { data: cardsAvaliacoesData } = useQuery(
    ['motoristas-card-avaliacoes', params.id, filters],
    () =>
      params.id &&
      API.getCardsAvaliacao(params.id, {
        ...filters,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  // O sexto card referente ao dado de formulários preenchidos do motorista
  const fetchCardsInfoFormulario = async (query = {}) => {
    const res = await API.getCardFormulario(params.id, query);
    setCardsForms(res.data);
  };

  // Card do 'like' e 'dislike' ao final da primeira linha
  const { data: cardImproveData } = useQuery(
    ['motorista-card-improve', params.id, filters],
    () =>
      params.id &&
      API.getCardImprove({
        ...filters,
        id_motorista: params.id,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  // Card do 'FIFA' do motorista
  const { data: fatorDeRiscoData } = useQuery(
    ['motorista-fator-risco', params.id, filters],
    () =>
      params.id &&
      API.getRadarDeRisco(params.id, {
        ...filters,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  // Gráfico de radar de desvios (de barra)
  const { isFetching, data: radarDesviosData } = useQuery(
    ['motorista-radar-desvios', params.id, filters, radarDesviosTab],
    () =>
      params.id &&
      API.getRadarDesvio(params.id, {
        ...filters,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  // Gráfico de histórico de pontos do motorista e da empresa plotados juntamente
  const { data: historyData, isFetching: isFetchingHistory } = useQuery(
    ['motorista-historico', params.id, filters, historicoTab],
    () =>
      params.id &&
      API.getHistorico(params.id, { ...filters, tab: historicoTab }),
    {
      refetchOnWindowFocus: false,
    },
  );

  // Card com fatores de risco grave
  const { data: fatoresGraveGravissimo, isFetching: isFetchingFatoresRisco } =
    useQuery(
      ['motorista-fator-risco-grave', filters],
      () => API.getFatoresRiscoGraves(filters),
      {
        refetchOnWindowFocus: false,
      },
    );

  // Card com os dados de capacitação que o motorista já fez (treinamentos)
  const fetchCardCapacitacao = async () => {
    const res = await API.getCardCapacitacao(params.id);
    setCardCapacitacao(res.data);
  };

  const verifyLastTripWarning = (bloqueio, lastTrip) => {
    if (!lastTrip?.data_inicio) return false;
    const inicio_bloqueio = bloqueio.inicio_bloqueio;
    const fim_bloqueio = bloqueio.fim_bloqueio;
    const ultimaViagem = lastTrip.data_inicio;

    return ultimaViagem > inicio_bloqueio && ultimaViagem < fim_bloqueio;
  };

  useEffect(() => {
    fetchCardsInfoFormulario();
    fetchCardCapacitacao();
  }, []);

  if (hasMonitoramento && hasTelemetria) {
    tabsLabels.push(
      { value: 'ptsHr', label: 'PONTOS/HORA' },
      { value: 'ptsKm', label: 'PONTOS/KM' },
    );
  }
  if (hasMonitoramento && !hasTelemetria) {
    tabsLabels.push({ value: 'ptsHr', label: 'PONTOS/HORA' });
  }
  if (!hasMonitoramento && hasTelemetria) {
    tabsLabels.push({ value: 'ptsKm', label: 'PONTOS/KM' });
  }

  const { clients } = useFetchMultipleWithCache();
  const filtersConfig = [
    {
      filterName: 'client',
      label: 'Clientes',
      options: clients.data || [],
    },
  ];

  return (
    <S.Container>
      <FiltersGlobal
        hideRefleshButton
        customComponent={<h2>Estatisticas do motorista</h2>}
        isFetching={isFetching}
        handleFilters={newFilters =>
          setFilters instanceof Function &&
          setFilters(prevFilters => ({
            ...prevFilters,
            ...newFilters,
          }))
        }
        handleExport={() => setOpenExport(true)}
        data={filtersConfig}
      />
      {print && (
        <>
          {motoristaBloqueado &&
            driverBloqueio.map(bloqueio => (
              <div
                className="container-custom-card"
                style={{
                  backgroundColor: '#EDF2F9',
                  padding: '1.5rem 2rem',
                  marginBottom: '.5rem',
                }}
              >
                <IAcoesSuspensoes color="#f64e60" />
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    margin: '0 2.5rem',
                  }}
                >
                  <span style={{ fontSize: '20px', color: '#4B5166' }}>
                    Início do bloqueio:{' '}
                    {formatNewDate(bloqueio.inicio_bloqueio)}
                  </span>
                  <span style={{ fontSize: '20px', color: '#4B5166' }}>
                    Final do bloqueio: {formatNewDate(bloqueio.fim_bloqueio)}
                  </span>
                </div>
                <div
                  style={{
                    padding: '.5rem',
                    borderRadius: '4px',
                    display: 'flex',
                    alignItems: 'center',
                    backgroundColor: verifyLastTripWarning(bloqueio, lastTrip)
                      ? '#f64e60'
                      : '#939aab',
                  }}
                >
                  {verifyLastTripWarning(bloqueio, lastTrip) && (
                    <WarningAmberRounded
                      width="19px"
                      style={{ color: '#FFFFFF', marginRight: '.5rem' }}
                    />
                  )}
                  <span
                    style={{
                      color: '#FFFFFF',
                      fontSize: '20px',
                    }}
                  >
                    Última viagem registrada:{' '}
                    {lastTrip?.data_inicio
                      ? formatNewDate(lastTrip.data_inicio)
                      : 'Sem registro de viagem'}
                  </span>
                </div>
              </div>
            ))}
          <div className="container-custom-card">
            <div style={{ padding: 5 }}>
              {!cardImproveData?.data?.isUp ? (
                <LikeIcon width={20} height={20} />
              ) : (
                <DSLike width={20} height={20} />
              )}
            </div>
            {cardImproveData?.data ? (
              <div className="text_custom_card">
                Houve {cardImproveData?.data?.isUp ? 'um' : 'uma'}{' '}
                <span style={{ color: '#4B5166', fontWeight: 900 }}>
                  {cardImproveData?.data?.isUp ? 'aumento' : 'redução'} de{' '}
                  {cardImproveData?.data?.porcentagem ?? 0}
                </span>
                no desvio{' '}
                <span style={{ color: '#ff8040', fontWeight: 900 }}>
                  {cardImproveData?.data?.fator_risco ?? ''}
                </span>{' '}
                no período selecionado.
              </div>
            ) : (
              <div className="text_custom_card">Nenhum dado retornado.</div>
            )}
          </div>
          <br />
        </>
      )}
      <S.ContentCards>
        {cardsAvaliacoesData?.data.map((item, k) => (
          <>
            <Cards
              key={k}
              width="calc(33.33% - 10px)"
              title={item.title}
              percentage={item?.total === 'NaN' ? 0 : item?.total}
              valueFooter={
                <div>
                  Último período:{' '}
                  <span style={{ color: item.isUp ? '#1BC5BD' : '#f64e60' }}>
                    {item.isUp ? '▲' : '▼'}
                    {item.percentage === 'Infinity' ? 100 : item.percentage}%
                  </span>
                </div>
              }
            />
            <div className="space" />
          </>
        ))}
        <Cards
          width="calc(33.33% - 10px)"
          marginRight={10}
          title="Formulários preenchidos"
          percentage={cardsForms.formularios || 0}
          valueFooter={
            <div style={{ color: '#939aab' }}>
              {cardsForms?.respostas || 0} no último período{' '}
            </div>
          }
        />
        {!print && (
          <div className="container-custom-card">
            <div style={{ padding: 15 }}>
              {!cardImproveData?.data?.isUp ? <LikeIcon /> : <DSLike />}
            </div>
            {cardImproveData?.data ? (
              <div className="text_custom_card">
                Houve {cardImproveData?.data?.isUp ? 'um' : 'uma'}{' '}
                <span style={{ color: '#4B5166', fontWeight: 900 }}>
                  {cardImproveData?.data?.isUp ? 'aumento' : 'redução'} de{' '}
                  {cardImproveData?.data?.porcentagem ?? 0}
                </span>
                <br />
                no desvio{' '}
                <span style={{ color: '#ff8040', fontWeight: 900 }}>
                  {cardImproveData?.data?.fator_risco ?? ''}
                </span>{' '}
                no período selecionado.
              </div>
            ) : (
              <div className="text_custom_card">Nenhum dado retornado.</div>
            )}
          </div>
        )}
      </S.ContentCards>
      <S.ContentGraphMiddle>
        <div className="container-radar-graph">
          <GraphRadar
            data={fatorDeRiscoData?.data || []}
            height={300}
            print={print}
          />
        </div>
        <div className="container-graph">
          <GraphRDesvio
            height={200}
            data={radarDesviosData?.data || []}
            tabsItems={tabsLabels}
            handleTabClick={value => {
              setRadarDesviosTab(value);
            }}
            currentSelection={radarDesviosTab}
            isLoading={isFetching}
            print={print}
          />
        </div>
      </S.ContentGraphMiddle>
      {/* <div style={{ width: '100%' }}>
        <Divider />
      </div> */}
      <br />
      <S.ContentGraphBottom print={print}>
        <div className="container-graph">
          <HistoryDrivers
            data={historyData?.data || []}
            tab={historicoTab}
            labelsTab={tabsLabels}
            handleChangeTab={value => setHistoricoTab(value)}
            filters={filters}
            loading={isFetchingHistory}
          />
        </div>
        <br />
        <div style={{ display: 'flex', flexDirection: 'row' }}>
          <div className="card1">
            <div>
              {isFetchingFatoresRisco ? (
                <Skeleton
                  width="230px"
                  height="300px"
                  variant="rounded"
                  animation="wave"
                />
              ) : (
                <>
                  <div className="title">Desvios graves e gravíssimos</div>
                  <div className="sub-title">
                    <span
                      style={{
                        color: 'red',
                      }}
                    >
                      {fatoresGraveGravissimo?.data?.porcentagemCriticidade
                        ?.isUp
                        ? '▲'
                        : '▼'}{' '}
                      {fatoresGraveGravissimo?.data?.porcentagemCriticidade
                        ?.text || ''}{' '}
                    </span>
                    no período selecionado
                  </div>
                  <div className="sub-container">
                    <div className="text1">Desvio crítico mais recorrente</div>
                    <div className="text2">
                      {fatoresGraveGravissimo?.data?.desvio?.fator_risco}{' '}
                      {fatoresGraveGravissimo?.data?.desvio?.quantidade &&
                        `(${fatoresGraveGravissimo?.data?.desvio?.quantidade})`}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="card2">
            <div className="top">Treinamentos concluídos</div>
            <div>
              <div className="middle-total">
                {!cardCapacitacao?.value || cardCapacitacao === 'NaN'
                  ? 0
                  : cardCapacitacao.value}
                %
              </div>
              <div className="middle-sub">
                {cardCapacitacao.respostas ? cardCapacitacao.respostas : 0} de{' '}
                {cardCapacitacao.conteudos ? cardCapacitacao.conteudos : 0} no
                total
              </div>
            </div>
            <div className="bottom">
              {cardCapacitacao.acoes ? cardCapacitacao.acoes : 0} suspensões no
              total
            </div>
          </div>
        </div>
      </S.ContentGraphBottom>
    </S.Container>
  );
};
