import React from 'react';

// Styles
import { useTheme } from 'styled-components';

// Components
import GhostButton from 'components/Buttons/Ghost';
import { Modal, Button, IconButton, Icon } from '@mui/material';
import * as S from './styled';

const WarningModal = ({ open, handleClose, openNext }) => {
  const theme = useTheme();
  const handleModal = () => {
    handleClose(false);
    openNext(true);
  };

  const body = (
    <S.Paper>
      <S.Header>
        <S.Title>
          <h1>Atenção (Leia com atenção as informações abaixo)</h1>
        </S.Title>

        <IconButton size="small" onClick={() => handleClose(false)}>
          <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
            close
          </Icon>
        </IconButton>
      </S.Header>

      <S.Main>
        <S.StyledToast>
          <span>1. Sobre as filiais </span>
          <p>
            • Os nomes de filiais podem ser referentes ao tipo de tecnologia de
            monitoramento ou a uma base de apoio, não necessariamente serão as
            mesmas filiais cadastradas.
          </p>

          <span>2. Sobre a frequência de envios</span>
          <p>
            • A frequência de envios depende de cada transportadora e filial,
            consulte o responsável da sua unidade para receber a informação dos
            dias agendados para envios.
          </p>

          <span>3. Sobre não identificação do horário agendado </span>
          <p>
            • Caso não identifique um envio registrado no horário agendado
            verifique se seu servidor estava ativo no momento da execução, se o
            servidor estava ativo entre em contato com nosso suporte.
          </p>

          <span>4. Sobre envios não finalizados</span>
          <p>
            • Envios sem uma data de finalização após o período médio de
            execução devem ser investigados e podem ser a causa da avaliação com
            vídeo já disponibilizado não estar na plataforma. Entre em contato
            com nosso suporte.
          </p>

          <span>5. Sobre envios sem nenhum vídeo</span>
          <p>
            • Envios com 0 vídeos devem ser investigados e podem ser a causa da
            avaliação com vídeo já disponibilizado não estar na plataforma.
            Confira se tem vídeos disponibilizados e entre em contato com nosso
            suporte.
          </p>

          <span>6. Sobre detalhamentos </span>
          <p>
            • Ao visualizar os detalhes de algum envio, caso os vídeos estejam
            corrompidos ou com tempo insuficiente para realizar a avaliação será
            marcado como ‘NÃO’ na coluna ‘Enviado com sucesso’.
          </p>
        </S.StyledToast>
      </S.Main>

      <S.Footer>
        <GhostButton
          size="medium"
          variant="contained"
          customcolor={theme?.palette?.semantics?.feedback?.attention?.natural}
          onClick={() => handleClose(false)}
        >
          Cancelar
        </GhostButton>

        <GhostButton style={{ marginLeft: '10px' }} onClick={handleModal}>
          Ver envio
        </GhostButton>
      </S.Footer>
    </S.Paper>
  );

  return (
    <div>
      <Modal open={open} onClose={() => handleClose(false)}>
        {body}
      </Modal>
    </div>
  );
};

export default WarningModal;
