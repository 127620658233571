import React, { useEffect, useState } from 'react';
import { addMonths } from 'date-fns';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { Grid } from '@mui/material';
import GhostButton from 'components/Buttons/Ghost';
import { useForm } from 'react-hook-form';
import DateHour from 'components/Inputs/_withController/DateHour';
import SelectOne from 'components/Inputs/_withController/SelectOne';
import TextField from 'components/Inputs/_withController/TextField';
import InputAttachedFile from 'components/Inputs/_withController/InputAttachedFile';
import { ReactComponent as HistoricoIcon } from 'images/icons/sidebar/historico.svg';
import Loading from 'components/Loading';
import { GetLocation } from '../../Acidentes/components/GetLocation';
import { usePlans } from 'hooks/usePlans';
import {
  Container,
  Footer,
  LocationContainer,
  LocationTitle,
  SpacedRow,
} from './styled';

import { getTiposMultas, saveMulta, getMulta } from '../services';

const defaultValues = {
  id: null,
  id_motorista: '',
  id_caminhao: '',
  id_multa_tipo: '',
  data_multa: '',
  endereco: '',
  km: null,
  cep: '',
  latitude: null,
  longitude: null,
  id_distribuidora: null,
};

export const CriarMulta = ({ edit }) => {
  const params = useParams();
  const navigate = useNavigate();
  const { isProvider } = usePlans();
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const selects = useSelector(state => state.selects);
  const drivers = isProvider
    ? selects['drivers-viagens']?.map(d => ({
        value: d.id,
        text: d.nome,
        agregado: d.agregado,
        filial: d.filial.nome,
      }))
    : selects.drivers.map(d => ({
        value: d.id,
        text: d.nome,
        agregado: d.agregado,
        filial: d.filial.nome,
      }));
  const clients = selects.clients.map(d => ({ value: d.id, text: d.nome }));
  const empresas = selects.empresas.map(d => ({ value: d.id, text: d.nome }));
  const trucks = isProvider
    ? selects['trucks-viagens'].map(item => ({
        text: item.placa,
        value: item.id,
      }))
    : selects.trucks.map(item => ({
        text: item.placa,
        value: item.id,
      }));

  const { data: multa, isFetching } = useQuery(
    ['multa', params.id],
    () => getMulta(params.id),
    {
      onError: error => toast.error(error),
      enabled: edit,
      refetchOnWindowFocus: false,
    },
  );

  const { data: _tiposMulta = [] } = useQuery(
    ['tipos-multa'],
    () => getTiposMultas(),
    {
      refetchOnWindowFocus: false,
    },
  );

  const tiposMulta = _tiposMulta.map(item => ({
    ...item,
    text: item.titulo,
    value: item.id,
  }));

  const {
    control,
    handleSubmit,
    reset,
    watch,
    setValue,
    setError,
    clearErrors,
    register,
    formState: { errors },
  } = useForm({ defaultValues });

  const motorista = watch('id_motorista');
  const multa_tipo = watch('id_multa_tipo');

  useEffect(() => {
    if (edit && multa) {
      const data = { ...multa };
      delete data.motorista;
      delete data.caminhao;
      delete data.multa_tipo;
      delete data.distribuidora;
      delete data.empresa;
      reset(data);
    }
  }, [multa]);

  useEffect(() => {
    register('endereco', {
      required: { value: true, message: 'Campo obrigatório.' },
    });
  }, [register]);

  useEffect(() => {
    if (motorista) {
      const driver = drivers.find(d => d.value === motorista);
      setValue('filial', driver?.filial);
      setValue('agregado', driver?.agregado);
    } else {
      setValue('filial', '');
      setValue('agregado', '');
    }
  }, [motorista, drivers]);

  useEffect(() => {
    if (multa_tipo) {
      const tipo = tiposMulta.find(t => t.value === multa_tipo);
      setValue('gravidade', tipo?.gravidade);
      setValue('pontos', tipo?.pontos);
      setValue('valor', tipo?.valor);
    } else {
      setValue('gravidade', '');
      setValue('pontos', '');
      setValue('valor', '');
    }
  }, [multa_tipo, tiposMulta]);

  const onSubmit = async data => {
    try {
      setLoading(true);
      const req = await saveMulta({
        ...data,
        agregado: data.agregado === 'SIM',
        km: Number(data.km) ?? undefined,
        latitude: Number(data.latitude) ?? undefined,
        longitude: Number(data.longitude) ?? undefined,
      });
      if (req?.success) {
        toast.success(req?.message);
        navigate('/multas');
      } else toast.error(req?.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.warn('Erro ao salvar.', error);
    }
  };

  // #region Render
  return (
    <>
      {isFetching ? (
        <Loading />
      ) : (
        <Container>
          <SpacedRow>
            <h1>{edit ? `Editar` : `Adicionar`} multa de trânsito</h1>
            {edit && (
              <GhostButton
                startIcon={<HistoricoIcon />}
                size="medium"
                onClick={() => navigate(`/logs/multas/${params.id}`)}
              >
                HISTÓRICO DE ALTERAÇÕES
              </GhostButton>
            )}
          </SpacedRow>
          <form onSubmit={handleSubmit(onSubmit, err => console.log(err))}>
            <Grid container columnSpacing={2} rowSpacing={1}>
              <Grid item xs={12} md={9}>
                <SelectOne
                  required
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Campo obrigatório.',
                    },
                  }}
                  data={drivers}
                  name="id_motorista"
                  label="Motorista"
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12} md={3}>
                <TextField
                  control={control}
                  name="filial"
                  label="Filial"
                  disabled
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <TextField
                  control={control}
                  name="agregado"
                  label="Agregado"
                  disabled
                  errors={errors}
                />
              </Grid>
              <Grid item xs={12} md={6}>
                <SelectOne
                  required
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Campo obrigatório.',
                    },
                  }}
                  data={trucks}
                  name="id_caminhao"
                  label="Placa do veículo"
                  errors={errors}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <SelectOne
                  required
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: 'Campo obrigatório.',
                    },
                  }}
                  data={tiposMulta}
                  name="id_multa_tipo"
                  label="Tipo da infração"
                  errors={errors}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  control={control}
                  name="gravidade"
                  label="Gravidade"
                  disabled
                  errors={errors}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  control={control}
                  name="pontos"
                  label="Pontos"
                  disabled
                  errors={errors}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  control={control}
                  name="valor"
                  label="Valor da multa"
                  disabled
                  errors={errors}
                />
              </Grid>

              <Grid item xs={12} md={6}>
                <DateHour
                  control={control}
                  errors={errors}
                  rules={{
                    required: {
                      value: true,
                      message: 'Campo obrigatório.',
                    },
                  }}
                  required
                  label="Data da infração"
                  name="data_multa"
                  minDate={addMonths(new Date(), -6)}
                />
              </Grid>
            </Grid>

            <LocationContainer>
              <LocationTitle>
                <h2>Localização</h2>
                <span>
                  Clique no mapa ou busque a rodovia para definir a localização.
                </span>
              </LocationTitle>
              <GetLocation
                control={control}
                watch={watch}
                errors={errors}
                setValue={setValue}
                setError={setError}
                clearErrors={clearErrors}
                originalPos={
                  edit && multa
                    ? {
                        lat: Number(multa.latitude),
                        lng: Number(multa.longitude),
                      }
                    : null
                }
              />
            </LocationContainer>

            <Grid container columnSpacing={2} rowSpacing={1}>
              {isProvider ? (
                <Grid item xs={12} md={12}>
                  <SelectOne
                    required
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Campo obrigatório.',
                      },
                    }}
                    data={empresas}
                    name="id_empresa"
                    label="Empresa"
                    errors={errors}
                  />
                </Grid>
              ) : (
                <Grid item xs={12} md={12}>
                  <SelectOne
                    required
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'Campo obrigatório.',
                      },
                    }}
                    data={clients}
                    name="id_distribuidora"
                    label="Cliente"
                    errors={errors}
                  />
                </Grid>
              )}

              <Grid item xs={12}>
                <TextField
                  control={control}
                  label="Observações"
                  name="observacao"
                  placeholder="Preencha a observação"
                  errors={errors}
                  inputProps={{ maxLength: 1023 }}
                  multiline
                  rows={3}
                />
              </Grid>
              <Grid item xs={12}>
                <InputAttachedFile
                  control={control}
                  name="arquivo"
                  label="Arquivo"
                  inputLabel="Anexar Arquivo"
                  fileDir="multas/arquivos"
                />
              </Grid>
            </Grid>

            <br />
            <Footer>
              <GhostButton
                disabled={loading}
                size="medium"
                customcolor={
                  theme?.palette?.semantics?.feedback?.attention?.natural
                }
                icon={null}
                onClick={() => navigate(-1)}
              >
                Cancelar
              </GhostButton>

              <GhostButton loading={loading} size="medium" type="submit">
                Salvar
              </GhostButton>
            </Footer>
          </form>
        </Container>
      )}
    </>
  );
};
