import React, { useMemo } from 'react';
import { SingleTable } from '../../Components/SingleTable';
import { columns } from './constants';

export const MenosSeguros = ({ resData, isFetching }) => {
  const data = useMemo(() => {
    const _data = resData?.data?.data?.geral
      ? [...resData.data.data.geral]
      : [];
    _data.sort((a, b) => b.posicao - a.posicao);
    return _data.slice(0, 5);
  }, [resData]);

  return (
    <SingleTable
      title="Motoristas menos seguros"
      subtitle="Últimos 30 dias"
      data={data}
      columns={columns}
      link={{
        to: '/motoristas/rankings?order=DESC',
        children: 'Ver todos',
        justify: 'right',
      }}
      loading={isFetching}
    />
  );
};
