import React, { useState } from 'react';
import * as S from './styled';
import Modal from '@mui/material/Modal';
import GhostButton from 'components/Buttons/Ghost';
import { format } from 'date-fns';
import { Icon } from 'components/IconsOnisys';
import { usePlans } from 'hooks/usePlans';
import { useSelector, useDispatch } from 'react-redux';
import {
  toggleSnackbarOpen,
  toggleAlertModalOpen,
} from 'store/modules/header/actions';
import { requestExcel } from './services';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';

const getUsedData = (filters, collumns = [], isProvider, selectEmpresas) => {
  if (isProvider) {
    const empresas = filters?.empresas;
    if (empresas?.length) {
      const filteredEmpresas = selectEmpresas?.filter(item =>
        empresas.includes(item.id),
      );
      const newFiliais = filteredEmpresas.flatMap(item =>
        item?.filiais.map(filial => ({
          label:
            empresas?.length > 1
              ? `(${item?.nome}) ${filial?.nome}`
              : `${filial?.nome}`,
          value: filial?.id,
        })),
      );

      return collumns.map(item => {
        if (
          item.filterName === 'filial' ||
          item.filterName === 'filial_veiculo'
        ) {
          item.options = newFiliais;
        }
        return { ...item };
      });
    }
    return [...collumns];
  }
  return [...collumns];
};

const ExcelModal = ({
  handleClose,
  open,
  title,
  titleIcon,
  subtitle,
  onClick,
  excelFields,
  setData,
  loading,
  query,
  filtersPersis,
  collumns,
  file_name,
  route,
  ids = null,
  evaluationTab = null,
  isManualDownload,
  threeMonthsLimit,
  isLocal,
  localData,
}) => {
  const [isLoading, setLoading] = useState(false);
  const theme = useTheme();
  // console.log('LocalData on excelModal', localData);
  // console.log('Collumns', collumns);
  // console.log('Filters persist inside modal excel', filtersPersis);

  const fetchExcelData = async (route, query, combinedQuery) => {
    if (route && query && Object.keys(combinedQuery).length > 0) {
      const modifiedQuery = {
        ...combinedQuery,
        evaluateQuery: true,
        sendUsingEmail: false,
      };

      try {
        setLoading(true);
        const result = await requestExcel(route, modifiedQuery);
        setLoading(false);
        if (result.message === 'Query estimated to scan too many rows') {
          return 'cancel';
        }
        return result.message.data.data;
      } catch (error) {
        console.error('Error fetching Excel data:', error);
      }
    }
  };

  const handleExcelExport = async (
    route,
    query,
    combinedQuery,
    finalDate,
    initialDate,
    threeMonthsLimit,
    isLocal,
    localData,
  ) => {
    try {
      // arquivos que o back não retorna mais de 3 meses
      if (Math.abs(finalDate - initialDate) > 7889400000 && threeMonthsLimit) {
        toast.error('Não é possível exportar períodos maiores que 3 meses');
        handleClose();
        return;
      }

      let isBigFile = false;
      // recurso só disponivel na rota de desvios
      if (route === '/excel/desvios') {
        isBigFile = await fetchExcelData(route, query, combinedQuery);
        // console.log('isBigFile', isBigFile);
        if (isBigFile === 'cancel') {
          toast.error(
            'Seu arquivo excel ultrapassou o limite máximo de 510000 linhas',
          );
          handleClose();
          return;
        }
      }

      // console.log('isLocal on excelModal', isLocal);
      if (isLocal) {
        // console.log('Entrou no local data');
        dispatch(
          toggleSnackbarOpen({
            url: route,
            query: combinedQuery,
            isBigFile,
            isError: false,
            isManualDownload,
            isLocal,
            localData,
          }),
        );
        handleClose();
        return;
      }

      // console.log('isBigFile dentro da função', isBigFile);

      if (isBigFile) {
        // Se for um arquivo grande
        dispatch(
          toggleAlertModalOpen({
            title: 'Esse arquivo é muito grande',
            message: (
              <p>
                O arquivo está demorando mais que o esperado para ser gerado e
                será enviado no e-mail{' '}
                <span
                  style={{
                    color: theme.palette.brand.secondary.natural,
                    textTransform: 'none',
                  }}
                >
                  {user.email.toLowerCase()}
                </span>{' '}
                assim que estiver pronto.
              </p>
            ),
            url: route,
            query: combinedQuery,
            isBigFile,
            isError: false,
            isManualDownload,
          }),
        );
        handleClose();
      } else {
        // Se não for um arquivo grande
        dispatch(
          toggleSnackbarOpen({
            url: route,
            query: combinedQuery,
            isBigFile,
            isError: false,
            isManualDownload,
          }),
        );
        handleClose();
      }
    } catch (e) {
      console.error('Erro ao tentar exportar', e);
    }
  };

  const combinedQuery = {
    excelFields,
    ...query,
    file_name,
  };

  // Parametros de algumas rotas
  // route === '/excel/evaluations' ||
  // route === '/excel/evaluationsDescarga' ||
  // route === '/excel-provider/infractionExclude' ||
  // route === '/excel-provider/reviewOperation'
  if (ids != null) {
    combinedQuery.ids = ids;
  }
  if (evaluationTab != null) {
    combinedQuery.status = evaluationTab;
  }

  const { isProvider } = usePlans();
  const selectEmpresas = useSelector(state => state.selects.empresas);
  const user = useSelector(state => state.auth?.user?.user);
  const dispatch = useDispatch();

  // Verificação com valores padrão caso seja nulo ou indefinido
  const initialDate = filtersPersis?.initialDate
    ? new Date(filtersPersis.initialDate)
    : null;
  const finalDate = filtersPersis?.finalDate
    ? new Date(filtersPersis.finalDate)
    : null;

  // Verificação de null e formatação condicional
  const formattedInitialDate = initialDate
    ? format(initialDate, 'dd/MM/yyyy')
    : '';
  const formattedFinalDate = finalDate ? format(finalDate, 'dd/MM/yyyy') : '';
  const dateString =
    formattedInitialDate && formattedFinalDate
      ? `${formattedInitialDate} - ${formattedFinalDate}`
      : formattedInitialDate || formattedFinalDate || '';

  const setRenderValue = selected => {
    const limitRenderValue = 3;
    if (!selected.length) {
      return null;
    }
    if (selected.length < limitRenderValue) {
      return selected.join(', ');
    }
    return `${selected[0]} e mais ${selected.length - 1}`;
  };

  function gerarResumoFiltros(definicoesFiltros = [], filtrosSelecionados) {
    if (!Array.isArray(definicoesFiltros)) {
      return [];
    }

    return definicoesFiltros.reduce((resumo, definicao) => {
      // Garantindo que definicao e suas propriedades estejam presentes
      if (
        !definicao ||
        !definicao.filterName ||
        !definicao.label ||
        !Array.isArray(definicao.options)
      ) {
        return resumo; // Ignorar caso a definição não seja válida
      }

      const { filterName, label, options } = definicao;
      const valoresSelecionados = filtrosSelecionados?.[filterName];

      // Verificar se valoresSelecionados é um array com elementos
      if (
        Array.isArray(valoresSelecionados) &&
        valoresSelecionados.length > 0
      ) {
        const labelsSelecionados = options
          .filter(option => valoresSelecionados.includes(option.value))
          .map(option => option.label);

        if (labelsSelecionados.length > 0) {
          resumo.push({
            label,
            value: labelsSelecionados,
          });
        }
      }

      return resumo;
    }, []);
  }

  const usedData = getUsedData(
    filtersPersis,
    collumns,
    isProvider,
    selectEmpresas,
  );

  const filtrosData = gerarResumoFiltros(usedData, filtersPersis);
  // console.log('filtros data inside excelmodal', filtrosData);

  return (
    <Modal open={open}>
      <S.ModalExportacaoContainer>
        {/* Titulo */}
        <S.TituloContainer>
          <Icon
            name="icone_exportar"
            style={{
              color: theme.palette.brand.secondary.natural,
              width: '24px',
              height: '24px',
              marginRight: '8px',
            }}
          />
          <S.TextoTitulo>Confira os dados da sua exportação</S.TextoTitulo>
          <S.BotaoFechar onClick={handleClose}>
            <Icon
              name="icone_close"
              style={{
                color: theme.palette.brand.primary.light,
                width: '12px',
                height: '12px',
              }}
            />
          </S.BotaoFechar>
        </S.TituloContainer>
        {/* Linha 1 */}
        <S.ArquivoEFormatoLinhaContainer>
          <S.ArquivoContainer>
            <S.ArquivoLabel>Arquivo</S.ArquivoLabel>
            <S.ArquivoInput
              type="text"
              id="input-1"
              value={file_name?.length ? file_name : 'Nome do arquivo'}
              onChange={() => {}}
              disabled
            />
          </S.ArquivoContainer>
          <S.FormatoContainer>
            <S.FormatoLabel>Formato</S.FormatoLabel>
            <S.ArquivoInput
              type="text"
              id="input-2"
              value="Arquivo para Excel (.xls)"
              onChange={() => {}}
              disabled
            />
          </S.FormatoContainer>
        </S.ArquivoEFormatoLinhaContainer>
        <S.FiltersWrapper>
          {/* Data */}
          {initialDate && finalDate && (
            <S.FiltroContainer>
              <S.FiltroLabel>Período filtrado</S.FiltroLabel>
              <S.FiltroInput
                type="text"
                id="input-3"
                value={dateString} // Certifique-se de que dateString seja calculado adequadamente
                onChange={() => {}}
                disabled
              />
              <Icon
                name="icone_Calendario"
                style={{
                  color: theme.palette.brand.primary.light,
                  position: 'absolute',
                  right: '20px',
                  top: '65%',
                  transform: 'translateY(-50%)',
                  pointerEvents: 'none',
                  width: '24px',
                  height: '24px',
                }}
              />
            </S.FiltroContainer>
          )}
          {/* Campos de filtro */}
          {filtrosData.map((filtro, index) =>
            filtro.value ? ( // Verifica se o valor não é vazio, null ou undefined
              <S.FiltroContainer key={index}>
                <S.FiltroLabel>Filtro: {filtro.label}</S.FiltroLabel>
                <S.FiltroInput
                  type="text"
                  id={`input-${index + 4}`} // Ajuste do ID para ser único
                  value={setRenderValue(
                    Array.isArray(filtro.value) ? filtro.value : [],
                  )}
                  onChange={() => {}}
                  disabled
                />
                <Icon
                  name="icone_filtro"
                  style={{
                    color: theme.palette.brand.primary.light,
                    position: 'absolute',
                    right: '20px',
                    top: '65%',
                    transform: 'translateY(-50%)',
                    pointerEvents: 'none',
                    width: '24px',
                    height: '24px',
                  }}
                />
              </S.FiltroContainer>
            ) : null,
          )}
        </S.FiltersWrapper>
        {/* Botoes */}
        <S.BotoesContainer>
          <GhostButton
            onClick={handleClose}
            customcolor={theme.palette.semantics.feedback.attention.natural}
            style={{
              width: '93px',
              height: '32px',
              marginRight: '8px',
              color: 'red',
              borderColor: 'red',
            }}
          >
            Cancelar
          </GhostButton>
          <GhostButton
            loading={isLoading}
            onClick={() =>
              handleExcelExport(
                route,
                query,
                combinedQuery,
                initialDate,
                finalDate,
                threeMonthsLimit,
                isLocal,
                localData,
              )
            }
            style={{
              width: '77px',
              height: '32px',
            }}
          >
            Baixar
          </GhostButton>
        </S.BotoesContainer>
      </S.ModalExportacaoContainer>
    </Modal>
  );
};

export default ExcelModal;
