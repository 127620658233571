import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  .space {
    margin-right: 10px;
  }
  .title-empresa {
    font-family: 'Texta';
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 34px;
    color: #424449;
  }
`;

export const ContentCards = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  .container-custom-card {
    display: flex;
    ${({ print }) => (print ? `width: 100%;` : '')}
    flex-direction: row;
    justify-content: ${({ print }) => (print ? 'start' : 'center')};
    align-items: center;
    background-color: white;
    padding: 5px;
    border-radius: 4px;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
    min-width: 20%;
    height: 105px;
  }
  .text_custom_card {
    font-size: 14px;
    justify-content: center;
    font-style: normal;
    text-align: left;
  }
`;

export const ContentGraphMiddle = styled.div`
  display: flex;
  width: 100%;
  margin-bottom: 16px;
  margin-top: 16px;
  .container-graph {
    width: 70%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
  }
  .container-radar-graph {
    width: 30%;
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    margin-right: 16px;
  }
`;
export const ContentGraphBottom = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  .container-graph {
    width: ${({ print }) => (print ? '100%' : '70%')};
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
    border-radius: 4px;
  }

  .card1 {
    width: ${({ print }) => (print ? '100%' : '20%')};
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
    border-radius: 4px;
    padding: 16px;
  }
  .card2 {
    display: flex;
    flex-direction: column;
    padding: 16px;
    text-align: center;
    justify-content: space-between;
    width: ${({ print }) => (print ? '100%' : '20%')};
    background-color: white;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 4px;
    border-radius: 4px;
    .top {
      font-size: 16px;
      font-style: normal;
      font-weight: medium;
      letter-spacing: 0px;
      color: #4b5166;
    }
    .middle-total {
      text-align: center;
      font-size: ${({ print }) => (print ? '48px' : '64px')};
      font-weight: 900;
      font-style: normal;
      color: #4b5166;
    }
    .middle-sub {
      text-align: center;
      font-size: 16px;
      font-style: normal;
      color: #939aab;
    }
    .bottom {
      font-size: 16px;
      font-weight: 900;
      font-style: normal;
      letter-spacing: 0px;
      color: #f64e60;
    }
  }
  .title {
    font-size: 24px;
    font-weight: 900;
    letter-spacing: 0px;
    color: #4b5166;
    margin-top: 16px;
  }
  .sub-title {
    text-align: left;
    font-size: 16px;
    font-weight: medium;
    letter-spacing: 0px;
    color: #4b5166;
  }
  .sub-title > span {
  }
  .sub-container {
    height: 106px;
    border-left: 3px solid #939aab4d;
    border-radius: 5px;
    padding: 10px;
    margin-top: 16px;
    .text1 {
      text-align: left;
      font-size: 16px;
      font-weight: medium;
      letter-spacing: 0px;
      color: #4b5166;
    }
    .text2 {
      text-align: left;
      font-size: 16px;
      font-weight: 900;
      letter-spacing: 0px;
      color: #4b5166;
    }
  }
`;
