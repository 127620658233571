import styled, { css } from 'styled-components';

const StyledToast = styled.div`
  ${({ theme, variant, ...props }) => css`
    display: flex;
    transition: all 1s;
    align-items: center;
    width: 100%;
    height: ${props.active ? '50px' : '0px'};
    padding: ${props.active ? '11px' : '0px'};
    margin: ${props.active ? '20px 0' : '0 0 20px 0'};
    border: ${props.active
      ? `1px solid ${theme.palette.semantics.feedback[variant].natural}`
      : 'none'};
    border-radius: 4px;
    background: ${theme.palette.semantics.feedback[variant].light} 0% 0%
      no-repeat padding-box;

    cursor: pointer;
    h2 {
      width: 100%;
      color: ${theme.palette.words.subtitle.natural};
      font: normal 600 14px Texta;
      margin-left: 15px;
    }
  `}
`;

const EditButton = styled.button`
  border: none;
  text-decoration: underline;
  font: normal normal medium 14px/20px Texta;
  letter-spacing: 0px;
  color: #f64e60;
  background-color: transparent;
  opacity: 1;
`;

export { StyledToast, EditButton };
