import {
  getAgregados,
  getMotoristas,
  getStatusMotorista,
  getVeiculos,
  getDesviosTiposPadrao,
} from 'constants/_SERVICES/provider';

// PAGES
import Drivers from 'pages/Provider/Motoristas';
import LogMotorista from 'pages/Provider/Motoristas/History';
import { PerfilMotorista } from 'pages/Motoristas/PerfilMotorista';
import ModalAddEmMassa from 'pages/Provider/Motoristas/ModalAddEmMassa';
import RankingMotoristas from 'pages/Provider/RankingMotoristas';

export default {
  '/motoristas': {
    title: 'Motoristas',
    back: -1,
    date: false,
    defaults: [],
    pageFilterName: 'filterDriverProvider',

    page: <Drivers />,
  },
  '/motoristas/add-motoristas': {
    date: true,
    back: -1,
    defaults: [],
    page: <ModalAddEmMassa />,
  },

  '/motoristas/:id': {
    title: 'Perfil do motorista',
    date: false,
    back: -1,
    defaults: [],
    page: <PerfilMotorista />,
  },
  '/logs/driver/:id': {
    back: -1,
    defaults: [],
    page: <LogMotorista />,
  },

  '/motoristas/rankings': {
    title: 'Ranking motoristas',
    page: <RankingMotoristas />,
  },
};
