import {
  getExecutores,
  getBases,
  getTamanhos,
  getStates,
  getTypeClientIp,
} from 'constants/_SERVICES/provider';

// Pages
import CaeDetail from 'pages/LayoutDescarga/Cae/Detalhe';
import LayoutPdf from 'pages/LayoutDescarga/Config/ModelPdf';
import LayoutDescargaProvider from 'pages/Provider/LayoutDescarga';
import ConfigLayoutProvider from 'pages/Provider/LayoutDescarga/Config';

export default {
  '/layout': {
    title: 'Layout de Descarga',
    defaults: [],
    back: -1,
    pageFilterName: 'filterLayoutProvider',
    page: <LayoutDescargaProvider />,
  },

  '/layout/:id': {
    back: -1,
    title: 'Layout',
    id: true,
    status: true,
    defaults: [],
    page: <ConfigLayoutProvider />,
  },

  '/layout/pdf/:id': {
    back: -1,
    title: 'Layout',
    id: true,
    defaults: [],
    page: <LayoutPdf />,
  },

  '/cae/:id': {
    back: -1,
    title: 'Cae',
    id: true,
    defaults: [],
    page: <CaeDetail />,
  },
};
