import styled, { css } from 'styled-components';
import { Card } from '@mui/material';
import { makeStyles } from '@material-ui/styles';
import DefaultButton from 'components/Buttons/Default';

const StyledTitle = styled.h1`
  ${({ theme }) => css`
    text-align: left;
    font: normal normal 900 20px/20px Texta;
    letter-spacing: 0px;
    color: ${theme.palette.brand.primary.natural};
    opacity: 1;
    margin-bottom: 16px;
    margin-top: 24px;
  `}
`;

const StyledCard = styled(Card)`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    background: ${theme.palette.system.overlay} 0% 0% no-repeat padding-box;
    border: 1px solid ${theme.palette.brand.primary.light}4D;
    border-radius: 4px;
    opacity: 1;
    margin-bottom: 60px;
    .normalText {
      text-align: left;
      font-size: 14px;
      letter-spacing: 0px;
      color: #656e8c;
      opacity: 1;
    }

    .inputBox {
      border: 1px solid #939aab;
      border-radius: 4px;
      opacity: 1;
      padding: 8px;

      .p {
        text-align: left;
        font-size: 16px;
        letter-spacing: 0px;
        color: #4b5166;
        opacity: 1;
      }
    }
  `}
`;

const StyledCroquiCard = styled(Card)`
  ${({ theme }) => css`
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 25px;

    background: ${theme.palette.system.overlay} 0% 0% no-repeat padding-box;
    border: 1px solid ${theme.palette.brand.primary.light}4D;
    border-radius: 4px;
    opacity: 1;

    img {
      object-fit: contain;
      max-width: 100%;
      max-height: 100%;
    }
  `}
`;

const StyledIdentificarCroquiCard = styled.div`
  ${({ theme }) => css`
    display: flex;
    border: 1px solid;
    background: ${theme.palette.semantics.feedback.warning.light} 0% 0%
      no-repeat padding-box;
    border: 1px solid ${theme.palette.semantics.feedback.warning.natural};
    border-radius: 4px;
    opacity: 1;
    height: 300px;

    div {
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin: 40px;
      p {
        text-align: left;
        font: normal normal 900 14px/18px Texta;
        letter-spacing: 0px;
        color: ${theme.palette.words.title.natural};
        opacity: 1;
        margin: 5px;
      }
    }
  `}
`;

const Header = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.system.border};
    color: ${theme.palette.words.title.natural};
    width: 100%;
    text-transform: capitalize;
    font-weight: bold;
    font-size: 18px;
    letter-spacing: 1.1px;
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 20px;

    ${({ initial }) =>
      initial &&
      `
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  `};

    h3 {
      flex: 1;
      font-size: 18px;
    }

    span {
      display: flex;
      align-items: center;
      margin-right: 20px;
      p {
        color: ${theme.palette.words.subtitle.natural};
        font-size: 16px;
        font-weight: 500;
        text-transform: none;
      }
    }
  `}
`;

const Button = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;

    ${({ value }) => {
      return (
        value === 'SIM' &&
        `
    div:nth-child(1) {
      border-color: ${theme.palette.semantics.feedback.success.natural} !important;
      background: ${theme.palette.semantics.feedback.success.natural} !important;
      color: ${theme.palette.words.text.background};
    }
  `
      );
    }}

    ${({ value }) =>
      value === 'NAO' &&
      `
    div:nth-child(2) {
      border-left-width: 1px;
      border-right-width: 1px;
      border-color: ${theme.palette.semantics.feedback.attention.natural} !important;
      background: ${theme.palette.semantics.feedback.attention.natural} !important;
      color: ${theme.palette.words.text.contrast};
    }
    div:nth-child(1) {
      border-right-width: 1px;
      border-right-color: ${theme.palette.semantics.feedback.attention.natural} !important;
    }
    div:nth-child(3) {
      border-left-width: 1px;
      border-left-color: ${theme.palette.semantics.feedback.attention.natural} !important;
    }
  `}

  ${({ value }) =>
      value === 'N/A' &&
      `
    div:nth-child(3) {
      border-color: ${theme.palette.semantics.feedback.information.natural} !important;
      background: ${theme.palette.semantics.feedback.information.natural} !important;
      color: ${theme.palette.words.text.contrast};
    }
  `}

  div {
      width: 102px;
      font-weight: 900;
      height: 31px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid ${theme.palette.brand.primary.light};
      color: ${theme.palette.words.text.light};
      background: ${theme.palette.system.overlay};

      &:nth-child(1) {
        border-bottom-left-radius: 4px;
        border-top-left-radius: 4px;
        height: 33px;
      }

      &:nth-child(2) {
        border-right-radius: 1px;
        border-bottom-right-radius: 4px;
        border-top-right-radius: 4px;
        height: 33px;
      }
    }
  `}
`;

const StyledButton = styled(Button)`
  ${({ theme }) => css`
    height: 52px;
    border: 1px dashed;
    background: ${theme.palette.system.overlay} 0% 0% no-repeat padding-box;
    border: 1px dashed ${theme.palette.words.subtitle.natural};
    border-radius: 4px;
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px;
  `}
`;

const CancelButton = styled(DefaultButton)`
  ${({ theme, disabled }) => css`
    &.MuiButton-root {
      background: ${theme.palette.system.overlay};
      color: ${theme.palette.words.title.natural};
      border: ${disabled
        ? ''
        : `1px solid${theme.palette.words.title.natural}`};

      &:hover {
        background: ${theme.palette.brand.primary.background};
      }

      &:active {
        background: ${theme.palette.words.subtitle.natural};
        box-shadow: 0px 3px 15px ${theme.palette.words.subtitle.natural};
      }
    }
  `}
`;

const StorageImage = styled.img`
  background-color: red;
  max-width: 225px;
  max-height: 145px;
  width: auto;
  height: auto;
  border-radius: 4px;
`;

const Close = styled.div`
  position: absolute;
  z-index: 2;
  top: 2px;
  left: 200px;
  transform: scale(1);
  transition: 0.3s ease-out;
  :hover {
    transform: scale(1.2);
  }
`;

const ColumnTitle = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.words.subtitle.natural};
    font-size: 18px;
    font-weight: bold;
  `}
`;

const TanquesDisco = styled.div`
  height: 50px;
  width: 50px;
  border-radius: 50%;
  border-width: 1px;
  border-style: solid;
`;

const Item = styled.div`
  ${({ theme, ...props }) => css`
    margin-left: ${props.marginleft};
    display: ${props.display};
    align-items: ${props.alignitems};
    flex: ${props.flex};
    width: ${props.width};
    justify-content: ${props.justifycontent};
    margin-right: ${props.marginright};
  `}
`;

const Paragraph = styled.p`
  ${({ theme, ...props }) => css`
    color: ${theme.palette.brand.primary.natural};
    font-weight: ${props.fontweight ? props.fontweight : 600};
    margin-left: ${props.marginleft};
    font-size: 15px;
  `}
`;

const QuestionRow = styled.div`
  ${({ theme }) => css`
    display: flex;
    padding: 5px 0;
    border-bottom: 1px solid ${theme.palette.system.border};
    height: 100%;
    align-items: center;
  `}
`;

const IconDivCloseCroqui = styled.div`
  position: absolute;
  z-index: 2;
  top: 30px;
  right: 30px;
  padding: 0px 0px 0px 0px;
  width: 30px;
  height: 30px;
  transform: scale(1);
  transition: 0.3s ease-out;
  :hover {
    transform: scale(1.2);
  }
`;

const IconDivClose = styled.div`
  position: absolute;
  z-index: 2;
  top: 10px;
  margin-left: 95%;
  width: 30px;
  height: 30px;
  transform: scale(1);
  transition: 0.3s ease-out;
  :hover {
    transform: scale(1.2);
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const CardCol = styled.div`
  display: flex;
`;

const CardContentCol = styled.div`
  ${({ theme }) => css`
    border-bottom: 1px solid ${theme.palette.semantics.feedback.unknown.natural};
    display: flex;
    min-height: 45px;
    padding: 5px;
    align-items: center;
    flex: 2 !important;

    h4 {
      font-weight: 300;
    }
    h5 {
    }
  `}
`;

const Assinatura = styled.div`
  hr {
    margin: 20px 0;
  }

  h4 {
    color: #9a9ea8;
  }
`;

const AsignTitle = styled.p`
  font-size: 16px;
  text-transform: capitalize;
`;

const AsignDate = styled.p`
  font-size: 14px;
  text-transform: capitalize;
`;

const NoContent = styled.p`
  height: 42px;
  font-size: 16px;
`;

const HeaderRaizen = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  p {
    font-weight: bold;
  }

  div {
    margin-right: 35px;
    text-align: right;
  }

  img {
    width: 150px;
  }
`;

const TitleDivMain = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const TitleMain = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    text-align: center;
    color: ${theme.palette.brand.primary.dark};
    margin-bottom: 30px;
    font-weight: 900;
    text-align: center;

    img {
      margin-right: 15px;
    }
    h1 {
      max-width: 500px;
    }
  `}
`;

const ButtonContainer = styled.div`
  margin-top: 35px;
  margin-left: 50px;
  margin-right: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const StyledCardHeader = styled.div`
  ${({ theme }) => css`
    background: ${theme.palette.brand.primary.dark};
    padding: 5px 10px;
    text-align: center;

    h1 {
      color: white;
      font-size: 16px;
    }
  `}
`;

const StyledObs = styled.p`
  ${({ theme }) => css`
    color: var(--secondary-texts-inputs);
    text-align: left;
    font: normal normal medium 14px/20px Texta;
    letter-spacing: 0px;
    color: ${theme.palette.words.subtitle.natural};
    opacity: 1;
  `}
`;

const Page = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;

  @media all {
    .page-break {
      display: none;
    }
  }

  @media print {
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-top: 1rem;
      display: flex;
      page-break-before: always;
      /* align-items: center; */
    }

    .page-break-inside {
      page-break-inside: auto;
    }
  }

  @page {
    size: auto;
  }
`;

export const useStyles = makeStyles(() => ({
  inputText: {
    margin: '10px 0px',
    width: '100%',
    maxWidth: '395px',
  },
  botaoAddPergunta: {
    border: `2px solid #656E8C`,
    borderRadius: '4px',
    color: '#656E8C',
    backgroundColor: 'transparent',
  },
}));

export {
  StyledTitle,
  StyledCard,
  StyledCroquiCard,
  StyledIdentificarCroquiCard,
  StyledButton,
  CancelButton,
  StorageImage,
  IconDivCloseCroqui,
  IconDivClose,
  Button,
  Header,
  Close,
  ColumnTitle,
  TanquesDisco,
  Item,
  Paragraph,
  QuestionRow,
  IconContainer,
  StyledCardHeader,
  ButtonContainer,
  TitleMain,
  TitleDivMain,
  CardCol,
  CardContentCol,
  Assinatura,
  AsignTitle,
  AsignDate,
  NoContent,
  HeaderRaizen,
  StyledObs,
  Page,
};
