import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 2rem;
  margin-bottom: 2rem;
`;

export const Status = styled.div`
  width: 8rem;
  height: 1.5rem;
  text-align: center;
  border-radius: 0.75rem;
  background: ${({ theme, color }) =>
    theme.palette.semantics.feedback[color ?? 'info'].natural}30;
  color: ${({ theme, color }) =>
    theme.palette.semantics.feedback[color ?? 'info'].natural};
  font: normal normal 600 1rem Texta;
  text-transform: capitalize;
  letter-spacing: 0px;
`;

export const Title = styled.span`
  font: normal normal 900 3rem Texta;
  text-transform: capitalize;
  letter-spacing: 0px;
  color: #4b5166;
  opacity: 1;
`;

export const Avatar = styled.div`
  position: relative;
  width: 8rem;
  height: 8rem;
  border-radius: 4rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #939aab;
  box-shadow: 0px 3px 6px #00003229;
  font-family: Texta;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  span {
    font-size: 4rem;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
  }
`;

export const Ranking = styled.div`
  white-space: nowrap;
  font: normal normal medium 16px/16px Texta;
  color: #4b5166;
  span {
    font: normal normal 900 24px/20px Texta;
  }
`;

export const Icon = styled.div`
  width: 3rem;
`;

export const LoadingBox = styled.div`
  display: flex;
  width: 100%;
  height: 3rem;
  align-items: center;
  justify-content: center;
  background: rgba(149, 170, 201, 0.2);
  color: #939aab;
  border-radius: 4px;
`;

export const Content = styled.div`
  width: 100%;
`;

export const Flex = styled.div`
  display: flex;

  .full-width {
    width: 100%;
  }
  .flex-1 {
    flex: 1;
  }
  .mt-05 {
    margin-top: 0.5rem;
  }
  .mt-1 {
    margin-top: 1rem;
  }
  .gap-05 {
    gap: 0.5rem;
  }
  .gap-1 {
    gap: 1rem;
  }
  .gap-2 {
    gap: 2rem;
  }
  .column {
    flex-direction: column;
  }
  .row {
    flex-direction: row;
  }
  .justify-between {
    justify-content: space-between;
  }
  .justify-center {
    justify-content: center;
  }
  .align-center {
    align-items: center;
  }
  .align-start {
    align-items: start;
  }
  .align-end {
    align-items: end;
  }
`;
