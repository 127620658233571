import styled from 'styled-components';

export const Container = styled.div``;

export const Flex = styled.div`
  display: flex;
  gap: 1rem;

  &.gap-05 {
    gap: 0.5rem;
  }
`;

export const Title = styled.div`
  width: 100%;
  font-size: 24px;
  font-weight: 900;
  color: #4b5166;
  border-bottom: 1px solid #939aab4d;
`;
