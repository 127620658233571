import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Components
import ResponsibleModal from 'pages/Desvios/components/ResponsibleModal';
import FinishModal from 'pages/Desvios/components/FinishModal';
import ListPage from '../../components/ListPage';

// Services
import { columns } from './constants';
import { trackEvent } from 'utils/mixpanel';
import { statusTabs } from 'pages/Desvios/Listagem/constants';
import {
  aprovarDesvios,
  finalizarDesvios,
} from 'pages/Desvios/Listagem/services';
import { requestCards, requestDesvios, requestTotaisDesvios } from './services';

import { useFetchMultipleWithCache } from 'hooks/fetchFilters';
import { usePlans } from 'hooks/usePlans';

const defaultFilters = {
  status: 'ABERTO',
};
export const Desvios = ({ setOpenExport }) => {
  // Redux e hooks
  const params = useParams();
  const { isOpLogistico, isProvider } = usePlans();
  const user = useSelector(state => state.auth?.user?.user);

  const [filterDesvios, setFilterDesvios] = useState(defaultFilters);

  const [query, setQuery] = useState(null);
  const [loadingTab, setLoadingTab] = useState(true);
  const [selectedCard, setSelectedCard] = useState(null);
  const [selectedInfractions, setSelectedInfractions] = useState([]);

  const [responsibleModal, setResponsibleModal] = useState(null);
  const [idsFinishModal, setIdsFinishModal] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);

  const [resetTable, setResetTable] = useState(false);
  const [loadingLines, setLoadingLines] = useState([]);

  // Selects dos filtros
  const {
    clients,
    responsaveis,
    tecnologias,
    desviosTiposPadrao,
    criticidade,
  } = useFetchMultipleWithCache();

  const { refetch: fetchTotal, data: resTotal } = useQuery(
    ['totais-desvios', params?.id, query],
    () => query && requestTotaisDesvios(params?.id, query),
    {
      refetchOnWindowFocus: false,
    },
  );

  const {
    refetch: fetchData,
    isFetching,
    data: resData,
  } = useQuery(
    ['desvios', params?.id, query],
    () => query && requestDesvios(params?.id, query),
    {
      refetchOnWindowFocus: false,
      onSettled: () => {
        setLoadingLines([]);
        setLoadingTab(false);
        resetTable && setResetTable(false);
      },
    },
  );

  // Atualiza cards de acordo com os filtros selecionados
  // Atualiza tabela após cads
  const {
    refetch: fetchCards,
    isLoading: loadingCards,
    data: resCards,
  } = useQuery(
    [
      'cards-desvios',
      params?.id,
      {
        ...filterDesvios,
      },
    ],
    () =>
      requestCards(params?.id, {
        ...filterDesvios,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  // Altera tab inicial em caso de Distribuidora
  useEffect(() => {
    if (isProvider && !isOpLogistico && filterDesvios.status === 'ABERTO')
      setFilterDesvios(state => ({
        ...state,
        status: 'PENDENTE',
      }));
  }, []);

  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [filterDesvios, selectedCard]);

  // ********************  ACTIONS / BULKACTIONS *****************************
  // Funções das ações
  const handleAprovar = async data => {
    setLoadingModal(true);
    setLoadingLines(data?.ids || []);

    const res = await aprovarDesvios(data);
    if (res.data?.success) {
      fetchCards();
      fetchTotal();
      fetchData();
      toast.success(res.data?.message);
    } else {
      setLoadingLines([]);
      if (res.data?.message) toast.error(res.data.message);
    }
    setLoadingModal(false);
    setResponsibleModal(null);
  };

  const handleFinalizar = async ids => {
    setLoadingModal(true);
    setLoadingLines(ids || []);

    const res = await finalizarDesvios({ ids });
    if (res.data?.success) {
      fetchCards();
      fetchTotal();
      fetchData();
      toast.success(res.data?.message);
    } else {
      setLoadingLines([]);
      if (res.data?.message) toast.error(res.data.message);
    }
    setLoadingModal(false);
    setIdsFinishModal(null);
  };

  // Troca do tab (filterDesvios.status)
  const handleStatusTab = async (event, newValue) => {
    setLoadingTab(true);
    setFilterDesvios(state => ({ ...state, status: newValue }));
  };

  // Só habilita clique no card quando nao há nada carregando
  // Verifica se card clicado há valor válido: nao nulo e maior que 0.
  const handleClickCard = type => {
    if (!loadingCards && !isFetching) {
      const cards = resCards || [];
      const card = cards.find(item => item.type === type);
      if (card.value) {
        if (type === selectedCard) setSelectedCard(null);
        else setSelectedCard(type);
      }
    }
  };

  // Atualiza as colunas de acordo com o status selecionado
  const handleColumns = () => {
    const _columns = isProvider && !isOpLogistico ? columns : columns;
    const col = [..._columns].map(item => {
      if (item.id === 'createdAt') {
        return {
          ...item,
          subRow: {
            prefix: 'Vencimento:',
            value: (_, item) => item.data_vencimento || '-',
          },
        };
      }
      return item;
    });

    if (filterDesvios.status === 'FINALIZADO')
      col[5] = {
        header: 'Data Conclusão',
        id: 'data_encerramento',
        type: 'date',
        sort: true,
      };
    else if (filterDesvios.status === 'DELETADO')
      col[5] = {
        header: 'Data Exclusão',
        id: 'data_exclusao',
        type: 'date',
        sort: true,
      };

    return col;
  };

  // Atualiza bulkActions de acordo com o status selecionado
  // Os itens selecionados podem alterar o a lista de ações
  const getBulkActions = () => {
    let actions = [];
    const data = resData?.data?.data || [];
    const desviosProprios =
      !isProvider ||
      !data
        .filter(item => selectedInfractions.includes(item.id))
        .some(item => item.id_empresa);

    if (desviosProprios) {
      const filialIds = [];
      let mesmaFilial = false;
      switch (filterDesvios.status) {
        case 'ABERTO':
          // Confere se seleção tem a mesma filial
          for (const desvio of data) {
            const filialId = desvio.motorista?.filial?.id;
            if (
              selectedInfractions.includes(desvio.id) &&
              !filialIds.includes(filialId)
            )
              filialIds.push(filialId);
          }
          mesmaFilial = filialIds.length < 2;

          // nao mostrar 'actions' quando há filiais diferentes
          actions = mesmaFilial
            ? [
                {
                  title: 'Aprovar',
                  function: ids => {
                    trackEvent(user, 'APROVAR TODOS OS DESVIOS');
                    setResponsibleModal({ approve: true, data: ids });
                  },
                },
              ]
            : [];
          break;

        case 'PENDENTE':
          actions = [
            {
              title: 'Finalizar',
              function: ids => {
                trackEvent(user, 'FINALIZAR TODOS OS DESVIOS');
                setIdsFinishModal(ids);
              },
            },
          ];
          break;

        default:
          break;
      }
    }
    return actions.filter(Boolean);
  };

  // Atualiza ações de acordo com o status selecionado
  const getActions = () => {
    let actions = [
      {
        title: 'Abrir em nova guia',
        function: handleOpenNewTab,
      },
    ];
    if (!isProvider || isOpLogistico)
      switch (filterDesvios.status) {
        case 'ABERTO':
          actions = [
            ...actions,
            {
              title: 'Aprovar',
              function: id => {
                trackEvent(user, 'APROVAR DESVIOS');
                setResponsibleModal({ data: [id] });
              },
            },
          ];
          break;

        case 'PENDENTE':
          actions = [
            ...actions,
            {
              title: 'Finalizar',
              function: id => {
                trackEvent(user, 'FINALIZAR DESVIOS');
                setIdsFinishModal([id]);
              },
            },
          ];
          break;

        default:
          break;
      }
    return actions.filter(Boolean);
  };

  const handleOpenNewTab = id => {
    return window.open(`/desvios/${id}`);
  };

  // Configura as tabs da tela
  const headerTabs = {
    value: filterDesvios.status,
    items: statusTabs
      .filter(item => item.value !== 'ABERTO' || !isProvider || isOpLogistico)
      .map(item =>
        resTotal?.data
          ? {
              ...item,
              label: `${item.label} (${resTotal?.data?.data[item.value]})`,
            }
          : item,
      ),
    onChange: handleStatusTab,
    disabled: loadingCards || isFetching,
  };

  // Filtros utilizados
  const filterList = [
    {
      filterName: 'client',
      label: 'Clientes',
      options: clients.data || [],
    },
    {
      filterName: 'tecnologia',
      label: 'Tecnologias',
      options: tecnologias.data || [],
    },
    {
      filterName: 'criticidade',
      label: 'Criticidades',
      options: criticidade.data || [],
    },
    {
      filterName: 'desvio',
      label: 'Desvios',
      options: desviosTiposPadrao?.data || [],
    },
    {
      filterName: 'responsavel',
      label: 'Responsável',
      options: responsaveis.data || [],
    },
  ];

  const filters = {
    data: filterList,
    handleFilters: _filter =>
      setFilterDesvios(state => ({
        ...state,
        ..._filter,
        status: state.status,
      })),
    isFetching: isFetching || loadingCards,
    handleExport: () => setOpenExport(true),
  };

  return (
    <>
      <ListPage
        total={resTotal?.data?.data?.TOTAL}
        filterProps={filters}
        headerTabs={headerTabs}
        cards={resCards?.map(card => ({ ...card, disabled: isFetching })) || []}
        selectedCard={selectedCard}
        handleClickCard={handleClickCard}
        loadingCards={loadingCards}
        tableProps={{
          data: resData?.data?.data || [],
          columns: handleColumns(),
          setSelectedRows:
            ['ABERTO', 'PENDENTE'].includes(filterDesvios.status) &&
            (!isProvider || isOpLogistico)
              ? setSelectedInfractions
              : null,
          loading: isFetching || loadingTab,
          pageCount: resData?.data?.total || 0,
          visualizedKey: 'visto',
          local: false,
          bulk: getBulkActions(),
          actions: getActions(),
          reset: resetTable,
          onClickRow: handleOpenNewTab,
          loadingSelection: loadingLines,
          setQuery: q =>
            setQuery({
              ...filterDesvios,
              ...q,
              card: selectedCard,
            }),
          searchEvent: search =>
            trackEvent(user, 'Busca Desvios de Direção', null, search),
          sortBy: { id: 'data_desvio', order: 'DESC' },
          placeholder: `Buscar por ID`,
          empty: {
            title: 'Ops! Você não tem nenhum desvio disponível.',
            description: 'Verifique os filtros aplicados!',
          },
        }}
      />

      {responsibleModal && (
        <ResponsibleModal
          approve
          handleConfirm={handleAprovar}
          ids={responsibleModal?.data}
          handleClose={() => setResponsibleModal(null)}
          loading={loadingModal}
        />
      )}

      {idsFinishModal && (
        <FinishModal
          handleConfirm={() => handleFinalizar(idsFinishModal)}
          ids={idsFinishModal}
          handleClose={() => setIdsFinishModal(null)}
          loading={loadingModal}
        />
      )}
    </>
  );
};
