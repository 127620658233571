import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { toast } from 'react-toastify';
import * as API from '../../services';

// components
import { DeleteOutline } from '@mui/icons-material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { IconButton } from '@mui/material';
import Divider from '@mui/material/Divider';
import GhostButton from 'components/Buttons/Ghost';
import ConfirmModal from 'components/ConfirmModal';
import { Switch } from 'components/Inputs/Switch';
import Select from '../components/SelectMultiple';
import SelectDesvios from '../components/SelectMultipleDesvios';

// styles
import * as S from './styled';
import { styled } from '@mui/material/styles';
import { usePlans } from 'hooks/usePlans';

// icons
import { ReactComponent as BtnAdd } from 'images/icons/configuracoes/icone-adicionar.svg';
import { ReactComponent as EditarIcon } from 'images/icons/configuracoes/icone-editar.svg';
import { ReactComponent as HistoricoIcon } from 'images/icons/sidebar/historico.svg';
import { ReactComponent as IconSub } from 'images/escalation-list/icone-subtrair.svg';
import { ReactComponent as IconOnisys } from 'images/escalation-list/marca-onisys.svg';
import { ReactComponent as IconEmail } from 'images/escalation-list/icone-email.svg';
import { ReactComponent as IconDeslocamentos } from 'images/escalation-list/icone-lista-deslocamentos.svg';
import { useSelector } from 'react-redux';
import { useQuery } from 'react-query';
import { requestNiveis } from 'pages/Configuracoes/Usuarios/services';

const initialStep = {
  prazo: 0,
  plataforma: false,
  push: false,
  email: false,
  ids_usuarios: [],
};

const CustomStepIcon = styled('div')(({ ownerState, steps }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor:
    ownerState.index === 0 || ownerState.index === steps.length - 1
      ? '#4B5166'
      : '#EDF2F9',
  color:
    ownerState.index === 0 || ownerState.index === steps.length - 1
      ? '#ffff'
      : '#4B5166',
  width: 33,
  fontWeight: 900,
  height: 33,
  borderRadius: '50%',
  transform: 'translateX(-4px)',
}));

const CustomStepIconComponent = props => {
  const { steps, active, completed, className, icon, handleRemoveStep } = props;

  return (
    <>
      <div style={{ position: 'absolute', transform: 'translate(-50px, 0px)' }}>
        {steps.length > 1 && icon - 1 === steps.length - 1 && (
          <IconButton onClick={handleRemoveStep}>
            <IconSub />
          </IconButton>
        )}
      </div>
      <CustomStepIcon
        ownerState={{ completed, active, index: icon - 1 }}
        className={className}
        steps={steps}
      >
        {icon}
      </CustomStepIcon>
    </>
  );
};

export const Detalhe = ({ item, herdaCliente }) => {
  const theme = useTheme();
  const { isProvider, hasTorre, hasTorrePlus } = usePlans();
  const location = useLocation();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = useState(0);
  const [title, setTitle] = useState('');
  const [status, setStatus] = useState('ATIVO');
  const [risco, setRisco] = useState('');
  const [grupos, setGrupos] = useState([]);
  const [desvioTipo, setDesvioTipo] = useState([]);
  const [desviosOptions, setDesviosOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [deleteModal, setDeleteModal] = useState(null);
  const [selectDesviosOptions, setSelectDesviosOptions] = useState([]);
  const [sending, setSending] = useState(false);

  const [prazo, setPrazo] = useState(null);
  const [steps, setSteps] = useState(
    isProvider && hasTorre && !hasTorrePlus ? [] : [initialStep],
  );

  const selects = useSelector(state => state.selects);
  const users = selects.users.map(user => ({
    id: user.id,
    value: user.nome,
    grupo: user.nivel,
  }));

  const handleExluir = async () => {
    if (item?.id) {
      const res = await API.deleteEscalation(item.id);

      if (res?.success) toast.success(res.message);
      else {
        toast.error(res?.message ?? 'Falha ao excluir.');
        return;
      }
    }
    navigate(-1);
  };

  const checkValid = data => {
    setErrors({});
    const _errors = {};
    if (!data.titulo) {
      _errors.titulo = 'Campo obrigatório.';
    }
    if (!data.ids_desvios?.length) {
      _errors.ids_desvios = 'Campo obrigatório.';
    }

    if (isProvider && (hasTorre || hasTorrePlus)) {
      if (!hasTorrePlus && !data.prazo) {
        _errors.prazo = 'Campo obrigatório.';
      }
      if (data.prazo && data.prazo < 20) {
        _errors.prazo = 'Valor deve ser maior ou igual a 20.';
      }
      if (data.prazo && data.prazo > 1440) {
        _errors.prazo = 'Valor deve ser no máximo 24 horas (1440 minutos).';
      }
    }

    if (
      data.prazo &&
      data.etapas?.length &&
      data.prazo < data.etapas.reduce((acc, item) => (acc += item.prazo), 0)
    ) {
      _errors.prazo =
        'A soma das etapas do escalation devem ter período menor que o prazo de tratativa total.';
    }

    if (!isProvider || hasTorrePlus) {
      for (const index in data.etapas) {
        if (index > 0 && !data.etapas[index].prazo) {
          _errors[`etapas[${index}].prazo`] = 'Campo obrigatorio';
        }
        if (index > 0 && data.etapas[index].prazo > 60) {
          _errors[`etapas[${index}].prazo`] =
            'Valor não pode ser maior que 60 minutos.';
        }
        if (!data.etapas[index].ids_usuarios?.length) {
          _errors[`etapas[${index}].ids_usuarios`] = 'Campo obrigatorio';
        }
      }
    }

    if (Object.getOwnPropertyNames(_errors).length) {
      setErrors(_errors);
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    const payload = {
      ...item,
      titulo: title,
      is_default: false,
      risco,
      ids_desvios: selectDesviosOptions,
      etapas: steps,
      status,
      prazo,
    };

    if (checkValid(payload)) {
      setSending(true);

      const res = await API.saveEscalation(payload);
      if (res.success) {
        toast.success('Lista salva com sucesso');
        navigate(-1);
      } else {
        toast.error(res.error?.message ?? res.message);
        setSending(false);
      }
    }
  };

  useQuery(['niveis-usuario'], () => requestNiveis(), {
    onSuccess: (data = []) => {
      setGrupos(data.map(item => ({ value: item.id, label: item.titulo })));
    },
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (location?.state) {
      setDesvioTipo(location.state.desviosTipos);
      let desvioOptions = location.state.desviosTipos;
      desvioOptions = desvioOptions.map(item => {
        return {
          id: item.id,
          criticidade: item.criticidade,
          value: item?.titulo || '',
        };
      });
      setDesviosOptions(desvioOptions);

      if (item) {
        const { titulo, risco, status, prazo, ids_desvios, etapas } =
          item || '';
        setRisco(risco);
        setPrazo(prazo);
        setTitle(titulo);
        setStatus(status ?? 'ATIVO');

        setSelectDesviosOptions(ids_desvios);
        if (etapas?.length) setSteps([...etapas]);
      }
    }
  }, [location]);

  const filterArray = (array, ids) => {
    let result = array.filter(item => ids && ids.includes(item.id));
    return result || [];
  };

  const handleAddStep = () => {
    setSteps(prevSteps => [...prevSteps, { ...initialStep }]);
  };

  const handleRemoveStep = () => {
    setSteps(prevSteps => prevSteps.slice(0, -1));
  };

  const handleRemoveDesvio = id => {
    setSelectDesviosOptions(ids => ids.filter(item => item !== id));
  };

  const handleRemoveUsuario = (index, id) => {
    const newSteps = steps.map((step, stepIndex) =>
      stepIndex === index
        ? {
            ...step,
            ids_usuarios: step.ids_usuarios.filter(item => item !== id),
          }
        : step,
    );
    setSteps(newSteps);
  };

  const handlePeopleChange = (index, selectedPeople) => {
    const newSteps = steps.map((step, stepIndex) =>
      stepIndex === index ? { ...step, ids_usuarios: selectedPeople } : step,
    );
    setSteps(newSteps);
  };

  const handleNotificationTypeChange = (index, notificationType) => {
    const newSteps = steps.map((step, stepIndex) => {
      if (stepIndex === index) {
        return { ...step, [notificationType]: !step[notificationType] };
      }
      return step;
    });
    setSteps(newSteps);
  };

  const handleTimeChange = (index, prazo) => {
    if (index) {
      const newSteps = steps.map((step, stepIndex) =>
        stepIndex === index ? { ...step, prazo } : step,
      );
      setSteps(newSteps);
    } else {
      setPrazo(prazo);
    }
  };

  return (
    <>
      <S.Container>
        <div className="container-header">
          {herdaCliente ? (
            <div>
              <div className="info">
                Configurado por {item.distribuidora.nome}
              </div>
              <div className="title">{title}</div>
            </div>
          ) : (
            <div className="new-list">
              <input
                className="input-title"
                type="text"
                placeholder="Nova Lista"
                value={title}
                onChange={e => setTitle(e.target.value)}
              />
              <EditarIcon color="#939aab" />
              {!!errors.titulo && <span>{errors.titulo}</span>}
            </div>
          )}

          {!!item?.id && (
            <div>
              <GhostButton
                startIcon={<HistoricoIcon />}
                size="medium"
                onClick={() =>
                  navigate(`/logs/configuracoes/escalation-list/${item.id}`)
                }
                sx={{ marginRight: '1rem' }}
              >
                HISTÓRICO DE ALTERAÇÕES
              </GhostButton>
            </div>
          )}
        </div>
        {!herdaCliente && <Divider />}
        <div>
          {!herdaCliente && (
            <div style={{ marginTop: 15 }}>
              <Switch
                check={status === 'ATIVO'}
                setCheckbox={value => setStatus(value ? 'ATIVO' : 'INATIVO')}
                disabled={false}
                textOn="Deslocamento ativo"
                textOff="Deslocamento inativo"
              />
            </div>
          )}
          <div className="select-header">
            <div className="title-header">Emitir alertas para:</div>
            {!herdaCliente && (
              <SelectDesvios
                width="350px"
                data={desviosOptions}
                value={selectDesviosOptions}
                handleChange={setSelectDesviosOptions}
                error={!!errors.ids_desvios}
                message={errors.ids_desvios}
              />
            )}

            {filterArray(desvioTipo, selectDesviosOptions).map(item => (
              <div key={item.id} className="label-hilight">
                {item.titulo}{' '}
                <span onClick={() => handleRemoveDesvio(item.id)}>X</span>
              </div>
            ))}
            {herdaCliente &&
              item.desvios?.length &&
              item.desvios.map(item => (
                <div key={item.id} className="label-hilight">
                  {item.titulo}
                </div>
              ))}
            {herdaCliente && item.prazo && (
              <div className="label-hilight-prazo">
                Tratar em até {item.prazo} min.
              </div>
            )}
          </div>
        </div>

        <Divider />
        <Stepper activeStep={activeStep} orientation="vertical">
          {steps.map((step, index) => (
            <Step key={index}>
              <StepLabel
                StepIconComponent={props => (
                  <CustomStepIconComponent
                    steps={steps}
                    handleRemoveStep={handleRemoveStep}
                    {...props}
                  />
                )}
              >
                <>
                  {index > 0 && (
                    <div className="container-step">
                      <div className="label-step">
                        Caso não seja resolvido em até
                      </div>

                      <div className="select-time">
                        <div className="container-btn-time">
                          <button
                            className="btn-time"
                            onClick={() =>
                              handleTimeChange(index, step.prazo + 1)
                            }
                            disabled={step.prazo >= 60}
                          >
                            +
                          </button>
                          <button
                            className="btn-time"
                            onClick={() =>
                              handleTimeChange(index, step.prazo - 1)
                            }
                            disabled={step.prazo <= 0}
                          >
                            -
                          </button>
                        </div>
                        <div>
                          <input
                            className={`display-time ${
                              !!errors[`etapas[${index}].prazo`] && 'error'
                            }`}
                            value={step.prazo}
                            min={0}
                            max={60}
                            type="number"
                            onChange={({ target }) =>
                              handleTimeChange(index, Number(target.value))
                            }
                          />{' '}
                          min
                        </div>
                        {!!errors[`etapas[${index}].prazo`] && (
                          <span>{errors[`etapas[${index}].prazo`]}</span>
                        )}
                        {!!errors.prazo && index === steps.length - 1 && (
                          <span>{errors.prazo}</span>
                        )}
                      </div>
                    </div>
                  )}
                  <div className="container-step">
                    <div className="label-step">Alerta é enviado para</div>
                    <div>
                      <Select
                        fieldName="Atribuir Responsável"
                        grupos={grupos}
                        data={users}
                        value={step.ids_usuarios}
                        handleChange={selectedPeople =>
                          handlePeopleChange(index, selectedPeople)
                        }
                        error={!!errors[`etapas[${index}].ids_usuarios`]}
                        message={errors[`etapas[${index}].ids_usuarios`]}
                      />
                    </div>
                    <div
                      className={`btn-config-push ${
                        step.plataforma ? 'active' : ''
                      }`}
                      onClick={() =>
                        handleNotificationTypeChange(index, 'plataforma')
                      }
                    >
                      <IconOnisys />
                      Plataforma
                    </div>
                    <div
                      className={`btn-config-push ${step.push ? 'active' : ''}`}
                      onClick={() =>
                        handleNotificationTypeChange(index, 'push')
                      }
                    >
                      <IconDeslocamentos />
                      Push
                    </div>
                    <div
                      className={`btn-config-push ${
                        step.email ? 'active' : ''
                      }`}
                      onClick={() =>
                        handleNotificationTypeChange(index, 'email')
                      }
                    >
                      <IconEmail />
                      E-mail
                    </div>
                  </div>
                  <div style={{ display: 'flex', gap: 8, marginTop: 5 }}>
                    {filterArray(users, step.ids_usuarios).map(p => (
                      <div key={p.id} className="label-hilight">
                        {p.grupo
                          ? `(${grupos.find(g => g.value === p.grupo)?.label})`
                          : ''}
                        {p.value}{' '}
                        <span onClick={() => handleRemoveUsuario(index, p.id)}>
                          X
                        </span>
                      </div>
                    ))}
                  </div>
                </>
              </StepLabel>
            </Step>
          ))}
        </Stepper>

        <div className="container-actions">
          <IconButton onClick={handleAddStep}>
            <BtnAdd />
          </IconButton>
          <div className="btn-add" onClick={handleAddStep}>
            Adicionar etapa
          </div>
        </div>
        <Divider sx={{ marginTop: 5 }} />

        {isProvider && (
          <>
            <div className="container-step">
              <div className="label-switch">
                <Switch
                  check={!!prazo}
                  setCheckbox={value => setPrazo(value ? 20 : null)}
                  textOn=""
                  textOff=""
                />
                Enviar deslocamento para empresas que prestam serviço para mim.
              </div>
              <div className="label-prazo">Tratar em no máx.</div>

              <div className="select-time">
                <div className="container-btn-time">
                  <button
                    className="btn-time"
                    onClick={() => handleTimeChange(null, (prazo ?? 0) + 1)}
                    disabled={prazo >= 1440}
                  >
                    +
                  </button>
                  <button
                    className="btn-time"
                    onClick={() => handleTimeChange(null, (prazo ?? 0) - 1)}
                    disabled={prazo <= 0}
                  >
                    -
                  </button>
                </div>
                <div>
                  <input
                    className={`display-time ${!!errors.prazo && 'error'}`}
                    value={prazo ?? 0}
                    min={0}
                    max={1440}
                    type="number"
                    onChange={({ target }) =>
                      handleTimeChange(null, Number(target.value))
                    }
                  />{' '}
                  min
                </div>
                {!!errors.prazo && <span>{errors.prazo}</span>}
              </div>
            </div>
            <Divider sx={{ marginTop: '8px' }} />
          </>
        )}

        <div style={{ marginTop: 20, display: 'flex', justifyContent: 'end' }}>
          {item?.id ? (
            <GhostButton
              size="medium"
              customcolor={
                theme?.palette?.semantics?.feedback?.attention?.natural
              }
              onClick={() => setDeleteModal(true)}
              sx={{ marginRight: '1rem' }}
            >
              EXCLUIR
            </GhostButton>
          ) : (
            <GhostButton
              size="medium"
              customcolor={
                theme?.palette?.semantics?.feedback?.attention?.natural
              }
              onClick={() => navigate(-1)}
              sx={{ marginRight: '1rem' }}
            >
              Voltar
            </GhostButton>
          )}
          <GhostButton size="medium" onClick={handleSave} loading={sending}>
            Salvar{!item?.id ? ` e ativar` : ''}
          </GhostButton>
        </div>
      </S.Container>
      {!!deleteModal && (
        <ConfirmModal
          open={!!deleteModal}
          handleClose={() => setDeleteModal(null)}
          title="Tem certeza que deseja remover essa lista?"
          titleIcon={
            <DeleteOutline
              sx={{
                color: theme.palette.semantics.feedback.attention.natural,
              }}
              fontSize="medium"
            />
          }
          buttonText="Confirmar"
          onClick={() => {
            handleExluir();
            setDeleteModal(null);
          }}
        />
      )}
    </>
  );
};
