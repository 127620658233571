import styled from 'styled-components';

export const Card = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: ${({ direction }) => direction ?? 'row'};
  font-size: 14px;
  color: #4b5166;
  background: #ffffff;
  border: 1px solid #939aab4d;
  border-radius: 4px;
  padding: 8px;
  text-align: center;

  .info {
    width: max-content;
    white-space: ${({ direction }) =>
      direction === 'row' ? 'nowrap' : 'normal'};
    text-align: end;
  }

  .title {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    font-size: 2rem;
    font-weight: 900;
  }

  .subtitle {
    font-weight: 900;
  }
  .gravissimo {
    color: #f64e60;
  }
  .grave {
    color: #ffa801;
  }
  .moderado {
    color: #0c12f2;
  }
  .primario {
    color: #1bc5bd;
  }
`;
