// Styles
import * as S from './style.js';
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

// Components
import EditTurma from './Modal/EditTurma';
import Button from 'components/Buttons/Default';
import ConfirmModal from 'components/ConfirmModal';
import { ModalListTasks } from './ModalBlocked/index.js';
import CadastrarTurmaModal from './Modal/AdicionarTurma';
import { DefaultTable } from 'components/_Table/templates/default';

// Components MUI
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ContentPasteOffOutlinedIcon from '@mui/icons-material/ContentPasteOffOutlined';

// Services
import { getTurmas, deleteTurma, updateTurma } from './services';

// Columns
import { columns } from './columns';

// Utils
import { trackEvent } from 'utils/mixpanel';

const Turmas = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [openModalAddAluno, setOpenModalAddAluno] = useState(false);
  const [openModalEditAluno, setOpenModalEditAluno] = useState(false);

  const [currenGroup, setCurrentGroup] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [viewContentConfirm, setViewContentConfirm] = useState(false);
  const [currentId, setCurrentId] = useState(-1);

  // Turmas bloqueadas
  const [conteudos, setConteudos] = useState(null);
  const [formularios, setFormularios] = useState(null);
  const [needRefetch, setNeedRefetch] = useState(false);

  // Tabelas
  const [data, setData] = useState([]);
  const [loadingDisable, setLoadingDisable] = useState(false);

  const { user } = useSelector(state => state?.auth?.user);
  let isConvidado = false;
  if (+user.nivel === 4) isConvidado = true;

  const handleConvidado = () => {
    toast.error('Você não tem permissão para executar essa ação!');
  };
  // Location
  // Verify click by home
  const location = useLocation();
  useEffect(() => {
    if (location.pathname.includes('criar')) {
      setOpenModalAddAluno(true);
      window.history.replaceState({}, {}, '/fiscalizacao/turmas');
    }
  }, [location]);

  const {
    data: res,
    isError,
    refetch: fetchTurma,
    isFetching: loading,
  } = useQuery(['list-turma', 1], () => getTurmas('formularios'), {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (loading || isError) {
      return null;
    }
    setData(res.data.data);
  }, [res]);

  const formatCol = () => {
    const cols = columns.map(i => {
      if (i.id === 'atividades') {
        return {
          ...i,
          onClick: (_, item) => item.atividades && openModalActivities(item.id),
        };
      }
      return i;
    });
    return cols;
  };

  const handleSetExcluir = async () => {
    setLoadingDisable(true);
    const res = await deleteTurma(currentId);
    if (res.data.success) {
      toast.success(res.data.message);
      fetchTurma();
    } else {
      if (res.data?.hasContent) {
        setFormularios(res.data.formularios || []);
        setConteudos(res.data.conteudos || []);
        setViewContentConfirm(true);
      }
      toast.error(res.data.message);
    }
    setConfirm(false);
    setLoadingDisable(false);
  };

  const handleActiveTurma = async turma => {
    const res = await updateTurma(turma.id, {
      id: turma.id,
      nome: turma.nome,
      ativa: 1,
      foto: turma.foto,
      para_motoristas: turma.para_motoristas,
      para_usuarios: turma.para_usuarios,
    });

    if (res.data.success) {
      fetchTurma();
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
    }
  };

  const navigateTO = id => {
    navigate(`/fiscalizacao/viewTurmas/${id}`, { id });
  };

  const openModalActivities = id => {
    Promise.all([setCurrentId(id)]).then(() => {
      setFormularios([]);
      setConteudos([]);
      setViewContentConfirm(true);
    });
  };

  const actions = [
    {
      title: 'Editar',
      function: id => {
        const info = data.find(item => Number(item.id) === Number(id));
        info.status = info.ativa;
        if (isConvidado) {
          handleConvidado();
          return;
        }
        setCurrentGroup(info);
        setOpenModalEditAluno(true);
      },
    },
    {
      title: 'Ativar',
      function: id => {
        const turma = data.find(item => Number(item.id) === Number(id));
        if (isConvidado) {
          handleConvidado();
          return;
        }
        handleActiveTurma(turma);
      },
      visible: item => !item.ativa,
    },
    {
      title: 'Desativar',
      function: id => {
        const turma = data.find(item => Number(item.id) === Number(id));
        if (isConvidado) {
          handleConvidado();
          return;
        }
        if (turma.automatica) {
          toast.error(
            'Essa é uma turma automática e ela não pode ser desativada',
          );
        } else {
          if (turma.atividades) {
            openModalActivities(id);
          } else {
            setCurrentId(id);
            setConfirm(true);
          }
        }
      },
      visible: item => item.ativa,
    },
    {
      title: 'Ver turma',
      function: id => navigateTO(id),
    },
  ];

  return (
    <S.Container>
      <CadastrarTurmaModal
        open={openModalAddAluno}
        handleClose={() => setOpenModalAddAluno(false)}
        fetchData={fetchTurma}
      />

      <EditTurma
        open={openModalEditAluno}
        dataEdit={currenGroup}
        handleClose={() => setOpenModalEditAluno(false)}
        fetchData={fetchTurma}
      />

      <S.ButtonContianer>
        <div>
          <h1>Turmas</h1>
        </div>
        <Button
          startIcon={<AddCircleOutlineIcon />}
          onClick={() =>
            !isConvidado ? setOpenModalAddAluno(true) : handleConvidado()
          }
        >
          <span style={{ fontSize: 14 }}>ADICIONAR TURMA</span>
        </Button>
      </S.ButtonContianer>

      <S.TableContainer>
        <DefaultTable
          data={data || []}
          columns={formatCol()}
          actions={actions}
          searchKeys={['nome', 'tipoUser']}
          loading={loading}
          placeholder="Buscar por turma"
          sortBy={{ id: 'id', order: 'DESC' }}
          searchEvent={search =>
            trackEvent(user, 'Busca Formulários Turmas', null, search)
          }
          empty={{
            title: 'Nenhuma turma encontrada',
            description: 'Verifique seus termos de pesquisa.',
            image: 'frota.png',
          }}
        />
      </S.TableContainer>
      <ConfirmModal
        open={confirm}
        handleClose={() => setConfirm(false)}
        title="Tem certeza que deseja desativar a turma?"
        subtitle="Os alunos perderão acesso ao conteúdo"
        buttonText="Confirmar"
        titleIcon={
          <ContentPasteOffOutlinedIcon
            htmlColor={theme.palette.semantics.feedback.attention.natural}
          />
        }
        onClick={() => handleSetExcluir()}
        loading={loadingDisable}
      />
      {viewContentConfirm && (
        <ModalListTasks
          open={viewContentConfirm}
          onClose={() => {
            setViewContentConfirm(false);
            if (needRefetch) {
              setNeedRefetch(false);
              fetchTurma();
            }
          }}
          conteudos={conteudos || []}
          formularios={formularios || []}
          idDisabled={currentId}
          fetchTurma={fetchTurma}
          setNeedRefetch={setNeedRefetch}
        />
      )}
    </S.Container>
  );
};

export default Turmas;
