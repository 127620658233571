import { formatNewDate } from '../../utils/dates';

export const _cards = [
  {
    type: 'CONCLUÍDO',
    value: 0,
    text: 'Conteúdos concluídos',
    icon: 'two.svg',
    selected: false,
  },
  {
    type: 'PENDENTE',
    value: 0,
    text: 'Apenas conteúdos pendentes',
    icon: 'schedule.svg',
    selected: false,
  },
  {
    type: 'VENCIDO',
    value: 0,
    text: 'Apenas conteúdos vencidos',
    icon: 'schedule_red.svg',
    selected: false,
  },
];

const formatColumns = (color, backgroundColor, text) => {
  return (
    <div
      style={{
        color,
        backgroundColor,
        borderRadius: '10px',
        textAlign: 'center',
        fontFamily: 'Texta',
        fontWeight: 'bold',
      }}
    >
      {text}
    </div>
  );
};

export const columns = [
  {
    header: 'MOTORISTA',
    id: 'motorista_nome',
    sort: true,
    type: 'string',
    subRow: {
      prefix: 'FIlial:',
      value: (_, item) => item?.filial_nome,
    },
  },
  {
    header: 'CONFIGURADO POR',
    id: 'empresa_nome',
    sort: true,
    type: 'string',
  },
  {
    header: 'DESVIO COMETIDO',
    id: 'titulo',
    sort: true,
    type: 'string',
  },
  {
    id: 'conteudo_nome',
  },
  {
    header: 'STATUS',
    id: 'filial.nome',
    sort: true,
    type: 'string',
    children: (_, item) => {
      switch (item.status) {
        case 'CONCLUÍDO':
          return formatColumns(
            'rgba(27, 197, 189, 1)',
            'rgba(27, 197, 189, .2)',
            item.status,
          );
        case 'PENDENTE':
          return formatColumns(
            'rgba(255, 168, 1)',
            'rgba(255, 168, 1, .2)',
            item.status,
          );

        case 'VENCIDO':
          return formatColumns(
            'rgba(246, 78, 96, 1)',
            'rgba(246, 78, 96, .2)',
            item.status,
          );

        case 'REPROVADO':
          return formatColumns(
            'rgba(246, 78, 96, 1)',
            'rgba(246, 78, 96, .2)',
            item.status,
          );
      }
    },
  },
  {
    header: 'DATA DE CONCLUSÃO',
    sort: true,
    type: 'string',
    align: 'center',
    children: (_, item) => {
      return (
        <div>
          {item?.status === 'VENCIDO' || item?.status === 'REPROVADO' ? (
            <div style={{ color: '#f64e60' }}>
              {formatNewDate(item.data_vencimento)}
            </div>
          ) : (
            <div>{formatNewDate(item.data_vencimento) || '-'}</div>
          )}

          <div
            style={{
              color: '#939aab',
              fontSize: '11px',
            }}
          >
            Envio: {formatNewDate(item?.created_at) || '-'}
          </div>
        </div>
      );
    },
  },

  {
    id: 'acoes',
  },
];
