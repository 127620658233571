// Styled
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useState, useEffect } from 'react';

// Components
import ConfirmModal from 'components/ConfirmModal';
import TextInput from 'components/Inputs/TextField';

// Components MUI
import { Divider } from '@mui/material';
import DeleteSweepOutlinedIcon from '@mui/icons-material/DeleteSweepOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import Checkbox from '../Components/Checkbox';
import IOSSwitch from '../Components/Swicth';
import NewItem from '../Components/NewItems';
import Text from '../Components/Text';
import GlobalModal from '..';
import * as S from './styled';

export const MultiplaEscolha = ({
  open,
  onClose,
  data,
  closeModalItems,
  currentElement,
  section,
}) => {
  const theme = useTheme();
  const [emptyModal, setEmptyModal] = useState(false);
  const [totalContent, setTotalContent] = useState(null);
  const [deleteField, setDeleteField] = useState(null);
  const [hasChanged, setHasChanged] = useState(false);

  // Step 1 (States)
  const [pergunta, setPergunta] = useState('');
  const [descricao, setDescricao] = useState('');
  const [obrigatorio, setObrigatorio] = useState(false);
  const [opcoes, setOpcoes] = useState([
    {
      index: 0,
      item: '',
      evidencia: false,
      nome_ocorrencia: '',
      gera_ocorrencia: false,
    },
    {
      index: 1,
      item: '',
      evidencia: false,
      nome_ocorrencia: '',
      gera_ocorrencia: false,
    },
  ]);

  // Verify Edit or New
  useEffect(() => {
    if (data) {
      data = JSON.parse(JSON.stringify(data));
      setPergunta(data.pergunta);
      setDescricao(data.descricao);
      setObrigatorio(data.obrigatorio);
      setOpcoes(data.opcoes);
    }
  }, [data]);

  const toogleText = (value, index) => {
    setHasChanged(true);
    const cloneAnswers = [...opcoes];
    for (const i in cloneAnswers) {
      if (cloneAnswers[i].index === index) {
        cloneAnswers[i].item = value;
      }
    }
    setOpcoes(cloneAnswers);
  };

  const deleteAnswer = index => {
    if (opcoes.length <= 2) {
      return toast.error(
        'É necessario ao menos duas respostas para a pergunta',
      );
    }
    setHasChanged(true);

    const cloneAnswers = [];
    for (const i in opcoes) {
      if (opcoes[i].index !== index) {
        cloneAnswers.push(opcoes[i]);
      }
    }
    setOpcoes(cloneAnswers);
    setDeleteField(null);
  };

  const addNewAnswer = () => {
    setHasChanged(true);

    const lastItem = opcoes[opcoes.length - 1];
    setOpcoes([
      ...opcoes,
      {
        index: lastItem.index + 1,
        item: '',
        correta: false,
      },
    ]);
  };

  const validateStepOne = okEmpty => {
    if (!pergunta) {
      toast.error('Preencha uma pergunta para o campo');
      return;
    }

    let haveText = 0;
    let haveEmpty = false;
    const finalAnswers = [];
    const texts = [];
    for (const i in opcoes) {
      if (opcoes[i].item !== '') {
        haveText++;
        finalAnswers.push(opcoes[i]);
        texts.push(opcoes[i].item);
      } else {
        haveEmpty = true;
      }
    }

    if (haveText < 2) {
      toast.error('Insira ao menos duas respostas para o campo');
      return;
    }

    if (new Set(texts).size !== texts.length) {
      toast.error(
        'Não é possivel inserir opções com o mesmo texto. Verifique as opções criadas!',
      );
      return;
    }

    for (const i in opcoes) {
      if (opcoes[i].gera_ocorrencia && !opcoes[i].nome_ocorrencia) {
        toast.error(
          `Insira o nome da ocorrência para a opção "${
            opcoes[i].item.length > 30
              ? `${opcoes[i].item.slice(0, 27)}...`
              : opcoes[i].item
          }"`,
        );
        return;
      }
    }

    if (haveEmpty && !okEmpty) {
      setEmptyModal(true);
      return;
    }

    setOpcoes(finalAnswers);

    setTotalContent({
      index: data?.index,
      pergunta,
      descricao,
      obrigatorio,
      opcoes: finalAnswers,
    });

    closeModalItems();
    resetFields();
  };

  const resetFields = () => {
    // Reset All fileds
    setPergunta('');
    setDescricao('');
    setObrigatorio(false);
    setOpcoes([
      {
        index: 0,
        item: '',
        nome_ocorrencia: '',
        gera_ocorrencia: false,
        evidencia: false,
      },
      {
        index: 1,
        item: '',
        nome_ocorrencia: '',
        gera_ocorrencia: false,
        evidencia: false,
      },
    ]);
  };

  // ----------------------------------COLLAPSE CONTROLLER--------------------------------//

  const [collapse, setCollapse] = useState([]);

  useEffect(() => {
    const opned = [];
    for (const i in opcoes) {
      opned.push(false);
    }
    setCollapse(opned);
  }, []);

  const handleCollapese = (idx, isFocus) => {
    const copyCollapse = [...collapse];
    for (const i in copyCollapse) {
      if (i != idx) copyCollapse[i] = false;
    }

    if (!isFocus) {
      if (copyCollapse[idx] == true) {
        copyCollapse[idx] = false;
      } else {
        copyCollapse[idx] = true;
      }
    } else {
      copyCollapse[idx] = true;
    }

    setCollapse(copyCollapse);
  };

  // Collapse Options
  const toogleEvidence = index => {
    setHasChanged(true);
    const cloneAnswers = [...opcoes];
    for (const i in cloneAnswers) {
      if (cloneAnswers[i].index === index) {
        if (cloneAnswers[i].evidencia) {
          cloneAnswers[i].gera_ocorrencia = false;
          cloneAnswers[i].nome_ocorrencia = '';
        }
        cloneAnswers[i].evidencia = !cloneAnswers[i].evidencia;
      }
    }
    setOpcoes(cloneAnswers);
  };

  const toogleOccurrence = index => {
    setHasChanged(true);
    const cloneAnswers = [...opcoes];
    for (const i in cloneAnswers) {
      if (cloneAnswers[i].index === index) {
        if (!cloneAnswers[i].gera_ocorrencia) cloneAnswers[i].evidencia = true;
        if (cloneAnswers[i].gera_ocorrencia) {
          cloneAnswers[i].nome_ocorrencia = '';
          cloneAnswers[i].reprovacao_automatica = false;
        }
        cloneAnswers[i].gera_ocorrencia = !cloneAnswers[i].gera_ocorrencia;
      }
    }
    setOpcoes(cloneAnswers);
  };

  const toogleDisapproval = index => {
    setHasChanged(true);
    const cloneAnswers = [...opcoes];
    const option = cloneAnswers.find(answer => answer.index === index);
    if (option) {
      option.reprovacao_automatica = !option.reprovacao_automatica;
    }
    setOpcoes(cloneAnswers);
  };

  const toogleNameOc = (value, index) => {
    setHasChanged(true);
    const cloneAnswers = [...opcoes];
    for (const i in cloneAnswers) {
      if (cloneAnswers[i].index === index) {
        cloneAnswers[i].nome_ocorrencia = value;
      }
    }
    setOpcoes(cloneAnswers);
  };

  // -------------------------------------STEP ONE---------------------------------------//
  const stepOne = disabled => {
    const fullDisabled = disabled && data?.id !== undefined;

    return (
      <S.StepOneBox>
        <div className="boxText">
          <TextInput
            label="Pergunta"
            placeholder="Pergunta"
            value={pergunta}
            onChange={e => {
              setHasChanged(true);
              setPergunta(e.target.value.slice(0, 250));
            }}
            disabled={fullDisabled}
          />
          <span className="counter">{`${pergunta?.length}/250`}</span>
        </div>

        <div className="boxText">
          <TextInput
            label="Descrição"
            placeholder="Descrição (opicional)"
            value={descricao}
            onChange={e => {
              setHasChanged(true);
              setDescricao(e.target.value.slice(0, 150));
            }}
            disabled={fullDisabled}
          />
          <span className="counter">{`${descricao?.length}/150`}</span>
        </div>

        <S.BoxAnswers>
          <S.Content>
            {opcoes.map((item, key) => (
              <S.Answers key={key}>
                <div className="boxTotalContent">
                  <div className="left">
                    <Checkbox value={false} disabled />

                    <Text
                      value={item.item}
                      onChange={value => {
                        toogleText(value.slice(0, 100), item.index);
                      }}
                      index={item.index}
                      onFocus={() => handleCollapese(key, true)}
                      disabled={fullDisabled}
                    />
                  </div>

                  {!fullDisabled && (
                    <DeleteSweepOutlinedIcon
                      htmlColor={
                        theme.palette.semantics.feedback.unknown.natural
                      }
                      onClick={() =>
                        item.item
                          ? setDeleteField(item.index)
                          : deleteAnswer(item.index)
                      }
                      style={{
                        cursor: 'pointer',
                      }}
                    />
                  )}

                  <ArrowForwardIosOutlinedIcon
                    fontSize="18px"
                    htmlColor={theme.palette.brand.primary.light}
                    onClick={() => handleCollapese(key)}
                    style={{
                      transform: collapse[key]
                        ? 'rotate(90deg)'
                        : 'rotate(0deg)',
                      transition: 'all ease .5s',
                      marginLeft: '10px',
                      cursor: 'pointer',
                    }}
                  />
                </div>

                <S.CollapseMu in={collapse[key]} unmountOnExit>
                  <S.ConifgAnw>
                    <div className="boxAll">
                      <span className="textOp">Evidência Obrigatória</span>
                      <IOSSwitch
                        checked={item.evidencia}
                        onChange={() => toogleEvidence(item.index)}
                      />
                    </div>

                    <div className="boxAll">
                      <span className="textOp">Criar Ocorrência</span>
                      <IOSSwitch
                        checked={item.gera_ocorrencia}
                        onChange={() => toogleOccurrence(item.index)}
                      />
                    </div>

                    <div className="boxAll">
                      <input
                        className="occurrenceName"
                        placeholder="Digite o nome da ocorrência"
                        value={item.nome_ocorrencia}
                        onChange={e =>
                          toogleNameOc(e.target.value.slice(0, 50), item.index)
                        }
                        disabled={!item.gera_ocorrencia}
                      />
                    </div>

                    <div className="left">
                      <Checkbox
                        value={item.reprovacao_automatica ?? false}
                        onChange={() => toogleDisapproval(item.index)}
                        disabled={!item.gera_ocorrencia}
                        label="Reprovar formulário ao gerar ocorrência"
                      />
                    </div>
                    <br />
                  </S.ConifgAnw>
                  <Divider />
                </S.CollapseMu>
              </S.Answers>
            ))}
            {!fullDisabled && opcoes.length < 30 && (
              <NewItem text="Nova Resposta" onClick={() => addNewAnswer()} />
            )}
          </S.Content>
        </S.BoxAnswers>

        {/* MODAL AREA */}
        <ConfirmModal
          open={emptyModal}
          title="Campos vazios"
          subtitle="Você adicionou campos de resposta vazios, esses itens serão descartados. Tem certeza que deseja prosseguir?"
          onClick={() => {
            validateStepOne(true);
            setEmptyModal(false);
          }}
          buttonText="Confirmar"
          handleClose={() => setEmptyModal(false)}
          titleIcon={
            <WarningAmberOutlinedIcon
              htmlColor={theme.palette.semantics.feedback.warning.natural}
            />
          }
        />

        <ConfirmModal
          open={deleteField !== null}
          title="Tem certeza que deseja apagar essa resposta?"
          subtitle="Essa opção não será exibida para o usuário"
          onClick={() => {
            deleteAnswer(deleteField);
          }}
          buttonText="Confirmar"
          handleClose={() => setDeleteField(null)}
          titleIcon={
            <WarningAmberOutlinedIcon
              htmlColor={theme.palette.semantics.feedback.warning.natural}
            />
          }
        />
      </S.StepOneBox>
    );
  };

  // -------------------------------------RENDER---------------------------------------//
  return (
    <GlobalModal
      stepOne={stepOne}
      validateStepOne={validateStepOne}
      open={open}
      onClose={onClose}
      resetFields={resetFields}
      totalContent={totalContent}
      hasData={!!data}
      obrigatorio={obrigatorio}
      setObrigatorio={setObrigatorio}
      currentElement={currentElement}
      section={section}
      hasChanged={hasChanged}
      setHasChanged={setHasChanged}
    />
  );
};
