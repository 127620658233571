import {
  getAgregados,
  getStatusItem,
  getTiposDesvio,
  getVeiculos,
  getMotoristas,
  getDesviosTiposPadrao,
} from 'constants/_SERVICES/user';

// Pages
import Drivers from 'pages/Motoristas';
import LogMotorista from 'pages/Motoristas/History';
import Ranking from 'pages/RankingMotoristas';
import DriversCalender from 'pages/MotoristasCronogramas';
import { PerfilMotorista } from 'pages/Motoristas/PerfilMotorista';
import DriversUpload from 'pages/Motoristas/ModalAddEmMassa';
import { MotoristasEstatisticas } from 'pages/Estatisticas/Motoristas';

export default {
  '/motoristas': {
    date: false,
    title: 'Motoristas',
    back: -1,
    defaults: [],
    pageFilterName: 'filterDriver',
    filters: [],

    page: <Drivers />,
  },

  '/motoristas/:id': {
    date: false,
    title: 'Perfil do motorista',
    back: -1,
    page: <PerfilMotorista />,
  },

  '/motoristas/add-motoristas': {
    back: -1,
    title: 'Adicionar em Massa - Motoristas',
    page: <DriversUpload />,
  },

  '/logs/driver/:id': {
    back: -1,
    defaults: [],
    page: <LogMotorista />,
  },

  '/motoristas/rankings': {
    title: 'Ranking motoristas',
    page: <Ranking />,
  },

  '/motoristas/cronograma': {
    page: <DriversCalender />,
    title: 'Avaliações',
    back: -1,
    // pageFilterName: 'filterMotoristaCronograma',
    // defaults: ['filials', 'clients', 'operations'],
    // filters: [
    //   {
    //     name: 'agregado',
    //     placeholder: 'Filtrar por Contratação',
    //     mode: 'single',
    //     section: 'Motoristas',
    //     data: async () => getAgregados(),
    //   },
    // ],
  },
  '/motoristas/estatisticas': {
    title: 'Motoristas',
    back: -1,
    defaults: [],
    page: <MotoristasEstatisticas />,
  },
};
