/* eslint-disable import/order */
import * as S from './styled';
import { toast } from 'react-toastify';

// React
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  initializeData,
  setConteudo,
} from 'store/modules/provider/capacitacaoConteudo/actions';

// Components
import Tabs from 'components/Tabs';
import Tag from '../components/Tag';
import Title from '../components/Title';
import Search from '../components/Search';
import ModalAdd from '../components/ModalAdd';
import Templates from '../components/Templates';
import SliderTag from '../components/SliderTag';
import PreviewModal from '../components/ModalPreview';
import DefaultButton from 'components/Buttons/Default';
import ModalTemplates from '../components/ModalTemplates';
import FiltersGlobal from 'components/FiltersGlobal';
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';

// Componentes MUI
import { Divider } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';

// Services
import * as services from './services';

const charge = [0, 1, 2, 3, 4, 5, 6];

// Timer
let timer = null;
let timerRoute = null;

const Capacitacao = () => {
  const { getTurmasStatus, getTurmas } = useFetchMultipleWithCache();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const filterForm = useSelector(state => state.filter);

  const [currentTab, setCurrentTab] = useState(0);

  const [tags, setTags] = useState([]);
  const [firstCharge, setFirstCharge] = useState(true);

  const [templates, setTemplates] = useState(null);
  const [filteredTemplates, setFilteredTemplates] = useState(charge);
  const [embarcadoras, setEmbarcadoras] = useState(null);
  const [filteredEmbarcadoras, setFilteredEmbarcadoras] = useState(charge);
  const [openPreview, setOpenPreview] = useState(false);

  const [modalAdd, setModalAdd] = useState(false);
  const [modalTemplate, setModalTemplate] = useState(false);
  const [allTemplates, setAllTemplates] = useState([]);
  const [allTemplatesEmbarcadoras, setAllTemplatesEmbarcadoras] = useState([]);
  const [inSearch, setInSearch] = useState(true);

  const [query, setQuery] = useState({
    card: null,
    tag: 'Todos',
    search: '',
  });

  const { user } = useSelector(state => {
    return state.auth.user;
  });
  let isConvidado = false;
  if (+user.nivel === 4) isConvidado = true;

  const handleConvidado = () => {
    toast.error('Você não tem permissão para executar essa ação!');
  };

  // -------------------------------------PAGE CONTROLLER---------------------------------------//

  const {
    data: res,
    isError,
    isLoading: loading,
  } = useQuery(['embarcadora-conteudos', 1], () => services.GetContents(), {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (loading) return null;

    if (!isError) {
      setTemplates(
        [...res?.data?.contents, ...res?.data?.contentsOnisys] || [],
      );
      setEmbarcadoras(res?.data?.contentsEmbarcadoras || []);
      setAllTemplatesEmbarcadoras([
        ...res?.data?.contentsEmbarcadoras?.filter(
          item => item.habilitar_template,
        ),
      ]);
      setAllTemplates([...res?.data?.contents, ...res?.data?.contentsOnisys]);
      setTags(res?.data?.tags || []);
    } else {
      toast.error(res.message);
    }
  }, [res]);

  const handlePageChange = (e, tab) => {
    setInSearch(true);
    setCurrentTab(tab);
  };

  const previewContent = async (id_cont, mode) => {
    const temps = mode === 'embarcadora' ? embarcadoras : templates;

    const cont = temps.find(temp => temp.id === id_cont);

    const id = cont.id;

    const geral = {
      titulo: cont.titulo,
      descricao: cont.descricao,
      capa: cont.imagem_capa,
      data_inicio: cont.data_inicio,
      data_fim: cont.data_fim,
      tags: cont.tags,
      status: cont.status,
    };

    const midias = cont.midias.map(m => {
      return {
        id: m.id,
        titulo: m.titulo,
        descricao: m.descricao,
        link: m.link,
        draggableId: m.ordem,
        ordem: m.ordem,
        tipo: m.tipo,
      };
    });

    const avaliacao = cont.perguntas;

    dispatch(
      initializeData({
        id,
        geral,
        midias,
        avaliacao,
      }),
    );
    setOpenPreview(true);
    // setInSearch(true);
  };

  // Controll back arrow in window when modal is opened in same tab (BETA VERSION)
  useEffect(() => {
    timerRoute = setTimeout(() => {
      if (!openPreview && !firstCharge) {
        window.onpopstate = event => {
          event.preventDefault();
        };
      }
    }, 100);

    return function cleanup() {
      clearTimeout(timerRoute);
    };
  }, [openPreview, firstCharge]);

  // ------------------------------------FILTERS CONTROLLER-------------------------------------//
  const handleQuery = object => {
    setQuery({ ...query, ...object });
  };

  useEffect(() => {
    if (filterForm) setQuery({ ...query, ...filterForm?.conteudos });
  }, [filterForm]);

  // ----------------------------Primeira Tab - Meus Conteúdos
  useEffect(() => {
    filterTab0();
  }, [query, templates]);

  const filterTab0 = skip => {
    if (currentTab !== 0) {
      return null;
    }

    let hasFilter = [];
    const temps = templates;
    let status = null;

    if (!temps || firstCharge) {
      setFilteredTemplates(charge);
      setFirstCharge(false);
      return;
    }

    // Filtro Status
    status = query?.status ? query.status : false;
    if (status.length) {
      hasFilter = temps?.filter(temp => status.includes(temp?.status));
    } else {
      hasFilter = temps;
    }

    // Filtro Publico
    const turma = query?.turma;
    if (turma === 'MOTORISTAS') {
      const hasMotoristas = [];
      for (const i in hasFilter) {
        const { turmas } = hasFilter[i];
        let hasPublic = false;
        for (const j in turmas) {
          const turma = turmas[j];
          if (turma.para_motoristas) {
            hasPublic = true;
          }
        }
        if (hasPublic) {
          hasMotoristas.push(hasFilter[i]);
        }
      }
      hasFilter = hasMotoristas;
    } else if (turma === 'GESTORES') {
      const hasGestor = [];
      for (const i in hasFilter) {
        const { turmas } = hasFilter[i];
        let hasPublic = false;
        for (const j in turmas) {
          const turma = turmas[j];
          if (turma.para_usuarios) {
            hasPublic = true;
          }
        }
        if (hasPublic) {
          hasGestor.push(hasFilter[i]);
        }
      }
      hasFilter = hasGestor;
    }

    // Filtro Search
    if (query.search) {
      hasFilter = hasFilter?.filter(temp => {
        const turmasName = temp.turmas
          .map(item => item.nome.toLowerCase())
          .join('*');
        if (
          temp.titulo
            .toLowerCase()
            .includes(query.search.toLocaleLowerCase()) ||
          temp.tags
            .toString()
            .toLocaleLowerCase()
            .includes(query.search.toLocaleLowerCase()) ||
          turmasName.includes(query.search.toLocaleLowerCase())
        ) {
          return temp;
        }
      });
    }

    if (query.tag !== 'Todos') {
      hasFilter = hasFilter?.filter(temp => temp.tags.includes(query.tag));
    }

    !skip && setInSearch(true);
    clearTimeout(timer);

    timer = setTimeout(() => {
      if (!query.search && query.tag === 'Todos' && !status && !turma) {
        setFilteredTemplates(null);
      } else {
        setFilteredTemplates(hasFilter);
      }
      setInSearch(false);
    }, 400);
  };

  // ---------------------------- Segunda Tab - Conteúdos Embarcadoras
  useEffect(() => {
    filterTab1();
  }, [query, embarcadoras]);

  const filterTab1 = skip => {
    if (currentTab !== 1) {
      return null;
    }

    let hasFilter = [];
    const temps = embarcadoras;
    let status = null;

    if (!temps || firstCharge) {
      setFilteredEmbarcadoras(null);
      setFirstCharge(false);
      return;
    }

    // Filtro Status
    status = query?.status ? query.status : false;
    if (status.length) {
      hasFilter = temps?.filter(temp => status.includes(temp?.status));
    } else {
      hasFilter = temps;
    }

    // Filtro Publico
    const turma = query?.turma;
    if (turma === 'MOTORISTAS') {
      const hasMotoristas = [];
      for (const i in hasFilter) {
        const { turmas } = hasFilter[i];
        let hasPublic = false;
        for (const j in turmas) {
          const turma = turmas[j];
          if (turma.para_motoristas) {
            hasPublic = true;
          }
        }
        if (hasPublic) {
          hasMotoristas.push(hasFilter[i]);
        }
      }
      hasFilter = hasMotoristas;
    } else if (turma === 'GESTORES') {
      const hasGestor = [];
      for (const i in hasFilter) {
        const { turmas } = hasFilter[i];
        let hasPublic = false;
        for (const j in turmas) {
          const turma = turmas[j];
          if (turma.para_usuarios) {
            hasPublic = true;
          }
        }
        if (hasPublic) {
          hasGestor.push(hasFilter[i]);
        }
      }
      hasFilter = hasGestor;
    }

    // Filtro Search
    if (query.search) {
      hasFilter = hasFilter?.filter(temp => {
        const turmasName = temp.turmas
          .map(item => item.nome.toLowerCase())
          .join('*');
        if (
          temp.titulo
            .toLowerCase()
            .includes(query.search.toLocaleLowerCase()) ||
          temp.tags
            .toString()
            .toLocaleLowerCase()
            .includes(query.search.toLocaleLowerCase()) ||
          turmasName.includes(query.search.toLocaleLowerCase())
        ) {
          return temp;
        }
      });
    }

    if (query.tag !== 'Todos') {
      hasFilter = hasFilter?.filter(temp => temp.tags.includes(query.tag));
    }

    !skip && setInSearch(true);
    clearTimeout(timer);

    timer = setTimeout(() => {
      if (!query.search && query.tag === 'Todos' && !status && !turma) {
        setFilteredEmbarcadoras(null);
      } else {
        setFilteredEmbarcadoras(hasFilter);
      }
      setInSearch(false);
    }, 400);
  };

  useEffect(() => {
    if (!firstCharge) {
      filterTab0(true);
      filterTab1(true);
    }
  }, [currentTab]);

  return (
    <S.Container>
      <S.Header>
        <Title>Conteúdos</Title>
        <div style={{ marginTop: 15 }} />
        <FiltersGlobal
          hideRefleshButton
          hideDate
          hideExportButton
          persistDate
          data={[
            {
              filterName: 'turma',
              label: 'Turma',
              options: getTurmas?.data || [],
            },
            {
              filterName: 'status',
              label: 'Status',
              options: getTurmasStatus?.data || [],
            },
          ]}
          customComponent={
            <span className="buttonArea">
              <DefaultButton
                children="Novo Conteúdo"
                onClick={() =>
                  !isConvidado ? setModalAdd(true) : handleConvidado()
                }
                startIcon={<AddCircleOutline />}
              />
            </span>
          }
        />
      </S.Header>
      <Tabs
        value={currentTab}
        items={[
          {
            value: 0,
            label: `Conteúdos ${templates ? `(${templates.length})` : ''}`,
          },
          {
            value: 1,
            label: `Embarcadoras ${
              embarcadoras ? `(${embarcadoras.length})` : ''
            }`,
          },
        ]}
        onChange={handlePageChange}
      />

      <Divider style={{ marginTop: '10px' }} />

      <SliderTag width="72vw">
        {!loading &&
          tags.map((item, key) => (
            <Tag
              children={item}
              key={key}
              onClick={() => handleQuery({ tag: item })}
              active={item === query.tag}
              disabled={item === query.tag}
            />
          ))}
        {loading && <Tag loading />}
      </SliderTag>

      <S.SearchArea>
        <Search
          value={query.search}
          setValue={value => handleQuery({ search: value })}
          placeholder="Buscar Conteúdo"
        />
        <p>{query.tag}</p>
      </S.SearchArea>

      <S.Body>
        {currentTab === 0 && (
          <Templates
            templates={filteredTemplates || templates}
            setModalAdd={setModalAdd}
            query={query}
            loadingScreen={loading || inSearch}
            onClickNavigateEdit={id =>
              navigate(`/capacitacao/editar/${id}?step=6`)
            }
            onClickSketch={id => {
              dispatch(setConteudo(id, 'id'));
              navigate(`/capacitacao/criar?conteudo=${id}`);
            }}
            onClickView={id => previewContent(id, 'transportadora')}
          />
        )}
        {currentTab === 1 && (
          <Templates
            templates={filteredEmbarcadoras || embarcadoras}
            setModalAdd={setModalAdd}
            query={query}
            loadingScreen={loading || inSearch}
            onClickNavigateEdit={id =>
              navigate(`/capacitacao/editar/${id}?step=6`)
            }
            onClickSketch={id => {
              dispatch(setConteudo(id, 'id'));
              navigate(`/capacitacao/criar?conteudo=${id}`);
            }}
            onClickView={id => previewContent(id, 'embarcadora')}
          />
        )}
      </S.Body>

      {/* Modal Area */}
      <ModalAdd
        open={modalAdd}
        onClose={() => setModalAdd(false)}
        setModalTemplate={setModalTemplate}
        title="Criar Conteúdo"
        textLeft="Criar conteúdo do zero"
        textRight="Utilizar template"
        description={
          'Você poderá criar um conteúdo clicando em "Criar conteúdo do zero" ou poderá copiar um conteúdo já existente e editar como preferir clicando em "Utilizar template".'
        }
        actionRight={() => setModalTemplate(true)}
        actionLeft={() => navigate('/capacitacao/criar')}
      />

      {modalTemplate && currentTab === 0 && (
        <ModalTemplates
          tags={tags}
          templates={allTemplates}
          open={modalTemplate}
          onClose={() => setModalTemplate(false)}
        />
      )}

      {modalTemplate && currentTab === 1 && (
        <ModalTemplates
          tags={tags}
          templates={allTemplatesEmbarcadoras}
          open={modalTemplate}
          onClose={() => setModalTemplate(false)}
        />
      )}

      {openPreview && (
        <PreviewModal
          open={openPreview}
          onClose={() => {
            setOpenPreview(false);
            setTimeout(() => {
              setInSearch(false);
            }, 500);
          }}
          backPath="/capacitacao"
        />
      )}
    </S.Container>
  );
};

export default Capacitacao;
