import styled from 'styled-components';

export const InputWrapper = styled.div`
  display: flex;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.system.border};
  border-radius: 4px;
  padding: 0 8px;
  width: 100%;
  color: ${({ theme }) => theme.palette.words.subtitle.natural};
`;

export const StyledInput = styled.input`
  border: none;
  outline: none;
  flex: 1;
  padding: 8px;
  font-size: 16px;
`;
