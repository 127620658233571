import React, { useEffect } from 'react';
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  Legend,
} from 'recharts';

import { differenceInDays, format, eachMonthOfInterval } from 'date-fns';
import { paletteColors } from '../../../styles/colors';
import { ptBR } from 'date-fns/locale';
import { useTheme } from 'styled-components';

// components custom
import { Skeleton } from '@mui/material';

// styles
import * as S from './styled';
import Tabs from 'components/FiltersGlobal/Tabs';
import { useState } from 'react';

import { formatarNome, getGroup, getInterval } from './util';

const tabsDates = [
  { value: 'day', label: 'D' },
  { value: 'week', label: 'S' },
  { value: 'month', label: 'M' },
];

const CustomTooltip = ({ active, payload, label, yAxisPosfix, periodDate }) => {
  const theme = useTheme();
  if (active && payload && payload.length) {
    const formatLabel = () => {
      if (periodDate === 'week') {
        return payload?.[0]?.payload?.key;
      }
      if (periodDate === 'month') return label;
      return payload?.[0]?.payload?.period;
    };

    return (
      <div
        style={{
          backgroundColor: theme?.palette?.brand?.primary?.background,
          padding: '10px',
          borderRadius: '4px',
          border: `1px solid ${theme?.palette?.system?.divider}`,
          transform: 'translate(0, -67%)',
        }}
      >
        {/* Título */}
        <p style={{ color: '#939aab', fontSize: 12, textAlign: 'center' }}>
          {formatLabel()}
        </p>

        {/* Iterando sobre o payload para exibir cada valor */}
        {payload.map((entry, index) => (
          <div
            key={`tooltip-item-${index}`}
            style={{
              marginBottom: 4,
              position: 'relative',
              top: '-100%', // Move para cima
            }}
          >
            <span
              style={{
                color: entry.color,
                fontWeight: 'bold',
                marginRight: 8,
              }}
            >
              {entry.name}:
            </span>
            <span>
              {entry.value}
              {yAxisPosfix}
            </span>
          </div>
        ))}
      </div>
    );
  }

  return null; // Garante que não renderiza nada se não for ativo
};

const CustomTick = props => {
  const { x, y, payload } = props;
  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={16}
        textAnchor="middle"
        fill="#939aab"
        transform="rotate(0)"
      >
        <tspan x={0} dy="20">
          {payload.value}
        </tspan>
      </text>
    </g>
  );
};

const CustomLegend = ({ payload }) => (
  <div
    style={{
      display: 'flex',
      gap: '16px',
      alignItems: 'center',
      marginBottom: '8px',
    }}
  >
    {payload.map((entry, index) => (
      <div
        key={`item-${index}`}
        style={{ display: 'flex', alignItems: 'center' }}
      >
        {/* Circulo com a cor da linha */}
        <div
          style={{
            width: 14,
            height: 14,
            backgroundColor: entry.color,
            borderRadius: '50%',
            marginRight: 5,
          }}
        />
        {/* Label */}
        <span
          style={{
            color: '#4B5166',
            fontWeight: 500,
            textTransform: 'capitalize',
          }}
        >
          {entry.value}
        </span>
      </div>
    ))}
  </div>
);

export const History = ({
  titulo = '',
  data = [],
  series = ['x'],
  series_divs = [],
  calcDistinct = false,
  initialDate,
  finalDate,
  loading,
  headerComponent,
  leftHeaderComponent,
  hidePeriods,
  formatRenderData = null,
  initialPeriod = 'day',
  lineColor = 'default',
  yAxisPosfix = '',
  ...props
}) => {
  const [renderData, setRenderData] = useState([]);
  const [interval, setInterval] = useState('auto');
  const [periodDate, setPeriodDate] = useState(initialPeriod);
  const [difference, setDifference] = useState(0);

  /// onst difference = differenceInDays(initialDate, finalDate);

  const formatYAxisTick = value => {
    if (value >= 1000000) return `${(value / 1000000).toFixed(1)}M`; // Milhões
    if (value >= 1000) return `${(value / 1000).toFixed(1)}k`; // Milhares
    return `${value.toFixed(1)}${yAxisPosfix}`; // Outros valores
  };

  const generateMonthLabels = (initialDate, finalDate) => {
    try {
      // Converte as datas e valida se são válidas
      const startDate = new Date(initialDate);
      const endDate = new Date(finalDate);

      if (isNaN(startDate) || isNaN(endDate)) {
        throw new Error('Datas inválidas!');
      }

      if (startDate > endDate) {
        throw new Error('A data inicial não pode ser maior que a data final.');
      }

      // Cria o intervalo de meses
      const months = eachMonthOfInterval({
        start: startDate,
        end: endDate,
      });

      // Formata os meses
      return months.map(date => format(date, 'MMM yyyy', { locale: ptBR }));
    } catch (error) {
      console.error('Erro ao gerar labels dos meses:', error.message);
      return []; // Retorna um array vazio em caso de erro
    }
  };

  const monthLabels =
    initialDate && finalDate && generateMonthLabels(initialDate, finalDate);

  useEffect(() => {
    const diff = differenceInDays(new Date(finalDate), new Date(initialDate));
    setDifference(diff);

    const _formatRenderData = () => {
      // Retorna vazio para data invalida ou vazia
      if (!data?.length || !series?.length) {
        setRenderData([]);
        return;
      }
      if (formatRenderData instanceof Function) {
        setRenderData(formatRenderData(data));
        if (props.interval) setInterval(props.interval);
        return;
      }

      /// setLegendCostum();
      const _data = data?.reduce((acumulador, item) => {
        const group = getGroup(periodDate, difference);
        const { meta } = item;
        const name = formatarNome(item, group);
        const key = formatarNome(item, periodDate);
        const existente = acumulador.find(r => r.key === key);

        if (existente) {
          series.forEach(serie => {
            // Divisor
            if (series_divs?.length) {
              const key_div = `${serie}_${series_divs[0]}`;
              const key_div2 = `${serie}_${series_divs[1]}`;
              if (item[key_div]) existente[key_div] += item[key_div];
              if (item[key_div2]) existente[key_div2] += item[key_div2];
            }
            // Valor acumulavel
            else {
              if (item[serie]) {
                // Add , para acumulo de stringArray:  '1,2,3' + ',' + 'item[serie]'
                if (calcDistinct) existente[serie] += ',';
                if (item[serie]) existente[serie] += item[serie];
              }
            }
          });
        } else {
          const newObj = { name, key, meta, period: item.period };
          series.forEach(serie => {
            // Divisor
            if (series_divs?.length) {
              const key_div = `${serie}_${series_divs[0]}`;
              const key_div2 = `${serie}_${series_divs[1]}`;
              if (key_div in item) newObj[key_div] = item[key_div];
              if (key_div2 in item) newObj[key_div2] = item[key_div2];
            }
            // Valor acumulavel
            else {
              newObj[serie] = item[serie];
            }
          });
          acumulador.push(newObj);
        }

        return acumulador;
      }, []);
      // Calcula seria composta de divisao

      if (series_divs?.length) {
        for (const item of _data) {
          for (const serie of series) {
            item[serie] = 0;
            const key_div = `${serie}_${series_divs[0]}`;
            const key_div2 = `${serie}_${series_divs[1]}`;
            if (item[key_div] && item[key_div2]) {
              item[serie] = Number((item[key_div] / item[key_div2]).toFixed(2));
            }
          }
        }
        // console.log(data);
      }
      // Quantidade de valor distinto da stringArray
      // Ex: '1,2,3,4,5,2' >> [1,2,3,4,5] >> 5
      else if (calcDistinct) {
        for (const item of _data) {
          for (const serie of series) {
            const stringArray = item[serie];
            if (stringArray === '') {
              item[serie] = 0;
            } else {
              const uniqueValues = Array.from(
                new Set(
                  stringArray
                    .split(',')
                    .filter(sa => sa)
                    .map(Number),
                ),
              );
              item[serie] = uniqueValues.length;
            }
          }
        }
      }

      setRenderData(_data);
      setInterval(getInterval(periodDate, difference));
    };
    _formatRenderData();
  }, [data, series, initialDate, finalDate, periodDate]);

  const ConditionalLabel = ({ x, y, value, series, data }) => {
    return series?.length === 1 && data?.length <= 32 ? (
      <text x={x} y={y - 10} fill="#8884d8" textAnchor="middle" fontSize={12}>
        {formatYAxisTick(value)}
      </text>
    ) : null;
  };

  return (
    <S.Container
      height={leftHeaderComponent || headerComponent ? '380px' : '320px'}
    >
      {headerComponent ?? (
        <div className="container-filters">
          <div>
            <h1 className="graphtitle">{titulo}</h1>
            {leftHeaderComponent && leftHeaderComponent}
          </div>
          <div className="titletop">
            {!hidePeriods && (
              <Tabs
                tab={periodDate}
                tabs={tabsDates}
                handleApplay={setPeriodDate}
              />
            )}
          </div>
        </div>
      )}
      {loading ? (
        <Skeleton
          style={{ margin: '1rem' }}
          width="98%"
          height={174}
          animation="wave"
          variant="rounded"
        />
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart
            height={300}
            data={renderData}
            margin={{ top: 10, right: 40, left: 20, bottom: 5 }}
          >
            {renderData.length === 0 && (
              <text
                x="50%"
                y="50%"
                textAnchor="middle"
                dominantBaseline="middle"
                fontSize={22}
                fill="#999"
              >
                Nenhum dado encontrado, tente usar outros filtros.
              </text>
            )}
            <CartesianGrid vertical={false} />

            <XAxis
              opacity={difference < 180 ? 1 : 0}
              padding={{ left: 0, right: 0 }}
              dataKey="name"
              height={70}
              tickLine={difference < 180}
              interval={interval}
              tick={difference < 180 ? <CustomTick /> : false}
            />

            {monthLabels && difference > 180 && (
              <g>
                {monthLabels.map((label, index) => (
                  <text
                    key={index}
                    x={`${9 + (index / monthLabels.length) * 93}%`}
                    y="70%"
                    textAnchor="middle"
                    dominantBaseline="middle"
                    fontSize={16}
                    fill="#939aab"
                  >
                    {label}
                  </text>
                ))}
              </g>
            )}
            {renderData.length !== 0 && (
              <YAxis
                opacity={0.5}
                padding={{ left: 0, right: 0 }}
                tickLine={false}
                tickFormatter={formatYAxisTick}
                domain={
                  formatYAxisTick ? [0, dataMax => dataMax * 1.2] : undefined
                }
                minTickGap={-20}
              />
            )}
            <Tooltip
              content={
                <CustomTooltip
                  yAxisPosfix={yAxisPosfix}
                  periodDate={periodDate}
                />
              }
              cursor={{ strokeDasharray: '3 3', stroke: '#939AAB' }}
            />

            {series.map((serie, idx) => (
              <Line
                key={`line-${serie}`}
                type="linear"
                dataKey={serie}
                name={serie}
                stroke={paletteColors[lineColor][idx]}
                dot={{ r: renderData.length >= 30 ? 0 : 4 }}
                label={props => (
                  <ConditionalLabel
                    {...props}
                    index={props.index}
                    series={series}
                    data={renderData}
                  />
                )}
              />
            ))}

            {data.some(item => item.meta) && (
              <Line
                type="linear"
                dataKey="meta"
                name="Meta"
                stroke="#f64e60"
                strokeDasharray={['10 10']}
                dot={{ r: 0 }}
                activeDot={{ r: 0 }}
              />
            )}

            <Legend content={<CustomLegend />} />
          </LineChart>
        </ResponsiveContainer>
      )}
    </S.Container>
  );
};
