import React from 'react';

import { Divider } from '@mui/material';
import Tabs from 'components/Tabs';
import FiltersGlobal from 'components/FiltersGlobal';
import Cards from 'pages/_templates/ListPage/components/Cards';
import { DefaultTable } from 'components/_Table/templates/default';

import * as S from './styled';

const ListPage = ({
  total,
  cards = null,
  headerTabs = null,
  selectedCard = null,
  disableCards = false,
  loadingCards = false,
  handleClickCard = null,
  tableProps = {},
  filterProps = { data: [] },
  preTable = null,
}) => {
  return (
    <>
      {headerTabs && (
        <S.Flex>
          <FiltersGlobal
            hideRefleshButton
            customComponent={
              <>
                {!!total && (
                  <S.Total>
                    Total de desvios: <span>{total}</span>
                  </S.Total>
                )}
                <div style={{ width: '100%' }}>
                  <Tabs {...headerTabs} />
                  <Divider />
                </div>
              </>
            }
            data={[]}
            {...filterProps}
          />
        </S.Flex>
      )}

      {!!cards && (
        <Cards
          cards={cards}
          selectedCard={selectedCard}
          disableCards={disableCards}
          loadingCards={loadingCards}
          handleClickCard={handleClickCard}
        />
      )}

      <S.TableContainer>
        {preTable}
        <div style={{ width: '100%' }}>
          <DefaultTable {...tableProps} />
        </div>
      </S.TableContainer>
    </>
  );
};

export default ListPage;
