import styled, { css } from 'styled-components';
import TextField from '@mui/material/TextField';

export const StyledTextField = styled(TextField)`
  ${({ theme, error }) => css`
    width: 100%;

    .MuiInputBase-root {
      font-size: 24px;
    }

    .MuiInputBase-input::placeholder {
      color: var(--neutral400, #939aab);
      font-family: Texta;
      font-size: 24px;
      font-style: normal;
      font-weight: 800;
      line-height: normal;
      opacity: 4;
    }

    .MuiInput-underline:before {
      border-bottom: 1px solid
        ${error
          ? theme.palette.semantics.feedback.attention.natural
          : theme.palette.brand.primary.light};
    }

    .MuiInput-underline:after {
      border-bottom: 2px solid
        ${error
          ? theme.palette.semantics.feedback.attention.natural
          : theme.palette.brand.primary.light};
    }

    .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: 2px solid
        ${error
          ? theme.palette.semantics.feedback.attention.natural
          : theme.palette.brand.primary.light};
    }

    .MuiInputAdornment-root svg {
      color: #939aab;
    }
  `}
`;
