import React, { useState } from 'react';
import { useTheme } from 'styled-components';

import {
  Modal,
  IconButton,
  Icon,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@mui/material';

import InfoCard from 'components/Cards/InfoCard';
import GhostButton from 'components/Buttons/Ghost';
import TextInput from 'components/Inputs/TextField';
import * as S from './styled';

const DeleteModal = ({ ids, handleClose, handleConfirm, loading }) => {
  const theme = useTheme();
  const lista_justificativas = [
    'Violação criada por engano',
    'Violação não compatível',
  ];
  const title = `Tem certeza que deseja excluir violação?`;
  const subtitle = 'A violação será movida para aba Deletados.';

  // Icone do header variável com titleIconName
  const titleIcon = (
    <Icon
      sx={{ color: theme.palette.semantics.feedback.attention.natural }}
      fontSize="medium"
    >
      delete-outline
    </Icon>
  );

  // Objeto de dados a serem preenchidos
  const [data, setData] = useState({
    motivo_exclusao: '',
    observacao_exclusao: '',
  });

  const handleChange = (name, value) => {
    setData(prev => {
      return { ...prev, [name]: value };
    });
  };

  // Bloqueia botão de confirmar quando há item sem preencher
  const checkReady = () => {
    let ready = true;
    if (!data.motivo_exclusao) ready = false;
    return ready;
  };

  const handleClickConfirm = () => {
    const req = {
      ...data,
      ids: ids.map(id => parseInt(id)),
    };
    handleConfirm(req);
  };

  const open = Boolean(ids);

  return (
    <Modal open={open}>
      <S.Paper>
        <S.Header>
          <S.Title>
            {titleIcon}
            <h1>{title}</h1>
          </S.Title>

          <IconButton size="small" onClick={handleClose}>
            <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
              close
            </Icon>
          </IconButton>
        </S.Header>

        <InfoCard message={subtitle} />

        <FormControl component="fieldset">
          <RadioGroup
            aria-label="Justificar"
            name="Justificar"
            value={data.motivo_exclusao}
            onChange={e => handleChange('motivo_exclusao', e.target.value)}
          >
            {lista_justificativas.map(item => (
              <FormControlLabel
                key={`radio_${item}`}
                value={item}
                control={<Radio />}
                label={item}
              />
            ))}
          </RadioGroup>
        </FormControl>

        <TextInput
          label="Observação"
          value={data.observacao_exclusao}
          onChange={e => handleChange('observacao_exclusao', e.target.value)}
          name="observacao_exclusao"
          maxRows={2}
          multiline
          inputProps={{ maxLength: 120 }}
        />

        <S.Footer>
          <GhostButton
            customcolor={
              theme?.palette?.semantics?.feedback?.attention?.natural
            }
            size="medium"
            onClick={handleClose}
          >
            Cancelar
          </GhostButton>

          <GhostButton
            onClick={handleClickConfirm}
            size="medium"
            style={{ marginLeft: '10px' }}
            disabled={!checkReady()}
            loading={loading}
          >
            Confirmar
          </GhostButton>
        </S.Footer>
      </S.Paper>
    </Modal>
  );
};

export default DeleteModal;
