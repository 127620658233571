import React from 'react';
import GhostButton from 'components/Buttons/Ghost';
import * as S from './styled';
import { useTheme } from 'styled-components';

const ModalBottom = ({ open, data, handleClose, handleApply }) => {
  const theme = useTheme();

  return (
    <S.Main open={open}>
      <S.Wrapper>
        <h2>{data.length}</h2>
        <h3>selecionado(s)</h3>
      </S.Wrapper>

      <S.Wrapper>
        <GhostButton
          customcolor={theme?.palette?.semantics?.feedback?.attention?.natural}
          size="medium"
          onClick={handleClose}
        >
          Cancelar
        </GhostButton>

        <GhostButton
          sx={{ marginLeft: '20px' }}
          size="medium"
          onClick={handleApply}
        >
          Justificar indisponibilidade
        </GhostButton>
      </S.Wrapper>
    </S.Main>
  );
};

export default ModalBottom;
