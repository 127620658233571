import api from 'services/api';

export const fetchDrivers = async provider => {
  const res = await api.get(
    provider ? '/select-drivers-provider' : '/select-drivers',
  );
  if (res.data?.success)
    return res.data?.data.map(d => ({
      value: d.id,
      label: `${d.nome} (Filial: ${d.filial?.nome})`,
      nome: d.nome,
    }));
  return [];
};
