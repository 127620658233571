/* eslint-disable import/order */

// Styles
import * as S from './styled';
import colors from 'styles/colors';

// React
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { usePlans } from 'hooks/usePlans';
import { setConteudo } from 'store/modules/provider/capacitacaoConteudo/actions';

// Components
import Tag from '../../components/Tag';
import ModalNewTag from './NewTagModal';
import Loading from 'components/Loading';
import Radio from '../../components/Radio';
import Calendar from 'components/Inputs/Calendar';
import ConfirmModal from 'components/ConfirmModal';
import GhostButton from 'components/Buttons/Ghost';
import TextInput from 'components/Inputs/TextField';
import SelectTags from 'components/Inputs/SelectTags';
import DropZoneArchives from 'components/Inputs/DropZoneCustom';

// Components MUI
import AddIcon from '@mui/icons-material/Add';
import { Grid, IconButton } from '@mui/material';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';

// Utils
import * as services from '../services';
import { validation } from './validation';

// Capa default Header
const img =
  'https://firebasestorage.googleapis.com/v0/b/onisysweb.appspot.com/o/capacitacao%2Finfo%2Fcapas%2F1673467756358%2F2023-01-11_17-09-19_Capa_Conteudo_Capacitacao.jpg?alt=media&token=3ca2cd3b-f1c8-438a-a277-88580f7cbdbd';

const initializeForm = {
  titulo: '',
  descricao: '',
  capa: '',
  data_inicio: new Date(),
  data_fim: '',
  final: false,
  categories: '',
  carga_horaria: null,
};

export const Gerais = ({
  setChanged,
  trySave,
  setTrySave,
  setCurrentStep,
  isEdit,
  tags,
  isPublished,
  steps,
}) => {
  // Redux
  const dispatch = useDispatch();

  // Planos
  const { isOpLogistico, isProvider, hasTorrePlus, hasTorre } = usePlans();

  // Navigate
  const navigate = useNavigate();

  const { id, geral, template } = useSelector(state => state.conteudoProvider);
  const [categories, setCategories] = useState([]);
  const [formStates, setFormStates] = useState([]);
  const [errors, setErrors] = useState(null);
  const [loading, setLoading] = useState(false);

  // Modal
  const [openModalTag, setOpenModalTag] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);

  const { data: desvios_tipos_padrao = [] } = useQuery(
    ['desvios_tipos_padrao'],
    () => services.getDesviosTiposPadrao(),
    {
      refetchOnWindowFocus: false,
    },
  );

  // -------------------------------------INITIALIZE DATA-----------------------------------------//
  useEffect(() => {
    let tagsCat = [];
    tagsCat = tags.map(i => ({ state: false, name: i }));
    if (geral) {
      let formTags = geral.tags || [];
      if (formTags[0] && typeof formTags[0] === 'object')
        formTags = formTags.map(item => item.tag);
      const hasInclude = [];
      for (const i in tagsCat) {
        if (formTags.includes(tagsCat[i].name)) {
          tagsCat[i].state = true;
          hasInclude.push(tagsCat[i].name);
        }
      }
      const diff = formTags.filter(tag => hasInclude.indexOf(tag) === -1);
      for (const i in diff) {
        tagsCat.push({
          state: true,
          name: diff[i],
        });
      }

      setFormStates(
        JSON.parse(
          JSON.stringify({
            ...geral,
            final: !!geral?.data_fim,
            data_fim: geral?.data_fim || '',
            categories: hasInclude,
            desvios_tipos_padrao: geral?.desvios_tipos_padrao?.map(
              item => item.id,
            ),
          }),
        ),
      );
    } else {
      setFormStates(initializeForm);
    }

    if (geral?.titulo && geral?.descricao && geral?.data_inicio) {
      setChanged(false);
    } else {
      setChanged(true);
    }

    setCategories(tagsCat);
  }, [geral, tags]);

  // -------------------------------------IMAGE CONTROLL-----------------------------------------//
  const addImage = items => {
    if (items?.length > 0) {
      formStates.capa = items[0].imagem_url;
    }
  };

  // -------------------------------------TAGS CONTROLL-----------------------------------------//
  const toogleTag = key => {
    const clTags = [...categories];
    clTags[key].state = !clTags[key].state;
    setCategories(clTags);
    setChanged(true);
  };

  // -------------------------------------PAGE CONTROLL-----------------------------------------//
  const changingMonitoring = (key, value) => {
    if (!key && !value) {
      setChanged(true);
      return;
    }

    setFormStates({
      ...formStates,
      [key]: value || '',
    });
    setChanged(true);
  };

  // -------------------------------------SAVE FORM-----------------------------------------//
  useEffect(() => {
    if (trySave) {
      setErrors(null);

      // Organiza o objeto e faz a request
      const tags = [];
      for (const i in categories) {
        if (categories[i].state) {
          tags.push({ tag: categories[i].name });
        }
      }

      // Validações
      const valid = validation(formStates, setTrySave, setErrors, tags);
      if (!valid) return;

      if (!formStates.final) {
        formStates.data_fim = null;
      }

      delete formStates?.final;
      const imagem_capa = formStates.capa;
      delete formStates?.capa;

      const data = {
        id,
        pagina: 'geral',
        ...formStates,
        carga_horaria: formStates.carga_horaria || null,
        imagem_capa,
        tags,
        desvios_tipos_padrao: (formStates?.desvios_tipos_padrao ?? []).map(
          id => ({
            id,
          }),
        ),
        from_template: !id ? template : null,
      };

      sendRequest(data);
    }
  }, [trySave]);

  const sendRequest = async data => {
    setLoading(true);
    let res = null;

    res = id
      ? await services.EditInfos(id, data)
      : await services.SaveGeneralInfos(data);
    if (res.success) {
      setTrySave(false);
      setLoading(false);
      setChanged(false);
      !isEdit && setCurrentStep(steps[1]);
      window.history.replaceState(
        {},
        {},
        `/capacitacao/criar?conteudo=${res.data.id}`,
      );

      dispatch(setConteudo(res.data.id, 'id'));
      dispatch(
        setConteudo(
          {
            ...res.data,
            capa: res?.data?.imagem_capa,
            tags: res?.data?.tags?.map(item => item.tag),
          },
          'geral',
        ),
      );
      toast.success('Informações salvas com sucesso!');
    } else {
      toast.error(res.message);
      setTrySave(false);
      setLoading(false);
    }
  };

  // -------------------------------------DELETE FORM-----------------------------------------//
  const deleteForm = async () => {
    const res = await services.DeleteContent(id);
    if (res.success) {
      toast.success('Conteúdo excluído com sucesso!');
      navigate(-1);
    } else {
      toast.error(res.message);
    }
  };

  // -------------------------------------RENDER-----------------------------------------//
  return (
    <S.Box>
      {!loading && (
        <Grid
          className="formArea"
          container
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={12} md={9}>
            <TextInput
              required
              label="Título"
              error={errors?.titulo}
              onChange={e =>
                changingMonitoring('titulo', e.target.value.slice(0, 100))
              }
              placeholder="Digite de maneira resumida o tema do conteúdo."
              value={formStates.titulo}
              // disabled={isPublished}
            />
            <S.Counter>{`${formStates?.titulo?.length}/100`}</S.Counter>
          </Grid>
          <Grid item xs={12} md={3}>
            <TextInput
              label="Carga horária (horas)"
              error={errors?.carga_horaria}
              onChange={e =>
                changingMonitoring('carga_horaria', e.target.value)
              }
              placeholder="Digite aqui."
              value={formStates.carga_horaria ?? ''}
              InputProps={{ type: 'number' }}
            />
            <S.Counter>{'\u00A0'}</S.Counter>
          </Grid>
          <Grid item xs={12} md={12}>
            <TextInput
              label="Descrição"
              error={errors?.descricao}
              placeholder="Descreva brevemente os assuntos que serão abordados."
              value={formStates.descricao}
              onChange={e =>
                changingMonitoring('descricao', e.target.value.slice(0, 300))
              }
              multiline
              required
              rows={6}
              // disabled={isPublished}
            />
            <S.Counter>{`${formStates?.descricao?.length}/300`}</S.Counter>
          </Grid>

          <Grid item xs={6} md={6}>
            <Calendar
              value={new Date(formStates.data_inicio)}
              onChange={e => changingMonitoring('data_inicio', e)}
              futureDate
              pastDate={false}
              error={errors?.data_inicio}
              label="Data de início"
              style={{ maxWidth: '100%' }}
              disabled={isPublished}
            />
          </Grid>
          <Grid item xs={6} md={6}>
            <S.FinishBox>
              <span className="textBox">
                <Radio
                  style={{ marginRight: '4px' }}
                  value={!formStates.final}
                  // disabled={isPublished}
                  label="Nunca encerrar"
                  onChange={() =>
                    changingMonitoring('final', !formStates.final)
                  }
                />
              </span>
              <Calendar
                value={
                  formStates.final
                    ? formStates.data_fim
                      ? new Date(formStates.data_fim)
                      : ''
                    : ''
                }
                onChange={e => changingMonitoring('data_fim', e)}
                futureDate
                pastDate={false}
                error={errors?.data_fim}
                label="Data de encerramento"
                style={{
                  maxWidth: '100%',
                  opacity: formStates.final ? 1 : 0.3,
                }}
                disabled={!formStates.final /* || isPublished */}
              />
            </S.FinishBox>
          </Grid>

          <Grid item xs={12} md={12}>
            <DropZoneArchives
              data={
                geral?.capa
                  ? { imagem_url: geral?.capa, legenda: '' }
                  : { imagem_url: img, legenda: '' }
              }
              multiple={false}
              fileDir="capacitacao/embarcadora/capas"
              addFunction={addImage}
              changeMonitoring={changingMonitoring}
              automaticSave
              label="Capa Principal"
              titleButton="Alterar Capa"
              unicCenter
              // onlyRead={isPublished}
            />
          </Grid>

          <Grid item xs={12} md={12}>
            <div
              style={{
                flex: 1,
                flexDirection: 'col',
                alignItems: 'flex-start',
                justifyContent: 'center',
              }}
            >
              <div>
                <h3
                  style={{
                    color: errors?.tags ? colors.redDanger : colors.greyTitle,
                    fontWeight: errors?.tags ? 'bold' : 'normal',
                  }}
                >
                  Selecione as categorias (máx: 3)
                </h3>
              </div>
              <S.BoxTags>
                {categories.map((i, k) => (
                  <Tag
                    key={k}
                    children={i.name}
                    active={i.state}
                    onClick={() => toogleTag(k)}
                  />
                ))}
                <IconButton onClick={() => setOpenModalTag(true)}>
                  <AddIcon />
                </IconButton>
              </S.BoxTags>
            </div>
          </Grid>
          {(hasTorre || hasTorrePlus) && (
            <Grid item xs={12} md={12}>
              <SelectTags
                label="Tags de desvios"
                value={formStates.desvios_tipos_padrao}
                handleChange={value =>
                  changingMonitoring('desvios_tipos_padrao', value)
                }
                fieldName="desvios_tipos_padrao"
                data={
                  (desvios_tipos_padrao?.data ?? []).map(item => ({
                    value: item.id,
                    label: item.nome,
                  })) ?? []
                }
              />
            </Grid>
          )}
          {isEdit && (
            <Grid item xs={6} md={6}>
              <GhostButton
                children="Excluir conteúdo"
                customcolor={colors.redDanger}
                onClick={() => setDeleteModal(true)}
              />
            </Grid>
          )}

          {/* MODAL AREA */}
          <ModalNewTag
            open={openModalTag}
            handleClose={() => setOpenModalTag(false)}
            categories={categories}
            handleSave={tags => {
              setCategories(tags);
              setOpenModalTag(false);
              setChanged(true);
            }}
          />

          <ConfirmModal
            open={deleteModal}
            titleIcon={
              <DeleteOutlineOutlinedIcon htmlColor={colors.redDanger} />
            }
            title="Deseja excluir este conteúdo?"
            description="Todos os dados referentes a ele serão excluídos da plataforma Onisys."
            subtitle="Todos os dados referentes a ele serão excluídos da plataforma Onisys."
            handleClose={() => setDeleteModal(false)}
            onClick={deleteForm}
            buttonText="Excluir"
            isNegative
          />
        </Grid>
      )}
      {loading && (
        <S.LoadingBox>
          <Loading />
        </S.LoadingBox>
      )}
    </S.Box>
  );
};
