import React, { useState, useEffect } from 'react';

// react dom
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

// components
import Divider from '@mui/material/Divider';
import Button from 'components/Buttons/Default';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import { toast } from 'react-toastify';
import { DefaultTable } from 'components/_Table/templates/default';
import { trackEvent } from 'utils/mixpanel';
import { generateFileName } from 'utils/generateFileName';
import ExcelModal from 'components/ExcelModalNew';

// styles
import { useTheme } from 'styled-components';

// icons
import { AddCircleOutline, SaveAlt } from '@mui/icons-material';

// components
import GhostButton from 'components/Buttons/Ghost/index.js';
import CadastroGestor from 'pages/Configuracoes/Usuarios/ModaUsuarios';
import CadastroMotorista from 'pages/Motoristas/ModalAddMotorista';
import * as S from './style.js';
import { getMotoristas, inativateDriver, inativateGestor } from './services';

// colums
import { columnsAluno } from './columns';

const Alunos = () => {
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });
  const theme = useTheme();
  const navigate = useNavigate();

  let isConvidado = false;
  if (+user.nivel === 4) isConvidado = true;

  const handleConvidado = () => {
    toast.error('Você não tem permissão para executar essa ação!');
  };

  const [openModalAddGestor, setOpenModalAddGestor] = useState(false);
  const [openModalAddMotorista, setOpenModalAddMotorista] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [excelData, setExcelData] = useState(null);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isDriver, setIsDriver] = React.useState(true);

  const [dataMotorista, setDataMotorista] = useState([]);
  const [dataUsuario, setDataUsuario] = useState([]);

  // const [actionsAlunos, setActionsAlunos] = useState([]);
  const [currentUserEditing, setActionsUserEditing] = useState({});

  const [loading, setLoading] = useState(false);

  const fetchUsers = () => {
    setLoading(true);
    getMotoristas().then(res => {
      const motoristas = res.data.drivers;
      const { users } = res.data;
      motoristas.map(item => {
        Object.assign(item, {
          filial_name: item.filial.nome,
          turmas: item.turmas,
          conteudos: item.conteudos,
        });
        return item;
      });
      users.map(item => {
        Object.assign(item, {
          filial_name: item.filial.nome,
          turmas: item.turmas,
          conteudos: item.conteudos,
        });
        return item;
      });
      setDataMotorista(motoristas);
      setDataUsuario(users);
      setLoading(false);
    });
  };
  useEffect(() => {
    fetchUsers();
  }, []);

  const sortBy = [];

  const open = Boolean(anchorEl);

  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSetStatus = async (id, type) => {
    const status = [...dataMotorista, ...dataUsuario].some(item =>
      !item.status ? 'ATIVO' : 'INATIVO',
    );
    if (type === 'GESTOR') {
      const res = await inativateGestor(id, { status });
      if (res.data.success) {
        toast.success(res.data.message);
        fetchUsers();
      } else {
        toast.error(res.data.message);
      }
    } else if (type === 'MOTORISTA') {
      const res = await inativateDriver(id);
      if (res.data.success) {
        toast.success(res.data.message);
        fetchUsers();
      } else {
        toast.error(res.data.message);
      }
    }
  };

  const handleEditDriver = id => {
    const item = dataMotorista.find(item => item.id === id);
    delete item.nomes_turmas;
    delete item.clients;
    item.id_da_empresa = item.filial.id_da_empresa;
    delete item.filial;
    Object.assign(item, {
      agregado: item.agregado ? 'SIM' : 'NÃO',
    });
    setActionsUserEditing(item);
    setOpenModalAddMotorista(true);
  };
  const handleEditUser = id => {
    const item = dataUsuario.find(item => item.id === id);
    const newObj = {};
    Object.assign(newObj, {
      id: item.id,
      nome: item.nome,
      email: item.email,
      telefone_de_contato: item.telefone_de_contato,
      status: item.status,
      funcao: item.funcao,
      filial: { id: item.id_da_filial },
      matricula: item.matricula,
      nivel: item.nivel,
      foto: item.foto,
    });
    setActionsUserEditing(newObj);
    setOpenModalAddGestor(true);
  };

  const actionsAlunos = !isConvidado
    ? [
        {
          function: id => {
            navigate(
              `/capacitacao/perfil-do-aluno/info-aluno?id=${id}&tipo=${
                isDriver ? 'motorista' : 'gestor'
              }`,
            );
          },
          title: 'Ver Perfil',
        },
        {
          title: 'Ativar/Inativar',
          function: id =>
            handleSetStatus(id, isDriver ? 'MOTORISTA' : 'GESTOR'),
        },
        {
          title: 'Editar',
          function: id =>
            isDriver ? handleEditDriver(id) : handleEditUser(id),
        },
      ]
    : [
        {
          function: id => {
            navigate(
              `/capacitacao/perfil-do-aluno/info-aluno?id=${id}&tipo=${
                isDriver ? 'motorista' : 'gestor'
              }`,
            );
          },
          title: 'Ver Perfil',
        },
      ];
  // ------------------------------------ EXCEL ---------------------------------//
  // Exportção excel
  // const handleExcel = async () => {
  //   try {
  //     setLoadingExcel(true);
  //     const excelData = {
  //       Motoristas: dataMotorista.map(item =>
  //         columnsAluno.reduce((acc, curr) => {
  //           acc[curr.header] = item[curr.id];
  //           return acc;
  //         }, {}),
  //       ),
  //       Gestores: dataUsuario.map(item =>
  //         columnsAluno.reduce((acc, curr) => {
  //           acc[curr.header] = item[curr.id];
  //           return acc;
  //         }, {}),
  //       ),
  //     };

  //     const formatedDate = formatNameDate(new Date());
  //     exportToExcel(excelData, `capacitacao_alunos_${formatedDate}`);
  //     toast.success('Arquivo gerado com sucesso!');
  //   } catch (err) {
  //     // console.log(err);
  //     toast.error('Falha ao gerar arquivo.');
  //   }
  //   setLoadingExcel(false);
  // };

  const handleExcel = async () => {
    try {
      setLoadingExcel(true);

      // Função genérica para mapear dados
      const mapData = data =>
        data.map(item =>
          columnsAluno.reduce((acc, curr) => {
            acc[curr.header] = item[curr.id]; // Transforma os dados com base nos headers e ids
            return acc;
          }, {}),
        );

      // const result = {
      //   Motoristas: mapData(dataMotorista),
      //   Gestores: mapData(dataUsuario),
      // };

      const result = [
        ...mapData(dataMotorista), // Adiciona motoristas
        ...mapData(dataUsuario), // Adiciona gestores
      ];

      setExcelData(result);
    } catch (error) {
      console.error('Erro ao processar dados para Excel:', error);
      throw error;
    } finally {
      setLoadingExcel(false);
    }
  };

  // Chama handleExcel apenas uma vez ao abrir o modal
  useEffect(() => {
    if (openExcelModal) {
      handleExcel();
    }
  }, [openExcelModal]); // Dependência do estado openExcelModal

  // ------------------------------------ RENDER ---------------------------------//
  return (
    <S.Container>
      {/* usuario cadastro */}
      {openExcelModal && (
        <ExcelModal
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          file_name={generateFileName(
            window.location.pathname.split('/').pop(),
          )}
          route="/excel/NãoTemService"
          isLocal
          localData={excelData}
        />
      )}
      {openModalAddGestor && (
        <CadastroGestor
          open={openModalAddGestor}
          handleClose={() => setOpenModalAddGestor(false)}
          dataEdit={currentUserEditing}
          fetchData={() => fetchUsers()}
        />
      )}
      {openModalAddMotorista && (
        <CadastroMotorista
          open={openModalAddMotorista}
          handleClose={() => setOpenModalAddMotorista(false)}
          dataEdit={currentUserEditing}
          fetch={() => {
            setOpenModalAddMotorista(false);
            fetchUsers();
          }}
        />
      )}
      <div>
        <h1>Alunos</h1>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Button
            startIcon={<AddCircleOutline />}
            onClick={e => (!isConvidado ? handleClick(e) : handleConvidado())}
          >
            <span style={{ fontSize: 14 }}>ADICIONAR ALUNOS</span>
          </Button>
          <GhostButton
            startIcon={<SaveAlt />}
            loading={loadingExcel}
            size="medium"
            onClick={() => {
              trackEvent(user, 'Exportar Planilha Alunos');
              setOpenExcelModal(true);
            }}
            style={{ marginLeft: '10px' }}
          >
            EXPORTAR
          </GhostButton>
        </div>
      </div>
      <S.HeaderContianer>
        <S.ButtonContianer>
          <Menu
            id="fade-menu"
            MenuListProps={{
              'aria-labelledby': 'fade-button',
            }}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            TransitionComponent={Fade}
          >
            <MenuItem
              sx={{ width: 170 }}
              onClick={() => {
                setActionsUserEditing(null);
                setOpenModalAddGestor(true);
              }}
            >
              Adicionar Gestor
            </MenuItem>
            <MenuItem
              sx={{ width: 170 }}
              onClick={() => {
                setActionsUserEditing(null);
                setOpenModalAddMotorista(true);
              }}
            >
              Adicionar Motorista
            </MenuItem>
          </Menu>
        </S.ButtonContianer>
      </S.HeaderContianer>
      <Divider light sx={{ marginTop: 2, marginBottom: 2 }} />
      <S.Controls>
        <div
          className="left"
          style={{
            background: isDriver
              ? theme.palette.system.disabled
              : theme.palette.system.highlight,
          }}
          onClick={() => {
            setLoading(true);
            setIsDriver(true);
            setInterval(() => {
              setLoading(false);
            }, 1500);
          }}
        >
          Motorista
        </div>
        <div
          className="right"
          style={{
            background: !isDriver
              ? theme.palette.system.disabled
              : theme.palette.system.highlight,
          }}
          onClick={() => {
            setLoading(true);
            setIsDriver(false);
            setInterval(() => {
              setLoading(false);
            }, 1500);
          }}
        >
          Gestor
        </div>
      </S.Controls>
      <S.TableContainer>
        {isDriver && (
          <DefaultTable
            data={dataMotorista || []}
            columns={columnsAluno}
            actions={actionsAlunos}
            searchKeys={['nome', 'filial_name']}
            loading={loading}
            placeholder="Buscar Cliente"
            searchEvent={search =>
              trackEvent(user, 'Buscar Cliente', null, search)
            }
            empty={{
              title: 'Nenhum cliente encontrado',
              description: '',
            }}
          />
        )}
        {!isDriver && (
          <DefaultTable
            data={dataUsuario || []}
            columns={columnsAluno}
            actions={actionsAlunos}
            searchKeys={['nome', 'filial_name']}
            loading={loading}
            placeholder="Buscar Cliente"
            searchEvent={search =>
              trackEvent(user, 'Buscar Cliente', null, search)
            }
            empty={{
              title: 'Nenhum cliente encontrado',
              description: '',
            }}
          />
        )}
      </S.TableContainer>
    </S.Container>
  );
};

export default Alunos;
