import React, { useEffect, useState } from 'react';
import { Icon } from 'components/IconsOnisys';
import * as S from './styled';
import { requestExcel } from './services';
import { downloadFromLink } from 'utils/helpers';
import {
  toggleSnackbarClose,
  toggleAlertModalOpen,
} from 'store/modules/header/actions';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useTheme } from 'styled-components';
import ExportToExcel from 'utils/exportToCvs';

const LoadingAlert = ({
  open = false,
  texto = null,
  isBigFile,
  route,
  query,
  isError,
  isManualDownload,
  isLocal,
  localData,
}) => {
  const [hasFetched, setHasFetched] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth?.user?.user);
  const theme = useTheme();

  useEffect(() => {
    if (route && query && Object.keys(query).length > 0 && !hasFetched) {
      // console.log('Entrou aqui');
      let modifiedQuery = {};
      if (isBigFile) {
        modifiedQuery = {
          ...query,
          evaluateQuery: false,
          sendUsingEmail: true,
        };
      } else {
        modifiedQuery = {
          ...query,
          evaluateQuery: false,
          sendUsingEmail: false,
        };
      }
      // console.log('modifiedQuery', modifiedQuery);
      // console.log('isLocal on loading Alert', isLocal);

      if (!isError) {
        if (isLocal) {
          // console.log('localData loading', localData);
          ExportToExcel({
            excel: localData,
            name: modifiedQuery.file_name,
          });
          setTimeout(() => {
            dispatch(toggleSnackbarClose());
          }, 500);
          return;
        }

        const fetchData = async () => {
          try {
            const result = await requestExcel(route, modifiedQuery);
            // console.log('result', result);
            // console.log('result query', modifiedQuery);
            // console.log('result isBigFile', isBigFile);
            if (!isBigFile && result.link) {
              // console.log('Downloading', result.link);
              downloadFromLink(result.link);
              dispatch(toggleSnackbarClose());
            }
            // arquivos que o back não retorna link
            if (!isBigFile && isManualDownload) {
              if (
                route === '/excel/motoristas_ranking' ||
                route === '/excel-provider/motoristas_ranking' ||
                route === '/excel-provider/empresas_ranking'
              ) {
                ExportToExcel({
                  excel: result.message.data.excel,
                  name: modifiedQuery.file_name,
                });
                dispatch(toggleSnackbarClose());
              } else {
                ExportToExcel({
                  excel: result.message.data.data.excel,
                  name: modifiedQuery.file_name,
                });
                dispatch(toggleSnackbarClose());
              }
            }
            if (result?.message?.status === 400) {
              // caso passar dos varios segundos de processamento
              // que foram definidos no backend
              // toast.error(result?.message?.data?.message);
              dispatch(
                toggleAlertModalOpen({
                  title: 'Esse arquivo é muito grande',
                  message: (
                    <p>
                      O arquivo está demorando mais que o esperado para ser
                      gerado e será enviado no e-mail{' '}
                      <span
                        style={{
                          color: theme.palette.brand.secondary.natural,
                          textTransform: 'none',
                        }}
                      >
                        {user.email.toLowerCase()}
                      </span>{' '}
                      assim que estiver pronto.
                    </p>
                  ),
                  url: route,
                  query,
                  isBigFile: true,
                  isError: true,
                }),
              );
              dispatch(toggleSnackbarClose());
            }
            if (result?.message === undefined) {
              // caso esteja sem internet
              toast.error('Sem conexão com o servidor');
              dispatch(toggleSnackbarClose());
            }
          } catch (error) {
            toast.error('Erro ao solicitar Excel:', error);
            dispatch(toggleSnackbarClose());
          } finally {
            setHasFetched(true);
          }
        };

        fetchData();
      }
    }

    return () => {
      setHasFetched(false);
    };
  }, [route, query]);

  if (!open) return null;

  return (
    <S.SnackContainer>
      <S.LoadingAndTextContainer>
        {!isBigFile && <S.StyledCircularProgress size={32} />}
        {isBigFile && (
          <Icon
            name="icone_redondo_check"
            style={{
              color: '#939AAB',
              width: '32px',
              height: '32px',
            }}
          />
        )}
        {!isBigFile && (
          <S.Texto>
            {texto || 'Seu arquivo está sendo preparado para download.'}
          </S.Texto>
        )}
        {isBigFile && (
          <S.Texto>
            {texto ||
              'Seu arquivo está sendo preparado e em breve chegará no seu e-mail.'}
          </S.Texto>
        )}
        {isBigFile && (
          <S.BotaoFechar
            onClick={() => {
              dispatch(toggleSnackbarClose());
            }}
          >
            <Icon
              name="icone_close"
              style={{
                color: '#939AAB',
                width: '12px',
                height: '12px',
              }}
            />
          </S.BotaoFechar>
        )}
      </S.LoadingAndTextContainer>
    </S.SnackContainer>
  );
};

export default LoadingAlert;
