/* eslint-disable import/order */

// Styles
import * as S from './styled';

// React && Hooks
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { setFilter } from 'store/modules/filter/actions';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { setFilterProvider } from 'store/modules/provider/filterProvider/actions';
import { useTheme } from 'styled-components';
// Components
import { Select } from './Select';
import Section from 'components/Section';
import GhostButton from 'components/Buttons/Ghost';

// Components MUI
import { Popover, SvgIcon } from '@mui/material';
import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

// Assets
import { ReactComponent as filterIcon } from 'images/icons/filter.svg';

// Actions
import {
  counter,
  load,
  setterFilter,
  checkDependency,
  checkClear,
} from './actions';

export const Filter = ({
  datas,
  sections,
  expanded,
  handleExpand,
  keys,
  keysDefault,
  page,
}) => {
  // -----------------------------HOOKS---------------------------------//
  // Infos
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth?.user?.user);
  const theme = useTheme();
  // Variables
  const pgFilters = keys;
  const glFilters = keysDefault;
  const provider = user.provider;
  const pageFilterName = page?.pageFilterName;

  // Filter States
  const [countFilter, setCountFilter] = useState(0);
  const [filtersTemp, setFiltersTemp] = useState(null);

  // Modal Infos
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'filter-popover' : undefined;

  // ------------------------FILTER IMPORTS------------------------//
  // Filtro global
  const filterTransportadora = useSelector(state => state.filter);
  const filterProvider = useSelector(state => state.filterProvider);
  const filter = provider ? filterProvider : filterTransportadora;

  // Filtros da página
  const pageFilter = useSelector(state => state[pageFilterName]);

  const setPageFilter = useMemo(
    () => setterFilter(provider, pageFilterName),
    [provider, pageFilterName],
  );

  // Inicializa objetos
  const globalState = {};
  const pageState = {};
  if (glFilters) for (const key of glFilters) globalState[key] = '';
  if (pgFilters) for (const key of pgFilters) pageState[key] = '';

  const initial = useMemo(
    () => ({ ...globalState, ...pageState }),
    [globalState, pageState],
  );

  // -----------------------------EFFECTS---------------------------------//

  useEffect(() => updateValues(), [glFilters, pageFilter]);

  // ---------------------------------------HANDLERS-------------------------------------------//
  const handleClick = e => setAnchorEl(e.currentTarget);

  const handleClose = () => {
    setAnchorEl(null);
    updateValues();
  };

  const handleClear = () => {
    setFiltersTemp(initial);
    const buttonApply = document.getElementById('aplicar');
    buttonApply?.focus();
  };

  const handleApply = () => {
    const newFilter = {};
    const newPageFilter = { ...pageFilter };

    if (glFilters) {
      for (const key of glFilters) {
        // Limpar filial ao alterar empresa
        if (key === 'empresas' && filtersTemp[key]) {
          const count = filtersTemp[key].split(',').length;
          if (count !== 1) newFilter.filial = '';
          newFilter[key] = filtersTemp[key];
        } else {
          newFilter[key] = filtersTemp[key];
        }
      }
      dispatch(provider ? setFilterProvider(newFilter) : setFilter(newFilter));
    }
    if (pgFilters && pageFilter) {
      for (const key of pgFilters) newPageFilter[key] = filtersTemp[key];
      dispatch(setPageFilter(newPageFilter));
    }
    setAnchorEl(null);
    toast.success('Filtros aplicados com sucesso!');
    updateCount(newFilter, newPageFilter);
  };

  const handleChange = (value, key) => {
    const clear = checkClear(key, sections, provider);
    setFiltersTemp(prev => ({ ...prev, ...clear, [key]: value }));
  };

  // ----------------------------------------UPDATES-----------------------------------------//
  const updateCount = (gFil, pFil) => {
    const count = counter(glFilters, pgFilters, gFil, pFil);
    setCountFilter(count);
  };

  const updateValues = () => {
    setFiltersTemp(load(filter, pageFilter, glFilters, pgFilters));
    updateCount(filter, pageFilter);
  };

  // ---------------------------------------RENDER-------------------------------------------//

  const renderSection = (section, idx) => (
    <Section
      title={section.name}
      open={expanded[idx]}
      handleClick={() => handleExpand(idx)}
    >
      {section.filters.map((filter, key) => {
        const { visible, data } = checkDependency(
          filter,
          filtersTemp,
          provider,
          section.filters,
          datas,
          user,
        );
        if (visible)
          return (
            <S.SelectWrapper key={key}>
              <Select
                placeholder={filter.placeholder}
                data={data || filter?.select || datas?.[filter.name] || []}
                value={filtersTemp?.[filter.name] || ''}
                setValue={val => handleChange(val, filter.name)}
                mode={filter.mode}
              />
            </S.SelectWrapper>
          );
      })}
    </Section>
  );

  const renderItem = section => (
    <>
      {section?.filters?.map((filter, key) => {
        const { visible, data } = checkDependency(
          filter,
          filtersTemp,
          provider,
          section.filters,
          datas,
          user,
        );
        if (visible)
          return (
            <S.SelectWrapper key={key}>
              <Select
                placeholder={filter?.placeholder}
                data={data || filter?.select || datas?.[filter?.name] || []}
                value={filtersTemp?.[filter?.name] || ''}
                setValue={val => handleChange(val, filter?.name)}
                mode={filter.mode}
              />
            </S.SelectWrapper>
          );
      })}
    </>
  );

  return (
    <>
      <S.StyledButton
        endIcon={anchorEl ? <ArrowDropUp /> : <ArrowDropDown />}
        startIcon={<SvgIcon component={filterIcon} />}
        aria-describedby={id}
        variant="contained"
        onClick={handleClick}
        anchorel={anchorEl}
      >
        Filtros
        <S.Count count={countFilter}>{countFilter}</S.Count>
      </S.StyledButton>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      >
        <S.Main>
          <S.Header>
            <h2>Filtros</h2>
            <S.ClearButton onClick={handleClear}>Limpar filtros</S.ClearButton>
          </S.Header>
          <S.SelectsContainer>
            {sections.map((section, idx) => (
              <Fragment key={idx}>
                {!section.name && renderItem(section, idx)}
                {section.name && renderSection(section, idx)}
              </Fragment>
            ))}
          </S.SelectsContainer>

          <S.Footer>
            <GhostButton
              customcolor={
                theme?.palette?.semantics?.feedback?.attention?.natural
              }
              onClick={handleClose}
              size="medium"
              id="cancelar"
            >
              Cancelar
            </GhostButton>

            <GhostButton onClick={handleApply} size="medium" id="aplicar">
              Aplicar
            </GhostButton>
          </S.Footer>
        </S.Main>
      </Popover>
    </>
  );
};
