import styled, { css } from 'styled-components';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  .container-swith {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .title {
    text-align: left;
    font: normal normal 900 24px/46px Texta;
    letter-spacing: 0px;
    color: #4b5166;
    margin-bottom: 20px;
  }
  .content {
    background: #edf2f9 0% 0% no-repeat padding-box;
    border: 2px solid #939aab4d;
    border-radius: 4px;
    height: 225px;
    width: 100%;
    padding-left: 46px;
    padding-top: 33px;
    .subtitle {
      text-align: left;
      font: normal normal 900 20px/20px Texta;
      letter-spacing: 0px;
      color: #4b5166;
      margin-bottom: 8px;
    }
    .text {
      text-align: left;
      font: normal normal medium 16px/20px Texta;
      letter-spacing: 0px;
      color: #4b5166;
    }
  }
  .style-text-sw {
    text-align: left;
    font: normal normal 900 24px/46px Texta;
    letter-spacing: 0px;
    color: #4b5166;
  }
  .container-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .btn {
    background: #4b5166;
    opacity: 0.2;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: white;
    margin-left: 10px;
    user-select: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .btn:hover {
    opacity: 0.8;
  }

  .btn.selected {
    opacity: 1;
  }

  .btn2 {
    background: #939aab;
    opacity: 0.2;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: 8px;
    padding-bottom: 8px;
    color: white;
    margin-left: 10px;
    user-select: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }

  .btn2:hover {
    opacity: 0.8;
  }

  .btn2.selected {
    opacity: 1;
  }

  .container-btn-meta {
    display: flex;
    flex-direction: row;
    justify-content: start;
    margin-top: 30px;
  }
  .btn-meta {
    background: #f9fbfd;
    border: 1px solid #939aab4d;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;
    padding-top: 8px;
    color: #939aab;
    margin-right: 10px;
    padding-bottom: 8px;
    user-select: none;
  }
  .btn-meta2 {
    background: #ff8040;
    border: 1px solid #ff8040;
    border-radius: 4px;
    padding-left: 16px;
    padding-right: 16px;
    cursor: pointer;
    padding-top: 8px;
    color: #ffff;
    user-select: none;
    margin-right: 10px;
    padding-bottom: 8px;
  }
  .btn-meta2:hover {
    opacity: 0.8;
  }
  .container-tabs {
    margin-top: 42px;
  }
`;

export { Main };
