// React
import { useRef } from 'react';
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';
import ReactPlayer from 'react-player';
import EXIF from 'exif-js';

// Styles
import { useTheme } from 'styled-components';

// Components
import fileUpload from 'images/image_file_2.png';
import ConfirmModal from 'components/ConfirmModal';
import Loading from 'components/Loading';

// Compoenets MI
import { DeleteOutline } from '@mui/icons-material';
import PhotoSizeSelectActualOutlinedIcon from '@mui/icons-material/PhotoSizeSelectActualOutlined';

// Utils
import { firestorage } from 'utils/firebase';
import { Tooltip } from '@mui/material';
import { filter } from 'underscore';
import { useSelector } from 'react-redux';
import EditModalFile from './EditFileModal';
import ModalFile from './FileModal';
import * as S from './styled';

// Regras
// 1- Não é possivel fazer a passagem de um data no formato {} e multiple=true *EM CONJUNTO*
// 2- Não é possivel receber "automaticSave" e "saveNow" *EM CONJUNTO*

// Detalhes
// * multiple=false && data.length=1 ==> Imagem é exibida em fullScreen no DropZone
// * Se "deleteFunction" não for passada nos parametros, as ações de icones de exclusão não serão exibidos

// USO ASSINCRONO (saveNow & setSaveNow)
// As respostas são enviadas nos parametros das funções "deleteFunction", "addFunction" e "editFunction"
// São enviadas no formato de array independente da quantidade de itens ou parametros passados
// É necessario que "saveNow" seja igual "true" para que as respostas sejam retornadas em conjunto, porém todas elas seguem sendo enviadas separadamente a cada alteração
// A pagina que utiliza o componente deve manipular o state "setSaveNow" para o momento que desejar receber as respostas

// USO SINCRONO (automaticSave)
// As respostas são enviadas nos parametros das funções "deleteFunction", "addFunction" e "editFunction"
// São enviadas no formato de array independe da quantidade de itens ou parametros passados
// É necessario que automaticSave seja SEMPRE true nos parametros passados
// As respostas sao retornadas a cada confirmação executada nos modais e nao arguardam manipulações externas da página

const DropZoneArchives = ({
  data, // Imagens que inicializam com o compoenete = Types: [] | {}  = {imagem_url: "", legenda: ""}
  multiple, // Define se o input pode receber mais de um file = Type: boolean
  automaticSave, // Respostas imediatas a cada confirmação executada = Type: boolean
  saveNow, // Gatilho de retorno para estrtutura assincrona = Type: boolena
  setSaveNow, // "Set" do state "saveNow", deve ser usado em conjunto com o item acima
  fileDir, // Rota de destino para o fire base = Type: string
  addFunction, // Função que recebe nos parametros os item a serem adicionados no DB
  editFunction, // Função que recebe nos parametros os item a serem editados no DB
  deleteFunction, // Função que recebe nos parametros os item a serem deletados no DB
  changeMonitoring, // Função de monitoramento da pagina (para usos async)
  label, // Label do compoenente
  titleButton, // Titulo do botao que adiciona novos arquivos
  haveLegend, // Adiciona o campo de "Legenda" nos modais de adição e edição Type: boolean
  typesString = 'jpeg, jpg, png, bmp', // Tipos recebidos nos inputs em formato de texto para exibição com o usúario
  types = ['image/*'], // Tipos em formato para o Input
  imageVideoTypes, // Opcional - Tipos em formato para o Input
  onlyRead, // Desativa todas as execuções de ações do DropZone
  minimal, // Altera o tipo de exibicao do layout (usado para formulario)
  minimalText, // Se passado coloca um texto junto ao icone de minimal (passar texto direto)
  minimalRequired, // Mostra um * a frente do texto de minimalText
  leftText, // Texto alinhado a direita do dropzone minimal
  rightText, // Texto alinha a direta do dropzone minimal
  tooltip, // Tooltip para mininal
  error, // Altera cor do minimal para redDanger se true
  noTopButton, // Remove a exibição do botao no topo
  unicSize = '450px', // Define o tamanho do componente quando o item é de exibiçao unica
  unicCenter, // Centraliza a foto coletando todos os espaçoes (testado apenas multiple = false ** a imagem é cortada)
  newImagesByModal, // Defini o titulo e habilita o botao de adicionar novas imagens pelo modal de edição
  maxSize = null, // Define o tamanho maximo do arquivo em MB
}) => {
  const theme = useTheme();
  // Input References
  const input = useRef(null);
  const newInput = useRef(null);
  const inputModal = useRef(null);

  // Images States
  const [currentItem, setCurrentItem] = useState(null); // Contem a referencia de qual imagagem foi clicada
  const [images, setImages] = useState(null); // Contem data formatada para array
  const [addImages, setAddImages] = useState(null); // Contem imagens a serem adicionadas
  const [allImages, setAllImages] = useState([]); // Contem imagens passadas + adicionadas no input
  const [awaitDelete, setAwaitDelete] = useState([]); // Contem varias imagens que serão deletadas
  const [itemDelete, setItemDelete] = useState(null); // Contem um imagem unica que será deletada
  const [itemEdit, setItemEdit] = useState(null); // Contem imagens que serão editadas
  const [editNew, setEditNew] = useState([]);
  const [editOld, setEditOld] = useState([]);

  const [hasChanged, setChanged] = useState(false);

  // Modal States
  const [editModal, setEditModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [modalAddImages, setModalAddImages] = useState(false);

  // Loadings
  const [loadingAdd, setLoadingAdd] = useState(false);

  // States for Errors
  const [block, setBlock] = useState(true);
  const [message, setMessage] = useState('');

  // Custom Types (videos)
  const [customTypes, setCustomTypes] = useState(types);
  const [customTypesString, setCustomTypesString] = useState(typesString);
  const [video, setVideo] = useState(null);

  const { user } = useSelector(state => state.auth);

  function convertToDecimal(degrees, minutes, seconds, direction) {
    let decimal = degrees + minutes / 60 + seconds / 3600;
    if (direction === 'S' || direction === 'W') {
      decimal *= -1;
    }
    return decimal;
  }

  // Função para formatar a data EXIF para ISO
  function formatExifDate(exifDate) {
    if (!exifDate) return null;

    // O formato EXIF é geralmente "YYYY:MM:DD HH:MM:SS"
    // Substituir : por - nos primeiros dois casos (ano e mês)
    const formattedDate = exifDate.replace(
      /^(\d{4}):(\d{2}):(\d{2})/,
      '$1-$2-$3',
    );

    // Criar objeto Date
    const date = new Date(formattedDate);

    // Verificar se a data é válida
    if (isNaN(date.getTime())) {
      console.error('Data EXIF inválida:', exifDate);
      return new Date().toISOString();
    }

    return date.toISOString();
  }

  // ---------------------------------FORMATAÇÃO DE PARAMETROS----------------------------//

  // Essa função formata a data sempre que um novo arquivo é adiconado ao compoenente
  useEffect(() => {
    fomratedData();
  }, []);

  const fomratedData = () => {
    let newImages = [];

    // Verifica o tipo do arquivo de data
    if (!multiple && data) {
      newImages = Array.isArray(data) ? data : [data];
    } else if (data) {
      newImages = data;
    }

    setImages(newImages);

    if (imageVideoTypes) {
      setCustomTypes(['video/*', 'image/*']);
      setCustomTypesString('jpeg, jpg, png, bmp, mp4');
    }
  };

  useEffect(() => {
    if (!Array.isArray(images) && multiple) {
      setMessage(
        'Erro de DropZone - Formtação de arquivo invalida = Parametro data nao é um array e multiple está ativo',
      );
      return;
    }

    if ((saveNow || setSaveNow) && automaticSave) {
      setMessage(
        "Erro de DropZone - Conflito de parametros = Use 'saveNow' OU 'automaticSave'",
      );
      return;
    }
    setBlock(false);

    let unicImages = [];

    if (images && multiple) {
      unicImages = [...images, ...unicImages];
    }

    if (addImages && multiple) {
      unicImages = [...addImages, ...unicImages];
    }

    if (addImages && !multiple) {
      unicImages = addImages;
    } else if (images && !multiple) {
      unicImages = images;
    }

    setAllImages(unicImages);
  }, [images, addImages]);

  // ---------------------------------CONTROLES DE INPUT----------------------------//

  const handleInput = async dropFiles => {
    if (
      maxSize !== null &&
      input.current?.files?.[0] &&
      !multiple &&
      input.current.files[0].size > maxSize * 1024 * 1024
    ) {
      toast.warning(`O arquivo deve ter no máximo ${maxSize}MB`);
      return;
    }

    setChanged(true);

    if (newImagesByModal) {
      setEditModal(false);
    }

    let filesDrop = [];
    if (input.current?.files) {
      filesDrop = input.current.files;
    }

    let filesButton = [];
    if (newInput.current?.files) {
      filesButton = newInput.current.files;
    }

    let drop = [];
    if (dropFiles) {
      drop = dropFiles;
    }

    let filesModal = [];
    if (inputModal.current?.files && newImagesByModal) {
      filesModal = inputModal.current.files;
    }

    let files = [...filesDrop, ...filesButton, ...drop, ...filesModal];

    for (const i in customTypes) {
      if (customTypes[i].includes('*')) {
        // Verification only type
        for (const j in files) {
          if (files[j].type.includes(customTypes[i].split('/')[0])) {
            files[j].ok = true;
          }
        }
      } else {
        // Verification extension
        for (const j in files) {
          if (files[j].type === customTypes[i]) {
            files[j].ok = true;
          }
        }
      }
    }

    const okToFilter = [];
    const strFailure = [];
    for (const i in files) {
      if (files[i].ok) {
        okToFilter.push(files[i]);
      } else {
        strFailure.push(files[i].name);
      }
    }

    files = okToFilter;
    if (strFailure.length > 0) {
      toast.error(
        `Não é possível realizar o upload dos arquivos: ${strFailure.toString()}`,
      );
    }

    if (files.length === 0) {
      return;
    }

    const awaitAdd = [];
    for (const i in files) {
      setLoadingAdd(true);
      const file = files[i];
      const newFileName = `${Date.now()}_${file.name}`;

      if (file.type.includes('video')) {
        setVideo(file);
      }

      // Extrair dados EXIF (GPS) antes do upload
      let latitude = null;
      let longitude = null;
      let dateCreated = null;

      // Somente extrair EXIF de arquivos de imagem, não de vídeos
      if (file.type.includes('image')) {
        try {
          // Criar uma promise para processar o arquivo EXIF
          new Promise(resolve => {
            EXIF.getData(file, function () {
              // Verificar se existem dados GPS
              if (
                EXIF.getTag(this, 'GPSLatitude') &&
                EXIF.getTag(this, 'GPSLongitude')
              ) {
                const latValues = EXIF.getTag(this, 'GPSLatitude');
                const latRef = EXIF.getTag(this, 'GPSLatitudeRef') || 'N';

                const longValues = EXIF.getTag(this, 'GPSLongitude');
                const longRef = EXIF.getTag(this, 'GPSLongitudeRef') || 'E';

                // Converter para decimal
                latitude = convertToDecimal(
                  latValues[0],
                  latValues[1],
                  latValues[2],
                  latRef,
                );

                longitude = convertToDecimal(
                  longValues[0],
                  longValues[1],
                  longValues[2],
                  longRef,
                );
              } else {
                console.log('Nenhuma coordenada GPS encontrada na imagem');
              }

              // Extrair a data de criação da imagem
              const dateTimeOriginal = EXIF.getTag(this, 'DateTimeOriginal');
              const dateTime = EXIF.getTag(this, 'DateTime');
              const createDate = EXIF.getTag(this, 'CreateDate');

              if (dateTimeOriginal) {
                dateCreated = formatExifDate(dateTimeOriginal);
              } else if (dateTime) {
                dateCreated = formatExifDate(dateTime);
              } else if (createDate) {
                dateCreated = formatExifDate(createDate);
              } else {
                // Se não encontrar data nos metadados, usar a data atual
                dateCreated = new Date().toISOString();
              }

              resolve();
            });
          });
        } catch (err) {
          console.error('Erro ao extrair dados EXIF:', err);
        }
      }

      const fileReference = firestorage.ref(`/${fileDir}/`).child(newFileName);
      const uploadTask = fileReference.put(file);
      uploadTask.on(
        'state_changed',
        snapShot => {},
        err => {
          toast.error('Erro ao fazer upload de arquivo');
          console.log('Err', err);
        },
        async () => {
          await fileReference.getDownloadURL().then(fireBaseUrl => {
            // Adicionar latitude e longitude ao objeto de imagem
            awaitAdd.push({
              imagem_url: fireBaseUrl,
              legenda: '',
              video: file.type.includes('video'),
              latitude, // Adicionar a latitude extraída
              longitude, // Adicionar a longitude extraída
              data_evidencia: dateCreated || new Date().toISOString(), // Data de criação da imagem
            });

            if (awaitAdd.length == files.length) {
              if (newImagesByModal) {
                setEditNew(awaitAdd);
                setEditOld(addImages || []);
              }

              if (addImages && multiple) {
                setAddImages([...awaitAdd, ...addImages]);
                if (!editFunction) addInDatabase([...awaitAdd, ...addImages]);
              } else {
                setAddImages(awaitAdd);
                if (!editFunction) addInDatabase(awaitAdd);
              }

              setTimeout(() => {
                setLoadingAdd(false);
              }, 1000);
            }
          });
        },
      );
    }

    if (input.current?.files) {
      input.current.value = null;
    }

    if (newInput.current?.files) {
      newInput.current.value = null;
    }

    if (inputModal.current?.files) {
      inputModal.current.value = null;
    }

    if (editFunction) {
      setModalAddImages(true);
    }
  };

  const handleDelete = () => {
    setChanged(true);
    let inDB = false;

    const awaitCurrent = [];
    for (const i in images) {
      if (images[i].imagem_url === itemDelete.imagem_url) {
        inDB = true;
      } else {
        awaitCurrent.push(images[i]);
      }
    }
    setImages(awaitCurrent);

    const awaitAdd = [];
    for (const i in addImages) {
      if (addImages[i].imagem_url !== itemDelete.imagem_url) {
        awaitAdd.push(addImages[i]);
      }
    }

    setAddImages(awaitAdd);
    setDeleteModal(false);

    if (inDB) {
      setAwaitDelete([...awaitDelete, itemDelete]);
      // TODO: Se necessario fazer função que deleta no Firebase
      deleteFirebase([...awaitDelete, itemDelete]);
    }

    if (newImagesByModal) {
      addInDatabase('reset');
    }
  };

  // ---------------------------------FUNÇÕES DE RETORNO----------------------------//

  // Controle de retorno async
  // (Testing includes 'hasChanges', in bug case, remove this condition of useEffect)
  useEffect(() => {
    if (saveNow || (hasChanged && !newImagesByModal)) {
      deleteFirebase();
      addInDatabase();
      if (itemEdit) {
        editInDatabase();
      }
      if (changeMonitoring) changeMonitoring();
      if (setSaveNow && saveNow) {
        setSaveNow(false);
      }
      if (hasChanged) {
        setChanged(false);
      }
    }
  }, [saveNow, hasChanged]);

  const deleteFirebase = async params => {
    if (params && params.length > 0) {
      deleteFunction && deleteFunction(params);
    } else {
      deleteFunction &&
        deleteFunction(awaitDelete.length > 0 ? awaitDelete : null);
    }
  };

  const addInDatabase = async notEditableImages => {
    if (notEditableImages) {
      addFunction(notEditableImages);
    } else {
      addFunction(addImages && addImages?.length > 0 ? addImages : null);
    }
  };

  const editInDatabase = async () => {
    Promise.all([editFunction(itemEdit), setItemEdit(false)]).then(() => {
      setChanged(true);
    });
  };

  // ---------------------------------CONTROLE DE MODAL----------------------------//
  const closeModal = () => {
    let inputRef = null;
    inputRef = images ? newInput : input;
    inputRef.current.value = '';

    setModalAddImages(false);
    setEditModal(false);
    setCurrentItem(null);
  };

  // ----------------------DROPZONE-----------------------------//
  const [inDrag, setInDrag] = useState(false);
  const drag = event => {
    event.preventDefault();
  };

  const dragEnter = event => {
    event.preventDefault();
    setInDrag(true);
  };

  const dragOut = event => {
    event.preventDefault();
    setInDrag(false);
  };

  const fileDrop = event => {
    setInDrag(false);
    event.preventDefault();
    const { files } = event.dataTransfer;
    // TODO: Verificar o type dos arquivos no drag&drop
    if (!multiple && files.length > 1) {
      return toast.error('Só é permitido fazer upload de um arquivo');
    }
    handleInput(files);
  };

  return (
    <>
      {!block && (
        <S.Wrapper>
          <S.ContentHeader>
            {!minimal && (
              <>
                <S.Title>{label}</S.Title>
                {allImages &&
                  allImages.length > 0 &&
                  !onlyRead &&
                  !noTopButton && (
                    <S.AddButton htmlFor="inputDrop">{titleButton}</S.AddButton>
                  )}
              </>
            )}

            <input
              type="file"
              id="inputDrop"
              onChange={() => handleInput()}
              ref={newInput}
              multiple={multiple}
              accept={customTypes.toString()}
            />
          </S.ContentHeader>
          {!minimal && (
            <S.Box
              onDragOver={!onlyRead ? drag : undefined}
              onDragEnter={!onlyRead ? dragEnter : undefined}
              onDragLeave={!onlyRead ? dragOut : undefined}
              onDrop={!onlyRead ? fileDrop : undefined}
              inDrag={!onlyRead ? inDrag : undefined}
              className={inDrag ? 'opacity' : ''}
              isError={error}
            >
              {/* Empty */}
              {allImages && allImages.length <= 0 && !inDrag && !onlyRead && (
                <S.EmptyBox>
                  <input
                    type="file"
                    ref={input}
                    onChange={() => handleInput()}
                    multiple={multiple}
                    accept={customTypes.toString()}
                    disabled={onlyRead}
                  />
                  <img src={fileUpload} alt="Empty Box" />
                  <span>Solte o aquivo aqui ou clique para fazer upload</span>
                  <span>{`(${customTypesString})`}</span>
                </S.EmptyBox>
              )}
              {allImages && allImages.length <= 0 && !inDrag && onlyRead && (
                <S.EmptyBox className="disabled">
                  <img src={fileUpload} alt="Empty Box" />
                  <span>Nenhum arquivo adicionado</span>
                </S.EmptyBox>
              )}

              {/* Data */}
              {allImages && allImages.length > 0 && !inDrag && (
                <S.ImagesArea unic={allImages.length === 1 && !multiple}>
                  {allImages.map((imagem, idx) => (
                    <S.ContentImage
                      key={idx}
                      unic={allImages.length === 1 && !multiple}
                    >
                      {deleteFunction && !onlyRead && (
                        <span
                          onClick={() => {
                            setDeleteModal(true);
                            setItemDelete(imagem);
                          }}
                        >
                          <S.IconBox
                            name="close"
                            size={25}
                            color={
                              theme.palette.semantics.feedback.attention.natural
                            }
                          />
                        </span>
                      )}

                      <S.BoxImage
                        unic={allImages.length === 1 && !multiple}
                        unicSize={unicSize}
                        key={idx}
                        onClick={() => {
                          setCurrentItem(idx);
                          if (editFunction) setEditModal(true);
                        }}
                      >
                        {imagem.imagem_url &&
                        imagem.imagem_url.includes('pdf') ? (
                          <embed
                            className={unicCenter ? 'unicCenter' : ''}
                            src={imagem.imagem_url}
                            draggable={false}
                          />
                        ) : imageVideoTypes && video ? (
                          <div className="react-player-wrapper">
                            <ReactPlayer
                              controls
                              playing={false}
                              url={imagem.imagem_url}
                              width="100%"
                              height="100%"
                            />
                          </div>
                        ) : (
                          <img
                            className={unicCenter ? 'unicCenter' : ''}
                            src={imagem.imagem_url}
                            draggable={false}
                          />
                        )}
                        {/* <img
                          className={unicCenter ? 'unicCenter' : ''}
                          src={imagem.imagem_url}
                          draggable={false}
                        /> */}
                      </S.BoxImage>

                      {haveLegend && (
                        <Tooltip title={imagem.legenda || 'Sem legenda'}>
                          <S.Legend>
                            {imagem.legenda
                              ? imagem.legenda.length > 30
                                ? `${imagem.legenda.slice(0, 27)}...`
                                : imagem.legenda
                              : '-'}
                          </S.Legend>
                        </Tooltip>
                      )}
                    </S.ContentImage>
                  ))}
                </S.ImagesArea>
              )}

              {loadingAdd && (
                <S.BoxLoading>
                  <div>
                    <Loading />
                  </div>
                </S.BoxLoading>
              )}

              <ConfirmModal
                open={deleteModal}
                handleClose={() => setDeleteModal(false)}
                title="Tem certeza que deseja excluir essa imagem?"
                titleIcon={
                  <DeleteOutline
                    sx={{
                      color: theme.palette.semantics.feedback.attention.natural,
                    }}
                    fontSize="medium"
                  />
                }
                subtitle={
                  automaticSave
                    ? 'Essa ação não poderá ser desfeita'
                    : 'Essa ação será concluida após salvar as informações'
                }
                buttonText="Confirmar"
                onClick={() => handleDelete()}
              />
            </S.Box>
          )}
          {minimal && (
            <S.AreaMinimal>
              {leftText && (
                <S.EvidenceMinimal
                  visible={!!allImages.length}
                  className="left"
                  onClick={() => {
                    setCurrentItem(0);
                    setEditModal(true);
                  }}
                >
                  {leftText}
                </S.EvidenceMinimal>
              )}

              <S.BoxMinimal
                hasError={error && !allImages.length}
                hasItems={!!allImages.length}
                onClick={
                  allImages.length > 0
                    ? () => {
                        setCurrentItem(0);
                        setEditModal(true);
                      }
                    : undefined
                }
              >
                {tooltip && (
                  <Tooltip
                    title={
                      !onlyRead ? tooltip : `${allImages.length} arquivo(s)`
                    }
                  >
                    <div className="iconsBox">
                      <PhotoSizeSelectActualOutlinedIcon
                        htmlColor={
                          error && !allImages.length
                            ? theme.palette.semantics.feedback.attention.natural
                            : theme.palette.semantics.feedback.unknown.natural
                        }
                      />
                      <div className="circleCounter">{allImages.length}</div>
                    </div>
                  </Tooltip>
                )}
                {!tooltip && (
                  <div className="iconsBox">
                    <PhotoSizeSelectActualOutlinedIcon
                      htmlColor={
                        error && !allImages.length
                          ? theme.palette.semantics.feedback.attention.natural
                          : theme.palette.semantics.feedback.unknown.natural
                      }
                    />
                    <div className="circleCounter">{allImages.length}</div>
                  </div>
                )}
                {minimalText && (
                  <div className="boxText">
                    <span className="minimalText">{minimalText}</span>
                    {minimalRequired && <span className="requirePoint">*</span>}
                  </div>
                )}

                <input
                  type="file"
                  ref={input}
                  onChange={() => handleInput()}
                  multiple={multiple}
                  accept={customTypes.toString()}
                  disabled={onlyRead || !!allImages.length}
                />
              </S.BoxMinimal>
              {rightText && (
                <S.EvidenceMinimal
                  visible={!!allImages.length}
                  onClick={() => {
                    setCurrentItem(0);
                    setEditModal(true);
                  }}
                >
                  {rightText}
                </S.EvidenceMinimal>
              )}
            </S.AreaMinimal>
          )}
          {addImages && modalAddImages && (
            <ModalFile
              data={newImagesByModal ? editNew : addImages}
              setData={newImagesByModal ? setEditNew : setAddImages}
              open={modalAddImages}
              setOpen={() => closeModal()}
              loading={loadingAdd}
              addInDatabase={addInDatabase}
              automaticSave={automaticSave}
              haveLegend={haveLegend}
              onlyRead={onlyRead}
              newImagesByModal={newImagesByModal}
              editNew={editNew}
              editOld={editOld}
              setAddImages={setAddImages}
              multiple={multiple}
            />
          )}

          {allImages.length > 0 && currentItem !== null && editModal && (
            <EditModalFile
              data={allImages}
              setData={setAllImages}
              activeItem={currentItem}
              open={editModal}
              setOpen={() => closeModal()}
              setItemDelete={setItemDelete}
              handleDelete={handleDelete}
              itemEdit={itemEdit}
              setItemEdit={setItemEdit}
              editInDatabase={editInDatabase}
              automaticSave={automaticSave}
              addFunction={addFunction}
              haveLegend={haveLegend}
              deleteFunction={deleteFunction}
              onlyRead={onlyRead}
              // Input Props
              inputRef={inputModal}
              handleInput={handleInput}
              multiple={multiple}
              accept={customTypes.toString()}
              newImagesByModal={newImagesByModal}
            />
          )}
        </S.Wrapper>
      )}
      {/* TODO: Desativado para evitar error de async nas paginas */}
      {/* {block && (
            <S.BoxError>{message}</S.BoxError>
        )} */}
    </>
  );
};

export default DropZoneArchives;
