// React
import React, { useState, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { startOfMonth, subMonths } from 'date-fns';

// components pagina
import DesviosAbertos from './DesviosAbertos';
import { GraficoDesviosTratadoNoprazo } from './GraficoDesviosTratadoNoprazo';
import CustomCard from '../FatorRisco/Cards/Card';
import { SvgIcon } from '@mui/material';
import { ReactComponent as filterIco } from 'images/icons/filter.svg';

// Styles
import * as S from './styled';

// services
import * as API from './services';
import Filters from './Filters';
import { usePlans } from 'hooks/usePlans';
import FiltersGlobal from 'components/FiltersGlobal';
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';
import { transformData } from 'hooks/filterByPeriod';

const MinhasTarefas = ({ handleCount, hidden }) => {
  const theme = useTheme();
  const {
    isProvider,
    hasTelemetria,
    hasMonitoramento,
    hasTorrePlus,
    hasTorre,
  } = usePlans();
  const { filiais, responsaveis } = useFetchMultipleWithCache();
  const [period, setPeriod] = useState('day');
  const [loading, setLoading] = useState(false);
  const [filter, setFilter] = useState({});
  const [anchorEl, setAnchorEl] = useState(null);
  const [cards02, setCards02] = useState([]);
  const [cards03, setCards03] = useState([]);
  const [alertas, setAlertas] = useState([]);
  const [dateHDesvio, setHdesvio] = useState({
    finalDate: new Date(),
    initialDate: startOfMonth(subMonths(new Date(), 11)),
  });
  const [dataHdesvio, setDataHdesvio] = useState(null);

  const fetchCards02 = async () => {
    const res = await API.getCards2();
    setCards02(res?.data || []);
  };

  const fetchCards03 = async () => {
    const res = await API.getCardsRevisoes();
    setCards03(res?.data || []);
  };

  const fetchHistoricoDesvios = async () => {
    setLoading(true);
    const res = await API.historicoDesvios({ ...dateHDesvio, ...filter });
    setDataHdesvio(res?.data || null);
    setLoading(false);
  };

  const fetchAlertaDesvios = async () => {
    const res = await API.alertaDesvios();
    setAlertas(res?.data || []);
  };

  useEffect(() => {
    fetchCards02();
    fetchCards03();
    fetchAlertaDesvios();
  }, []);

  useEffect(() => {
    if (!hasTorre && !hasTorrePlus) {
      fetchHistoricoDesvios();
    }
  }, [dateHDesvio, filter]);

  useEffect(() => {
    if (dataHdesvio?.rows) {
      const initial = new Date(filter.initialDate);
      const final = new Date(filter.finalDate);

      const differenceInDays = (final - initial) / (1000 * 60 * 60 * 24);
      setDataHdesvio(
        transformData(dataHdesvio?.rows, period, differenceInDays),
      );
    }
  }, [period]);

  const countFilter = Object.getOwnPropertyNames(filter).length;

  const renderFilters = () => {
    return (
      <div style={{ padding: 20 }}>
        <FiltersGlobal
          handleDate={setHdesvio}
          handlePeriodFilter={setPeriod}
          handleFilters={setFilter}
          customComponent={
            <div className="title">Histórico de tratativas no prazo</div>
          }
          hideExportButton
          hideRefleshButton
          enablePeriodFilters
          data={[
            {
              filterName: 'responsavel',
              label: 'Responsáveis',
              options: responsaveis?.data || [],
            },
          ]}
        />
      </div>
    );
  };

  return (
    <S.ContainerProvider hidden={hidden}>
      <div className="card">
        <DesviosAbertos data={alertas} handleCount={handleCount} />
      </div>
      <div className="flex">
        <div className="card flex-1">
          <GraficoDesviosTratadoNoprazo
            filterCustom={renderFilters()}
            handleDate={setHdesvio}
            data={dataHdesvio?.rows ?? []}
            metaValue={dataHdesvio?.meta ?? 0}
            title="Histórico de tratativas no prazo"
            filter={
              <S.StyledButton
                textcolor={theme.palette.words.text.natural}
                backgroundcolor="transparent"
                startIcon={<SvgIcon component={filterIco} />}
                height="36px"
                onClick={event => setAnchorEl(event.currentTarget)}
                disabled={loading}
              >
                Filtros
                <S.Count count={!!countFilter}>{countFilter}</S.Count>
              </S.StyledButton>
            }
          />
        </div>
        <div>
          <CustomCard
            height="6.5rem"
            marginTop={0}
            title="Tratativas no prazo"
            percentage={
              <span style={{ color: '#939aab' }}>{cards02 ?? 0}</span>
            }
          />
          <CustomCard
            height="6.5rem"
            title="Revisões aprovadas"
            percentage={
              <span style={{ color: '#939aab' }}>{cards03 ?? 0}</span>
            }
          />
        </div>
      </div>
      <Filters
        id="simple-popover"
        open={!!anchorEl}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        filter={filter}
        setFilter={setFilter}
      />
    </S.ContainerProvider>
  );
};

export default MinhasTarefas;
