// Styles
import colors from 'styles/colors';
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

// Components
import Button from 'components/Buttons/Default';
import { DefaultTable } from 'components/_Table/templates/default';
import ConfirmModal from 'components/ConfirmModal';
import InfoCard from 'components/Cards/InfoCard';

// utils
import { trackEvent } from 'utils/mixpanel';

// Components MUI
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ContentPasteOffOutlinedIcon from '@mui/icons-material/ContentPasteOffOutlined';
import ErrorOutlineOutlinedIcon from '@mui/icons-material/ErrorOutlineOutlined';

// Services
import { useQuery } from 'react-query';
import { getTurmas, deleteTurma, updateTurma } from './services';
import { Tooltip } from '@mui/material';

// Table columns
import { columnsTurmas } from './columns';
import { ModalListTasks } from './ModalBlocked/index.js';
import CadastrarTurmaModal from './Modal/AdicionarTurma';
import EditTurma from './Modal/EditTurma';
import * as S from './style.js';

const Turmas = () => {
  const user = useSelector(state => state.auth?.user?.user);
  const theme = useTheme();
  const navigate = useNavigate();

  const [openModalAddTurma, setOpenModalAddTurma] = useState(false);
  const [openModalEditTurma, setOpenModalEditTurma] = useState(false);

  const [currenGroup, setCurrentGroup] = useState(null);
  const [confirm, setConfirm] = useState(false);
  const [viewContentConfirm, setViewContentConfirm] = useState(false);
  const [currentId, setCurrentId] = useState(-1);

  // Tumras bloqueadas
  const [conteudos, setConteudos] = useState(null);
  const [needRefetch, setNeedRefetch] = useState(false);

  // Tabelas
  const [data, setData] = useState([]);
  const [loadingDisable, setLoadingDisable] = useState(false);

  const {
    data: res,
    isError,
    refetch: fetchTurma,
    isFetching: loading,
  } = useQuery(['list-turma', 1], () => getTurmas(), {
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (loading || isError) {
      return null;
    }
    setData(res.data.data);
  }, [res]);

  const handleSetExcluir = async () => {
    setLoadingDisable(true);

    const res = await deleteTurma(currentId);
    if (res.data.success) {
      toast.success(res.data.message);
      fetchTurma();
    } else {
      if (res.data?.hasContent) {
        setConteudos(res.data.conteudos || []);
        setViewContentConfirm(true);
      }
      toast.error(res.data.message);
    }
    setConfirm(false);
    setLoadingDisable(false);
  };

  const handleActiveTurma = async turma => {
    const res = await updateTurma(turma.id, {
      id: turma.id,
      nome: turma.nome,
      ativa: 1,
      foto: turma.foto,
      para_motoristas: turma.para_motoristas,
      para_usuarios: turma.para_usuarios,
    });

    if (res.data.success) {
      fetchTurma();
      toast.success(res.data.message);
    } else {
      toast.error(res.data.message);
    }
  };

  const navigateTO = id => {
    navigate(`/capacitacao/viewTurmas/${id}`, { id });
  };

  const openModalActivities = id => {
    Promise.all([setCurrentId(id)]).then(() => {
      setConteudos([]);
      setViewContentConfirm(true);
    });
  };

  const actions = [
    {
      title: 'Editar',
      function: id => {
        const info = data.find(item => Number(item.id) === Number(id));
        info.status = info.ativa;
        setCurrentGroup(info);
        setOpenModalEditTurma(true);
      },
    },
    {
      title: 'Ativar/Desativa',
      function: id => {
        const turma = data.find(item => Number(item.id) === Number(id));
        if (turma?.ativa) {
          if (turma.atividades) {
            openModalActivities(id);
          } else {
            setCurrentId(id);
            setConfirm(true);
          }
        } else {
          handleActiveTurma(turma);
        }
      },
    },
    {
      title: 'Ver turma',
      function: id => navigateTO(id),
    },
  ];

  const handleColumns = () => {
    const col = [...columnsTurmas].map(item => {
      if (item.id === 'atividades') {
        return {
          children: (value, item) => (
            <S.ColDriver>
              <Tooltip
                title={
                  item.atividades
                    ? `${item.atividades} atividade(s) relacionada(s)`
                    : 'Nenhuma atividade relacionada'
                }
                onClick={
                  item.atividades
                    ? () => {
                        openModalActivities(item.id);
                      }
                    : undefined
                }
              >
                <S.Paragraph
                  className="activity"
                  hasActivity={!!item.atividades}
                >
                  <span>{item.atividades}</span>
                  <ErrorOutlineOutlinedIcon
                    htmlColor={
                      item.atividades ? colors.blueInfo : colors.greyTiny
                    }
                  />
                </S.Paragraph>
              </Tooltip>
            </S.ColDriver>
          ),
        };
      }

      return item;
    });
    return col;
  };
  return (
    <S.Container>
      <CadastrarTurmaModal
        open={openModalAddTurma}
        handleClose={() => setOpenModalAddTurma(false)}
        fetchData={fetchTurma}
      />

      <EditTurma
        open={openModalEditTurma}
        dataEdit={currenGroup}
        handleClose={() => setOpenModalEditTurma(false)}
        fetchData={fetchTurma}
      />

      <S.ButtonContianer>
        <div>
          <h1>Turmas</h1>
        </div>
        <Button
          startIcon={<AddCircleOutlineIcon />}
          onClick={() => setOpenModalAddTurma(true)}
        >
          <span style={{ fontSize: 14 }}>ADICIONAR TURMA</span>
        </Button>
      </S.ButtonContianer>

      <div style={{ marginTop: '20px' }}>
        <InfoCard message="Crie e gerencie as turmas com as transportadoras que receberão seus conteúdos." />
      </div>

      <S.TableContainer>
        <DefaultTable
          data={data || []}
          onClickRow={id => navigateTO(id)}
          columns={handleColumns()}
          searchKeys={['nome']}
          loading={loading || loadingDisable}
          placeholder="Buscar por turma"
          sortBy={{ id: 'id', order: 'DESC' }}
          actions={actions}
          searchEvent={search => trackEvent(user, 'Busca turma', null, search)}
          empty={{
            title: 'Buscar Turma',
            description: `Suas turmas aparecerão aqui".`,
          }}
        />
      </S.TableContainer>
      <ConfirmModal
        open={confirm}
        handleClose={() => setConfirm(false)}
        title="Tem certeza que deseja desativar a turma?"
        subtitle="As transportadoras perderão acesso ao conteúdo"
        buttonText="Confirmar"
        titleIcon={<ContentPasteOffOutlinedIcon htmlColor={colors.redDanger} />}
        onClick={() => handleSetExcluir()}
        loading={false}
      />
      {viewContentConfirm && (
        <ModalListTasks
          open={viewContentConfirm}
          onClose={() => {
            setViewContentConfirm(false);
            if (needRefetch) {
              setNeedRefetch(false);
              fetchTurma();
            }
          }}
          conteudos={conteudos || []}
          idDisabled={currentId}
          fetchTurma={fetchTurma}
          setNeedRefetch={setNeedRefetch}
        />
      )}
    </S.Container>
  );
};

export default Turmas;
