/* eslint-disable import/order */
// Styles
import * as S from './styled';
import { useTheme } from 'styled-components';

// React
import { useState } from 'react';
import { toast } from 'react-toastify';

// Components
import GhostButton from 'components/Buttons/Ghost';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';

// Components MUI
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';

// Utils
import { fields } from './constants';
import { verifyErrors } from './actions';
import { approveCae } from '../../services';

export const ModalEvidencia = ({ open, handleClose, cliente, cae }) => {
  const compose = fields[cliente];
  const theme = useTheme();
  const [arquivo_evidencia, setArquivoEvidencia] = useState('');
  const [loading, setLoading] = useState(false);

  const handleCreate = async () => {
    const error = verifyErrors(compose, arquivo_evidencia);
    if (error) {
      toast.error('O campo de evidência é obrigatório para esse cliente');
      return;
    }

    setLoading(true);
    const res = await approveCae({
      ...cae,
      arquivo_evidencia,
      status: 'FINALIZADO',
    });
    setLoading(false);

    if (res.success) {
      toast.success(res.message);

      handleClose();
    } else {
      toast.error(res.message);
    }
  };

  return (
    <S.ModalMUI open={open} onClose={handleClose}>
      <S.Container>
        <S.Header>
          <div className="left">
            <AssignmentOutlinedIcon />
            <p className="title">Adicionar Evidência</p>
          </div>
          <div className="right">
            <CloseRoundedIcon onClick={!loading && handleClose} />
          </div>
        </S.Header>
        <S.Body>
          <InputAttachedFile
            idInput="input_file_layout_evidence"
            label="Evidência CAE"
            inputLabel="Anexar Arquivo"
            fileUrl={arquivo_evidencia}
            setFileUrl={value => setArquivoEvidencia(value)}
            fileDir="layout/cae"
            required={compose.required}
          />
        </S.Body>
        <S.Footer>
          <GhostButton
            children="Cancelar"
            customcolor={
              theme?.palette?.semantics?.feedback?.attention?.natural
            }
            onClick={handleClose}
            disabled={loading}
          />
          <GhostButton
            children="Aprovar"
            onClick={handleCreate}
            loading={loading}
          />
        </S.Footer>
      </S.Container>
    </S.ModalMUI>
  );
};
