// Icons
import { ReactComponent as gerais } from '../../../images/formulario/gerais.svg';
import { ReactComponent as publico } from '../../../images/formulario/publico.svg';
import { ReactComponent as config } from '../../../images/formulario/config.svg';
import { ReactComponent as formulario } from '../../../images/formulario/formulario.svg';

// Components
import { InformacoesGerais } from './InfoGerais';
import { Publico } from './Publico';
import { Configuracoes } from './Configuracoes';
import { Revisar } from './Revisar';

export const steps = [
  {
    id: 0,
    linkTo: '',
    icon: gerais,
    title: 'Informações Gerais',
    pageTitle: 'Informações Gerais',
    page: 'Informações Gerais',
    step: 1,
    value: 0,
    Component: params => InformacoesGerais(params),
  },
  {
    id: 1,
    linkTo: '',
    icon: publico,
    title: 'Público',
    pageTitle: 'Público',
    page: 'Público',
    step: 2,
    value: 1,
    Component: params => Publico(params),
  },
  {
    id: 2,
    linkTo: '',
    icon: config,
    title: 'Configurações',
    pageTitle: 'Configurações',
    page: 'Configurações',
    step: 3,
    value: 2,
    Component: params => Configuracoes(params),
  },
  {
    id: 3,
    linkTo: '',
    icon: formulario,
    title: 'Revisar',
    pageTitle: 'Revisar e Publicar',
    page: 'Formulário',
    step: 4,
    value: 3,
    Component: params => Revisar(params),
  },
];
