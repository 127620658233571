// Icons
import { ReactComponent as reports } from '../../../images/formulario/reports.svg';
import { ReactComponent as tarefas } from '../../../images/formulario/tarefas.svg';

// Components
import { Users } from './Users';
import { General } from './General';

export const itensBar = [
  {
    id: 0,
    page: 'Respostas',
    linkTo: '',
    icon: tarefas,
    Component: params => Users(params),
  },
  {
    id: 1,
    page: 'Reports',
    linkTo: '',
    icon: reports,
    Component: params => General(params),
  },
];

export const justifications = [
  { value: 'Férias', label: 'Férias' },
  { value: 'Atestado', label: 'Atestado' },
  { value: 'Afastamento', label: 'Afastamento' },
  { value: 'OUTRO', label: 'Outro motivo' },
];
