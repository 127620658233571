import React, { useState, useEffect, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import axios from 'axios';

// components
import TableLocal from 'components/TableLocal';

// utils
import { padWithLeadingZeros } from 'utils/helpers';

// styles
import { useTheme } from 'styled-components';

// icons
import WorkspacePremiumIcon from '@mui/icons-material/WorkspacePremium';
import icon_certificado from 'images/icons/certificados/certificado.svg';
import Loading from 'components/Loading';
import { toast } from 'react-toastify';
import Header from '../components';
import * as S from './style.js';
import ModalReviewFull from '../../TakeTest';

import { getCertificates, showContent, requestCertificate } from '../services';

// collums
import { columnsConquistas } from './columns';

const Certificados = () => {
  const theme = useTheme();
  const [searchParams] = useSearchParams();

  const id = searchParams.get('id');
  const tipo = searchParams.get('tipo');
  const [cards, setCards] = useState([]);

  const [curentContent, setCurentContent] = useState([]);
  const [selectedClass, setSelectedClass] = useState([]);
  const [selectedRow, setSelectedRow] = useState('');
  const [certificados, setCertificados] = useState([]);
  const [loading, setLoading] = useState(false);

  const [openPreviewFull, setOpenPreviewFull] = useState(false);
  const [info, setInfo] = useState(null);

  const fetchCertificates = async () => {
    const res = await getCertificates(id, tipo);
    setCards(res.cards);
    setCertificados(res.data);
    setInfo(res.aluno);
  };

  useEffect(() => {
    fetchCertificates();
  }, []);

  const bulkActions = [
    {
      title: 'Baixar certificado',
      function: () => downloadAllCertificados(),
    },
  ];

  const sortBy = [
    {
      id: 'titulo',
      desc: true,
    },
  ];

  const formatTable = arr => {
    arr?.forEach(i => {
      if (i.id === 'id') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph
                fontweight="bold"
                color={theme.palette.brand.primary.natural}
              >
                {row.original.id}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'titulo') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <div className="container-img-name">
                {/* <Avatar alt={''} src={row.original.foto} sx={{ marginRight: 1 }} /> */}
                <img src={icon_certificado} alt="icone certificado" />
                <S.Paragraph
                  fontweight="bold"
                  color={theme.palette.brand.primary.natural}
                >
                  {row.original.conteudo.titulo}
                </S.Paragraph>
              </div>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'autor') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph
                fontweight="bold"
                color={theme.palette.brand.primary.natural}
              >
                {row.original.conteudo.responsavel.nome}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'conclusao') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph
                fontweight="bold"
                color={theme.palette.brand.primary.natural}
              >
                {new Date(row.original.createdAt).toLocaleDateString()}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'coeficiente') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph
                fontweight="bold"
                color={theme.palette.brand.primary.natural}
              >
                {row.original.conteudo?.respostas[0]?.nota || '-'}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'validade') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph
                fontweight="bold"
                color={theme.palette.brand.primary.natural}
              >
                {new Date(row.original.validade_fim).toLocaleDateString()}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      }
    });
    return arr;
  };

  const download = async (url, idx = 0) => {
    if (!url) return toast.warn('URL inválida!');
    await axios
      .get(url, {
        responseType: 'arraybuffer',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/pdf',
        },
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute(
          'download',
          `Certificado${idx > 0 ? `_${idx}` : ''}.pdf`,
        );
        document.body.appendChild(link);
        link.click();
      })
      .catch(error => {
        throw new Error('Erro ao baixar certificado!');
      });
  };

  const geraCertificado = async id => {
    const contentId = certificados.find(i => Number(i.id) === Number(id))
      ?.conteudo?.id;
    const nota =
      certificados.find(i => Number(i.id) === Number(id))?.conteudo
        ?.respostas[0]?.nota || '-';
    const cert = await requestCertificate(id, { nota, contentId });
    if (cert.data.success) {
      const link = cert.data.data.link;
      if (link) {
        try {
          await download(link);
        } catch (error) {
          console.log(error);
          toast.warn('Erro ao baixar certificado!');
        }
      }
    } else toast.error(cert.data.message);
  };

  const baixarCertificado = async id => {
    const link = certificados.find(i => Number(i.id) === Number(id))?.link;
    if (link) {
      try {
        await download(link);
      } catch (error) {
        geraCertificado(id);
      }
    } else geraCertificado(id);
  };

  const downloadAllCertificados = async () => {
    for (const certificadoId of selectedRow) {
      baixarCertificado(certificadoId);
    }
  };
  if (loading) {
    return (
      <div style={{ position: 'absolute', left: '55%' }}>
        <Loading />
      </div>
    );
  }
  return (
    <>
      <h1>Meus Certificados</h1>
      <Header nome={info?.nome} foto={info?.foto} filial={info?.filial} />
      <S.Container>
        {openPreviewFull && (
          <ModalReviewFull
            open={openPreviewFull}
            handleClose={() => {
              setOpenPreviewFull(false);
            }}
            isPreview
            data={curentContent}
          />
        )}
        {/* TODO componentizar esses cards */}
        <S.containerCard>
          {cards.map(item => (
            <div className="container-card">
              <div className="icon-container">
                <WorkspacePremiumIcon />
              </div>
              <div>
                <div>
                  <h1>{padWithLeadingZeros(item.value, 2)}</h1>
                  <h3>{item.text}</h3>
                </div>
              </div>
            </div>
          ))}
        </S.containerCard>
        <div>
          <div>
            <div style={{ marginTop: 20, marginBottom: 10 }}>Certificado</div>
            <S.RankingTitle />
            <div style={{ width: '100%', border: '1px solid #d1d1d1' }} />
          </div>
          <div
            style={{
              background: theme.palette.system.overlay,
              padding: 15,
              marginTop: 20,
              borderRadius: 4,
              boxShadow: `${theme.palette.system.shadow} 0px 1px 4px`,
            }}
          >
            <TableLocal
              columns={formatTable(columnsConquistas)}
              data={certificados}
              sortBy={sortBy}
              permitIsSortedOccur
              tableType
              actions={[
                {
                  name: 'ver conteúdo',
                  text: 'Ver conteúdo',
                  action: async val => {
                    setLoading(true);
                    const id = certificados?.find(
                      item => Number(item.id) === Number(val),
                    ).id_conteudo;
                    const res = await showContent(id);
                    if (res.success) {
                      setCurentContent(res.data.content);
                      setOpenPreviewFull(true);
                      setLoading(false);
                    }
                  },
                },
                {
                  name: 'baixar',
                  text: 'Baixar certificado',
                  action: val => baixarCertificado(val),
                },
              ]}
              setSelectedData={setSelectedRow}
              setSelectedRow={setSelectedClass}
              bulkActions={bulkActions}
              empty={{
                image: '',
                title: 'Seus certificados aparecerão aqui!',
                subtitle: '  ',
              }}
              search={{
                inputLabel: 'Buscar Cliente',
                keys: ['titulo'],
              }}
            />
          </div>
        </div>
      </S.Container>
    </>
  );
};

export default Certificados;
