export function isValidHHMMFormat(timeString) {
  const regex = /^([01]\d|2[0-3]):[0-5]\d$/;
  return regex.test(timeString);
}

export function convertHHMMToMinutes(hhmm) {
  const [hours, minutes] = hhmm.split(':').map(Number);
  return hours * 60 + minutes;
}

export function convertMinutesToHHMM(minutes) {
  const hours = Math.floor(minutes / 60);
  const mins = minutes % 60;
  // Formata com dois dígitos
  return `${String(hours).padStart(2, '0')}:${String(mins).padStart(2, '0')}`;
}
