import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
`;

export const Circle = styled.div`
  width: 6rem;
  height: 6rem;
  border-radius: 3rem;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #939aab;
  box-shadow: 0px 3px 6px #00003229;
  font-family: Texta;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  span {
    font-size: 4rem;
    font-weight: bold;
    color: white;
    text-transform: uppercase;
  }
`;

export const EditButton = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 1.75rem;
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1rem;
  background: #4b5166;
  color: #fff;
  border: 2px solid #fff;
  box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.8);
  cursor: pointer;

  input {
    display: none;
  }
  label {
    cursor: pointer;
  }
`;
