import styled from 'styled-components';

export const HiddenContainer = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  overflow: hidden;
  visibility: hidden;
  z-index: -999;
`;

export const Container = styled.div`
  @media all {
    .page-break {
      display: none;
    }
  }

  @media print {
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-top: 1rem;
      display: block;
      page-break-before: always;
    }
  }

  @page {
    size: auto;
    margin: 10mm;
  }
`;

export const FilterContainer = styled.div`
  display: flex;
  gap: 0.5rem;
  margin-top: 0.5rem;
`;

export const FilterCard = styled.div`
  border: 1px solid #939aab4d;
  border-radius: 4px;
  font: normal normal normal 10px/24px Texta;
  letter-spacing: 0px;
  color: #656e8c;
  padding: 0.5rem;
`;
