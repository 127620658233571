import React, { useState } from 'react';
import { useTheme } from 'styled-components';
import { Controller, useFieldArray } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import { Divider, Grid, Tooltip } from '@mui/material';
import Select from 'components/Inputs/Select';
import SelectSearch from 'components/Inputs/SelectSearch';
import TextInput from 'components/Inputs/TextField';
import Switch from '../components/Switch';
import AddIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import HelpIcon from '@mui/icons-material/HelpOutlineOutlined';
import { useQuery } from 'react-query';
import * as services from '../../services';
import { DashedButton } from 'components/Buttons/Dashed';
import Condicoes from '../components/Condicoes';
import Criticidade from '../components/Criticidade';
import Collapse from '../components/Collapse';
import InfoCard from 'components/Cards/InfoCard';
import InputMask from 'react-input-mask';
import * as S from '../styled';
import {
  convertHHMMToMinutes,
  convertMinutesToHHMM,
  isValidHHMMFormat,
} from './utils';

const ID_DIRECAO = 1104;
const ID_DESCANSO = 1105;
const ID_DESC_CONTINUO = 1106;
const ID_DIRECAO_PERSONALIZADA = 1161;
const ID_DESCANSO_PERSONALIZADA = 1162;
const ID_DESC_CONTINUO_PERSONALIZADA = 1163;
const IDs_JORNADA_PERSONALIZADA = [
  ID_DIRECAO_PERSONALIZADA,
  ID_DESCANSO_PERSONALIZADA,
  ID_DESC_CONTINUO_PERSONALIZADA,
];

const LABELS_PERSONALIZADAS = {
  [ID_DIRECAO_PERSONALIZADA]: 'Tempo máximo de direção',
  [ID_DESCANSO_PERSONALIZADA]: 'Tempo mínimo de descanso (a cada 24h)',
  [ID_DESC_CONTINUO_PERSONALIZADA]:
    'Tempo mínimo de descanso contínuo (a cada 24h)',
};

const MINUTOS_PADRAO = {
  [ID_DIRECAO]: 330,
  [ID_DESCANSO]: 660,
  [ID_DESC_CONTINUO]: 480,
};

const Automaticos = ({
  control,
  watch,
  setValue,
  errors,
  disableForm,
  customDisableForm,
  isProvider,
}) => {
  const theme = useTheme();
  const params = useParams();
  const [changed, setChanged] = useState(false);
  const [enableFields, setEnableFields] = useState([]);
  const { data: tiposPadrao = [] } = useQuery(
    ['desvios-configuracoes-padrao'],
    () => services.getTiposPadrao('AUTOMATICO'),
    { staleTime: Infinity },
  );

  const { data: _tiposVeiculos = [] } = useQuery(
    ['tipos-veiculos-telemetria'],
    () => services.getTiposVeiculos(),
    { staleTime: Infinity },
  );

  const tiposVeiculos = [
    { value: null, name: 'Todos' },
    ..._tiposVeiculos
      .filter(veiculo => veiculo.id >= 0)
      .map(tipo => ({
        name: tipo.tipo,
        value: tipo.id,
      })),
  ];

  const tipos = tiposPadrao
    .filter(tipo => tipo.id != 1 && tipo.id != 2)
    .map(tipo => ({ name: tipo.nome, value: tipo.id }));

  const { fields, append, remove } = useFieldArray({
    name: 'desvios_tipos',
    control,
  });

  return (
    <>
      <br />
      <Divider />

      {fields.map((field, index) => {
        const canRemove = !field.createdAt && fields.length > 1;
        const idTipo = watch(`desvios_tipos[${index}].id_desvio_tipo_padrao`);
        const tipoPadrao = tiposPadrao.find(t => t.id === idTipo);
        const path = `desvios_tipos[${index}]`;
        const auto_intervalo_tempo = tipoPadrao?.intervalo_tempo || null;
        const auto_quantidade = tipoPadrao?.quantidade || null;
        const auto_tempo_duracao = tipoPadrao?.tempo_duracao || null;
        let hasAuto = false;
        let enabledField = enableFields.some(t => t === index);

        if (auto_intervalo_tempo || auto_quantidade || auto_tempo_duracao)
          hasAuto = true;

        // changed pode ser 0
        if (changed != null && changed !== false && changed == index) {
          setValue(
            `${path}.id_tipo_veiculo`,
            tipoPadrao?.id_tipo_veiculo != null
              ? tipoPadrao.id_tipo_veiculo
              : null,
          );
          setValue(
            `${path}.quantidade`,
            tipoPadrao?.quantidade != null ? tipoPadrao.quantidade : null,
          );
          setValue(
            `${path}.tempo_duracao`,
            tipoPadrao?.tempo_duracao != null ? tipoPadrao.tempo_duracao : null,
          );
          setValue(
            `${path}.intervalo_tempo`,
            tipoPadrao?.intervalo_tempo != null
              ? tipoPadrao.intervalo_tempo
              : null,
          );

          if ([ID_DIRECAO, ID_DESCANSO, ID_DESC_CONTINUO].includes(idTipo)) {
            setValue(`${path}.limiar`, MINUTOS_PADRAO[idTipo]);
          } else {
            setValue(`${path}.limiar`, null);
          }
          setChanged(false);
        }

        return (
          <Collapse
            key={field.id}
            handleRemove={() => {
              remove(index);
              setEnableFields([]);
            }}
            canRemove={!disableForm && canRemove}
            last={index + 1 === fields.length}
            title={`Desvio ${index > 0 ? `(${index + 1})` : ''}
              ${field?.titulo ? ` - ${field.titulo}` : ''}
            `}
            switchProps={{
              control,
              name: `desvios_tipos[${index}].ativo`,
              disabled: disableForm,
            }}
          >
            <Grid container spacing={2}>
              <Grid item xs={3} sm={1.5}>
                <div
                  style={{
                    display: 'flex',
                    alignContent: 'center',
                    paddingTop: '2.5rem',
                  }}
                >
                  <Switch
                    disabled={disableForm || customDisableForm}
                    name={`desvios_tipos[${index}].ativo`}
                    control={control}
                  />
                </div>
              </Grid>

              <Grid item xs={9} sm={4.5}>
                <Controller
                  rules={{
                    required: {
                      value: !customDisableForm,
                      message: 'Campo obrigatório.',
                    },
                  }}
                  control={control}
                  name={`desvios_tipos[${index}].id_desvio_tipo_padrao`}
                  render={({ field: { onChange, name, ref, value } }) => (
                    <SelectSearch
                      required={!customDisableForm}
                      disabled={disableForm || customDisableForm}
                      ref={ref}
                      name={name}
                      value={value || ''}
                      handleChange={e => {
                        setChanged(index);
                        onChange(e);
                      }}
                      error={
                        !!errors.desvios_tipos?.[index]?.id_desvio_tipo_padrao
                      }
                      message={
                        errors.desvios_tipos?.[index]?.id_desvio_tipo_padrao
                          ?.message
                      }
                      data={tipos}
                      label="Selecione o tipo de desvio"
                      searchPlaceHolder="Buscar desvio"
                      confirm
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  rules={{
                    required: {
                      value: !customDisableForm,
                      message: 'Campo obrigatório.',
                    },
                  }}
                  control={control}
                  name={`desvios_tipos[${index}].titulo`}
                  render={({ field }) => (
                    <TextInput
                      required={!customDisableForm}
                      disabled={disableForm || customDisableForm}
                      error={!!errors.desvios_tipos?.[index]?.titulo}
                      message={errors.desvios_tipos?.[index]?.titulo?.message}
                      label="Título do desvio"
                      placeholder="Insira um título"
                      {...field}
                    />
                  )}
                />
              </Grid>

              {!params?.id && hasAuto && (
                <InfoCard
                  message="As parametrizações abaixo são recomendadas com base em números reais de desvios e acidentes. Deseja editar mesmo assim?"
                  key="info"
                  button="EDITAR"
                  onClick={() => setEnableFields(state => [...state, index])} //
                />
              )}

              {IDs_JORNADA_PERSONALIZADA.includes(tipoPadrao?.id) && (
                <Controller
                  rules={{
                    required: {
                      value:
                        tipoPadrao?.id &&
                        IDs_JORNADA_PERSONALIZADA.includes(tipoPadrao.id),
                      message: 'Campo obrigatório.',
                    },
                    validate: {
                      isNumeric: value =>
                        !isNaN(value) || 'O valor é inválido.',
                      maxMinutes: value =>
                        value <= 1440 || 'O valor não pode exceder 24 horas.',
                      positive: value =>
                        value >= 0 || 'O valor deve ser maior ou igual a 0.',
                    },
                  }}
                  control={control}
                  name={`desvios_tipos[${index}].limiar`}
                  render={({ field }) => {
                    const _value =
                      typeof field.value == 'number'
                        ? convertMinutesToHHMM(field.value)
                        : field.value;
                    return (
                      <Grid item xs={12} sm={6}>
                        <InputMask
                          mask="99:99"
                          disabled={disableForm || customDisableForm}
                          {...field}
                          required={
                            tipoPadrao?.id &&
                            IDs_JORNADA_PERSONALIZADA.includes(tipoPadrao.id)
                          }
                          label={LABELS_PERSONALIZADAS[tipoPadrao?.id]}
                          placeholder="hh:mm"
                          value={_value || ''}
                          onChange={e => {
                            if (isValidHHMMFormat(e.target.value)) {
                              field.onChange(
                                convertHHMMToMinutes(e.target.value),
                              );
                            } else field.onChange(e.target.value);
                          }}
                        >
                          {inputProps => (
                            <TextInput
                              {...inputProps}
                              error={!!errors.desvios_tipos?.[index]?.limiar}
                              message={
                                errors.desvios_tipos?.[index]?.limiar?.message
                              }
                            />
                          )}
                        </InputMask>
                      </Grid>
                    );
                  }}
                />
              )}

              <Controller
                control={control}
                name={`desvios_tipos[${index}].quantidade`}
                render={({ field }) => {
                  return (
                    tipoPadrao?.quantidade !== 0 && (
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          type="number"
                          disabled={
                            disableForm ||
                            (!enabledField && hasAuto) ||
                            customDisableForm
                          }
                          inputProps={{ min: 0 }}
                          error={!!errors.desvios_tipos?.[index]?.quantidade}
                          message={
                            errors.desvios_tipos?.[index]?.quantidade?.message
                          }
                          label="Quantidade de desvios"
                          placeholder="Insira a quantidade"
                          {...field}
                          onChange={({ target }) =>
                            field.onChange(
                              target.value ? parseInt(target.value) : '',
                            )
                          }
                          value={field.value || auto_quantidade}
                        />
                      </Grid>
                    )
                  );
                }}
              />

              <Controller
                rules={{
                  required: {
                    value:
                      tipoPadrao?.intervalo_tempo == null && !customDisableForm,
                    message: 'Campo obrigatório.',
                  },
                }}
                control={control}
                name={`desvios_tipos[${index}].intervalo_tempo`}
                render={({ field }) => {
                  return (
                    tipoPadrao?.intervalo_tempo !== 0 && (
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          required={
                            !tipoPadrao?.intervalo_tempo && !customDisableForm
                          }
                          disabled={
                            disableForm ||
                            (!enabledField && hasAuto) ||
                            customDisableForm
                          }
                          type="number"
                          inputProps={{ min: 0 }}
                          error={
                            !!errors.desvios_tipos?.[index]?.intervalo_tempo
                          }
                          message={
                            errors.desvios_tipos?.[index]?.intervalo_tempo
                              ?.message
                          }
                          label={
                            <span>
                              Intervalo de tempo (min.){' '}
                              <Tooltip
                                title="Tempo de tolerância antes que o desvio comece a ser contabilizado."
                                arrow
                              >
                                <HelpIcon sx={{ fontSize: '1rem' }} />
                              </Tooltip>
                            </span>
                          }
                          placeholder="Insira o intervalo de tempo"
                          {...field}
                          value={field.value || auto_intervalo_tempo}
                          onChange={({ target }) =>
                            field.onChange(
                              target.value ? parseInt(target.value) : null,
                            )
                          }
                        />
                      </Grid>
                    )
                  );
                }}
              />

              <Controller
                rules={{
                  required: {
                    value:
                      tipoPadrao?.tempo_duracao == null && !customDisableForm,
                    message: 'Campo obrigatório.',
                  },
                }}
                control={control}
                name={`desvios_tipos[${index}].tempo_duracao`}
                render={({ field }) => {
                  return (
                    tipoPadrao?.tempo_duracao !== 0 && (
                      <Grid item xs={12} sm={6}>
                        <TextInput
                          required={
                            tipoPadrao?.tempo_duracao == null &&
                            !customDisableForm
                          }
                          disabled={
                            disableForm ||
                            (!enabledField && hasAuto) ||
                            customDisableForm
                          }
                          type="number"
                          inputProps={{ min: 0 }}
                          error={!!errors.desvios_tipos?.[index]?.tempo_duracao}
                          message={
                            errors.desvios_tipos?.[index]?.tempo_duracao
                              ?.message
                          }
                          label="Tempo de duração do desvio (seg.)"
                          placeholder="Insira o tempo de duração"
                          {...field}
                          value={field.value || auto_tempo_duracao}
                          onChange={({ target }) =>
                            field.onChange(
                              target.value ? parseInt(target.value) : null,
                            )
                          }
                        />
                      </Grid>
                    )
                  );
                }}
              />

              <Controller
                control={control}
                name={`desvios_tipos[${index}].id_tipo_veiculo`}
                render={({ field: { onChange, name, ref, value } }) => {
                  return (
                    tipoPadrao?.id_tipo_veiculo !== 0 && (
                      <Grid item xs={12} sm={6}>
                        <Select
                          ref={ref}
                          disabled={
                            disableForm ||
                            tipoPadrao?.id_tipo_veiculo != null ||
                            customDisableForm
                          }
                          name={name}
                          value={value}
                          handleChange={onChange}
                          error={
                            !!errors.desvios_tipos?.[index]?.id_tipo_veiculo
                          }
                          data={tiposVeiculos}
                          label="Tipo de veículo"
                        />
                      </Grid>
                    )
                  );
                }}
              />

              <Grid item xs={12} sm={6}>
                <Controller
                  rules={{
                    required: {
                      value: !customDisableForm,
                      message: 'Campo obrigatório.',
                    },
                  }}
                  control={control}
                  name={`desvios_tipos[${index}].pontos`}
                  render={({ field }) => (
                    <TextInput
                      required={!customDisableForm}
                      disabled={disableForm}
                      type="number"
                      inputProps={{ min: 0 }}
                      error={!!errors.desvios_tipos?.[index]?.pontos}
                      message={errors.desvios_tipos?.[index]?.pontos?.message}
                      label="Pontuação"
                      placeholder="Insira a pontuação"
                      {...field}
                      onChange={({ target }) =>
                        field.onChange(
                          target.value ? parseInt(target.value) : '',
                        )
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Criticidade
                  name={`desvios_tipos[${index}].criticidade`}
                  control={control}
                  errors={errors}
                  disableForm={disableForm}
                />
                {errors.desvios_tipos?.[index]?.criticidade?.message && (
                  <span
                    style={{
                      color: theme.palette.semantics.feedback.attention.dark,
                      margin: '10px 0',
                    }}
                  >
                    {errors.desvios_tipos?.[index]?.criticidade?.message}
                  </span>
                )}
              </Grid>

              <Grid item xs={12} sm={12}>
                <Condicoes
                  path={`desvios_tipos[${index}]`}
                  control={control}
                  disableForm={disableForm || customDisableForm}
                />
              </Grid>
            </Grid>
          </Collapse>
        );
      })}
      <DashedButton
        type="button"
        disabled={disableForm || customDisableForm}
        onClick={() =>
          append({ ativo: true, plano_de_acao: '', id_tipo_veiculo: null })
        }
      >
        <AddIcon />
        ADICIONAR DESVIO
      </DashedButton>
    </>
  );
};

export default Automaticos;
