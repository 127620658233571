import React, { useEffect, useState } from 'react';
import { useTheme } from 'styled-components';

import { Modal } from '@mui/material';

import { Grid, IconButton } from '@mui/material';
import { EditOutlined } from '@mui/icons-material';
import Icon from 'components/Icons';
import GhostButton from 'components/Buttons/Ghost';
import Switch from 'components/Inputs/_withController/Switch';
import TextField from 'components/Inputs/_withController/TextField';

import * as S from './styled';
import * as services from '../../services';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';

const defaultValues = {
  id: '',
  titulo: '',
  pontos: '',
  pontos_acao_suspensao: '',
  ativo_acao_suspensao: false,
};

const ModalPontuacao = ({ registro, handleClose, open, refetch }) => {
  const theme = useTheme();
  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({ defaultValues });

  useEffect(() => {
    if (registro)
      reset({
        ...registro,
      });
  }, [registro]);

  const onSubmit = async data => {
    try {
      setLoading(true);
      const req = await services.updatePontuacao(data.id, {
        ...data,
        pontos_acao_suspensao: Number(data.pontos_acao_suspensao),
      });
      if (req?.success) {
        toast.success(req?.message);
        refetch();
        handleClose();
      } else toast.error(req?.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.warn('Erro ao salvar.', error);
    }
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <S.Container>
          <S.Header>
            <div style={{ alignItems: 'center' }}>
              <EditOutlined
                sx={{ color: theme.palette.brand.secondary.natural }}
                fontSize="medium"
              />
              <h2>Editar Pontuação Ações e Suspensões</h2>
            </div>

            <IconButton size="small" onClick={handleClose}>
              <Icon
                sx={{ color: theme.palette.words.subtitle.natural }}
                name="close"
              />
            </IconButton>
          </S.Header>
          <S.Main>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12}>
                <TextField
                  control={control}
                  name="titulo"
                  disabled
                  label="Desvio"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  control={control}
                  name="pontos"
                  disabled
                  type="number"
                  label="Pontuação do desvio"
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <TextField
                  required
                  control={control}
                  name="pontos_acao_suspensao"
                  type="number"
                  inputProps={{ min: 0 }}
                  errors={errors}
                  label="Pontuação Ações e Suspenções"
                  placeholder="Insira a pontuação"
                  rules={{
                    required: { value: true, message: 'Campo obrigatório.' },
                  }}
                />
              </Grid>

              <Grid item xs={12} sm={12}>
                <Switch
                  name="ativo_acao_suspensao"
                  control={control}
                  textOn="Ativo"
                  textOff="Inativo"
                />
              </Grid>
            </Grid>
          </S.Main>

          <S.Footer>
            <div>
              <GhostButton
                customcolor={
                  theme?.palette?.semantics?.feedback?.attention?.natural
                }
                onClick={handleClose}
                size="medium"
              >
                Cancelar
              </GhostButton>

              <GhostButton
                type="submit"
                size="medium"
                sx={{ marginLeft: '20px' }}
                loading={loading}
              >
                Salvar
              </GhostButton>
            </div>
          </S.Footer>
        </S.Container>
      </form>
    </Modal>
  );
};

export default ModalPontuacao;
