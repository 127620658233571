import styled, { css } from 'styled-components';

export const ModalExportacaoContainer = styled.div`
  ${({ theme }) => css`
    position: absolute;
    width: 640px;
    background-color: ${theme.palette.system.overlay};
    border: 1px ${theme.palette.system.divider};
    border-radius: 1px;
    padding: 25px 25px;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`;

export const TituloContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
  width: 576px;
  flex-direction: row;
  margin-bottom: 24px;
`;

export const TextoTitulo = styled.p`
  font-weight: 800;
  font-size: 18px;
  line-height: 25.2px;
  color: #424449;
  align: center;
`;

export const BotaoFechar = styled.button`
  padding-bottom: 10px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  position: absolute;
  right: 10px;
  transition: color 0.3s ease;
  width: 10px;
  height: 10px;

  &:focus {
    outline: none;
  }

  &:hover img {
    opacity: 0.7;
  }
`;

export const ArquivoEFormatoLinhaContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  margin-bottom: 24px;
`;

export const ArquivoContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  margin-right: 16px;
`;

export const ArquivoLabel = styled.label`
  text-align: left;
  display: block;
  margin-bottom: 2px;
  color: #424449;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
`;

export const ArquivoInput = styled.input`
  padding-right: 8px;
  padding-left: 8px;
  width: 280px;
  height: 40px;
  gap: 8px;
  box-sizing: border-box;
  margin: 0;
  background-color: #edf2f9;
  border: none;
  border-radius: 5px;
  color: #939aab;
  font-weight: 500;
  font-size: 18px;
  line-height: 25.2px;
`;

export const FormatoContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const FormatoLabel = styled.label`
  text-align: left;
  display: block;
  margin-bottom: 2px;
  color: #424449;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
`;

export const FormatoInput = styled.input`
  padding-right: 8px;
  padding-left: 8px;
  width: 280px;
  height: 40px;
  gap: 8px;
  box-sizing: border-box;
  margin: 0;
  background-color: #edf2f9;
  border: none;
  border-radius: 5px;
  color: #939aab;
  font-weight: 500;
  font-size: 18px;
  line-height: 25.2px;
`;

export const BotoesContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: row;
  justify-content: flex-end;
  width: 576px;
`;

export const FiltroContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  margin-bottom: 24px;
`;

export const FiltroLabel = styled.label`
  text-align: left;
  display: block;
  margin-bottom: 2px;
  color: #424449;
  font-weight: 500;
  font-size: 14px;
  line-height: 19.6px;
`;

export const FiltroInput = styled.input`
  padding-right: 8px;
  padding-left: 8px;
  width: 576px;
  height: 40px;
  gap: 8px;
  box-sizing: border-box;
  margin: 0;
  background-color: #edf2f9;
  border: none;
  border-radius: 5px;
  color: #939aab;
  font-weight: 500;
  font-size: 18px;
  line-height: 25.2px;
  position: relative;
`;

export const FiltersWrapper = styled.div`
  width: 100%;
  height: 250px;
  overflow-y: auto;
`;
