import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';

import { ItemDetailTable } from 'components/_Table/templates/ItemDetail';
import { Detail } from './components';

import { trackEvent } from 'utils/mixpanel';
import { usePlans } from 'hooks/usePlans';

import { requestDesvios, requestCount } from '../services';
import { columns, columnsEmbarcador } from '../columns';

export const ListaPreview = forwardRef(
  (
    {
      setTableData,
      setSelectedInfractions,
      loadingLines,
      setLoadingLines,
      setIsFetching,
      setLastUpdated,
      getActions,
      getBulkActions,
      handleOpenNewTab,
    },
    ref,
  ) => {
    const { hasTorrePlus, hasTorre, isProvider, isOpLogistico } = usePlans();

    const user = useSelector(state => state.auth?.user?.user);
    const filtersPersist = useSelector(state => {
      return state?.filter?.filters?.desvios;
    });

    const filterDesvios = useSelector(state => state.filterDesvios);

    const [resetTable, setResetTable] = useState(false);
    const [query, setQuery] = useState(null);

    const {
      refetch: fetchData,
      isFetching,
      data: resData,
    } = useQuery(['desvios', query], () => query && requestDesvios(query), {
      refetchOnWindowFocus: false,
      refetchInterval: hasTorrePlus || hasTorre ? 60000 : false,
      onSuccess: resData => {
        setTableData(resData?.data?.data || []);
        setLoadingLines([]);
        resetTable && setResetTable(false);
      },
      onError: () => {
        setTableData([]);
      },
      onSettled: () => {
        const currentTime = new Date();
        setLastUpdated(currentTime);
      },
    });

    useEffect(() => {
      setIsFetching(isFetching);
    }, [isFetching]);

    const initial = useRef(null);
    useEffect(() => {
      if (initial.current) {
        initial.current = false;
        return;
      }

      setResetTable(true);
    }, [filtersPersist, filterDesvios.status]);

    // Atualiza totalizador apenas quando muda filtros
    const {
      refetch: fetchCount,
      isFetching: loadingCount,
      data: resCount,
    } = useQuery(['count-desvios', query], () => requestCount(query), {
      refetchOnWindowFocus: false,
      refetchInterval: hasTorrePlus || hasTorre ? 60000 : false,
      staleTime: 0,
      enabled: !initial.current,
    });

    useImperativeHandle(ref, () => ({
      refetchGeral: () => {
        fetchCount();
        fetchData();
      },
      refetchCount: () => fetchCount(),
      refetchLista: () => fetchData(),
    }));

    // Atualiza as colunas de acordo com o status selecionado
    const handleColumns = () => {
      let _columns = isProvider && !isOpLogistico ? columnsEmbarcador : columns;
      if (isProvider && hasTorrePlus)
        _columns = [
          {
            header: 'Motorista/Transp',
            id: 'motorista_nome',
            type: 'string',
            tooltip: true,
            sort: true,
            conditional: [
              {
                condition: (_, item) => !!item.id_motorista,
                style: theme => ({
                  color: theme?.palette?.words.title.natural,
                }),
              },
              {
                condition: (_, item) => !item.id_motorista,
                style: theme => ({
                  color: theme?.palette?.words.subtitle.light,
                }),
              },
            ],
            subRow: {
              value: (_, item) => item?.empresa_nome,
            },
          },
          ..._columns.slice(1),
        ];

      if (filterDesvios.status === 'FINALIZADO')
        _columns[4] = {
          header: 'Data Conclusão',
          id: 'data_encerramento',
          type: 'date',
          sort: true,
        };
      else if (filterDesvios.status === 'DELETADO')
        _columns[4] = {
          header: 'Data Exclusão',
          id: 'data_exclusao',
          type: 'date',
          sort: true,
        };

      return _columns;
    };

    return (
      <div>
        <ItemDetailTable
          data={resData?.data?.data || []}
          columns={handleColumns()}
          setSelectedRows={
            ['ABERTO', 'PENDENTE'].includes(filterDesvios.status) &&
            (!isProvider || isOpLogistico || hasTorrePlus)
              ? setSelectedInfractions
              : null
          }
          loading={isFetching || loadingCount}
          loadingCounter={loadingCount}
          pageCount={resCount?.data?.total || 0}
          local={false}
          bulk={getBulkActions()}
          actions={getActions()}
          reset={resetTable}
          loadingSelection={loadingLines}
          setQuery={q =>
            setQuery({
              ...query,
              ...filtersPersist,
              ...q,
              status: filterDesvios.status,
            })
          }
          searchEvent={search =>
            trackEvent(user, 'Busca Desvios de Direção', null, search)
          }
          sortBy={{ id: 'data_desvio', order: 'DESC' }}
          placeholder={`Buscar por ID, ID frota, placa ou ${
            isProvider && !(isOpLogistico || hasTorrePlus)
              ? 'empresa'
              : 'motorista'
          }`}
          empty={{
            title: 'Ops! Você não tem nenhum desvio disponível.',
            description: 'Verifique os filtros aplicados!',
          }}
          renderDetail={(item, onClose) => (
            <Detail
              infraction={item}
              onClose={onClose}
              actions={getActions()}
              onOpen={handleOpenNewTab}
            />
          )}
        />
      </div>
    );
  },
);
