import { getTiposDesvios, getCriticidade } from 'constants/_SERVICES/provider';

// Pages
import GraficosDesvioProvider from 'pages/Provider/Estatisticas/Desvios';
import GraficosLayoutProvider from 'pages/Estatisticas/LayoutDescarga';

export default {
  '/estatisticas-desvio': {
    date: true,
    defaults: ['empresas', 'motoristas', 'filials'],
    pageFilterName: 'filterEstatisticasDesviosProvider',
    filters: [
      {
        name: 'tipo',
        placeholder: 'Filtrar por Tipo',
        section: 'Desvio',
        mode: 'multiple',
        data: async () => getTiposDesvios(),
      },
      {
        name: 'carga',
        placeholder: 'Filtrar por Criticidade',
        section: 'Desvio',
        mode: 'multiple',
        data: async () => getCriticidade(),
      },
    ],
    page: <GraficosDesvioProvider />,
  },

  '/estatisticas-layout': {
    defaults: [],
    title: 'Layouts',
    page: <GraficosLayoutProvider />,
  },
};
