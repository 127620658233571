import React, { useEffect, useRef, useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';

// Components
import ListPage from '../../components/ListPage';

// Services
import { trackEvent } from 'utils/mixpanel';
import { requestAcoesSuspensoes, requestCards } from './services';
import { columns } from './constants';
import { Faixas } from '../../components/Faixas';

export const AcoesSuspensoes = () => {
  // Redux e hooks
  const params = useParams();
  const user = useSelector(state => state.auth?.user?.user);
  const clients = useSelector(state => state.selects.clients);

  // General States
  const [query, setQuery] = useState(null);
  const [tab, setTab] = useState('');
  const [resetTable, setResetTable] = useState(false);
  const [loadingTab, setLoadingTab] = useState(true);
  const [loadingLines, setLoadingLines] = useState([]);

  const [selectedCard, setSelectedCard] = useState(null);

  const actions = [
    {
      title: 'Abrir em nova guia',
      function: id => handleOpenNewTab(id),
    },
  ];

  // -------------------------- REQUESTS ------------------------------------------//

  const {
    refetch: fetchData,
    isFetching,
    data: resData,
  } = useQuery(
    ['acoes-suspensoes', params?.id, query],
    () => query && requestAcoesSuspensoes(params?.id, query),
    {
      refetchOnWindowFocus: false,
      onSuccess: () => {
        setLoadingLines([]);
        setLoadingTab(false);
        resetTable && setResetTable(false);
      },
    },
  );

  // Atualiza cards de acordo com os filtros selecionados
  // Atualiza tabela após cards
  const {
    refetch: fetchCards,
    isLoading: loadingCards,
    data: resCards,
  } = useQuery(
    [
      'cards-acoes-suspensoes',
      params?.id,
      {
        client: tab,
      },
    ],
    () =>
      requestCards(params?.id, {
        client: tab,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
  }, [tab, selectedCard]);

  // Troca do tab
  const handleChangeTab = async (event, newValue) => {
    setSelectedCard(null);
    setLoadingTab(true);
    setTab(newValue);
  };

  // Só habilita clique no card quando nao há nada carregando
  // Verifica se card clicado há valor válido: nao nulo e maior que 0.
  const handleClickCard = type => {
    if (!loadingCards && !isFetching) {
      const cards = resCards || [];
      const card = cards.find(item => item.type === type);

      if (card.value) {
        if (type === selectedCard) setSelectedCard(null);
        else setSelectedCard(type);
      }
    }
  };

  const handleOpenNewTab = id => {
    return window.open(
      `/acoes-suspensoes/${params.id}?id_acao=${id}${
        tab ? `&id_cliente=${tab}` : ''
      }`,
    );
  };

  // Configura as tabs da tela
  const headerTabs = {
    last: false,
    value: tab,
    items: [
      { value: '', label: 'Minhas ações' },
      ...clients.map(client => ({ value: client.id, label: client.nome })),
    ],
    onChange: handleChangeTab,
    disabled: loadingCards || isFetching,
  };

  return (
    <ListPage
      filterProps={{ hideDate: true, hideExportButton: true }}
      headerTabs={headerTabs}
      cards={
        resCards?.map(card => ({
          ...card,
          disabled: isFetching,
        })) || []
      }
      selectedCard={selectedCard}
      handleClickCard={handleClickCard}
      loadingCards={loadingCards}
      preTable={
        <Faixas
          faixas={resData?.data?.faixasData ?? []}
          idFaixaAtual={resData?.data?.driverData?.id ?? null}
          pontos={resData?.data?.driverData?.pontos ?? null}
        />
      }
      tableProps={{
        data: resData?.data?.data || [],
        columns,
        loading: isFetching || loadingTab,
        pageCount: resData?.data?.count || 0,
        visualizedKey: 'visto',
        local: false,
        actions,
        reset: resetTable,
        onClickRow: handleOpenNewTab,
        loadingSelection: loadingLines,
        setQuery: q =>
          setQuery({
            ...q,
            client: tab,
            status:
              selectedCard && selectedCard === 'acoes_pendentes'
                ? 'PENDENTE'
                : selectedCard && selectedCard === 'acoes_finalizadas'
                ? 'LIBERADO'
                : undefined,
          }),
        searchEvent: search =>
          trackEvent(user, 'Busca Ação Suspensão', null, search),
        sortBy: {
          id: 'pontos',
          order: 'DESC',
        },
        placeholder: `Buscar por ID`,
        empty: {
          title: 'Ops! Você não tem nenhuma ação.',
          description: 'Verifique os filtros selecionados.',
        },
      }}
    />
  );
};
