/* eslint-disable import/order */

// Styles
import * as S from './styled';

// React
import { useMemo, useState } from 'react';

// Components MUI
import { Popover, MenuItem } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

// Context
import { useTable } from '../../../context';
import { _setSelected } from '../../../context/actions';

export const Bulk = ({ visible = 3, disabled, options = [] }) => {
  const [anchor, setAnchor] = useState(null);
  const { state } = useTable();
  const { selected } = state;

  const formatText = useMemo(() => {
    return selected?.length > 1 ? 'selecionados' : 'selecionado';
  }, [selected]);

  if (!selected?.length || !options.length) return null;

  return (
    <S.Container>
      <S.Option aria-readonly disabled={disabled} className="onlyRead">
        {selected.length} {formatText}
      </S.Option>
      {options?.map((option, index) => {
        const show = option?.visible ? option?.visible(selected) : true;

        if (index < visible && show) {
          return (
            <S.Button
              key={index}
              disabled={disabled}
              onClick={() => option?.function(selected)}
              variant={option?.variant}
            >
              {option?.icon}
              {option?.title}
            </S.Button>
          );
        }
        return null;
      })}
      {visible < options?.length && (
        <>
          <S.Option
            disabled={disabled}
            data-testid="more-options"
            onClick={e => setAnchor(e.currentTarget)}
          >
            Mais
            <ArrowDropDownIcon />
          </S.Option>
          <Popover
            id={anchor ? 'popover' : undefined}
            open={Boolean(anchor)}
            anchorEl={anchor}
            onClose={() => setAnchor(null)}
            onClick={() => setAnchor(null)}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
          >
            {options?.map((option, idx) => {
              if (idx >= visible) {
                return (
                  <MenuItem
                    key={idx}
                    disabled={disabled}
                    aria-hidden={!anchor}
                    onClick={() => option?.function(selected)}
                  >
                    <S.Option> {option?.title}</S.Option>
                  </MenuItem>
                );
              }
            })}
          </Popover>
        </>
      )}
    </S.Container>
  );
};
