import api from 'services/api';

// criar um usuario normal
const createUser = async data => {
  const res = await api.post('provider/configuracoes/userExists', data);
  return res;
};

// retorna uma lista de turma
const getTurmas = async pagina => {
  const res = await api.get('/training/turmas', {
    params: {
      pagina,
    },
  });
  return res;
};

// retorna uma turma
const getTurma = async id => {
  const res = await api.get(`/training/turma/${id}`);
  return res;
};

// Pega Lista de atividades da turma
const getTurmaActivities = async id => {
  const res = await api.get(`/training/turma-activities/${id}`);
  return res;
};

// cria a turma
const createTurma = async data => {
  const res = await api.post(`/training/create-turma`, data);
  return res;
};

// atualiza a turma
const updateTurma = async (id, data) => {
  const res = await api.put(`/training/turma/${id}`, data);
  return res;
};

// atualiza o status da turma
const updateStatus = async (id, data) => {
  const res = await api.put(`/training/turma-status/${id}`, data);
  return res;
};

// deleta a turma
const deleteTurma = async id => {
  const res = await api.delete(`/training/turma/${id}`);
  return res;
};

// deleta a relacão turma conteudo/formulario
const deleteActivitieTurma = async body => {
  const res = await api.put(`/training/turma-delete-relation`, body);
  return res;
};

// deleta um ou mais aluno da turma
const deleteAlunoTurma = async data => {
  const res = await api.post(`/training/delete-turma-aluno/`, data);
  return res;
};
// associa alunos a uma turma
const createTurmaAluno = async data => {
  const res = await api.post('/training/create-turma-aluno', data);
  return res;
};

export {
  createUser,
  getTurmas,
  getTurma,
  updateTurma,
  createTurmaAluno,
  updateStatus,
  deleteTurma,
  createTurma,
  deleteAlunoTurma,
  deleteActivitieTurma,
  getTurmaActivities,
};
