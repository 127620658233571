import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  width: 100%;
  align-items: end;
`;

export const Tab = styled.div`
  border: 1px solid #939aab4d;
  border-radius: 4px 4px 0px 0px;
  padding: 0 1.5rem;
  height: ${({ active }) => (active ? '3.5rem' : '3rem')};
  align-content: center;
  background: ${({ active }) => (active ? '#EDF2F9' : 'transparent')};
  cursor: pointer;
  font: normal normal medium 16px/14px Texta;
  letter-spacing: 0px;
  color: #4b5166;
`;
