import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { Grid } from '@mui/material';
import GhostButton from 'components/Buttons/Ghost';
import { useTheme } from 'styled-components';
import Select from 'components/Inputs/Select';
import TextInput from 'components/Inputs/TextField';
import { useNavigate, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import * as S from './styled';
import { requestConfiguration, saveConfiguration } from '../services';
import Footer from 'components/ActionFooter';
import { grupos } from '../constants';
import Automaticos from './Automaticos';
import Velocidades from './Velocidades';
import Manuais from './Manuais';
import Cerca from './Cerca';
import { usePlans } from 'hooks/usePlans';

const defaultValues = {
  titulo: '',
  descricao: '',
  grupo: '',
  ativo: true,
  id_distribuidora: null,
  desvios_tipos: [
    {
      ativo: true,
      titulo: null,
      pontos: '',
      intervalo_tempo: null,
      intervalo_posicoes: null,
      plano_de_acao: '',
      id_desvio_tipo_padrao: null,
      id_tipo_veiculo: null,
      pista_molhada: null,
      carregado: null,
      velocidade_final: 230,
      ativo_acao_suspensao: false,
    },
  ],
};

const EditConfig = ({ novo }) => {
  const navigate = useNavigate();
  const params = useParams();
  const { planosAtivos } = usePlans();
  const { user } = useSelector(state => state.auth);
  const isProvider = user?.user?.provider;
  const [submitting, setSubmitting] = useState(false);
  const theme = useTheme();

  const selects = useSelector(state => {
    return state.selects;
  });

  const {
    control,
    handleSubmit,
    reset,
    watch,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues,
  });

  const clients = [
    { id: 0, value: null, name: isProvider ? '' : 'Todos' },
    ...selects.clients
      ?.filter(
        i =>
          control._fields?.id_distribuidora?.value == i.id ||
          i.status !== 'ATIVO',
      ) // Não pode atribuir parametro para embarcadora ativa
      .map(i => ({ name: i.nome, value: i.id })),
  ] || [{ id: 0, value: null, name: 'Todos' }];

  const { data, error, isLoading, refetch } = useQuery(
    ['configuracao-desvio', params.id],
    () => requestConfiguration(params.id),
    {
      enabled: false,
      refetchOnWindowFocus: false,
    },
  );

  useEffect(() => {
    if (!novo) refetch();
  }, [novo]);

  useEffect(() => {
    if (data) {
      let _data = { ...data };
      delete _data.empresa;
      delete _data.distribuidora;

      if (data.grupo === 'VELOCIDADE') {
        const desvios_tipos = data.desvios_tipos.map((violacao, index) => {
          const proximo_limiar = data.desvios_tipos[index + 1]?.limiar;
          return {
            ...violacao,
            velocidade_final: proximo_limiar ? proximo_limiar - 1 : 230,
          };
        });
        _data = { ..._data, desvios_tipos };
      }

      reset(_data);
    }
  }, [data]);

  const onSubmit = async data => {
    setSubmitting(true);
    try {
      const req = await saveConfiguration({
        ...data,
        ativo: data.desvios_tipos.some(tipo => tipo.ativo),
      });
      setSubmitting(false);

      if (req.success) {
        toast.success(req.message);
        navigate(-1);
      } else toast.error(req.message);
    } catch (error) {
      setSubmitting(false);
      toast.error('Falha ao salvar.');
      console.warn('Erro ao salvar.', error);
    }
  };

  const disableForm = useMemo(() => {
    return !isProvider && data && !data.id_empresa;
  }, [data, isProvider]);

  const customDisableForm = useMemo(() => {
    if (data?.titulo === 'Desvios Onisys') return true;
  }, [data]);

  const grupo = watch(`grupo`);

  const onChangeGroup = useCallback(({ target }) => {
    const titulo = getValues('titulo');
    reset({
      ...defaultValues,
      titulo,
      grupo: target.value,
    });
  }, []);

  return (
    <form
      onSubmit={handleSubmit(onSubmit, e =>
        toast.error('Preencha todos os campos obrigatórios!'),
      )}
    >
      <S.Container>
        <S.Title>
          <h1>{`${novo ? 'Adicionar' : 'Editar'} Grupo`}</h1>
        </S.Title>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12}>
            <S.SectionTitle>
              <h2>Atributos</h2>
            </S.SectionTitle>
          </Grid>
          <Grid item xs={12} sm={12}>
            <Controller
              rules={{
                required: {
                  value: !customDisableForm,
                  message: 'Campo obrigatório.',
                },
              }}
              control={control}
              name="titulo"
              render={({ field }) => (
                <TextInput
                  required={!customDisableForm}
                  disabled={disableForm || customDisableForm}
                  error={!!errors.titulo}
                  message={errors.titulo?.message}
                  label="Título"
                  placeholder="Insira um título"
                  {...field}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} sm={isProvider ? 12 : 6}>
            <Controller
              control={control}
              name="grupo"
              render={({ field: { name, ref, value } }) => (
                <Select
                  ref={ref}
                  disabled={disableForm || !novo || customDisableForm}
                  name={name}
                  value={value}
                  handleChange={onChangeGroup}
                  error={!!errors.grupo}
                  data={grupos.filter(
                    g =>
                      !g.plans ||
                      planosAtivos.some(plan =>
                        g.plans.includes(plan.id_do_plano),
                      ),
                  )}
                  label="Categoria do desvio"
                  placeholder="Selecione a categoria do desvio"
                />
              )}
            />
          </Grid>

          {!isProvider && (
            <Grid item xs={12} sm={6}>
              <Controller
                control={control}
                name="id_distribuidora"
                render={({ field: { onChange, name, ref, value } }) => (
                  <Select
                    disabled={disableForm || customDisableForm}
                    ref={ref}
                    name={name}
                    value={value}
                    handleChange={onChange}
                    error={!!errors?.id_distribuidora}
                    message={errors?.id_distribuidora?.message}
                    data={clients}
                    label="Cliente"
                  />
                )}
              />
            </Grid>
          )}
        </Grid>

        {grupo === 'AUTOMATICO' && (
          <Automaticos
            control={control}
            watch={watch}
            errors={errors}
            disableForm={disableForm}
            customDisableForm={customDisableForm}
            setValue={setValue}
            isProvider={isProvider}
          />
        )}

        {grupo === 'VELOCIDADE' && (
          <Velocidades
            control={control}
            watch={watch}
            errors={errors}
            disableForm={disableForm}
            getValues={getValues}
            setValue={setValue}
            isProvider={isProvider}
          />
        )}

        {grupo === 'CERCA' && (
          <Cerca
            control={control}
            watch={watch}
            errors={errors}
            disableForm={disableForm}
            getValues={getValues}
            isProvider={isProvider}
          />
        )}

        {grupo === 'MANUAL' && (
          <Manuais
            control={control}
            watch={watch}
            errors={errors}
            disableForm={disableForm}
            customDisableForm={customDisableForm}
            isProvider={isProvider}
            setValue={setValue}
          />
        )}
      </S.Container>
      <Footer
        title="Informações Atualizadas"
        actions={
          <>
            <GhostButton
              onClick={() => navigate(-1)}
              customcolor={theme.palette.semantics.feedback.attention.dark}
            >
              Voltar
            </GhostButton>
            <GhostButton
              disabled={disableForm}
              loading={submitting}
              type="submit"
            >
              Salvar
            </GhostButton>
          </>
        }
      />
    </form>
  );
};

export default EditConfig;
