import styled, { css } from 'styled-components';

const optionModifier = {
  hover: ({ theme }) => css`
    color: ${theme.palette.brand.secondary.natural};
  `,
  disabled: () => css`
    cursor: not-allowed;
    opacity: 0.5;
  `,
};

export const Container = styled.div``;

export const Option = styled.button`
  ${({ theme, disabled }) => css`
    padding: 0px 10px;
    color: ${theme.palette.words.subtitle.natural};
    font-weight: 600;
    font-size: 14px;
    border: none;
    background: transparent;
    transition: color 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;

    &::first-letter {
      text-transform: uppercase;
    }

    &:hover {
      ${!disabled && optionModifier.hover}
    }

    &:disabled {
      ${optionModifier.disabled}
    }

    &.onlyRead {
      cursor: default !important;
      color: ${theme.palette.words.title.natural} !important;
    }
  `}
`;
