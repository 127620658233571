import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Tabs from 'pages/Desvios/Estatisticas/DesempenhoGeral/components/Tabs';
import { Grid } from '@mui/material';
import Loading from 'components/Loading';
import TextInput from 'components/Inputs/TextField';
import Select from 'components/Inputs/Select';
import DefaultButtonPopover from 'components/Buttons/DefaultPopover';
import * as C from '../styled';
import * as S from './styled';
import * as api from './services';
import { useTheme } from 'styled-components';

// Components
import { Switch } from 'components/Inputs/Switch';
import GhostButton from 'components/Buttons/Ghost';

// icons
import { ReactComponent as HistoricoIcon } from 'images/icons/sidebar/historico.svg';
import { sub } from 'date-fns';
import { da } from 'date-fns/locale';

export const Configuracoes = () => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [currentTab, setCurrentTab] = useState(0);
  const [currentSection, setCurrentSection] = useState(0);

  // Parametrizacoes
  const [allParametrizacoes, setAllParametrizacoes] = useState([]);

  useEffect(() => {
    requireData();
  }, []);

  const requireData = async () => {
    const { parametrizacoes } = await api.getParametrizacoes();

    const parametrizacoesData = parametrizacoes.map((item, index) => {
      const categoria = {
        value: index,
        label: item.nome,
        page: item.nome,
        subSections: [],
        itens: [],
        changed: false,
      };

      categoria.itens = item.indicadores;
      return categoria;
    });

    setAllParametrizacoes(parametrizacoesData);
    setLoading(false);
  };

  const handleSave = async (headers, itens) => {
    const updatedItens = itens.filter(item => item.changed);

    if (updatedItens.filter(item => item.value === '').length > 0)
      return toast.warning('É necessário preencher todos os campos!');

    const subItems = [];
    const data = updatedItens.map(item => {
      item?.cards &&
        item?.cards?.forEach(
          (el, index) =>
            el.value &&
            subItems.push({
              parametrizacao_indicador: el.id,
              valor: el.value,
            }),
        );

      return {
        parametrizacao_indicador: item.id,
        valor: item.value,
      };
    });

    if (subItems.length > 0) {
      const res = await api.updateParametrizacao(subItems);
      if (res.success) {
        toast.success(res.message);
        if (headers) {
          setAllParametrizacoes(previous => {
            const newParametrizacoes = [...previous];
            newParametrizacoes[currentTab].subSections[
              currentSection
            ].changed = false;
            return newParametrizacoes;
          });
        } else {
          setAllParametrizacoes(previous => {
            const newParametrizacoes = [...previous];
            newParametrizacoes[currentTab].changed = false;
            return newParametrizacoes;
          });
        }
      }
    }
    const existUndefined = data.find(
      item =>
        item.parametrizacao_indicador === undefined && item.valor === undefined,
    );

    if (data.length > 0 && !existUndefined) {
      const res = await api.updateParametrizacao(data);
      if (res.success) {
        toast.success(res.message);

        if (headers) {
          setAllParametrizacoes(previous => {
            const newParametrizacoes = [...previous];
            newParametrizacoes[currentTab].subSections[
              currentSection
            ].changed = false;
            return newParametrizacoes;
          });
        } else {
          setAllParametrizacoes(previous => {
            const newParametrizacoes = [...previous];
            newParametrizacoes[currentTab].changed = false;
            return newParametrizacoes;
          });
        }
      } else {
        toast.error(res.message);
      }
    }
  };

  const handlePageTab = (event, newValue) => {
    setCurrentTab(newValue);
    setCurrentSection(0);
  };

  const handleChange = (i, k, headers, value, subIndex = null) => {
    if (headers) {
      setAllParametrizacoes(previous => {
        const newParametrizacoes = [...previous];
        newParametrizacoes[currentTab].subSections[
          currentSection
        ].parametrizacoes[k].value = value;
        newParametrizacoes[currentTab].subSections[
          currentSection
        ].parametrizacoes[k].changed = true;
        newParametrizacoes[currentTab].subSections[
          currentSection
        ].changed = true;
        return newParametrizacoes;
      });
    } else {
      setAllParametrizacoes(previous => {
        const newParametrizacoes = [...previous];
        if (subIndex !== null) {
          // Atualizando um sub_indicador fora de headers

          newParametrizacoes[currentTab].itens[k].cards[subIndex].valor_padrao =
            value;
          newParametrizacoes[currentTab].itens[k].cards[subIndex].value = value;

          newParametrizacoes[currentTab].itens[k].changed = true;
        } else {
          // Atualizando um item principal fora de headers
          newParametrizacoes[currentTab].itens[k].value = value;
          newParametrizacoes[currentTab].itens[k].changed = true;
          newParametrizacoes[currentTab].itens[k].valor_padrao = value;
        }
        newParametrizacoes[currentTab].changed = true;

        return newParametrizacoes;
      });
    }
    renderSquare();
  };

  const renderSquare = () => {
    let headers;
    let itens = allParametrizacoes[currentTab].itens;
    let disabledSave = !allParametrizacoes[currentTab].changed;

    if (allParametrizacoes[currentTab].subSections.length > 0) {
      headers = allParametrizacoes[currentTab].subSections;
      itens =
        allParametrizacoes[currentTab].subSections[currentSection]
          .parametrizacoes;
      disabledSave =
        !allParametrizacoes[currentTab].subSections[currentSection].changed;
    }

    const getTitle = data => {
      switch (data) {
        case 'AVALIACOES':
          return 'Avaliações';
        case 'DESVIOS':
          return 'Desvios';
        case 'EVENTOS':
          return 'Eventos';
        case 'CERCA':
          return 'Cerca Eletrônica';
        case 'VELOCIDADE':
          return 'Velocidade';
        case 'ACOES':
          return 'Ações/Suspensões';
        default:
          return data;
      }
    };

    const getSubTitle = (item, k) => {
      if (item.agrupador === 'DESVIOS' && (k === 0 || k === 2)) {
        return k === 0 ? 'DESVIOS DE DIREÇÃO' : 'DESVIOS DE DESCARGA';
      }
      if (item.agrupador === 'ACOES' && (k === 0 || k === 1))
        return k === 0 ? 'AÇÕES DISCIPLINARES' : 'SUSPENSÕES';
      if (item.tipo_valor === 'card' && k === 0) return true;
    };

    const getGridValue = (item, k) => {
      let gridValue =
        itens.length % 2 === 0 ? 6 : itens.length - 1 === k ? 12 : 6;

      if (item.agrupador === 'ACOES') gridValue = 12;

      return gridValue;
    };

    return (
      <S.NotifyBox>
        <div className="boxBody">
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            columnSpacing={2}
            rowSpacing={2}
            marginTop={1}
          >
            {itens.map((i, k) => (
              <>
                <Grid item md={getGridValue(i, k)}>
                  <label>
                    {i.tipo_valor === 'select' && (
                      <div>
                        <Select
                          color={i?.cor || 'secondary'}
                          label={i?.nome || ''}
                          value={i.value ? i.value : i.valor_padrao}
                          placeholder={i?.descricao || ''}
                          onChange={e =>
                            handleChange(i, k, headers, e.target.value)
                          }
                          data={i?.list || []}
                        />
                      </div>
                    )}
                    {i.tipo_valor === 'check' ? (
                      <Select
                        data={[
                          { name: 'SIM', value: 'SIM' },
                          { name: 'NÃO', value: 'NAO' },
                        ]}
                        value={i.value}
                        label={i.nome}
                        placeholder={i.descricao}
                        handleChange={e =>
                          handleChange(i, k, headers, e.target.value)
                        }
                      />
                    ) : (
                      <>
                        {i.tipo_valor !== 'card' &&
                          i.tipo_valor !== 'select' && (
                            <TextInput
                              style={{ backgroundColor: 'transparent' }}
                              label={i.nome}
                              value={i.value}
                              placeholder={i.descricao}
                              onChange={e =>
                                handleChange(i, k, headers, e.target.value)
                              }
                            />
                          )}
                      </>
                    )}
                  </label>
                </Grid>
              </>
            ))}
          </Grid>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            columnSpacing={2}
            rowSpacing={2}
            marginTop={1}
          >
            {itens
              .filter(f => f.tipo_valor === 'card')
              .map((i, k) => {
                // Achar o índice correto do item no array original
                const realIndex = itens.findIndex(item => item === i);

                return (
                  <>
                    {getSubTitle(i, k) && (
                      <Grid
                        item
                        md={12}
                        style={{ marginTop: k !== 0 ? '20px' : '-20px' }}
                      >
                        <p className="subtitle">
                          PRAZO DE VENCIMENTO PARA DESVIOS
                        </p>
                      </Grid>
                    )}

                    <Grid item md={getGridValue(i, k)}>
                      <label>
                        {i.tipo_valor === 'card' && (
                          <S.GridCustom item md={getGridValue(i, k)}>
                            <S.Criticidade color={i.cor}>
                              <p>{i.nome}</p>
                            </S.Criticidade>

                            {i.cards
                              .sort((a, b) => a.card_posicao - b.card_posicao)
                              .map((subI, indexPa) => (
                                <>
                                  {subI.tipo_valor === 'select' && (
                                    <Grid container columnSpacing={1}>
                                      <Grid
                                        item
                                        md={subI.tamanho_horizontal}
                                        key={indexPa}
                                      >
                                        <Select
                                          label={subI?.nome || ''}
                                          value={
                                            subI.value
                                              ? subI.value
                                              : subI.valor_padrao
                                          }
                                          placeholder=""
                                          onChange={e =>
                                            handleChange(
                                              i,
                                              realIndex, // Usa o índice real
                                              headers,
                                              e.target.value,
                                              indexPa,
                                            )
                                          }
                                          data={subI?.list || []}
                                        />
                                      </Grid>
                                    </Grid>
                                  )}

                                  {subI.tipo_valor === 'check' && (
                                    <div
                                      style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        marginTop: '10px',
                                      }}
                                    >
                                      <Switch
                                        check={
                                          subI.value
                                            ? subI.value === 'SIM'
                                            : subI.valor_padrao === 'SIM'
                                        }
                                        setCheckbox={e => {
                                          handleChange(
                                            subI,
                                            realIndex,
                                            headers,
                                            e ? 'SIM' : 'NAO',
                                            indexPa,
                                          );
                                        }}
                                        textOn=""
                                        textOff=""
                                      />
                                      <p className="switchTitle">
                                        {subI?.nome || ''}
                                      </p>
                                    </div>
                                  )}
                                </>
                              ))}
                          </S.GridCustom>
                        )}
                      </label>
                    </Grid>
                  </>
                );
              })}
          </Grid>
          <S.Footer>
            <DefaultButtonPopover
              disabled={disabledSave}
              size="medium"
              onClick={() => handleSave(headers, itens)}
            >
              Salvar
            </DefaultButtonPopover>
          </S.Footer>
        </div>
      </S.NotifyBox>
    );
  };

  return (
    <S.Container>
      {loading ? (
        <div className="boxLoading">
          <Loading />
        </div>
      ) : (
        <>
          <C.TitleArea
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'start',
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <div>
                <span className="textArea">Configurações</span>
              </div>
              <div>
                <GhostButton
                  startIcon={<HistoricoIcon />}
                  size="medium"
                  onClick={() => navigate(`/logs/parametrizacoes`)}
                  sx={{ marginRight: '1rem' }}
                >
                  HISTÓRICO DE ALTERAÇÕES
                </GhostButton>
              </div>
            </div>
            <span className="subTitle">Configure seus prazos e metas</span>
          </C.TitleArea>
          <Grid item marginBottom={3} md={12}>
            <Tabs
              value={currentTab}
              items={allParametrizacoes}
              onChange={handlePageTab}
              width="700px"
            />
          </Grid>
          {renderSquare()}
        </>
      )}
    </S.Container>
  );
};
