import React from 'react';

import * as S from './styled';
import { CircularProgress, Divider } from '@mui/material';
import Loading from 'components/Loading';
import { ArrowForwardIos } from '@mui/icons-material';
import { Info } from './components/Info';
import { Selo } from 'components/Selo';
import { Link } from 'pages/Estatisticas/Components/SingleTable/Link';
import { ReactComponent as RankingIcon } from 'images/icons/motoristas/perfil/header/ranking.svg';
import { useQuery } from 'react-query';
import { requestBasic, requestPosRanking, requestSelo } from './services';

export const Header = ({ id, handleClickExport, print = false }) => {
  const { isLoading: loadingBasic, data: resBasic } = useQuery(
    ['empresa-basic', id],
    () => id && requestBasic(id),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { isLoading: loadingRanking, data: resRanking } = useQuery(
    ['pos-empresa-ranking', id],
    () => id && requestPosRanking(id),
    {
      refetchOnWindowFocus: false,
    },
  );

  const { data: resSelo } = useQuery(
    ['empresa-selo', id],
    () => id && requestSelo(id),
    {
      refetchOnWindowFocus: false,
    },
  );

  const text = resBasic?.nome || '';
  return (
    <>
      <S.Container print={print}>
        <div>
          <S.Avatar>
            {!loadingBasic ? (
              resBasic?.logo_url ? (
                <img src={resBasic.logo_url} alt="Avatar" />
              ) : (
                <span>{text ? text.charAt(0) : ''}</span>
              )
            ) : (
              <Loading />
            )}
          </S.Avatar>
        </div>
        <S.Content>
          <S.Flex>
            <S.Status
              color={
                resBasic?.empresa_distribuidoras?.some(i => i.ativo)
                  ? 'success'
                  : 'attention'
              }
            >
              {resBasic?.empresa_distribuidoras?.some(i => i.ativo)
                ? 'Ativo'
                : 'Inativo'}
            </S.Status>
          </S.Flex>
          <S.Flex>
            <S.Flex className="full-width gap-1">
              <S.Title title={text}>{String(text).toLowerCase()}</S.Title>
              <Selo
                data={{
                  stars: resSelo?.estrelas,
                  seal: !!resSelo?.estrelas,
                  sealText: `${resSelo?.meses || 0} meses`,
                }}
                entidade="Transportador"
              />
            </S.Flex>
            <S.Flex className="mt-1">
              <S.Flex className="gap-05">
                <S.Icon>
                  {loadingRanking ? (
                    <S.LoadingBox>
                      <CircularProgress color="inherit" size={24} />
                    </S.LoadingBox>
                  ) : (
                    <RankingIcon />
                  )}
                </S.Icon>
                <div>
                  <S.Ranking>
                    <span>{resRanking ?? '-'}</span> Posição no ranking
                  </S.Ranking>
                  <Link
                    component="button"
                    onClick={() => window.open(`/empresas/ranking`)}
                  >
                    Ver ranking unificado{' '}
                    <ArrowForwardIos style={{ fontSize: '10px' }} />
                  </Link>
                </div>
              </S.Flex>
            </S.Flex>
          </S.Flex>
          <S.Flex className="full-width text" style={{ gap: '0.5rem' }}>
            <div>
              Razão social:{' '}
              <span className="bold">{resBasic?.razao_social ?? ''}</span>
            </div>
            <div>
              CNPJ: <span className="bold">{resBasic?.cnpj ?? ''}</span>
            </div>
          </S.Flex>
          <Divider />
          <S.Flex className="full-width">
            {!print && (
              <S.Flex className="full-width mt-1 gap-1">
                <Info
                  subtitle={`${resBasic?.endereco?.rua ?? ''} ${
                    resBasic?.endereco?.numero ?? ''
                  } ${
                    resBasic?.endereco?.complemento
                      ? `(${resBasic?.endereco?.complemento})`
                      : ''
                  }`}
                  text={`${resBasic?.endereco?.bairro ?? ''}-${
                    resBasic?.endereco?.cidade ?? ''
                  }, ${resBasic?.endereco?.estado ?? ''}, ${
                    resBasic?.endereco?.cep ?? ''
                  } `}
                />
                <Info text="Contato" title={resBasic?.nome_do_contato ?? '-'} />
                <Info
                  subtitle={resBasic?.email_do_contato ?? '-'}
                  text={resBasic?.telefone_do_contato ?? '-'}
                />
                <Info
                  text="Motoristas"
                  title={resBasic?.total_motoristas ?? '-'}
                />
              </S.Flex>
            )}
          </S.Flex>
        </S.Content>
      </S.Container>
      {print && (
        <S.Flex className="full-width gap-1">
          <Info
            subtitle={`${resBasic?.endereco?.rua ?? ''} ${
              resBasic?.endereco?.numero ?? ''
            } ${
              resBasic?.endereco?.complemento
                ? `(${resBasic?.endereco?.complemento})`
                : ''
            }`}
            text={`${resBasic?.endereco?.bairro ?? ''}-${
              resBasic?.endereco?.cidade ?? ''
            }, ${resBasic?.endereco?.estado ?? ''}, ${
              resBasic?.endereco?.cep ?? ''
            } `}
          />
          <Info text="Contato" title={resBasic?.nome_do_contato ?? '-'} />
          <Info
            subtitle={resBasic?.email_do_contato ?? '-'}
            text={resBasic?.telefone_do_contato ?? '-'}
          />
          <Info text="Motoristas" title={resBasic?.total_motoristas ?? '-'} />
        </S.Flex>
      )}
    </>
  );
};
