// Styled
import { useTheme } from 'styled-components';

// React
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { useEffect, useLayoutEffect, useState } from 'react';

// Compoenents
import ConfirmModal from 'components/ConfirmModal';
import DefaultButton from 'components/Buttons/Default';

// Components Material
import { Divider } from '@mui/material';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleOutlineOutlinedIcon from '@mui/icons-material/CheckCircleOutlineOutlined';

// Utils
import OnisysLogo from 'images/Onisys_Simbolo.png';
import { useNavigate } from 'react-router-dom';
import { items } from '../../items';
import { formatStatusResponse, formatStatusUser } from '../../actions';
import * as services from '../../ParaMim/services';
import * as S from './styled';
import { getMetadata } from '../../../../utils/helpers';

const verfyHasGetPosition = formulario => {
  return formulario?.secoes?.some(secao =>
    secao.campos?.some(campo => campo.id_tipo_campo === 31),
  );
};

const ModalResponse = ({
  open,
  onClose,
  form,
  preview,
  previewWeb,
  backPath,
}) => {
  const theme = useTheme();
  // Redux
  const { user } = useSelector(state => state.auth.user);

  // Capa default Header
  const img = require(`images/defaultFormulario.jpg`);

  // General States
  const [stopIn, setStopIn] = useState(0);
  const [changed, setChanged] = useState(false);
  const [loading, setLoading] = useState(false);
  const [secoes, setSecoes] = useState(form?.form?.secoes);
  const [firstAccess, setFirstAccess] = useState(true);
  const [warningView, setWarningView] = useState(false);
  const [position, setPosition] = useState(null);
  const [positionError, setPositionError] = useState(null);
  const formulario = form?.form;

  // Errors
  const [errosEvidencias, setErrosEvidencias] = useState(null);
  const [errosObrigatorios, setErrosObrigatorios] = useState(null);
  const [saveError, setSaveError] = useState(false);

  // Modals
  const [closeModal, setCloseModal] = useState(false);
  const [finalModal, setFinalModal] = useState(false);
  const selectFileds = [1, 2, 3, 4];

  // Controll back arrow in window when modal is opened in same tab (BETA VERSION)
  const navigate = useNavigate();
  useEffect(() => {
    if (backPath) {
      window.onpopstate = event => {
        event.preventDefault();
        navigate(backPath);
      };
    }
  }, [backPath]);

  useEffect(() => {
    setSecoes(form?.form?.secoes);
    const sec = form?.form?.secoes;
    let hasSaved = 0;
    for (const i in sec) {
      if (sec[i].save) {
        hasSaved++;
      }
    }

    setStopIn(hasSaved);
  }, [form]);

  useLayoutEffect(() => {
    if ((firstAccess && stopIn, formulario?.status === 'Iniciado')) {
      setTimeout(() => {
        const content = document.querySelector(`.contentControllScroll`);
        content.scrollTo({
          top: content.scrollHeight,
          behavior: 'smooth',
        });
      }, 400);
      setFirstAccess(false);
    }
  }, [stopIn]);

  useEffect(() => {
    if (!firstAccess) {
      setChanged(true);
    }
  }, [secoes]);

  useEffect(() => {
    const handleSetPosition = pos => {
      setPosition(`${pos.coords.latitude}, ${pos.coords.longitude}`);
    };
    if (verfyHasGetPosition(formulario) && !position) {
      if ('geolocation' in navigator && 'permissions' in navigator) {
        navigator.permissions.query({ name: 'geolocation' }).then(result => {
          if (result.state === 'granted') {
            // Permissão já concedida, pega a localização direto
            navigator.geolocation.getCurrentPosition(handleSetPosition);
          } else if (result.state === 'prompt') {
            // Permissão ainda não foi solicitada, solicita agora
            navigator.geolocation.getCurrentPosition(
              handleSetPosition,
              error => {
                if (error.code === 1)
                  setPositionError(
                    'Permissão de localização negada pelo usuário.',
                  );
              },
            );
          } else {
            setPositionError('Permissão de localização negada pelo usuário.');
          }
        });
      } else {
        setPositionError('Geolocalização não suportada pelo navegador.');
      }
    }
  }, [formulario]);

  // Modal Controller
  const [visible, setVisible] = useState('100vh');

  useEffect(() => {
    if (open) {
      openModal();
    }
  }, [open]);

  const openModal = () => {
    setTimeout(() => {
      setVisible('0px');
      if (previewWeb) {
        setTimeout(() => {
          setWarningView(true);
        }, 500);
      }
    }, 300);
  };

  const backModal = () => {
    setCloseModal(false);
    setVisible('100vh');
    setWarningView(false);
    setTimeout(() => {
      onClose();
    }, 300);
  };

  // -------------------------------------SET FIELDS---------------------------------------//
  const formatFieldResponse = (field, sectionId, key) => {
    let ResponseComponent = null;

    for (const i in items) {
      const { types } = items[i];
      types.find(item => {
        if (item.id_tipo_campo == field.id_tipo_campo) {
          ResponseComponent = item.ResponseComponent;
        }
      });
    }

    return (
      <ResponseComponent
        field={field}
        secoes={secoes}
        setSecoes={setSecoes}
        sectionId={sectionId}
        key={key}
        errosEvidencias={errosEvidencias}
        errosObrigatorios={errosObrigatorios}
        preview={preview}
        previewWeb={previewWeb}
        selects={form?.selects}
      />
    );
  };

  const atualizarMetadados = async secoes => {
    // Coletar todas as promessas
    const promises = [];
    const arquivosParaAtualizar = [];

    // Identificar todos os arquivos que precisam de metadados
    secoes.forEach(secao => {
      if (!secao.save) return;

      secao.campos.forEach(campo => {
        if (!selectFileds.includes(Number(campo.id_tipo_campo))) return;

        Object.values(campo.opcoes).forEach(opcao => {
          if (!opcao.checked || !opcao.arquivos?.length) return;

          opcao.arquivos.forEach(arquivo => {
            if (arquivo.imagem_url) {
              // Guardar referência ao arquivo e criar promessa
              arquivosParaAtualizar.push(arquivo);
              promises.push(getMetadata(arquivo.imagem_url));
            }
          });
        });
      });
    });

    // Resolver todas as promessas simultaneamente
    const resultados = await Promise.all(promises);

    // Atualizar os arquivos com os resultados
    arquivosParaAtualizar.forEach((arquivo, index) => {
      arquivo.data_evidencia = resultados[index];
    });

    return secoes;
  };

  // -------------------------------------SAVE AND NEXT---------------------------------------//
  const saveAndNext = async () => {
    // ResetFields
    setErrosEvidencias(null);
    setErrosObrigatorios(null);

    // Type Fields

    const textFields = [
      5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28,
      29, 30,
    ];
    const inputSelect = [19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30];

    // Type Erros
    const _REQUIRED_ERROS = [];
    const _EVIDENCE_ERROS = [];

    for (const i in secoes) {
      const secao = secoes[i];
      if (secao.save) {
        const { campos } = secao;
        for (const j in campos) {
          if (selectFileds.includes(Number(campos[j].id_tipo_campo))) {
            const { opcoes } = campos[j];
            let hasOneSelected = false;
            for (const k in opcoes) {
              if (opcoes[k].checked) {
                hasOneSelected = true;

                if (
                  opcoes[k]?.evidencia &&
                  (!opcoes[k]?.arquivos?.length || !opcoes[k]?.arquivos)
                ) {
                  // Erro de evidenvia obrigatoria
                  _EVIDENCE_ERROS.push({
                    idSecao: secao.id,
                    idCampo: campos[j].id,
                    idResposta: opcoes[k].id,
                    ordem: campos[j].ordem,
                  });
                }
              }
            }

            if (!hasOneSelected && campos[j].obrigatorio) {
              // Erro de obrigatoria
              _REQUIRED_ERROS.push({
                idSecao: secao.id,
                idCampo: campos[j].id,
                ordem: campos[j].ordem,
              });
            }
          } else if (textFields.includes(Number(campos[j].id_tipo_campo))) {
            if (
              inputSelect.includes(Number(campos[j].id_tipo_campo)) &&
              campos[j].obrigatorio &&
              !form?.selects[campos[j].id_tipo_campo]?.length
            ) {
              return null;
            }

            if (!campos[j].resposta && campos[j].obrigatorio) {
              // Erro de obrigatoria
              _REQUIRED_ERROS.push({
                idSecao: secao.id,
                idCampo: campos[j].id,
                ordem: campos[j].ordem,
              });
            }

            // Validaçao especifica para campo de Lat e Long
            if (
              campos[j].resposta &&
              campos[j].id_tipo_campo == 13 &&
              campos[j].obrigatorio
            ) {
              const response = campos[j].resposta?.split(',');
              if (response[0] === '' || response[1] === '') {
                // Erro de obrigatoria (um campo só preenchido)
                _REQUIRED_ERROS.push({
                  idSecao: secao.id,
                  idCampo: campos[j].id,
                  ordem: campos[j].ordem,
                });
              }
            }
          }
        }
      }
    }

    await atualizarMetadados(secoes);

    if (_EVIDENCE_ERROS.length || _REQUIRED_ERROS.length) {
      toast.error('Preencha todos os requisitos obrigatórios para continuar');
      setErrosEvidencias(_EVIDENCE_ERROS);
      setErrosObrigatorios(_REQUIRED_ERROS);

      let first = null;

      if (_EVIDENCE_ERROS.length && _REQUIRED_ERROS.length) {
        first =
          _REQUIRED_ERROS[0].ordem < _EVIDENCE_ERROS[0].ordem
            ? _REQUIRED_ERROS[0].idCampo
            : _EVIDENCE_ERROS[0].idCampo;
      } else if (_EVIDENCE_ERROS.length) {
        first = _EVIDENCE_ERROS[0].idCampo;
      } else if (_REQUIRED_ERROS.length) {
        first = _REQUIRED_ERROS[0].idCampo;
      }

      const fieldError = document.querySelector(`.fieldClass-${first}`);
      if (fieldError) {
        fieldError.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
        });
      }
      return;
    }

    // Add position in response

    if (verfyHasGetPosition(formulario)) {
      for (const secao of secoes) {
        if (secao.campos.length) {
          for (const campo of secao.campos) {
            if (campo.id_tipo_campo === 31) {
              campo.resposta = position ?? positionError;
            }
          }
        }
      }
    }

    // Remove relation with state for not toggle save before request
    const copySessionsSend = JSON.parse(JSON.stringify(secoes));

    if (stopIn < secoes.length) {
      setStopIn(stopIn + 1);
      secoes[stopIn].save = true;
      setSecoes([...secoes]);
    }

    setTimeout(() => {
      const nextSection = document.querySelector(
        `.section-controller-${stopIn}`,
      );
      if (nextSection) {
        nextSection.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }, 100);

    setLoading(true);
    const res = await services.responseSection(formulario.id, copySessionsSend);

    if (res.success) {
      setChanged(false);
      setSaveError(false);
      if (stopIn === secoes?.length) {
        onClose();
      }
    } else {
      toast.error(res.message);
      toast.error('Ocorreu um erro, recarregue a página e tente novamente');
      setSaveError(true);
      setWarningView(true);
    }
    setLoading(false);
  };

  // -------------------------------------CLOSE MODAL---------------------------------------//
  const closeVerify = () => {
    if (changed) {
      setCloseModal(true);
    } else {
      backModal();
    }
  };

  return (
    <>
      {!preview && (
        <S.BigModal
          open={open}
          onClose={onClose}
          disableEscapeKeyDown={!previewWeb}
          visible={visible}
        >
          <S.Cover className=".coverModal">
            {(previewWeb || saveError) && (
              <S.PrevieWarning view={warningView} saveError={saveError}>
                <div className="warning">
                  {!saveError
                    ? 'Você esta acessando o modo de visualização, as perguntas nessa página não podem ser respondidas'
                    : 'Desculpe, ocorreu um erro em nossos servidores, recarregue a página e tente novamente ou entre em contato com nosso suporte'}
                </div>
                <span className="close" onClick={() => setWarningView(false)}>
                  Fechar
                </span>
              </S.PrevieWarning>
            )}
            <S.Header>
              <div className="left">
                <h1>{formulario?.titulo}</h1>
              </div>
              <div className="medium">
                <img src={OnisysLogo} />
              </div>
              <div className="right">
                <span className="stArea">
                  {!previewWeb ? (
                    formatStatusResponse(
                      formulario.form_status,
                      formulario.data_fim_atual
                        ? formulario.data_inicio_atual
                        : null,
                      theme,
                    )
                  ) : (
                    <div className="previewWeb">Prévia Desktop</div>
                  )}
                </span>
                <CancelRoundedIcon
                  htmlColor={theme.palette.semantics.feedback.unknown.natural}
                  onClick={
                    !previewWeb && !loading
                      ? () => closeVerify()
                      : () => backModal()
                  }
                  style={{ cursor: 'pointer' }}
                />
              </div>
            </S.Header>
            <S.Body>
              <S.Content className="contentControllScroll">
                {!previewWeb && (
                  <div className="currentUser">
                    <div className="leftUser">
                      <div className="ball">
                        {user.foto && <img />}
                        {!user.foto && (
                          <div className="circle">{user.nome[0]}</div>
                        )}
                      </div>
                      <div className="name">{user.nome}</div>
                      <div className="status">
                        {formatStatusUser(formulario.status, theme)}
                      </div>
                    </div>
                  </div>
                )}
                <S.HeaderSection src={formulario?.capa || img}>
                  <span className="primaryBox">
                    <div className="titleForm">{formulario?.titulo}</div>
                    <span className="legend">
                      <span className="redPointer">*</span>
                      <span className="textReq">Obrigatórios</span>
                    </span>
                  </span>
                  <Divider />
                  <div className="descriptionForm">{formulario?.descricao}</div>
                  <div className="cover" />
                </S.HeaderSection>

                {secoes?.map((secao, idx) => {
                  if (!previewWeb && idx < stopIn) {
                    return (
                      <S.BodySection
                        key={idx}
                        className={`section-controller-${idx}`}
                        previewWeb={previewWeb}
                        saveError={saveError}
                      >
                        <span className="topArea">
                          <div className="titleSection">{secao?.titulo}</div>
                          <span className="counterSec">{`Seção ${idx + 1}/${
                            secoes?.length
                          }`}</span>
                        </span>
                        {secao.descricao && (
                          <div className="descriptSection">
                            {secao?.descricao}
                          </div>
                        )}
                        <Divider />
                        {secao.campos.map((campo, key) =>
                          formatFieldResponse(campo, secao.id, key),
                        )}
                      </S.BodySection>
                    );
                  }
                  if (previewWeb) {
                    return (
                      <S.BodySection
                        key={idx}
                        className={`section-controller-${idx}`}
                        previewWeb={previewWeb}
                      >
                        <span className="topArea">
                          <div className="titleSection">{secao?.titulo}</div>
                          <span className="counterSec">{`${idx + 1}/${
                            secoes?.length
                          }`}</span>
                        </span>
                        {secao?.descricao && (
                          <div className="descriptSection">
                            {secao?.descricao}
                          </div>
                        )}
                        <Divider />
                        {secao.campos.map((campo, key) =>
                          formatFieldResponse(campo, secao.id, key),
                        )}
                      </S.BodySection>
                    );
                  }
                })}

                <S.FooterSection previewWeb={previewWeb}>
                  <div className="statusSection">
                    {changed
                      ? 'Informações não atualizadas'
                      : 'Informações atualizadas'}
                  </div>
                  <div className="buttonArea">
                    {!saveError && (
                      <DefaultButton
                        children={
                          stopIn === secoes?.length
                            ? 'Finalizar'
                            : 'Salvar e Avançar'
                        }
                        onClick={
                          stopIn !== secoes?.length
                            ? () => saveAndNext()
                            : () => setFinalModal(true)
                        }
                        loading={loading}
                      />
                    )}
                  </div>
                </S.FooterSection>
              </S.Content>
            </S.Body>
          </S.Cover>
        </S.BigModal>
      )}
      {preview && secoes && (
        <S.Cover className=".coverModal" preview={preview}>
          <S.Body preview={preview}>
            <S.Content className="contentControllScroll" preview={preview}>
              <S.HeaderSection src={formulario?.capa || img} preview={preview}>
                <span className="primaryBox">
                  <div className="titleForm">{formulario?.titulo}</div>
                </span>
                <Divider />
                <div className="descriptionForm">{formulario?.descricao}</div>
                <div className="cover" />
              </S.HeaderSection>

              {secoes?.map((secao, idx) => {
                return (
                  <S.BodySection
                    key={idx}
                    className={`section-controller-${idx}`}
                    preview={preview}
                  >
                    <span className="topArea">
                      <div className="titleSection">{secao?.titulo}</div>
                      <span className="counterSec">{`${idx + 1}/${
                        secoes?.length
                      }`}</span>
                    </span>
                    {secao?.descricao && (
                      <div className="descriptSection">{secao?.descricao}</div>
                    )}
                    <Divider />
                    {secao.campos.map((campo, key) =>
                      formatFieldResponse(campo, secao.id, key),
                    )}
                  </S.BodySection>
                );
              })}
            </S.Content>
          </S.Body>
        </S.Cover>
      )}
      <ConfirmModal
        open={closeModal}
        onClick={() => backModal()}
        title="Tem certeza que deseja fechar?"
        subtitle="Informações não salvas serão perdidas"
        buttonText="Confirmar"
        handleClose={() => setCloseModal(false)}
      />
      <ConfirmModal
        open={finalModal}
        onClick={() => {
          setFinalModal(false);
          saveAndNext();
        }}
        title="Tem certeza que deseja confirmar o envio do formulário?"
        subtitle="As perguntas respondidas não poderão mais ser alteradas e o item já ficará visível para o gestor responsável"
        buttonText="Confirmar"
        handleClose={() => setFinalModal(false)}
        titleIcon={
          <CheckCircleOutlineOutlinedIcon
            htmlColor={theme.palette.brand.secondary.natural}
          />
        }
      />
    </>
  );
};

export default ModalResponse;
