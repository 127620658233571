import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateBox = styled.div`
  ${({ theme }) => css`
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    color: ${theme.palette.words.subtitle.natural};
    border: 1px solid ${theme.palette.brand.primary.light};
    border-radius: 4px;
    height: 32px;
    padding: 0 4px;

    .arrow {
      border-radius: 4px;
      cursor: pointer;
      font-size: 24px;
    }

    .arrow:hover {
      background-color: ${theme.palette.brand.primary.light}30;
    }
    .arrow.null {
      cursor: not-allowed;
    }
  `}
`;
