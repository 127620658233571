import styled, { css, keyframes } from 'styled-components';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

export const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.h1`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    font-size: 24px;
    color: ${theme.palette.words.title.natural};
    font-weight: 900;
  `}
`;

export const Subtitle = styled.h1`
  ${({ theme }) => css`
    font-size: 1rem;
    font-weight: normal;
    color: ${theme.palette.words.text.light};
  `}
`;
export const Action = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin: 1rem 0;
`;

export const Selector = styled.div`
  width: 100%;
  cursor: pointer;
  text-align: center;
  background: #4b5166;
  color: white;
  border-radius: 4px;
  opacity: ${({ active }) => (active ? 1 : 0.3)};
  font: normal normal 900 20px/48px Texta;
  transition: opacity 0.3s ease;

  :hover {
    opacity: 1;
  }
`;

export const Main = styled.div`
  width: 100%;
`;

export const FiltersContainer = styled.div`
  display: flex;
  margin-top: 1rem;
  width: 100%;
  gap: 0.5rem;
  align-items: center;

  .span {
    flex: 1;
    font: normal normal bold 20px Texta;
    color: #494f65;
  }
`;

export const Footer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const CloseButton = styled(Button)`
  min-width: 15px !important;
  max-width: 15px !important;
  padding: 0px !important;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
  :hover {
    transform: scale(1.1);
  }

  .MuiButton-startIcon {
    padding: 0px !important;
    margin: 0px !important;
  }
`;

export const Paper = styled.div`
  ${({ theme }) => css`
    position: absolute;
    width: 480px;
    background-color: ${theme.palette.system.overlay};
    border: 0px 5px 20px ${theme.palette.system.divider};
    border-radius: 8px;
    padding: 1rem;
    margin: 0 auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `}
`;

export const RadioContainer = styled.div`
  ${({ theme }) => css`
    margin: 1rem 0;
    min-height: 100px;

    /* width */
    ::-webkit-scrollbar {
      width: 5px;
      background: ${theme.palette.system.highlight};
    }

    /* Track */
    ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px ${theme.palette.system.highlight};
      border-radius: 10px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: ${theme.palette.system.divider};
      border-radius: 10px;
    }
  `}
`;

export const StyledButton = styled(Button)`
  && {
    justify-content: center;
    align-items: center;
    text-align: center;
    text-transform: none;
    color: ${props => props.textcolor};
    background: white;
    border: 1px solid #939aab4d;
    font-weight: 400;
    font-size: 16px;
    font-style: normal;
    height: 45px;
    padding: 5px 15px;
    transition: opacity 300ms;
    width: 150px;
  }
`;
