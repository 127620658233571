import styled, { css } from 'styled-components';

export const AttachFile = styled.div`
  ${({ theme }) => css`
    margin-top: 7px;
    border-radius: var(--border_radius, 4px);
    border: 2px solid #939aab;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 16.5px 14px;
    width: 100%;
    position: relative;
    height: 56px;
    background-color: ${props =>
      props.disabled ? theme.palette.system.disabled : ''};
    p {
      margin-bottom: 5px;
      color: ${theme.palette.words.subtitle.natural};
      font: normal medium 14px/20px Texta;
      display: flex;
      span {
        font-size: 20px;
        font-weight: 500;
        color: red;
      }
    }

    input[type='file']::-webkit-file-upload-button {
      border: none;
      padding: 0.2em 0.4em;
      border-radius: 0.2em;
      background-color: ${theme.palette.brand.primary.light};
      transition: 1s;
      color: ${theme.palette.words.placeholder.contrast};
      height: 30px;
    }

    input[type='file']::file-selector-button {
      padding: 0.2em 0.4em;
      border-radius: 0.2em;
      background-color: ${theme.palette.brand.primary.light};
      transition: 1s;
      color: ${theme.palette.words.placeholder.contrast};
      height: 30px;
    }

    input[type='file'] {
      color: ${theme.palette.words.text.light};
    }

    p {
      color: ${theme.palette.words.text.light};
      font-size: 14px;
    }

    input[type='file'] {
      display: none;
    }
  `}
`;

export const Label = styled.label`
  ${({ theme }) => css`
    width: 137px;
    height: 30px;
    padding: 0.3em 0.6em;
    border-radius: 4px;
    background-color: #939aab4d;
    color: ${theme.palette.words.text.light};
    font-weight: bold;
    display: ${props => (props.disabled ? 'none' : 'flex')};
    align-items: center;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    transition: 0.3s ease-out;
    font-size: 12px;
    :hover {
      background-color: ${theme.palette.semantics.feedback.unknown.light};
      color: ${theme.palette.brand.primary.natural};
    }
  `}
`;

export const Text = styled.label`
  ${({ theme }) => css`
    color: ${theme.palette.words.text.light};
    width: 100%;
    display: flex;
    align-items: center;
    cursor: ${props => (props.disabled ? 'default' : 'pointer')};
    &:before {
      border-bottom: none !important;
    }
    &:after {
      border-bottom: none !important;
    }
    input {
      padding: 16.5px 14px !important;
    }
  `}
`;

export const Title = styled.p`
  margin-bottom: 5px;
  color: ${({ theme }) => theme.palette.words.subtitle.natural};
  font: normal medium 14px/20px Texta;
  display: flex;
  span {
    font-size: 20px;
    font-weight: 500;
    color: red;
  }
`;

export const Link = styled.a`
  left: 0;
  margin-left: 10px;
  margin-top: 10px;
  font-family: 'Roboto';
  max-width: 250px;
  font-size: 11px;
  overflow-x: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const IconDivClose = styled.div`
  display: ${props => (props.disabled ? 'none' : 'flex')};
  margin-left: 10px;
  padding-top: 0px 0px 0px 0px;
  top: 0px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  transform: scale(1);
  transition: 0.3s ease-out;
  :hover {
    transform: scale(1.2);
  }
`;

export const StyledDiv = styled.div`
  position: absolute;
  left: 10px;
  display: flex;
`;
