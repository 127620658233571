import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 1rem;

  .card {
    width: 100%;
    max-width: 10rem;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    background-color: white;
    border-radius: 4px;
    border: 1px solid #939aab4d;

    display: flex;
    flex-direction: column;
    padding: 1rem;
    gap: 0.5rem;

    align-items: center;
    justify-content: start;
    text-align: center;
    cursor: pointer;

    .text {
      display: flex;
      height: 100%;
      align-items: center;
      justify-content: center;
    }
  }
`;
