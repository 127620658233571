import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTheme } from 'styled-components';
import { toast } from 'react-toastify';
import * as API from '../../services';

// components
import { DeleteOutline } from '@mui/icons-material';
import Divider from '@mui/material/Divider';
import GhostButton from 'components/Buttons/Ghost';
import ConfirmModal from 'components/ConfirmModal';
import { Switch } from 'components/Inputs/Switch';
import SelectDesvios from '../components/SelectMultipleDesvios';
import { ReactComponent as Pendente } from 'images/icons/configuracoes/icone-pendente.svg';
import { ReactComponent as Check } from 'images/icons/configuracoes/icone-check.svg';

// styles
import * as S from './styled';
import { usePlans } from 'hooks/usePlans';

// icons
import { ReactComponent as EditarIcon } from 'images/icons/configuracoes/icone-editar.svg';
import { ReactComponent as HistoricoIcon } from 'images/icons/sidebar/historico.svg';

export const DetalheTorre = ({ item }) => {
  const theme = useTheme();
  const { isProvider, hasTorre, hasTorrePlus } = usePlans();
  const location = useLocation();
  const navigate = useNavigate();

  const [title, setTitle] = useState('');
  const [status, setStatus] = useState('ATIVO');
  const [risco, setRisco] = useState('');
  const [desvioTipo, setDesvioTipo] = useState([]);
  const [desviosOptions, setDesviosOptions] = useState([]);
  const [errors, setErrors] = useState({});
  const [deleteModal, setDeleteModal] = useState(null);
  const [selectDesviosOptions, setSelectDesviosOptions] = useState([]);
  const [sending, setSending] = useState(false);

  const [prazo, setPrazo] = useState(null);

  const handleExluir = async () => {
    if (item?.id) {
      const res = await API.deleteEscalation(item.id);

      if (res?.success) toast.success(res.message);
      else {
        toast.error(res?.message ?? 'Falha ao excluir.');
        return;
      }
    }
    navigate(-1);
  };

  const checkValid = data => {
    setErrors({});
    const _errors = {};
    if (!data.is_default && !data.titulo) {
      _errors.titulo = 'Campo obrigatório.';
    }
    if (!data.ids_desvios?.length) {
      _errors.ids_desvios = 'Campo obrigatório.';
    }

    if (!data.prazo) {
      _errors.prazo = 'Campo obrigatório.';
    }
    if (data.prazo && data.prazo < 20) {
      _errors.prazo = 'Valor deve ser maior ou igual a 20.';
    }
    if (data.prazo && data.prazo > 1440) {
      _errors.prazo = 'Valor deve ser no máximo 24 horas (1440 minutos).';
    }

    if (Object.getOwnPropertyNames(_errors).length) {
      setErrors(_errors);
      return false;
    }
    return true;
  };

  const handleSave = async () => {
    const payload = {
      ...item,
      titulo: title,
      is_default: false,
      risco,
      ids_desvios: selectDesviosOptions,
      status,
      prazo,
      etapas: [],
    };

    if (checkValid(payload)) {
      setSending(true);

      const res = await API.saveEscalation(payload);
      if (res.success) {
        toast.success('Lista salva com sucesso');
        navigate(-1);
      } else {
        toast.error(res.error?.message ?? res.message);
        setSending(false);
      }
    }
  };

  useEffect(() => {
    if (location?.state) {
      setDesvioTipo(location.state.desviosTipos);
      let desvioOptions = location.state.desviosTipos;
      desvioOptions = desvioOptions.map(item => {
        return {
          id: item.id,
          criticidade: item.criticidade,
          value: item?.titulo || '',
        };
      });
      setDesviosOptions(desvioOptions);

      if (item) {
        const { titulo, risco, status, prazo, ids_desvios } = item || '';
        setRisco(risco);
        setPrazo(prazo);
        setTitle(titulo);
        setStatus(status ?? 'ATIVO');

        setSelectDesviosOptions(ids_desvios);
      }
    }
  }, [location]);

  const filterArray = (array, ids) => {
    let result = array.filter(item => ids && ids.includes(item.id));
    return result || [];
  };

  const handleRemoveDesvio = id => {
    setSelectDesviosOptions(ids => ids.filter(item => item !== id));
  };

  const handleTimeChange = prazo => {
    setPrazo(prazo);
  };

  return (
    <>
      <S.Container>
        <div className="container-header">
          <div className="new-list">
            <input
              className="input-title"
              type="text"
              placeholder="Nova Lista"
              value={title}
              onChange={e => setTitle(e.target.value)}
            />
            <EditarIcon color="#939aab" />
            {!!errors.titulo && <span>{errors.titulo}</span>}
          </div>

          {!!item?.id && (
            <div>
              <GhostButton
                startIcon={<HistoricoIcon />}
                size="medium"
                onClick={() =>
                  navigate(`/logs/configuracoes/escalation-list/${item.id}`)
                }
                sx={{ marginRight: '1rem' }}
              >
                HISTÓRICO DE ALTERAÇÕES
              </GhostButton>
            </div>
          )}
        </div>
        <Divider />
        <div>
          <div style={{ marginTop: 15 }}>
            <Switch
              check={status === 'ATIVO'}
              setCheckbox={value => setStatus(value ? 'ATIVO' : 'INATIVO')}
              disabled={false}
              textOn="Deslocamento ativo"
              textOff="Deslocamento inativo"
            />
          </div>
          <div className="select-header">
            <div className="title-header">
              {isProvider && hasTorre && !hasTorrePlus
                ? 'Desvios:'
                : `Emitir alertas para:`}
            </div>
            <SelectDesvios
              width="350px"
              data={desviosOptions}
              value={selectDesviosOptions}
              handleChange={setSelectDesviosOptions}
              error={!!errors.ids_desvios}
              message={errors.ids_desvios}
            />

            {filterArray(desvioTipo, selectDesviosOptions).map(item => (
              <div key={item.id} className="label-hilight">
                {item.titulo}{' '}
                <span onClick={() => handleRemoveDesvio(item.id)}>X</span>
              </div>
            ))}
          </div>
        </div>

        <div className="container-step">
          <div className="label-prazo">Tratar em no máx.</div>

          <div className="select-time">
            <div className="container-btn-time">
              <button
                className="btn-time"
                onClick={() => handleTimeChange((prazo ?? 0) + 1)}
                disabled={prazo >= 1440}
              >
                +
              </button>
              <button
                className="btn-time"
                onClick={() => handleTimeChange((prazo ?? 0) - 1)}
                disabled={prazo <= 0}
              >
                -
              </button>
            </div>
            <div>
              <input
                className={`display-time ${!!errors.prazo && 'error'}`}
                value={prazo ?? 0}
                min={0}
                max={1440}
                type="number"
                onChange={({ target }) =>
                  handleTimeChange(Number(target.value))
                }
              />{' '}
              min
            </div>
            {!!errors.prazo && <span>{errors.prazo}</span>}
          </div>
        </div>
        {!!item?.id && (
          <>
            <S.ListEmpresas>
              <div className="item">
                <div className="icons">
                  <Check />
                  <span style={{ color: '#1BC5BD' }}>
                    {item?.empresasConfiguraram?.length ?? 0}
                  </span>
                </div>
                <p>Alertas configurados</p>
              </div>
              <div className="item">
                <div className="icons">
                  <Pendente />
                  <span style={{ color: '#FFA801' }}>
                    {item?.empresasNaoConfiguraram?.length ?? 0}
                  </span>
                </div>
                <p>Ainda não configurados</p>
              </div>
            </S.ListEmpresas>
            <Divider />
            <S.ListEmpresas>
              <div className="list">
                {item?.empresasConfiguraram?.map(emp => (
                  <div key={emp.id} className="icons">
                    <Check style={{ width: '12px' }} />
                    <span>{emp.nome}</span>
                  </div>
                ))}
              </div>
              <div className="list">
                {item?.empresasNaoConfiguraram?.map(emp => (
                  <div key={emp.id} className="icons">
                    <Pendente style={{ width: '12px' }} />
                    <span>{emp.nome}</span>
                  </div>
                ))}
              </div>
            </S.ListEmpresas>
          </>
        )}
        <Divider />

        <div style={{ marginTop: 20, display: 'flex', justifyContent: 'end' }}>
          <GhostButton
            size="medium"
            onClick={() => setDeleteModal(true)}
            customcolor={
              theme?.palette?.semantics?.feedback?.attention?.natural
            }
            sx={{ marginRight: '1rem' }}
          >
            Excluir
          </GhostButton>
          <GhostButton size="medium" onClick={handleSave} loading={sending}>
            Salvar{!item?.id ? ` e ativar` : ''}
          </GhostButton>
        </div>
      </S.Container>
      {!!deleteModal && (
        <ConfirmModal
          open={!!deleteModal}
          handleClose={() => setDeleteModal(null)}
          title="Tem certeza que deseja remover essa lista?"
          titleIcon={
            <DeleteOutline
              sx={{
                color: theme.palette.semantics.feedback.attention.natural,
              }}
              fontSize="medium"
            />
          }
          buttonText="Confirmar"
          onClick={() => {
            handleExluir();
            setDeleteModal(null);
          }}
        />
      )}
    </>
  );
};
