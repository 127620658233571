import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useReactToPrint } from 'react-to-print';

import { Grid } from '@mui/material';
import { Check, SaveAlt } from '@mui/icons-material';

import Loading from 'components/Loading';
import ConfirmModal from 'components/ConfirmModal';
import GhostButton from 'components/Buttons/Ghost';
import { ReactComponent as HistoricoIcon } from 'images/icons/sidebar/historico.svg';
import { ReactComponent as DriversIcon } from 'images/icons/sidebar/drivers.svg';
import { useTheme } from 'styled-components';
import { HeaderlessTable } from 'components/_Table/templates/headerless';

import { ExportToPdf } from './Export';
import * as services from './services';
import * as S from './styled';
import Info from './Info';
import Config from './Config';
import { usePlans } from 'hooks/usePlans';
import { SUSPENSAO_ID } from '../ListAcoes/constants';
import { columns } from './constants';
import { fetchClientsWithFaixas } from '../ListAcoes/services';

const DetalheAcoesSuspensoes = () => {
  const theme = useTheme();
  const { isProvider, isOpLogistico, hasTorre, hasTorrePlus } = usePlans();
  const { search } = useLocation();
  const query = new URLSearchParams(search);
  const idAcao = query.get('id_acao');
  const client = query.get('id_cliente');
  const printRefPdf = useRef();
  const params = useParams();
  const navigate = useNavigate();

  // Controla modal de confirmação das ações:
  // APROVAR, SALVAR, FINALIZAR E ENVIAR PARA PAD
  const [openModal, setOpenModal] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [formData, setFormData] = useState({});
  const { data: clientsWithFaixas = [] } = useQuery(
    ['clients-with-faixas'],
    () => fetchClientsWithFaixas(),
    {
      enabled: !isProvider,
      staleTime: Infinity,
    },
  );

  const {
    data: motorista,
    status: motoristaStatus,
    error: motoristaError,
  } = useQuery(
    ['motorista_acao', params.id],
    () => services.fetchMotorista(params.id, { client }),
    {
      refetchOnWindowFocus: false,
      enabled: !!params.id,
    },
  );

  const {
    data: acaoSuspensao,
    status,
    error,
  } = useQuery(
    ['acao_disciplinar', idAcao],
    () => services.fetchAcaoSuspensao(idAcao),
    {
      refetchOnWindowFocus: false,
      enabled: !!idAcao,
    },
  );

  const { data: desvios, isLoading: loadingDesvios } = useQuery(
    ['acoes-suspensoes-desvios', idAcao],
    () => services.fetchDesvios(idAcao),
    {
      refetchOnWindowFocus: false,
      enabled: !!idAcao,
    },
  );

  const isSuspensao = useMemo(
    () =>
      acaoSuspensao?.acao_suspensao_faixa?.acao_disciplinar?.id ===
      SUSPENSAO_ID,
    [acaoSuspensao],
  );

  const handlePrint = useReactToPrint({
    content: () => printRefPdf.current,
    onBeforeGetContent: () => setLoadingPdf(true),
    onAfterPrint: () => {
      setLoadingPdf(false);
    },
    copyStyles: true,
    documentTitle: `${isSuspensao ? 'Suspensão' : 'Ação'}_${acaoSuspensao?.id}`,
  });

  useEffect(() => {
    if (acaoSuspensao)
      setFormData({
        id_responsavel: acaoSuspensao.responsavel?.id ?? '',
        link_anexo: acaoSuspensao.link_anexo ?? '',
        motivo_liberacao: acaoSuspensao.motivo_liberacao ?? '',
        observacao: acaoSuspensao.observacao ?? '',
        tipo_motivo: [
          'Treinamento já cumprido',
          'Suspensão já aplicada',
        ].includes(acaoSuspensao.motivo_liberacao)
          ? acaoSuspensao.motivo_liberacao
          : 'Outro Motivo',
      });
  }, [acaoSuspensao]);

  useEffect(() => {
    if (status === 'error' && error) {
      toast.error(error);
    }
  }, [status, error]);

  // Controla modal de confirmação das ações:
  // APROVAR, SALVAR
  const handleOpenModal = event => {
    let objOpen = {
      event,
      titleIcon: (
        <Check
          sx={{ color: theme.palette.brand.secondary.natural }}
          fontSize="medium"
        />
      ),
      buttonText: 'CONFIRMAR',
    };

    switch (event) {
      case 'SALVAR':
        let hasChange = false;
        for (const key in formData) {
          // Um dos 2 nao é nulo ou "" e são diferentes
          if (
            (formData[key] || acaoSuspensao[key]) &&
            formData[key] !== acaoSuspensao[key]
          ) {
            if (
              (typeof formData[key] === 'string' && formData[key].trim()) ||
              typeof formData[key] !== 'string'
            )
              hasChange = true;
          }
        }
        if (!hasChange) {
          toast.warning('Nenhuma alteração foi aplicada!');
          return;
        }
        objOpen = {
          ...objOpen,
          title: 'Tem certeza que deseja salvar?',
          subtitle: 'Revise todos os campos alterados!',
        };
        break;

      case 'FINALIZAR':
        if (acaoSuspensao.status !== 'LIBERADO' && !formData.id_responsavel) {
          toast.warning('O campo "Responsável" é obrigatório.');
          const element = document.getElementById('id_responsavel');
          element?.focus();
          return;
        }
        if (
          isSuspensao &&
          acaoSuspensao.status !== 'LIBERADO' &&
          !formData.motivo_liberacao
        ) {
          toast.warning('O campo "Motivo da liberação" é obrigatório.');
          const element = document.getElementById('motivo_liberacao');
          element?.focus();
          return;
        }

        objOpen = {
          ...objOpen,
          title: `Tem certeza que deseja finalizar esta ${
            isSuspensao ? 'suspensão' : 'ação'
          }?`,
          subtitle: `O motorista passará para o status LIBERADO`,
        };
        break;
    }
    if (objOpen.title) setOpenModal(objOpen);
  };

  // Ações da confirmação do modal:
  // Mesmas das de cima + ALTERAR_DISTRIBUIDORA
  const handleConfirmModal = () => {
    const { event } = openModal;
    switch (event) {
      case 'SALVAR':
        handleSalvar();
        break;

      case 'FINALIZAR':
        handleFinalizar();
        break;
    }
  };

  // ---------------  AÇÕES --------------------------------
  const handleSalvar = async () => {
    setLoadingModal(true);
    // Monta obj com parametros editaveis apenas
    const payload = {
      ids: [acaoSuspensao.id],
    };
    if (formData.id_responsavel || acaoSuspensao.id_responsavel)
      payload.id_responsavel = formData.id_responsavel ?? null;
    if (formData.link_anexo || acaoSuspensao.link_anexo)
      payload.link_anexo = formData.link_anexo ?? null;
    if (formData.motivo_liberacao || acaoSuspensao.motivo_liberacao)
      payload.motivo_liberacao = formData.motivo_liberacao ?? null;
    if (formData.observacao || acaoSuspensao.observacao)
      payload.observacao = formData.observacao ?? null;

    const res = await services.saveAcaoSuspensao(payload);
    if (res.data?.success) {
      toast.success(res.data?.message);
      navigate('/acoes-suspensoes');
    } else if (res.data?.message)
      toast.error(res.data.error?.message ?? res.data.message);
    setLoadingModal(false);
    setOpenModal(null);
  };

  const handleFinalizar = async () => {
    setLoadingModal(true);
    // Monta obj com parametros editaveis apenas
    const payload = {
      ids: [acaoSuspensao.id],
    };
    if (formData.id_responsavel || acaoSuspensao.id_responsavel)
      payload.id_responsavel = formData.id_responsavel ?? null;
    if (formData.link_anexo || acaoSuspensao.link_anexo)
      payload.link_anexo = formData.link_anexo ?? null;
    if (formData.motivo_liberacao || acaoSuspensao.motivo_liberacao)
      payload.motivo_liberacao = formData.motivo_liberacao ?? null;
    if (formData.observacao || acaoSuspensao.observacao)
      payload.observacao = formData.observacao ?? null;
    const res = await services.finishAcaoSuspensao(payload);
    if (res.data?.success) {
      toast.success(res.data?.message);
      navigate('/acoes-suspensoes');
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setOpenModal(null);
  };

  const labelCliente = client
    ? clientsWithFaixas.find(item => item.value == client)?.label
    : null;

  return (
    <>
      {status === 'loading' && <Loading />}
      {status === 'success' && (
        <S.Main>
          <S.SpacedRow>
            <h1>
              {isProvider && !hasTorre && !hasTorrePlus
                ? `Matrícula: ${motorista?.matricula}`
                : motorista?.nome_motorista}
            </h1>

            {(!isProvider || !acaoSuspensao.empresa) && (
              <GhostButton
                startIcon={<DriversIcon style={{ width: '24px' }} />}
                size="medium"
                onClick={() =>
                  navigate(`/motoristas/${motorista?.id_motorista}`)
                }
                sx={{ marginRight: '1rem' }}
              >
                VER PERFIL DO MOTORISTA
              </GhostButton>
            )}
          </S.SpacedRow>

          <br />
          <br />

          <S.SpacedRow className="gap">
            {motorista?.cards?.map(card => (
              <S.Card>
                <p>{card.title}</p>
                <h3 style={{ color: card.color ?? undefined }}>{card.value}</h3>
              </S.Card>
            ))}
          </S.SpacedRow>

          <br />
          <br />

          <S.SpacedRow>
            <h1>
              Ações disciplinares geradas{' '}
              {!!labelCliente && <span>({labelCliente})</span>}
            </h1>
          </S.SpacedRow>

          <br />

          <HeaderlessTable
            data={motorista?.acoes?.map(acao => ({
              ...acao,
              selected: acao.id === idAcao,
            }))}
            columns={columns}
            loading={loadingDesvios}
            local
            sortBy={{ id: 'id', order: 'DESC' }}
            onClickRow={id =>
              navigate(
                `/acoes-suspensoes/${params.id}?id_acao=${id}${
                  client ? `&id_cliente=${client}` : ''
                }`,
              )
            }
            visualizedKey="selected"
            sizes={[5]}
          />

          <S.SpacedRow>
            <h1>
              {isProvider && !isOpLogistico
                ? 'Ação Sugerida'
                : `${isSuspensao ? 'Suspensão' : 'Ação Disciplinar'}`}{' '}
              <span style={{ fontWeight: 400 }}>
                #{idAcao ? idAcao.substring(0, 8) : '-'}
              </span>
            </h1>

            <S.SpacedRow>
              <GhostButton
                startIcon={<HistoricoIcon />}
                size="medium"
                onClick={() => navigate(`/logs/acoes-suspensoes/${idAcao}`)}
                sx={{ marginRight: '1rem' }}
              >
                HISTÓRICO DE ALTERAÇÕES
              </GhostButton>

              <GhostButton
                startIcon={<SaveAlt />}
                size="medium"
                onClick={handlePrint}
                style={{ marginRight: '1rem' }}
                loading={loadingPdf}
              >
                IMPRIMIR
              </GhostButton>
            </S.SpacedRow>
          </S.SpacedRow>

          <Grid container spacing="20px" marginTop="10px">
            <Grid item xs={12} sm={12} md={6}>
              <Info
                acaoSuspensao={acaoSuspensao}
                isSuspensao={isSuspensao}
                desvios={desvios}
                loading={loadingDesvios}
              />
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              <Config
                acaoSuspensao={acaoSuspensao}
                isSuspensao={isSuspensao}
                formData={formData}
                setFormData={setFormData}
                handleOpenModal={handleOpenModal}
              />
            </Grid>
          </Grid>
        </S.Main>
      )}
      {
        // Este modal controla todas as confirmações
        // openModal.event define a acao em handleConfirmModal
        openModal && (
          <ConfirmModal
            handleClose={() => setOpenModal(null)}
            open={Boolean(openModal)}
            title={openModal.title}
            subtitle={openModal.subtitle}
            titleIcon={openModal.titleIcon}
            buttonText={openModal.buttonText}
            onClick={handleConfirmModal}
            loading={loadingModal}
          />
        )
      }
      <ExportToPdf
        acaoSuspensao={acaoSuspensao}
        isSuspensao={isSuspensao}
        printRef={printRefPdf}
        desvios={desvios?.data}
      />
    </>
  );
};

export default DetalheAcoesSuspensoes;
