import styled from 'styled-components';

export const NavigationContainer = styled.nav`
  padding: 8px 16px;
  margin: 8px 0;
`;

export const BreadcrumbList = styled.ol`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
`;

export const BreadcrumbItem = styled.li`
  display: flex;
  align-items: center;
  &:not(:last-child)::after {
    content: '>';
    font-size: 18px;
    margin: 0 8px;
    color: ${({ theme }) => theme.palette.words.subtitle.natural};
  }
`;

export const BreadcrumbLink = styled.div`
  text-decoration: none;
  color: ${({ theme }) => theme.palette.words.subtitle.natural};
  cursor: pointer;
  font-size: 18px;
  &:hover {
    text-decoration: underline;
  }

  // Para o item atual (último item do breadcrumb)
  &.active {
    color: ${({ theme }) => theme.palette.words.title.natural};
    pointer-events: none;
    font-size: 24px;
    font-weight: 900;
  }
`;
