import api from 'services/api';

export const getHistoricoTratativas = async query => {
  const res = await api.get('/equipe-historico-tratativas', { params: query });
  return res.data;
};

export const getEquipe = async query => {
  const res = await api.get('/equipe', { params: query });
  return res.data;
};

export const getJustified = async query => {
  const res = await api.get('/equipe-justifica-exclusao', { params: query });
  return res.data;
};

export const getMovimentacoes = async query => {
  const res = await api.get('/equipe-movimentacoes', { params: query });
  return res.data;
};

export const getDesviosTratados = async query => {
  const res = await api.get('/equipe-desvios-tratados-prazo', {
    params: query,
  });
  return res.data;
};

export const getDesviosTratadosForaPrazo = async query => {
  const res = await api.get('/equipe-desvios-fora-prazo', {
    params: query,
  });
  return res.data;
};

export const getDesviosSemMotorista = async query => {
  const res = await api.get('/desvios/motorista-nao-identificado', {
    params: query,
  });
  if (res.data?.success) return res?.data.data;
  return { motoristas: [], placas: [] };
};

export const getCardsDesviosSemMotorista = async query => {
  const res = await api.get('/desvios/motorista-nao-identificado/cards', {
    params: query,
  });
  if (res.data?.success) return res.data.data;
  return [];
};
