import { ReactComponent as Lista } from 'images/icons/lista.svg';
import { ReactComponent as Agrupamentos } from 'images/icons/agrupamentos.svg';

export const hasReview = desvio => {
  const review = desvio.aprovado_distribuidora;
  let msg = '';

  // Revisão de exclusão
  if (desvio.data_exclusao) {
    if (review === 0) msg = 'Exclusão em revisão pelo cliente.';
    else if (review === 2) msg = 'Exclusão recusada pelo cliente.';
    else if (review === 3)
      msg = 'Cliente pediu mais informações para aprovar exclusão.';
  }

  // Revisão de troca de operação
  else if (desvio.data_alteracao_distribuidora) {
    if (!desvio.data_revisao_distribuidora)
      msg = 'Troca de operação em revisão pelo cliente';
    else msg = 'Troca de operação recusada pelo cliente';
  }

  return msg;
};

export const getTipoDesvio = infraction =>
  infraction?.desvio_tipo?.desvio_tipo_padrao?.categoria;
export const getGrupoDesvio = infraction =>
  infraction?.desvio_tipo?.desvio_tipo_padrao?.grupo;

export const getStatusColor = (status, theme) => {
  switch (status) {
    case 'ABERTO':
      return theme?.palette?.semantics?.feedback?.warning?.natural;
    case 'PENDENTE':
      return theme?.palette?.semantics?.feedback?.warning?.natural;
    case 'FINALIZADO':
      return theme?.palette?.semantics?.feedback?.information?.natural;
    case 'DELETADO':
      return theme?.palette?.semantics?.feedback?.attention?.natural;
  }
};

export const getCriticidadeColor = (status, theme) => {
  switch (status) {
    case 'PRIMÁRIA':
      return theme?.palette?.semantics?.feedback?.success?.natural;
    case 'MODERADA':
      return theme?.palette?.semantics?.feedback?.information?.natural;
    case 'GRAVE':
    case 'GRAVÍSSIMA':
      return theme?.palette?.semantics?.feedback?.attention?.natural;
    default:
      theme?.palette?.semantics?.feedback?.unknown?.natural;
  }
};

export const statusTabs = [
  { value: 'ABERTO', label: 'Abertos' },
  { value: 'PENDENTE', label: 'Pendentes' },
  { value: 'FINALIZADO', label: 'Finalizados' },
  { value: 'DELETADO', label: 'Excluídos' },
];

export const tipoListagemOptions = [
  { value: 'preview', icon: <Lista /> },
  { value: 'group', icon: <Agrupamentos /> },
];

export const iconsCards = {
  proximo_vencimento: 'schedule.svg',
  criticidade_alta: 'Grupo12754.svg',
  exclusao_revisao: 'warning.svg',
  desvios_analise: 'analise.svg',
};

export const fields = [
  {
    label: 'ID',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Responsáveis',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Criticidade',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Pontos',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Motorista',
    selected: true,
    default: false,
    hasTorre: true,
    provider: 2,
  },
  {
    label: 'Matrícula',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 1,
  },
  {
    label: 'Plano de ação',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  // {
  //   label: 'Observação',
  //   selected: true,
  //   default: false,
  //   hasTorre: false,
  //   provider: 2,
  // },
  {
    label: 'Motivo de exclusão',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Observação de exclusão',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Empresa',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 1,
  },
  {
    label: 'Tecnologia',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Filial',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Filial veículo',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Placa',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'ID Frota',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Próprio/Terceiro',
    selected: true,
    default: false,
    hasTorre: true,
    provider: 2,
  },
  {
    label: 'CPF',
    selected: true,
    default: false,
    hasTorre: true,
    provider: 2,
  },
  {
    label: 'Modelo',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Desvio',
    selected: true,
    default: true,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Status Embarcadora',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 1,
  },
  {
    label: 'Aprovado exclusão',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 1,
  },
  {
    label: 'Observações distribuidora',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 1,
  },
  {
    label: 'Velocidade lida',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Velocidade limite',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Data envio revisão',
    // value: 'Data hora exclusão',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 1,
  },
  {
    label: 'Data revisão distribuidora',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 1,
  },
  {
    label: 'Vencimento distribuidora',
    // value: 'vencimento_distribuidora',
    selected: true,
    default: true,
    hasTorre: false,
    provider: 1,
  },
  {
    label: 'Objeto',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 1,
  },
  {
    label: 'Comentário',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 1,
  },
  {
    label: 'Local',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 1,
  },
  {
    label: 'Usuário distribuidora',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 1,
  },
  {
    label: 'Criado por',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 1,
  },
  {
    label: 'Código viagem GR',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Data hora desvio',
    selected: true,
    default: true,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Data hora criação',
    selected: true,
    default: true,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Data hora vencimento',
    selected: true,
    default: true,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Data hora previsão',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Data hora exclusão',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Data vídeo',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Data hora envio tecnologia',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Data hora envio API',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Data hora tratativa',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Data hora conclusão',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Status',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 2,
  },
  {
    label: 'Embarcador',
    selected: true,
    default: false,
    hasTorre: false,
    provider: 0,
  },
];
