import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';

const Main = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;

    label {
      display: flex;
      align-items: center;

      input {
        display: none;
      }

      .imgArea {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        border: 2px solid ${theme.palette.brand.secondary.natural};
        padding: 3px;
        display: flex;
        align-items: center;
        justify-content: center;

        .borderImage {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            height: 100%;
            border-radius: 100px;
          }
        }

        .empty {
          background-color: ${theme.palette.system.border};
          width: 100%;
          height: 100%;
          border-radius: 100px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 900;
          color: ${theme.palette.words.title.natural};
        }
      }

      .textArea {
        display: flex;
        align-items: center;
        color: ${theme.palette.words.subtitle.natural};
        margin-left: 10px;

        span {
          margin-left: 5px;
        }
      }
    }
  `}
`;

const TitleWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 50px;
`;

const AdicinarDiv = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    align-items: center;

    button {
      width: 100%;
      height: 30px;
      border: none;
      background: transparent;
      font: normal normal 600 16px/23px Texta;
      text-align: left;
      color: ${theme.palette.words.subtitle.natural};
      padding: 0px 15px;
      margin: 4px 0px;

      :hover {
        background: #eee;
      }
    }

    span {
      height: 40px;
    }

    h2 {
      font: normal normal 600 16px/23px Texta;
      color: ${theme.palette.words.subtitle.natural};
    }
  `}
`;

const ItemPopover = styled.p`
  ${({ theme }) => css`
    color: ${theme.palette.words.title.natural};
    font-size: 16px;
    font-weight: 600;
  `}
`;

const Status = styled.h3`
  ${({ theme, ...props }) => css`
    padding: 2px 5px;
    display: flex;
    border-radius: 50px;
    font: normal normal 900 14px/14px Texta;
    background-color: ${props.children === 'ativo'
      ? theme.palette.semantics.feedback.success.light
      : theme.palette.semantics.feedback.attention.light};
    color: ${props.children === 'ativo'
      ? theme.palette.semantics.feedback.success.natural
      : theme.palette.semantics.feedback.attention.natural};
    text-align: center;
    justify-content: center;
    text-transform: capitalize;
  `}
`;

const Status2 = styled.h3`
  ${({ theme, ...props }) => css`
    width: 90px;
    padding: 4px;
    display: flex;
    border-radius: 40px;
    align-items: center;
    justify-content: center;
    text-transform: capitalize;
    background-color: ${props.children === 'ativo'
      ? theme.palette.semantics.feedback.success.light
      : theme.palette.semantics.feedback.attention.light};
    color: ${props.children === 'ativo'
      ? theme.palette.semantics.feedback.success.natural
      : theme.palette.semantics.feedback.attention.natural};
  `}
`;

const HeaderDays = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  span {
    width: 26px;
    text-align: center;
  }
`;

const StatusDisp = styled.div`
  display: flex;
  width: ${props =>
    props.qtd < 31
      ? `calc(100% - ${String((31 - props.qtd) * 28)}px)`
      : '100%'};
  height: 40px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-left: -4px;
`;

const UnChecked = styled.div`
  ${({ theme, color }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    width: 22px;
    height: 22px;
    border: 1px solid ${theme.palette.system.border};
    background-color: ${theme.palette.brand.primary.background};
    margin: 1px 2px;
    cursor: pointer;
    background: ${`${color}26`};
    border-color: ${color};
  `}
`;

const StatusDispWrapper = styled.div`
  position: relative;
`;

const LabelDisp = styled.div`
  ${({ theme, color }) => css`
    position: absolute;
    top: -130px;
    right: -80px;
    width: 220px;
    height: 120px;
    z-index: 9999;
    background: ${theme.palette.system.overlay};
    border: 1px solid ${theme.palette.brand.primary.light}4d;
    box-shadow: 0px 3px 15px ${theme.palette.system.shadow};
    padding: 10px;
    display: none;
    border-radius: 5px;

    ${StatusDispWrapper}:hover & {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    h2 {
      font: normal normal 900 14px/26px Texta;
      color: ${theme.palette.words.subtitle.natural};
    }

    h3 {
      font: normal normal 600 13px/19px Texta;
      color: ${theme.palette.words.subtitle.natural};
      display: list-item;
      margin-left: 15px;
    }

    h4 {
      padding: 2px 5px;
      display: flex;
      border-radius: 50px;
      text-align: center;
      justify-content: center;
      width: 100px;
      font: normal normal 600 13px/19px Texta;
      color: ${color || ttheme.palette.words.text.light};
      background-color: ${color
        ? `${color}26`
        : `${ttheme.palette.words.text.light}26`};
    }
  `}
`;

const DispPercentage = styled.h3`
  font: normal normal 900 14px/20px Texta;
  color: ${props =>
    props.value >= 95
      ? theme.palette.semantics.feedback.success.natural
      : theme.palette.semantics.feedback.attention.natural};
  text-align: center;
`;

const Info = styled.h3`
  ${({ theme, color }) => css`
    font: normal normal 600 16px/20px Texta;
    color: ${color || theme.palette.semantics.feedback.warning.natural};
    text-align: center;
    user-select: none;
  `}
`;

const Indicator = styled.div`
  font-weight: 600;
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 20px;
  font-size: 12px;
  margin-left: 10px;

  div {
    width: 10px;
    height: 10px;
    border-radius: 10px;
    margin-right: 5px;
  }
`;

const Page = styled.div`
  @media all {
    .page-break {
      display: none;
    }
  }

  @media print {
    html,
    body {
      height: initial !important;
      overflow: initial !important;
      -webkit-print-color-adjust: exact;
    }
  }

  @media print {
    .page-break {
      margin-top: 1rem;
      display: flex;
      page-break-before: always;
      /* align-items: center; */
    }

    .page-break-inside {
      page-break-inside: auto;
    }
  }

  @page {
    size: auto;
  }
`;

const StyledButton = styled(Button)`
  ${({ theme, ...props }) => css`
    &.MuiButton-root {
      min-width: 120px;
      width: ${props.width || ''};
      cursor: ${props.loading === 'true' ? 'default' : 'pointer'};
      margin-left: 10px;

      background: ${theme.palette.brand.secondary.natural};
      border-radius: 4px;

      font-size: 16px;
      font-weight: 500;
      text-transform: none;
      color: ${theme.palette.words.button.contrast};

      &:hover {
        background: ${theme.palette.brand.secondary.light};
      }

      &:active {
        background: ${theme.palette.brand.secondary.natural};
        box-shadow: 0px 3px 15px ${theme.palette.brand.secondary.natural};
      }
    }

    &.MuiButton-sizeSmall {
      height: 24px;
      font: normal normal 500 14px/24px Texta;
    }

    &.MuiButton-sizeMedium {
      height: 32px;
      font: normal normal 500 16px/18px Texta;
    }

    &.MuiButton-sizeLarge {
      height: 40px;
      font: normal normal 500 18px/24px Texta;
    }

    &&.Mui-disabled {
      background: ${theme.palette.system.border};
      color: ${theme.palette.words.text.light};
    }
  `}
`;

const ButtonApply = styled(Button)`
  && {
    ${({ theme }) => css`
      height: 32px;
      background: #ff8040;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 16px;
      gap: 4px;
      font-family: 'Texta';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      color: #ffffff;
      text-transform: none;
      margin-right: 8px;
    `}
  }
`;

export {
  Main,
  TitleWrapper,
  AdicinarDiv,
  ItemPopover,
  Status,
  Status2,
  HeaderDays,
  StatusDisp,
  UnChecked,
  StatusDispWrapper,
  LabelDisp,
  DispPercentage,
  Info,
  Indicator,
  Page,
  StyledButton,
  ButtonApply,
};
