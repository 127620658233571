import React, { useState, useMemo } from 'react';
import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';

import { Modal, IconButton, Icon, Grid } from '@mui/material';

import InfoCard from 'components/Cards/InfoCard';
import GhostButton from 'components/Buttons/Ghost';
import Select from 'components/Inputs/Select';
import * as S from './styled';

const EmpresaModal = ({
  handleConfirm,
  titleIconName = 'check',
  open,
  handleClose,
}) => {
  const theme = useTheme();
  const { companies } = useSelector(state => state);

  const empresas =
    companies?.length &&
    companies?.map(company => ({
      value: company.id,
      name: company.value,
    }));

  const title = 'Selecione a empresa';
  const subtitle =
    'Você só pode ver as informações das filiais de uma única empresa';

  // Icone do header variável com titleIconName
  const titleIcon = (
    <Icon
      sx={{ color: theme.palette.brand.secondary.natural }}
      fontSize="medium"
    >
      {titleIconName}
    </Icon>
  );

  // Objeto de dados a serem preenchidos
  const [empresa, setEmpresa] = useState('');

  return (
    <Modal open={open}>
      <S.Paper>
        <S.Header>
          <S.Title>
            {titleIcon}
            <h1>{title}</h1>
          </S.Title>

          <IconButton size="small" onClick={handleClose}>
            <Icon sx={{ color: theme.palette.words.subtitle.natural }}>
              close
            </Icon>
          </IconButton>
        </S.Header>

        <InfoCard message={subtitle} />

        <Grid container rowSpacing={0} columnSpacing={2} marginY={3}>
          <Grid item xs={12} sm={12}>
            <Select
              data={empresas}
              value={empresa}
              onChange={e => setEmpresa(`${e.target.value}`)}
              name="empresas"
              label="Selecione a empresa"
            />
          </Grid>
        </Grid>

        <S.Footer>
          <GhostButton
            customcolor={
              theme?.palette?.semantics?.feedback?.attention?.natural
            }
            size="medium"
            onClick={handleClose}
          >
            Cancelar
          </GhostButton>

          <GhostButton
            onClick={() => {
              handleConfirm(empresa);
              handleClose();
            }}
            size="medium"
            style={{ marginLeft: '10px' }}
            disabled={!empresa}
          >
            Confirmar
          </GhostButton>
        </S.Footer>
      </S.Paper>
    </Modal>
  );
};

export default EmpresaModal;
