import styled, { css } from 'styled-components';

export const Main = styled.div`
  width: 100%;
  height: 100%;
  padding: 5px 15px;
  overflow-y: scroll;
  margin-bottom: 5px;
  .label-hilight {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 20px;
    -ms-user-select: none;
    user-select: none;
    text-align: left;
    font-size: 14px;
    letter-spacing: 0px;
    color: #ffffff;
    background: #2e3240;
    border-radius: 12px;
    margin-right: 5px;
    margin-top: 5px;
  }
  .label-hilight > span {
    margin-left: 10px;
    margin-right: 10px;
    font-weight: bold;
  }
`;

export const Grid = styled.div`
  display: grid;
  gap: 1rem;
  grid-template-columns: 1fr 1fr;

  .fullWidth {
    grid-column: span 2;
  }
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 70px;
    border-bottom: 1px solid ${theme.palette.brand.primary.light}4d;

    div {
      display: flex;
      flex-direction: row;
      h2 {
        margin-left: 10px;
      }
    }
  `}
`;

export const Footer = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: row;
    margin-top: 5px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 80px;
    border-top: 1px solid ${theme.palette.brand.primary.light}4d;
  `}
`;

export const SaveButtonsGroup = styled.div`
  display: flex;
  gap: 8px; // Espaçamento entre os botões de salvar
`;

export const Container = styled.div`
  ${({ theme }) => css`
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    top: 0px;
    right: 0px;
    width: 550px;
    height: 100%;
    background-color: ${theme.palette.system.overlay};
    padding: 0px 30px;
  `}
`;

export const Section = styled.h3`
  ${({ theme }) => css`
    font: normal normal 900 13px/32px Texta;
    justify-content: center;
    color: ${theme.palette.words.subtitle.natural};
  `}
`;
