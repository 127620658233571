/* eslint-disable import/order */

// Styles
import * as S from './styled';
import { useTheme } from 'styled-components';
// React
import { toast } from 'react-toastify';
import { useEffect, useState } from 'react';

// Components
import { Switch } from 'components/Inputs/Switch';
import GhostButton from 'components/Buttons/Ghost';
import TextInput from 'components/Inputs/TextField';
import SelectOne from 'components/Inputs/SelectOne';

// Components MUI
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import BookmarkAddOutlinedIcon from '@mui/icons-material/BookmarkAddOutlined';

// Constants
import * as services from '../services';

const df = {
  titulo: '',
  descricao: '',
  ativa: true,
  listar: true,
};

export const ModalSection = ({
  open,
  handleClose,
  section,
  handleSave,
  env,
  tabs,
  data,
}) => {
  const [form, setForm] = useState(df);
  const [loading, setLoading] = useState(false);
  const theme = useTheme();

  useEffect(() => {
    if (section) {
      setForm({
        titulo: section.titulo,
        descricao: section.descricao,
        id_ambiente: section.id_ambiente,
        ativa: section.ativa,
        listar: section.listar,
      });
    } else {
      setForm(df);
    }
  }, [section]);

  // ------------------------HANDLE FUNCTIONS------------------------//
  const handleInput = (key, value) => {
    setForm({ ...form, [key]: value });
  };

  const handleSaveChanges = async () => {
    if (!form.titulo) return toast.error('Preencha o campo de título');

    setLoading(true);
    const request = section
      ? services.updateSection
      : services.createNewSection;

    const envKey = tabs?.find(
      item => item.id == (form.id_ambiente || env),
    )?.value;

    const res = await request({
      id_ambiente: form.id_ambiente || env,
      ordem: data[envKey].length + 1,
      ...section,
      ...form,
    });

    setLoading(false);
    if (res.success) {
      toast.success(res.message);
      handleSave({ ...res.data, guia: section?.guia || [] });
      setForm(df);
      handleClose();
    } else {
      toast.error(res.message);
    }
  };

  // ------------------------RETURN------------------------//
  return (
    <S.ModalMUI open={open}>
      <S.Container>
        <S.Header>
          <div className="left">
            <BookmarkAddOutlinedIcon />
            <h2>{section ? 'Editar Seção' : 'Criar Seção'}</h2>
          </div>
          <CloseRoundedIcon
            onClick={() => {
              setForm(df);
              handleClose();
            }}
          />
        </S.Header>
        <S.Body>
          <div className="input">
            <TextInput
              label="Nome da seção"
              value={form?.titulo}
              onChange={e => handleInput('titulo', e.target.value.slice(0, 35))}
              required
            />
            <div className="counter">{form?.titulo?.length || 0}/35</div>
          </div>
          <div className="input">
            <TextInput
              label="Descrição da seção"
              multiline
              rows={2}
              value={form?.descricao}
              onChange={e =>
                handleInput('descricao', e.target.value.slice(0, 100))
              }
            />
            <div className="counter">{form?.descricao?.length || 0}/100</div>
          </div>

          <div className={section ? 'input disabled' : 'input'}>
            <SelectOne
              value={form.id_ambiente || env}
              type="env"
              handleChange={val => handleInput('id_ambiente', val)}
              data={tabs
                ?.filter(item => item.id != 4)
                .map(item => ({
                  value: item.id,
                  text: item.label,
                }))}
              required
              label="Selecione um ambiente"
            />
          </div>

          <div className="actions">
            <label className="labelBox">
              <Switch
                textOff="Seção Inativa"
                textOn="Seção Ativa"
                check={form?.ativa}
                setCheckbox={e => handleInput('ativa', !form?.ativa)}
              />
            </label>
            {/* <label className="labelBox">
              <Switch
                textOn="Listado"
                textOff="Oculto"
                check={form?.listar}
                setCheckbox={e => handleInput('listar', !form?.listar)}
              />
            </label> */}
          </div>
        </S.Body>
        <S.Footer>
          <GhostButton
            customcolor={
              theme?.palette?.semantics?.feedback?.attention?.natural
            }
            children="Cancelar"
            onClick={() => {
              setForm(df);
              handleClose();
            }}
          />
          <GhostButton
            children="Salvar"
            onClick={() => handleSaveChanges()}
            loading={loading}
          />
        </S.Footer>
      </S.Container>
    </S.ModalMUI>
  );
};
