import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useReactToPrint } from 'react-to-print';

import {
  FormControl,
  IconButton,
  Divider,
  Grid,
  Link,
  SvgIcon,
} from '@mui/material';
import {
  Check,
  SaveAlt,
  NavigateBeforeRounded,
  NavigateNextRounded,
} from '@mui/icons-material';

import Loading from 'components/Loading';
import Table from 'components/TableLocal';
import Select from 'components/Inputs/Select';
import ConfirmModal from 'components/ConfirmModal';
import GhostButton from 'components/Buttons/Ghost';
import TextInput from 'components/Inputs/TextField';
import RadioGroup from 'components/Inputs/RadioGroup';
import FakeTextField from 'components/Inputs/FakeTextField';
import InputAttachedFile from 'components/Inputs/InputAttachedFile2';
import { ReactComponent as anexar } from 'images/icons/inputs/anexar.svg';
import { ReactComponent as HistoricoIcon } from 'images/icons/sidebar/historico.svg';
import { ReactComponent as DriversIcon } from 'images/icons/sidebar/drivers.svg';
import { formatNewDate } from 'utils/dates';
import { useTheme } from 'styled-components';

import { requestCertificate } from 'pages/CapacitacaoV2/MeuPerfil/services';
import axios from 'axios';
import { ExportToPdf } from './Export';
import * as services from '../services';
import * as S from './styled';
import { columns } from './columns';
import { getStatus } from '../constants';

const DetalheAcoesSuspensoes = () => {
  const theme = useTheme();
  const printRefPdf = useRef();
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useSelector(state => state.auth.user);
  const userLevel = user.nivel;
  const userFilials = user.usuario_filiais;
  const { users } = useSelector(state => {
    return state.selects;
  });

  const filter = useSelector(state => {
    return state.filter;
  });

  const filterAcoesSuspensoes = useSelector(state => {
    return state.filterAcoesSuspensoes;
  });

  // Controla modal de confirmação das ações:
  // APROVAR, SALVAR, FINALIZAR E ENVIAR PARA PAD
  const [openModal, setOpenModal] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);
  const [loadingPdf, setLoadingPdf] = useState(false);
  const [formData, setFormData] = useState({});

  const componentRef = useRef();

  const handleChange = (name, value) => {
    setFormData(prev => {
      return { ...prev, [name]: value };
    });
  };

  // Lista de responsáveis
  const responsibles = useMemo(
    () =>
      users
        .filter(i => {
          if (userLevel === 1) return true;
          if (userFilials.includes(i.id_da_filial)) return true;
          return false;
        })
        .map(usr => ({ name: usr.nome, value: usr.id })),
    [users, userLevel],
  );

  const {
    data: infraction,
    status,
    error,
  } = useQuery(
    ['acao_motorista', params.id],
    () =>
      services.fetchInfraction(params.id, {
        ...filter,
        idSuspensao: filterAcoesSuspensoes.faixa,
        idAcaoDisciplinar: filterAcoesSuspensoes.acaoDisciplinar,
        status: filterAcoesSuspensoes.status,
        dashboard: filterAcoesSuspensoes.dashboard,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const isSuspensao = useMemo(
    () => !!infraction?.acao?.acao?.is_reincidencia,
    [infraction],
  );

  const handlePrint = useReactToPrint({
    content: () => printRefPdf.current,
    onBeforeGetContent: () => setLoadingPdf(true),
    onAfterPrint: () => {
      setLoadingPdf(false);
    },
    copyStyles: true,
    documentTitle: `${isSuspensao ? 'Suspensão' : 'Ação'}_${infraction?.id}`,
  });

  useEffect(() => {
    if (infraction)
      setFormData({
        id_responsavel: infraction.id_responsavel,
        link_anexo: infraction.link_anexo,
        motivo_liberacao: infraction.motivo_liberacao,
        observacao: infraction.observacao,
      });
  }, [infraction]);

  useEffect(() => {
    if (status === 'error' && error) {
      toast.error(error);
    }
  }, [status, error]);

  // -------------------------------------------------FORMAT TABLE--------------------------------------------------//
  const formatTable = arr => {
    arr.forEach(i => {
      if (i.id === 'violacao.data_violacao') {
        i.Cell = function ({ row }) {
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {formatNewDate(row.original.violacao.data_violacao)}
            </div>
          );
        };
      }
      if (i.id === 'violacao.pontos') {
        i.Cell = function ({ row }) {
          return (
            <div style={{ display: 'flex', justifyContent: 'center' }}>
              {row.original.violacao.pontos}
            </div>
          );
        };
      }
    });
    return arr;
  };

  // Controla modal de confirmação das ações:
  // APROVAR, SALVAR
  const handleOpenModal = event => {
    let objOpen = {
      event,
      titleIcon: (
        <Check
          sx={{ color: theme.palette.brand.secondary.natural }}
          fontSize="medium"
        />
      ),
      buttonText: 'CONFIRMAR',
    };

    switch (event) {
      case 'SALVAR':
        let hasChange = false;
        for (const key in formData) {
          // Um dos 2 nao é nulo ou "" e são diferentes
          if (
            (formData[key] || infraction[key]) &&
            formData[key] !== infraction[key]
          ) {
            if (
              (typeof formData[key] === 'string' && formData[key].trim()) ||
              typeof formData[key] !== 'string'
            )
              hasChange = true;
          }
        }
        if (!hasChange) {
          toast.warning('Nenhuma alteração foi aplicada!');
          return;
        }
        objOpen = {
          ...objOpen,
          title: 'Tem certeza que deseja salvar?',
          subtitle: 'Revise todos os campos alterados!',
        };
        break;

      case 'FINALIZAR':
        if (infraction.status !== 'LIBERADO' && !formData.id_responsavel) {
          toast.warning('O campo "Responsável" é obrigatório.');
          const element = document.getElementById('id_responsavel');
          element?.focus();
          return;
        }
        if (
          isSuspensao &&
          infraction.status !== 'LIBERADO' &&
          !formData.motivo_liberacao
        ) {
          toast.warning('O campo "Motivo da liberação" é obrigatório.');
          const element = document.getElementById('motivo_liberacao');
          element?.focus();
          return;
        }

        objOpen = {
          ...objOpen,
          title: `Tem certeza que deseja finalizar esta ${
            isSuspensao ? 'suspensão' : 'ação'
          }?`,
          subtitle: `O motorista passará para o status LIBERADO`,
        };
        break;
    }
    if (objOpen.title) setOpenModal(objOpen);
  };

  // Ações da confirmação do modal:
  // Mesmas das de cima + ALTERAR_DISTRIBUIDORA
  const handleConfirmModal = () => {
    const { event } = openModal;
    switch (event) {
      case 'SALVAR':
        handleSalvar();
        break;

      case 'FINALIZAR':
        handleFinalizar();
        break;
    }
  };

  // ---------------  AÇÕES --------------------------------
  const handleSalvar = async () => {
    setLoadingModal(true);
    // Monta obj com parametros editaveis apenas
    const payload = {
      ids: [infraction.id],
      id_responsavel: formData.id_responsavel,
      link_anexo: formData.link_anexo,
      motivo_liberacao: formData.motivo_liberacao,
      observacao: formData.observacao,
    };
    const res = await services.saveInfractions(payload);
    if (res.data?.success) {
      toast.success(res.data?.message);
      navigate('/telemetria/acoes-suspensao');
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setOpenModal(null);
  };

  const handleFinalizar = async () => {
    setLoadingModal(true);
    // Monta obj com parametros editaveis apenas
    const payload = {
      ids: [infraction.id],
      id_responsavel: formData.id_responsavel,
      link_anexo: formData.link_anexo,
      motivo_liberacao: formData.motivo_liberacao,
      observacao: formData.observacao,
      status: 'LIBERADO',
    };
    const res = await services.saveInfractions(payload);
    if (res.data?.success) {
      toast.success(res.data?.message);
      navigate('/telemetria/acoes-suspensao');
    } else if (res.data?.message) toast.error(res.data.message);
    setLoadingModal(false);
    setOpenModal(null);
  };

  // --------------------------------------------DOWNLOAD--------------------------------------------
  const thirtyPartyRequest = async (url, motorista) => {
    try {
      await axios
        .get(url, {
          responseType: 'arraybuffer',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/pdf',
          },
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', `Certificado_${motorista}.pdf`);
          document.body.appendChild(link);
          link.click();
        })
        .catch(error => toast.warn(error));
    } catch (err) {
      toast.warn('Token de acesso expirado!');
    }
  };

  const downloadCertification = async (
    certificationId,
    nota,
    contentId,
    motorista,
  ) => {
    const cert = await requestCertificate(certificationId, { nota, contentId });
    if (cert.data.success) {
      const link = cert.data.data.link;
      if (link) {
        thirtyPartyRequest(link, motorista);
      }
    } else toast.error(cert.data.message);
  };

  const handleVisualizar = id => {
    const desvio = infraction?.violacao_acao_suspensao_motorista.find(
      d => d.id == id,
    );
    if (desvio?.violacao) navigate(`/telemetria/desvios/${desvio.violacao.id}`);
  };

  const actions = [
    {
      name: 'visualizar',
      text: 'Ver desvio',
      action: handleVisualizar,
    },
  ];

  //  ------------------   RENDERIZACAO --------------------------------
  const renderHeader = isSuspensao => {
    return (
      <S.SpacedRow>
        <h1>Detalhe da {isSuspensao ? 'Suspensão' : 'Ação Disciplinar'}</h1>

        <S.SpacedRow>
          <GhostButton
            startIcon={<HistoricoIcon />}
            size="medium"
            onClick={() =>
              navigate(`/logs/telemetria/acoes-suspensao/${params.id}`)
            }
            sx={{ marginRight: '1rem' }}
          >
            HISTÓRICO DE ALTERAÇÕES
          </GhostButton>

          <GhostButton
            startIcon={<DriversIcon style={{ width: '24px' }} />}
            size="medium"
            onClick={() => navigate(`/motoristas/${infraction.id_motorista}`)}
            sx={{ marginRight: '1rem' }}
          >
            VER PERFIL DO MOTORISTA
          </GhostButton>

          <GhostButton
            startIcon={<SaveAlt />}
            size="medium"
            onClick={handlePrint}
            style={{ marginRight: '1rem' }}
            loading={loadingPdf}
          >
            IMPRIMIR
          </GhostButton>

          <IconButton
            disabled={!infraction?.previous}
            onClick={() =>
              navigate(`/telemetria/acoes-suspensao/${infraction.previous}`)
            }
          >
            <NavigateBeforeRounded />
          </IconButton>

          <IconButton
            disabled={!infraction?.next}
            onClick={() =>
              navigate(`/telemetria/acoes-suspensao/${infraction.next}`)
            }
          >
            <NavigateNextRounded />
          </IconButton>
        </S.SpacedRow>
      </S.SpacedRow>
    );
  };

  // Lado Esquerdo do detalhe do desvio
  // Não editável
  const renderAcaoInfo = () => {
    const hasImage = infraction?.motorista?.foto
      ? infraction.motorista.foto
      : false;
    return (
      <>
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid item xs={12} sm={12} display="flex" flexDirection="row">
            <S.StyledLogoWrapper>
              <S.StyledLogo backgroundImage={hasImage}>
                {!hasImage && <h2>{infraction.motorista.nome[0]}</h2>}
              </S.StyledLogo>
            </S.StyledLogoWrapper>

            <S.StyledTextWrapper>
              <h1>{infraction.motorista.nome.toLowerCase()}</h1>
              <p>Filial: {infraction.motorista.filial?.nome}</p>
            </S.StyledTextWrapper>
          </Grid>

          <Divider style={{ margin: '10px 0px 10px 10px', width: '100%' }} />

          <Grid item xs={8} sm={8}>
            <TextInput
              id="acao"
              label="Ação Disciplinar"
              value={infraction.acao.acao.nome}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={4} sm={4}>
            <TextInput
              id="pontos"
              label="Pontuação"
              value={infraction.acao.limiar}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} sm={4}>
            <TextInput
              id="faixa"
              label="Faixa"
              value={infraction.acao.titulo}
              inputProps={{ readOnly: true }}
              variant="filled"
              fillcolor={infraction.acao.cor}
            />
          </Grid>

          <Grid item xs={6} sm={4}>
            <TextInput
              id="inicio"
              label="Data do alcance de faixa"
              value={formatNewDate(infraction.data_inicio)}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} sm={4}>
            <TextInput
              id="fim"
              label="Data de finalização"
              value={formatNewDate(infraction.data_fim)}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={8}>
            <FakeTextField disabled label="Capacitação">
              {infraction.acao?.capacitacao?.titulo && (
                <Link
                  href={`${window.location.origin}/capacitacao/editar/${infraction.acao.capacitacao.id}`}
                  target="_blank"
                >
                  {infraction.acao.capacitacao?.titulo}
                </Link>
              )}
            </FakeTextField>
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextInput
              id="status"
              label="Status"
              value={getStatus(infraction.status, isSuspensao)}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FakeTextField disabled label="Certificado">
              {infraction.acao?.capacitacao?.capacitacao_certificados?.[0] && (
                <>
                  <SvgIcon component={anexar} />
                  <Link
                    href={
                      infraction.acao.capacitacao.capacitacao_certificados[0]
                        .link
                    }
                    target="_blank"
                    download
                  >
                    {extrairNomeArquivo(
                      infraction.acao.capacitacao.capacitacao_certificados[0]
                        .link,
                    )}
                  </Link>
                </>
              )}
            </FakeTextField>
          </Grid>
        </Grid>
      </>
    );
  };

  const renderSuspensaoInfo = () => {
    const hasImage = infraction.motorista?.foto
      ? infraction.motorista.foto
      : false;
    return (
      <>
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid item xs={12} sm={12} display="flex" flexDirection="row">
            <S.StyledLogoWrapper>
              <S.StyledLogo backgroundImage={hasImage}>
                {!hasImage && <h2>{infraction.motorista.nome[0]}</h2>}
              </S.StyledLogo>
            </S.StyledLogoWrapper>

            <S.StyledTextWrapper>
              <h1>{infraction.motorista.nome.toLowerCase()}</h1>
              <p>Filial: {infraction.motorista.filial?.nome}</p>
            </S.StyledTextWrapper>
          </Grid>

          <Divider style={{ margin: '10px 0px 10px 10px', width: '100%' }} />

          <Grid item xs={6} sm={4}>
            <TextInput
              id="faixa"
              label="Faixa"
              value={infraction.acao.titulo}
              inputProps={{ readOnly: true }}
              variant="filled"
              fillcolor={infraction.acao.cor}
            />
          </Grid>

          <Grid item xs={6} sm={4}>
            <TextInput
              id="inicio"
              label="Data da suspensão"
              value={formatNewDate(infraction.data_inicio)}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} sm={4}>
            <TextInput
              id="fim"
              label="Data da liberação"
              value={formatNewDate(infraction.data_fim)}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} sm={4}>
            <TextInput
              id="pontos"
              label="Pontuação"
              value={infraction.acao.limiar}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} sm={4}>
            <TextInput
              id="reincidencia"
              label="Incidência/Reincidência"
              value={infraction.reincidencia?.reincidencia}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={6} sm={4}>
            <TextInput
              id="dias"
              label="Dias de suspensão"
              value={infraction.reincidencia?.dias}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={8}>
            <FakeTextField disabled label="Capacitação">
              {infraction.acao?.capacitacao?.titulo && (
                <Link
                  href={`${window.location.origin}/capacitacao/editar/${infraction.acao.capacitacao.id}`}
                  target="_blank"
                >
                  {infraction.acao.capacitacao?.titulo}
                </Link>
              )}
            </FakeTextField>
          </Grid>

          <Grid item xs={12} sm={4}>
            <TextInput
              id="status"
              label="Status"
              value={getStatus(infraction.status, isSuspensao)}
              inputProps={{ readOnly: true }}
              variant="filled"
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <FakeTextField disabled label="Certificado">
              {infraction.motorista?.capacitacao_certificados?.[0] && (
                <>
                  <SvgIcon component={anexar} />
                  <Link
                    onClick={() => {
                      const certification =
                        infraction.motorista.capacitacao_certificados[0];
                      downloadCertification(
                        certification.id,
                        certification.conteudo?.respostas?.[0].nota_percentual,
                        certification.conteudo?.id,
                        infraction.motorista.nome,
                      );
                    }}
                    target="_blank"
                    download
                  >
                    Certiificado.pdf
                  </Link>
                </>
              )}
            </FakeTextField>
          </Grid>
        </Grid>
      </>
    );
  };

  // Lado direito detalhe do desvio
  // Dados editáveis
  const renderInfractionInfo = () => {
    return (
      <S.ColumnWrapper>
        <Grid container columnSpacing={2} rowSpacing={1}>
          <Grid item xs={12} sm={12}>
            <Select
              id="id_responsavel"
              label={`${
                !isSuspensao && infraction.status === 'LIBERADO'
                  ? ''
                  : 'Atribuir '
              }Responsável`}
              value={formData?.id_responsavel}
              disabled={infraction.status === 'LIBERADO'}
              onChange={e => handleChange('id_responsavel', e.target.value)}
              placeholder="Atribuir Responsável"
              data={responsibles}
              required={infraction.status !== 'LIBERADO'}
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <TextInput
              label="Observações"
              placeholder="Inserir observações"
              onChange={e => handleChange('observacao', e.target.value)}
              multiline
              rows={4}
              value={formData?.observacao}
              disabled={infraction.status === 'LIBERADO'}
            />
          </Grid>

          {isSuspensao && infraction.status !== 'LIBERADO' && (
            <>
              <Grid item xs={12} sm={6}>
                <FormControl>
                  <p
                    style={{
                      color: theme.palette.words.subtitle.natural,
                      font: 'normal medium 14px/20px Texta',
                    }}
                  >
                    Motivo da liberação <span style={{ color: 'red' }}>*</span>
                  </p>
                  <RadioGroup
                    options={[
                      {
                        value: 'Treinamento já cumprido',
                        label: 'Treinamento já cumprido',
                      },
                      {
                        value: 'Suspensão já aplicada',
                        label: 'Suspensão já aplicada',
                      },
                      { value: 'Outro Motivo', label: 'Outro Motivo' },
                    ]}
                    value={formData.tipo_motivo ?? ''}
                    onChange={e => {
                      handleChange('tipo_motivo', e.target.value);
                      if (e.target.value === 'Outro Motivo')
                        handleChange('motivo_liberacao', '');
                      else handleChange('motivo_liberacao', e.target.value);
                    }}
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6} display="flex" alignItems="end">
                <div style={{ width: '100%' }}>
                  <TextInput
                    disabled={formData.tipo_motivo != 'Outro Motivo'}
                    label=""
                    placeholder="Insira o motivo"
                    value={formData.motivo_liberacao ?? ''}
                    onChange={e =>
                      handleChange('motivo_liberacao', e.target.value)
                    }
                    id="motivo_liberacao"
                    name="motivo_liberacao"
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12}>
                <InputAttachedFile
                  idInput="input_file_detalhe"
                  label="Arquivo Justificativa"
                  inputLabel="Anexar Evidência"
                  fileUrl={formData?.link_anexo}
                  setFileUrl={value => handleChange('link_anexo', value)}
                  fileDir={`/acoes/${params.id}`}
                  // deleteFromStorage nao apagar arquivo antigo
                />
              </Grid>
            </>
          )}
          {(!isSuspensao || infraction.status === 'LIBERADO') && (
            <Grid item xs={12} sm={12}>
              <InputAttachedFile
                disabled={infraction.status === 'LIBERADO'}
                idInput="input_file_detalhe"
                label="Anexo"
                inputLabel="Anexar Evidência"
                fileUrl={formData?.link_anexo}
                setFileUrl={value => handleChange('link_anexo', value)}
                fileDir={`/acoes/${params.id}`}
                // deleteFromStorage nao apagar arquivo antigo
              />
            </Grid>
          )}
        </Grid>
        {infraction.status !== 'LIBERADO' && (
          <S.Footer>
            <GhostButton
              id="btnSalvar"
              size="medium"
              onClick={() => handleOpenModal('SALVAR')}
            >
              Salvar
            </GhostButton>

            <GhostButton
              customcolor={
                theme?.palette?.semantics?.feedback?.attention?.natural
              }
              id="btnFinalizar"
              onClick={() => handleOpenModal('FINALIZAR')}
              size="medium"
              style={{ marginLeft: '10px' }}
            >
              Finalizar
            </GhostButton>
          </S.Footer>
        )}
      </S.ColumnWrapper>
    );
  };

  return (
    <>
      {status === 'loading' && <Loading />}
      {status === 'success' && (
        <S.Main>
          {renderHeader(isSuspensao)}
          <Grid container spacing="20px" marginTop="10px" ref={componentRef}>
            <Grid item xs={12} sm={12} md={6}>
              <S.ColumnWrapper>
                {isSuspensao ? renderSuspensaoInfo() : renderAcaoInfo()}
                <br />
                <Divider sx={{ width: '100%' }} />
                <br />
                <div style={{ width: '100%' }}>
                  <h2>Desvios Contabilizados</h2>
                </div>
                <br />
                <Table
                  columns={formatTable(columns)}
                  data={infraction?.violacao_acao_suspensao_motorista ?? []}
                  pageCount={
                    infraction?.violacao_acao_suspensao_motorista?.length ?? 0
                  }
                  sortBy={[]}
                  actions={actions}
                  empty={{
                    title: 'Ops! Não há desvios registrados.',
                    subtitle: 'Verifique o período selecionado',
                  }}
                />
              </S.ColumnWrapper>
            </Grid>

            <Grid item xs={12} sm={12} md={6}>
              {renderInfractionInfo(isSuspensao)}
            </Grid>
          </Grid>
        </S.Main>
      )}
      {
        // Este modal controla todas as confirmações
        // openModal.event define a acao em handleConfirmModal
        openModal && (
          <ConfirmModal
            handleClose={() => setOpenModal(null)}
            open={Boolean(openModal)}
            title={openModal.title}
            subtitle={openModal.subtitle}
            titleIcon={openModal.titleIcon}
            buttonText={openModal.buttonText}
            onClick={handleConfirmModal}
            loading={loadingModal}
          />
        )
      }
      <ExportToPdf
        infraction={infraction}
        printRef={printRefPdf}
        formatTable={formatTable}
      />
    </>
  );
};

export default DetalheAcoesSuspensoes;
