import { useState } from 'react';
import { useQuery } from 'react-query';
import { AddCircleOutline } from '@mui/icons-material';
import { getTemplates } from './service';
import { useNavigate } from 'react-router-dom';

// Styles
import * as S from './styled';

// Components
import Templates from './components/Templates';
import Loading from './components/Loading';
import ModalTemplates from '../components/ModalTemplates';
import DefaultButton from 'components/Buttons/Default';

const Certificados = () => {
  const [modalTemplate, setModalTemplate] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  const { isFetching: loadingContent } = useQuery(
    ['certificados'],
    () => getTemplates(),
    {
      refetchOnWindowFocus: false,
      onSuccess: data => {
        setTemplates(data.data);
      },
      onError: data => {
        setError('Erro ao carregar templates. Por favor, tente novamente.');
      },
    },
  );

  const handleCreateNew = () => {
    navigate('/capacitacao/certificados/criar');
  };

  return (
    <S.Container>
      <S.LineTitle>
        <h1
          style={{
            marginBottom: '20px',
          }}
        >
          Certificados
        </h1>
        <DefaultButton
          style={{
            paddingLeft: '20px',
            paddingRight: '20px',
            marginBottom: '20px',
          }}
          onClick={handleCreateNew}
          startIcon={<AddCircleOutline />}
        >
          Criar novo certificado
        </DefaultButton>
      </S.LineTitle>

      {error && (
        <div style={{ color: 'red', marginBottom: '20px' }}>{error}</div>
      )}

      {!loadingContent ? (
        <Templates
          templates={templates}
          conteudos={null}
          loadingScreen={loadingContent}
          onCreateNew={handleCreateNew}
        />
      ) : (
        <Loading />
      )}

      {modalTemplate && (
        <ModalTemplates
          open={modalTemplate}
          onClose={() => setModalTemplate(false)}
        />
      )}
    </S.Container>
  );
};

export default Certificados;
