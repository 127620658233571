import styled, { css } from 'styled-components';
import Button from '@mui/material/Button';

export const Header = styled.div`
  width: 100%;
`;

export const TabsContainer = styled.div`
  display: flex;
  gap: 1rem;
`;

export const TabsStatus = styled.div`
  flex: 1;
`;

export const TabsStatusItem = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`;

export const TabsUpdated = styled.div`
  font-weight: 500;
  font-size: 14;
  color: #939aab;
  white-space: nowrap;
`;

export const TabsListagem = styled.div``;

export const ButtonApply = styled(Button)`
  && {
    ${({ theme }) => css`
      height: 32px;
      background: #ff8040;
      border-radius: 4px;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0px 16px;
      gap: 4px;
      font-family: 'Texta';
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 25px;
      color: #ffffff;
      text-transform: none;
      margin-right: 8px;
    `}
  }
`;
