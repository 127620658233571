// Styles
import { useTheme } from 'styled-components';
import { colors } from 'styles/colors';

// React
import React, { useState } from 'react';

// Components
import Button from 'components/Buttons/Default';
import ButtonGhost from 'components/Buttons/Ghost';
import ConfirmModal from 'components/ConfirmModal';
import { DefaultTable } from 'components/_Table/templates/default';
import TableLocal from 'components/TableLocal';

// Components MUI
import { Divider } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import NoteAddOutlinedIcon from '@mui/icons-material/NoteAddOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PersonAddAltOutlinedIcon from '@mui/icons-material/PersonAddAltOutlined';
import Avatar from '@mui/material/Avatar';

// Utils
import DefaultButton from 'components/Buttons/Default';
import { columns, columnsTurmas } from '../columns';
import ViewTurma from './viewTurma';
import * as S from '../style';

const ListarTurmas = ({
  behaviorCloseModal,
  openModal,
  data,
  setSelectedTurmas,
  fetchTurma,
}) => {
  const theme = useTheme();
  // Tabela
  const [selectedRow, setSelectedRow] = useState([]);

  const [openModalTurma, setOpenModalTurma] = useState(false);
  const [openNewTab, setOpenNewTab] = useState(false);
  const [idTurma, setIdTurma] = useState(null);

  const handleAddAluno = () => {
    const result = data.filter(item => selectedRow.includes(item.id));
    setSelectedTurmas(result);
    behaviorCloseModal();
  };

  // --------------------------------------FORMATED TABLE--------------------------------------//

  const getAcron = str => {
    const matches = str.match(/\b(\w)/g);
    const acronym = matches.join('');
    return acronym.toUpperCase();
  };

  const formatTable = arr => {
    arr.forEach(i => {
      if (i.id === 'id') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph fontweight="bold" color="#494f65">
                {row.original.id}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      }

      if (i.id === 'nome') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <div className="container-img-name">
                <Avatar
                  alt={getAcron(row.original.nome)}
                  src={row.original.foto}
                  sx={{ marginRight: 1 }}
                />
                <S.Paragraph fontweight="bold" color="#494f65">
                  {row.original.nome}
                </S.Paragraph>
              </div>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'tipo') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver onClick={() => navigateTO(row.original.id)}>
              <S.Paragraph fontweight="bold" color="#494f65">
                {row.original.tipoUser}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'ativa') {
        i.Cell = function ({ row }) {
          let colorText = '';
          let colorBg = '';

          switch (row.original.ativa) {
            case true:
              colorText = colors.greenSucces;
              colorBg = colors.greenBackground;
              break;
            case false:
              colorText = colors.redDanger;
              colorBg = colors.redBackground;
              break;
          }

          return (
            <S.ColDriver>
              <S.Paragraph className="status" color={colorText} bg={colorBg}>
                {row.original.ativa ? 'Ativo' : 'Inativo'}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      } else if (i.id === 'n_alunos') {
        i.Cell = function ({ row }) {
          return (
            <S.ColDriver>
              <S.Paragraph fontweight="bold" color="#494f65">
                {row.original.n_alunos}
              </S.Paragraph>
            </S.ColDriver>
          );
        };
      }
    });
    return arr;
  };

  const redirectToCreationPage = () => {
    setOpenNewTab(false);
    window.open('/fiscalizacao/turmas/criar');
    window.onfocus = () => {
      fetchTurma();
    };
  };

  return (
    <S.ModalTurmas
      open={openModal}
      onClose={() => {
        behaviorCloseModal();
        window.onfocus = () => null;
      }}
      disableEscapeKeyDown={false}
    >
      <S.ContainerModal>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            padding: 10,
          }}
        >
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <PersonAddAltOutlinedIcon
              htmlColor={theme.palette.brand.secondary.natural}
            />
            <h1 style={{ marginLeft: '10px' }}>Adicionar Turmas</h1>
          </div>
          <IconButton
            onClick={() => {
              behaviorCloseModal();
              window.onfocus = () => null;
            }}
          >
            <CloseOutlinedIcon />
          </IconButton>
        </div>
        <Divider />
        <S.TableContainer>
          <TableLocal
            pageSizes={6}
            heightEmpty="285px"
            columns={formatTable(columnsTurmas)}
            data={data}
            sortBy={[]}
            permitIsSortedOccur
            tableType
            setSelectedData={setSelectedRow}
            actions={[
              {
                name: 'turma',
                action: id => {
                  Promise.all([setIdTurma(id)]).then(() =>
                    setOpenModalTurma(true),
                  );
                },
                text: 'Ver turma',
              },
            ]}
            bulkActions={[]}
            empty={{
              image: '',
              title: 'Turmas não encontradas.',
              subtitle: '',
            }}
            search={{
              inputLabel: 'Buscar Turma',
              keys: ['id', 'nome'],
            }}
          />

          {data.length <= 0 && (
            <S.AbsoluteButton>
              <DefaultButton
                startIcon={<AddCircleOutlineIcon />}
                children="Criar nova turma"
                onClick={() => setOpenNewTab(true)}
              />
            </S.AbsoluteButton>
          )}
        </S.TableContainer>
        <div
          style={{ display: 'flex', justifyContent: 'flex-end', padding: 15 }}
        >
          <ButtonGhost
            onClick={() => {
              behaviorCloseModal();
              window.onfocus = () => null;
            }}
            customcolor={
              theme?.palette?.semantics?.feedback?.attention?.natural
            }
          >
            Cancelar
          </ButtonGhost>
          <div style={{ marginLeft: 5 }} />
          <ButtonGhost onClick={handleAddAluno}>Adicionar</ButtonGhost>
        </div>

        {idTurma && (
          <ViewTurma
            openModal={openModalTurma}
            idturma={idTurma}
            behaviorCloseModal={() => {
              setOpenModalTurma(false);
              setIdTurma(null);
              fetchTurma();
            }}
          />
        )}

        <ConfirmModal
          open={openNewTab}
          handleClose={() => setOpenNewTab(false)}
          title="Deseja continuar?"
          titleIcon={
            <NoteAddOutlinedIcon
              sx={{ color: theme.palette.brand.secondary.natural }}
              fontSize="medium"
            />
          }
          subtitle="Você será direcionado para a criação de turmas"
          buttonText="Ir para criação"
          onClick={() => redirectToCreationPage()}
        />
      </S.ContainerModal>
    </S.ModalTurmas>
  );
};

export default ListarTurmas;
