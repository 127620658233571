import styled, { css, keyframes } from 'styled-components';

import { Modal } from '@mui/material';

const warning = keyframes`
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
`;

export const BigModal = styled(Modal)`
  margin-top: ${props => props.visible};
  transition: all ease 0.3s;
`;

export const Cover = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 100%;
    background-color: ${theme.palette.system.overlay};
    position: relative;
  `}
`;

export const Header = styled.header`
  ${({ theme }) => css`
    position: absolute;
    top: 0;
    width: 100%;
    height: 70px;

    display: flex;

    justify-content: center;
    align-items: center;
    box-shadow: 0px 0px 10px #999;
    padding: 0px 20px;
    z-index: 9999;
    background-color: ${theme.palette.system.overlay};

    .left {
      position: absolute;
      left: 0;
      margin-left: 30px;
    }

    .right {
      position: absolute;
      right: 0;
      margin-right: 30px;
      display: flex;
      align-items: center;

      .stArea {
        margin-right: 20px;

        .previewWeb {
          font-size: 18px;
          margin-right: 20px;
          font-weight: 600;
          background-color: ${theme.palette.semantics.feedback.warning.light};
          color: ${theme.palette.semantics.feedback.warning.natural};
          border-radius: 20px;
          padding: 0px 25px;
          animation: ${warning} 2s ease infinite;
        }
      }
    }

    .medium {
      img {
        width: 40px;
      }

      @media (max-width: 990px) {
        img {
          display: none;
        }
      }
    }
  `}
`;

export const Body = styled.div`
  padding-top: ${props => (props.preview ? '0px' : '70px')};
  height: 100%;
  display: flex;
  z-index: 1;
`;

export const Content = styled.div`
  ${({ theme, preview }) => css`
    background-color: ${theme.palette.brand.primary.background};
    width: 100%;
    padding: ${preview ? '0px' : '50px 180px'};
    overflow-y: auto;
    overflow-x: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    @media (max-width: 1260px) {
      padding: ${preview ? '0px' : '50px 100px'};
    }

    @media (max-width: 1100px) {
      padding: ${preview ? '0px' : '50px 30px'};
    }

    .currentUser {
      display: flex;
      justify-content: start;
      align-items: center;
      max-width: 1100px;
      width: 100%;

      .leftUser {
        display: flex;
        align-items: center;

        .ball {
          width: 36px;
          height: 36px;
          border: 1px solid ${theme.palette.brand.secondary.natural};
          background-color: ${theme.palette.system.highlight};
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 36px;
          padding: 1px;

          img {
          }

          .circle {
            font-weight: 600;
            color: ${theme.palette.words.title.natural};
            width: 100%;
            height: 100%;
            background-color: ${theme.palette.brand.primary.background};
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 36px;
          }
        }

        .name {
          margin-left: 10px;
          font-weight: 600;
          color: ${theme.palette.words.subtitle.natural};
          font-size: 18px;
        }

        .status {
          margin-left: 20px;
        }
      }
    }
  `}
`;

export const HeaderSection = styled.div`
  ${({ theme, preview }) => css`
    background-color: ${theme.palette.system.overlay};
    border: 1px solid ${theme.palette.semantics.feedback.unknown.natural};
    border-top: 5px solid ${theme.palette.brand.secondary.natural};
    border-radius: 5px;
    margin-top: ${preview ? '0px' : '30px'};
    max-width: 1100px;
    width: 100%;

    .primaryBox {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;

      .titleForm {
        font-size: ${preview ? '16px' : '25px'};
        font-weight: 600;
        color: ${theme.palette.words.title.natural};
      }

      .legend {
        display: flex;
        justify-content: center;
        align-items: center;

        .redPointer {
          font-size: 30px;
          color: ${theme.palette.semantics.feedback.attention.natural};
          font-weight: 600;
          margin-right: 5px;
          margin-bottom: -12px;
        }

        .textReq {
          font-weight: bold;
          color: ${theme.palette.words.subtitle.natural};
          font-size: 14px;
        }
      }
    }

    .descriptionForm {
      color: ${theme.palette.words.subtitle.natural};
      padding: 15px;
      font-weight: 500;
      font-size: ${preview ? '13px' : '16px'};
    }

    .cover {
      ${({ src }) => css`
        background-size: ${preview ? 'contain' : 'cover'};
        background-position: center;
        background-repeat: no-repeat;
        background-image: url(${src});
        display: flex;
        justify-content: center;
        align-items: center;
        height: ${preview ? 'auto' : '350px'};
        max-height: ${preview ? '350px' : 'auto'};
        min-height: ${preview ? '170px' : 'auto'};
        width: 100%;
      `}
    }
  `}
`;

export const BodySection = styled.div`
  ${({ theme, ...props }) => css`
    background-color: ${theme.palette.system.overlay};
    border: 1px solid ${theme.palette.semantics.feedback.unknown.natural};
    border-left: 5px solid ${theme.palette.brand.secondary.natural};
    border-radius: 5px;
    margin-top: 30px;
    max-width: 1100px;
    width: 100%;

    pointer-events: ${props.preview || props.previewWeb || props.saveError
      ? 'none'
      : 'all'};

    .topArea {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px;

      .titleSection {
        font-size: 20px;
        font-weight: 800;
        color: ${theme.palette.words.title.natural};
      }

      .counterSec {
        font-weight: 600;
        color: ${theme.palette.words.subtitle.natural};
        font-size: ${props.preview ? '10px' : '14px'};
      }
    }

    .descriptSection {
      color: ${theme.palette.words.subtitle.natural};
      padding: 15px;
      font-weight: 500;
    }
  `}
`;

export const FooterSection = styled.div`
  ${({ theme, previewWeb }) => css`
    margin-top: 20px;
    background-color: ${theme.palette.system.overlay};
    justify-content: space-between;
    align-items: center;
    border: 1px solid ${theme.palette.semantics.feedback.unknown.natural};
    border-radius: 5px;
    padding: 15px 20px;
    display: ${previewWeb ? 'none' : 'flex'};
    max-width: 1100px;
    width: 100%;

    .statusSection {
      font-weight: 600;
    }
  `}
`;

export const PrevieWarning = styled.div`
  ${({ theme, ...props }) => css`
    position: fixed;
    z-index: 999;
    width: 250px;
    background-color: ${props.saveError
      ? theme.palette.semantics.feedback.attention.natural
      : theme.palette.system.shadow};
    bottom: 0;
    right: 0;
    margin-bottom: 20px;
    margin-right: 20px;
    border-radius: 10px;
    padding: 10px;
    font-weight: 600;
    flex-direction: column;
    font-style: oblique;
    display: ${props.view ? 'flex' : 'none'};

    .warning {
      color: ${theme.palette.words.text.contrast};
    }

    .close {
      align-self: end;
      font-weight: 600;
      color: ${props.saveError
        ? theme.palette.brand.primary.light
        : theme.palette.brand.secondary.natural};
      margin: 10px;
      cursor: pointer;
    }
  `}
`;
