/* eslint-disable import/order */

// Styles
import * as S from './styled';
import colors from 'styles/colors';

// React
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';

// Components
import { PageGuia } from './Page';
import Tabs from 'components/Tabs';
import { ModalSort } from './ModalSort';
import Loading from 'components/Loading';
import { Switch } from 'components/Inputs/Switch';
import { ModalStatistics } from './ModalStatistics';
import EmptyDataCard from 'components/Cards/EmptyDataCard';

// Components MUI
import { Collapse, Divider } from '@mui/material';
import { AddCircleOutline } from '@mui/icons-material';
import ListRoundedIcon from '@mui/icons-material/ListRounded';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import FolderOffOutlinedIcon from '@mui/icons-material/FolderOffOutlined';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import BookmarkAddedOutlinedIcon from '@mui/icons-material/BookmarkAddedOutlined';
import FolderSpecialOutlinedIcon from '@mui/icons-material/FolderSpecialOutlined';
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import DomainVerificationOutlinedIcon from '@mui/icons-material/DomainVerificationOutlined';

// Utils
import * as services from './services';
import { message, feedback } from './constants';

export const GuiaProvider = () => {
  // States Data

  const [section, setSection] = useState(null);
  const [tab, setTab] = useState('monitoramento');

  // Control States
  const [page, setPage] = useState(null);
  const [screen, setScreen] = useState(0);
  const [search, setSearch] = useState('');

  // Modal States
  const [modalSortGuide, setModalSortGuide] = useState(false);
  const [modalStatistics, setModalStatistics] = useState(false);

  useEffect(() => {
    if (screen == 1) {
      const doc = document.getElementById('container-guide-screen');
      doc?.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [screen]);

  const scrollView = (idSecao, idGuia, currentSec) => {
    const doc = document.getElementById(`section-guide-${idSecao}-${idGuia}`);

    if (doc) {
      setTimeout(
        doc.scrollIntoView({
          behavior: 'smooth',
          inline: 'center',
        }),
        1000,
      );
    } else {
      const el = document.getElementById(`guide-section-${currentSec}`);
      el?.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  };

  const handleTab = (e, newTab) => {
    setTab(newTab);
    setSearch('');
  };

  // -----------------------------REQUESTS------------------------------------
  const [tabs, setTabs] = useState([]);
  const [routes, setRoutes] = useState([]);
  const { isLoading: loadingRoutes } = useQuery(
    [`/page-map-provider-routes`, 1],
    () => services.getRoutes(),
    {
      refetchOnWindowFocus: false,
      onSuccess: data =>
        data.success ? setRoutes(data.rotas) : toast.error(data.message),
    },
  );

  const [data, setData] = useState({});
  const { isLoading: loadingIndex } = useQuery(
    [`/guide-panel-provider-index`, 1],
    () => services.getIndex(),
    {
      refetchOnWindowFocus: false,
      onSuccess: data =>
        data.success ? formatInitialData(data.data) : toast.error(data.message),
    },
  );

  const formatInitialData = res => {
    let resTabs = [];
    let resData = {};
    res.forEach(item => {
      resTabs.push({
        id: item?.id,
        value: item?.titulo,
        label: item?.descricao,
      });
      resData[item?.titulo] = item?.secao;
    });

    setTabs(resTabs);
    setData(resData);
    calcCollapse(resData[tab]);
  };

  // -----------------------------FILTER------------------------------------
  const [filtered, setFiltered] = useState([]);

  useEffect(() => {
    if (!search) setFiltered(null);
    else {
      const newFiltered = data[tab].filter(item =>
        item.titulo?.toLowerCase().includes(search.toLowerCase()),
      );
      calcCollapse();
      setFiltered(newFiltered);
    }
  }, [search]);

  // -----------------------------COLLAPSE------------------------------------
  const [collapse, setCollapse] = useState([]);
  useEffect(() => calcCollapse(), [tab]);

  const calcCollapse = (newData, envTab) => {
    const newCollapse = (newData || data[envTab || tab])?.map(item => {
      const hasGuide = item?.guia?.length > 0;
      if (hasGuide) {
        return { id: item.id, open: false };
      }
      return { id: item.id, open: true };
    });
    setCollapse(newCollapse);
  };

  const handleCollapse = id => {
    const newCollapse = collapse?.map(item => {
      if (item.id === id) {
        return { ...item, open: !item.open };
      }
      return item;
    });
    setCollapse(newCollapse);
  };

  const verifyOpen = id => {
    const item = collapse?.find(item => item?.id === id);
    return item?.open;
  };

  const filterRoutes = () => {
    const idTab = tabs?.find(item => item.value === tab)?.id;
    let newRoutes = routes?.map(route => {
      const plans = route?.planos?.filter(
        plano => plano.ambiente === idTab || !plano.ambiente,
      );
      return { ...route, planos: plans };
    });

    newRoutes = newRoutes.filter(route => route.planos.length > 0);

    return newRoutes;
  };

  // --------------------------------FUNCTIONS--------------------------------------
  const handleOrderGuides = async sec => {
    const res = await services.orderGuide(sec);

    if (res.success) {
      toast.success(res.message);
      const newSections = data[tab]?.map(item => {
        if (item?.id === sec?.id) {
          return sec;
        }
        return item;
      });
      updateBySections(newSections);
      return true;
    }
    return false;
  };

  const handleCheckGuide = async (sectionId, guideId) => {
    const res = await services.updateGuideStatus(guideId);

    if (res.success) {
      const newSections = data[tab]?.map(item => {
        if (item?.id === sectionId) {
          const newGuide = item.guia?.map(item => {
            if (item?.id === guideId) {
              return { ...item, ativa: !item?.ativa };
            }
            return item;
          });
          return { ...item, guia: newGuide };
        }
        return item;
      });
      toast.success(res.message);
      updateBySections(newSections);
    } else {
      toast.error(res.message);
    }
  };

  // --------------------------------UPDATE BIG DATA--------------------------------------

  const updateByGuide = guide => {
    const newData = {
      ...data,
      [tab]: data[tab]?.map(secao => {
        if (secao?.id === guide?.id_secao) {
          const isNew = !(secao?.guia || [])?.find(
            item => item?.id === guide?.id,
          );
          if (isNew) {
            return {
              ...secao,
              guia: [...(secao?.guia || []), guide],
            };
          }
          return {
            ...secao,
            guia: secao?.guia?.map(item => {
              if (item?.id === guide?.id) {
                return guide;
              }
              return item;
            }),
          };
        }
        return secao;
      }),
    };

    setData({ ...newData });
    scrollView(guide.id_secao, guide.id);
  };

  const updateBySections = (sections, envTab) => {
    const newData = {
      ...data,
      [envTab || tab]: sections,
    };
    setData(newData);
  };

  // --------------------------------RETURN--------------------------------------
  return (
    <S.Container id="container-guide-screen">
      {(loadingIndex || loadingRoutes) && (
        <div className="boxLoading">
          <Loading />
        </div>
      )}
      {!loadingIndex && !loadingRoutes && (
        <>
          <S.TitleArea className="space">
            <span className="textArea">
              Configurações Guia do Usuário Embarcador
            </span>
          </S.TitleArea>

          <S.Content screen={screen}>
            <S.Box>
              <Tabs value={tab} items={tabs} onChange={handleTab} />
              <Divider />
              <S.SearchArea>
                {tab !== 'primeiros_passos' && (
                  <>
                    <input
                      type="text"
                      placeholder="Buscar seção"
                      value={search}
                      onChange={e => setSearch(e.target.value)}
                    />
                  </>
                )}
              </S.SearchArea>

              {!!data[tab]?.length &&
                // ENVIRONMENT
                (filtered || data[tab])?.map((section, key) => {
                  const guide = section?.guia;

                  return (
                    // SECTION
                    <S.SectionBox
                      key={key}
                      active={section?.ativa}
                      id={`guide-section-${section?.id}`}
                    >
                      <div className="header">
                        <div className="left">
                          <div className="titleArea">
                            {!section?.ativa && (
                              <FolderOffOutlinedIcon className="icon" />
                            )}
                            {section?.ativa && (
                              <FolderSpecialOutlinedIcon className="icon" />
                            )}
                            <h2 className="titleS">{section?.titulo}</h2>
                            <div className="status">
                              {section?.ativa ? 'Ativo' : 'Inativo'}
                            </div>
                            {/* <div className="visibility">
                                  {section?.listar && (
                                    <>
                                      <VisibilityOutlinedIcon />
                                      <span>Listado</span>
                                    </>
                                  )}
                                  {!section.listar && (
                                    <>
                                      <VisibilityOffOutlinedIcon />
                                      <span>Não listado</span>
                                    </>
                                  )}
                                </div> */}
                          </div>
                          <p className="descriptionS">{section.descricao}</p>
                        </div>
                        <div className="actions">
                          <button
                            onClick={() => {
                              setSection(section);
                              setModalSortGuide(true);
                            }}
                          >
                            <ListRoundedIcon />
                            Ordenar
                          </button>
                        </div>
                      </div>
                      <Divider />
                      <Collapse
                        in={verifyOpen(section?.id) /* || !guide?.length */}
                        unmountOnExit={false}
                      >
                        {/* GUIDE */}
                        {guide?.length > 0 &&
                          guide?.map((item, key) => (
                            <S.BoxLine
                              key={key}
                              active={section?.ativa}
                              id={`section-guide-${section.id}-${item.id}`}
                            >
                              <div className="page">
                                <BookmarkAddedOutlinedIcon
                                  htmlColor={
                                    item?.ativa
                                      ? colors.greenSucces
                                      : colors.greyTiny
                                  }
                                />
                                <div className="title">{item?.titulo}</div>
                                <div className="public">{item?.publico}</div>
                                <div className="infos">
                                  <div className="in">
                                    <span>Inciar em:</span> {item?.rota}
                                  </div>
                                  <div className="in">
                                    <span>Etapas:</span> {item?.etapas?.length}
                                  </div>
                                </div>
                                <div className="feedbacks">
                                  {feedback?.map((feed, k) => (
                                    <div
                                      className="box"
                                      key={k}
                                      onClick={() => {
                                        setPage(item);
                                        setModalStatistics(feed.type);
                                      }}
                                    >
                                      {item.feedback?.[feed.type]?.length || 0}
                                      {feed?.icon}
                                    </div>
                                  ))}
                                </div>
                              </div>
                              <div className="actions">
                                <Switch
                                  check={item?.ativa}
                                  setCheckbox={() =>
                                    handleCheckGuide(section?.id, item?.id)
                                  }
                                />
                                <EditOutlinedIcon
                                  onClick={() => {
                                    setScreen(1);
                                    setPage(item);
                                  }}
                                  className="edit"
                                  htmlColor={colors.greenMain}
                                />
                              </div>
                            </S.BoxLine>
                          ))}
                        {!guide?.length && (
                          <S.EmptySection>
                            <DomainVerificationOutlinedIcon />
                            <div className="text">
                              Adicione um guia na seção para que ela seja
                              exibida ao público
                            </div>
                          </S.EmptySection>
                        )}
                        <S.NewGuide
                          onClick={() => {
                            setSection(section);
                            setScreen(1);
                          }}
                        >
                          <AddCircleOutlineOutlinedIcon />
                          <p>Adicionar Novo Guia</p>
                        </S.NewGuide>
                      </Collapse>

                      {!!section?.guia?.length && (
                        <S.SeeAll
                          onClick={() => handleCollapse(section?.id)}
                          active={section?.ativa}
                        >
                          <div className="text">
                            {verifyOpen(section?.id)
                              ? 'Ocultar Guias'
                              : 'Mostrar Guias'}
                          </div>
                          <ExpandMoreRoundedIcon
                            style={{
                              transform: verifyOpen(section?.id)
                                ? 'rotate(180deg)'
                                : 'rotate(0deg)',
                              transition: 'all 0.2s ease-in-out',
                            }}
                          />
                        </S.SeeAll>
                      )}
                    </S.SectionBox>
                  );
                })}

              {!data[tab]?.length && (
                <EmptyDataCard
                  image="frota.png"
                  title={`Nenhuma seção cadastrada em ${tabs
                    ?.find(item => item.value === tab)
                    ?.label?.toLowerCase()}`}
                  subtitle="Adicione uma seção para esse produto"
                />
              )}
            </S.Box>
            <PageGuia
              setScreen={setScreen}
              page={page}
              setPage={setPage}
              setSection={setSection}
              update={updateByGuide}
              section={section}
              routes={filterRoutes()}
              scrollView={scrollView}
            />
          </S.Content>
        </>
      )}

      {/* Modal Area */}
      <ModalSort
        title={section?.titulo}
        data={section?.guia}
        open={modalSortGuide}
        section={section}
        handleClose={() => {
          setSection(null);
          setModalSortGuide(false);
        }}
        handleSave={handleOrderGuides}
      />

      {modalStatistics && page?.feedback && (
        <ModalStatistics
          open={modalStatistics}
          handleClose={() => {
            setPage(null);
            setModalStatistics(false);
          }}
          data={page?.feedback}
        />
      )}
    </S.Container>
  );
};
