import styled, { css } from 'styled-components';
import { MenuItem, Select } from '@mui/material';

import { Button } from '@mui/material';

const SelectCustom = styled(Select)`
  && {
    text-transform: none;
    color: #656e8c;
    background: ${props => props.backgroundcolor};
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    height: 45px;
    transition: opacity 300ms;
    transform: translateY(2px);
    margin-left: 10px;
    margin-right: 10px;
  }
`;

const Item = styled(MenuItem)`
  background: #fff;
  border: 1px solid #939aab4d;
  border-radius: 4px;
  margin-left: 10px;
  margin-bottom: 4px;
  margin-right: 10px;
  padding: 8px 16px 8px 16px;
`;

export { Item, SelectCustom };
