import {
  SUSPENSAO_ID,
  getStatus,
} from 'pages/AcoesSuspensoes/AcoesSuspensoes/ListAcoes/constants';

export const iconsCards = {
  acoes_pendentes: 'acoes_pendentes.svg',
  acoes_finalizadas: 'acoes_finalizadas.svg',
};

export const columns = [
  {
    header: 'Matricula',
    id: 'matricula',
    type: 'string',
    sort: true,
  },
  {
    header: 'Pontos',
    id: 'pontos',
    type: 'number',
    sort: true,
    align: 'center',
  },
  {
    header: 'Faixa atual',
    id: 'faixa_atual',
    type: 'string',
    sort: true,
    conditional: [
      {
        condition: value => value === 'Nenhuma',
        style: (theme, item) => ({
          color: theme.palette.words.text.natural,
        }),
      },
      {
        condition: value => value !== 'Nenhuma',
        style: (theme, item) => ({
          color: item.faixa_cor,
          textTransform: 'uppercase',
        }),
      },
    ],
  },
  {
    header: 'Total de Ações',
    id: 'total_acoes',
    type: 'number',
    sort: false,
    align: 'center',
  },
  {
    header: 'Última Ação gerada',
    id: 'ultima_acao_disciplinar',
    type: 'string',
    conditional: [
      {
        condition: (_, item) => item.is_bloqueado,
        style: theme => ({
          color: theme.palette.semantics.feedback.attention.natural,
        }),
      },
    ],
  },
  {
    header: 'Data Últ. Ação',
    id: 'data_ultima_acao',
    type: 'date',
    sort: true,
  },
  {
    header: 'Status Últ. Ação',
    id: 'ultimo_status',
    type: 'string',
    sort: true,
    width: 200,
    align: 'center',
    conditional: [
      {
        condition: value => value === 'APLICADO',
        style: theme => ({
          color: theme.palette.semantics.feedback.success.natural,
          backgroundColor: theme.palette.semantics.feedback.success.light,
          textAlign: 'center',
        }),
      },
      {
        condition: value => value === 'PENDENTE',
        style: theme => ({
          color: theme.palette.semantics.feedback.warning.natural,
          backgroundColor: theme.palette.semantics.feedback.warning.light,
          textAlign: 'center',
        }),
      },
    ],
  },
];
