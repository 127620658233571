import styled from 'styled-components';

export const Preview = styled.div`
  display: flex;
  border-radius: var(--border_radius, 4px);
  border: 1px solid var(--neutral400_30, rgba(147, 154, 171, 0.3));
  background: var(--neutral0, #fff);
  width: 100%;
  max-width: 740px;

  /* Additional styles for certificate content */
  iframe,
  .certificate {
    width: 100%;
    height: 100%;
    border: none;
  }

  body {
    font-family: 'Texta', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    overflow: hidden;
    color: #424449;
  }
`;
