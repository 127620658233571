import React from 'react';
// components custom
import Tabs from 'components/Tabs';
import { History } from 'components/Graphs/History';

export const HistoryDrivers = ({
  tab = 'ptsHr',
  data = [],
  handleChangeTab = () => false,
  labelsTab = [],
  filters,
  loading,
}) => {
  return (
    <History
      titulo="Histórico de pontos"
      data={data}
      series={['Empresa', 'Motorista']}
      series_divs={['pontos', 'horas']}
      loading={loading}
      leftHeaderComponent={
        <Tabs
          value={tab}
          onChange={(e, value) => handleChangeTab(value)}
          items={labelsTab}
        />
      }
      initialDate={filters?.initialDate}
      finalDate={filters?.finalDate}
    />
  );
};
