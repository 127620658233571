import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { startOfDay, subDays } from 'date-fns';
import { useNavigate } from 'react-router-dom';

import { Divider, Skeleton } from '@mui/material';
import FiltersGlobal from 'components/FiltersGlobal';
import Card from 'pages/Home/FatorRisco/Cards/Card';
import { HeaderlessTable } from 'components/_Table/templates/headerless';

import * as S from './styled';
import {
  getDesviosSemMotorista,
  getCardsDesviosSemMotorista,
} from '../services';
import { usePlans } from 'hooks/usePlans';
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';
import { columnsMotorista, columnsPlaca } from './constants';
import { toast } from 'react-toastify';

export const NaoIdentificados = () => {
  const { isProvider } = usePlans();
  const navigate = useNavigate();
  const selects = useSelector(state => state.selects);
  const { clients, filiais, desviosTiposPadrao } = useFetchMultipleWithCache();

  const empresasFilter =
    selects?.empresas?.map(i => {
      return { label: i.nome, value: i.id };
    }) || [];

  const [filter, setFilter] = useState({
    initialDate: startOfDay(subDays(new Date(), 30)),
    finalDate: new Date(),
  });

  const { data, isFetching: loading } = useQuery(
    ['motoristas-nao-identificados', filter],
    () => getDesviosSemMotorista({ ...filter, filiais: filter.filial }),
    {
      refetchOnWindowFocus: false,
      enabled: !!filter.initialDate,
    },
  );

  const { data: dataCards, isFetching: loadingCards } = useQuery(
    ['motoristas-nao-identificados-cards', filter],
    () => getCardsDesviosSemMotorista({ ...filter, filiais: filter.filial }),
    {
      refetchOnWindowFocus: false,
      enabled: !!filter.initialDate,
    },
  );

  const handleFilters = _filter => {
    setFilter(state => ({ ...state, ..._filter }));
  };

  const filtersProviders = [
    {
      filterName: 'desvios',
      label: 'Desvios',
      options: desviosTiposPadrao?.data || [],
    },
    {
      filterName: 'empresas',
      label: 'Empresas',
      options: empresasFilter || [],
    },
    {
      filterName: 'filial',
      label: 'Filiais',
      options: filiais || [],
    },
  ];

  const filters = [
    {
      filterName: 'desvios',
      label: 'Desvios',
      options: desviosTiposPadrao?.data || [],
    },
    {
      filterName: 'clients',
      label: 'Clientes',
      options: clients.data || [],
    },
  ];

  const actions = [
    {
      title: 'Ver perfil',
      function: (id, item) => {
        if (item.id_motorista) navigate(`/motoristas/${item.id_motorista}`);
        else
          toast.error('Não foi possível acessar os detalhes deste motorista.');
      },
    },
  ];

  return (
    <S.Container>
      <Divider />
      <br />

      <FiltersGlobal
        hideRefleshButton
        hideExportButton
        customComponent={<h1>Motorista não identificado</h1>}
        data={isProvider ? filtersProviders : filters}
        isFetching={loading}
        handleFilters={handleFilters}
      />

      <br />

      <S.Flex>
        <S.Cards>
          {!loadingCards
            ? dataCards?.map(item => (
                <Card
                  key={item.text}
                  width="200px"
                  title={item.text}
                  percentage={item?.value === 'NaN' ? 0 : item?.value ?? 0}
                  valueFooter={
                    <div>
                      Porcentagem do todo:{' '}
                      <span>
                        {item.porcentual === 'Infinity' ? 100 : item.porcentual}
                      </span>
                    </div>
                  }
                />
              ))
            : [1, 2, 3].map(item => (
                <Skeleton
                  width={200}
                  height={105}
                  style={{ marginBottom: '16px' }}
                  variant="rounded"
                  animation="wave"
                />
              ))}
        </S.Cards>

        <S.TableContainer>
          <h2>Desvios atribuídos por placa</h2>
          <p>
            Placas com maior número de desvios com motorista não identificado
          </p>
          <HeaderlessTable
            compactRow
            data={data?.placas || []}
            columns={columnsPlaca}
            loading={loading}
            local
            sortBy={{ id: 'total_desvios', order: 'DESC' }}
            empty={{ image: null }}
            fixedItems={5}
          />
        </S.TableContainer>

        <S.TableContainer>
          <h2>Desvios atribuídos por motorista</h2>
          <p>
            Motoristas com maior número de desvios atribuídos posteriormente
          </p>
          <HeaderlessTable
            compactRow
            data={data?.motoristas || []}
            columns={columnsMotorista}
            actions={actions}
            loading={loading}
            local
            sortBy={{ id: 'total_desvios', order: 'DESC' }}
            empty={{ image: null }}
            fixedItems={5}
          />
        </S.TableContainer>
      </S.Flex>
    </S.Container>
  );
};
