import React, { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';

import { Divider, Menu, MenuItem } from '@mui/material';
import Tabs from 'components/Tabs';

import InfoCard from 'components/Cards/InfoCard';
import FiltersGlobal from 'components/FiltersGlobal';
import Cards from 'pages/_templates/ListPage/components/Cards';
import ExcelModal from 'components/ExcelModalNew';
import { DefaultTable } from 'components/_Table/templates/default';
import { generateFileName } from 'utils/generateFileName';

import {
  getAcoesSuspensoes,
  getCards,
  fetchClientsWithFaixas,
  getTotal,
} from './services';
import { columns, columnsProvider } from './constants';

import {
  getAcaoDisciplinar,
  getFaixasAcoes,
  getStatusAcaoSuspensao,
} from 'constants/_SERVICES/user';
import { trackEvent } from 'utils/mixpanel';
import { downloadFromLink } from 'utils/helpers';
import { usePlans } from 'hooks/usePlans';
import { useFetchMultipleWithCache } from 'hooks/fetchFilters';
import { setFilter } from 'store/modules/filterAcoesSuspensoes/actions';
import { setFilterProvider } from 'store/modules/provider/filterAcoesSuspensoesProvider/actions';

const ListAcoes = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id: idEmpresa } = useParams();
  const user = useSelector(state => state.auth?.user?.user);
  const { isProvider, isOpLogistico, hasTorrePlus } = usePlans();

  const filterAcoesSuspensoes = useSelector(state =>
    isProvider
      ? state.filterAcoesSuspensoesProvider
      : state.filterAcoesSuspensoes,
  );
  const empresas = useSelector(state => state.selects.empresas);

  const [tab, setTab] = useState(filterAcoesSuspensoes.client ?? '');
  const [anchorEl, setAnchorEl] = useState(null);
  const [query, setQuery] = useState(null);
  const [resetTable, setResetTable] = useState(false);
  const [loadingExcel, setLoadingExcel] = useState(false);
  const [title, setTitle] = useState('Ações e Suspensões');
  const filtersPersist = useSelector(state => {
    return state?.filter?.filters?.['acoes-suspensoes'];
  });

  // Set filter by queryparams
  useEffect(() => {
    if (isProvider) {
      const newfilter = {};
      const empresa = idEmpresa ? empresas.find(e => e.id == idEmpresa) : null;

      if (idEmpresa && empresa) {
        newfilter.empresa = idEmpresa;
        setTitle(empresa.nome);
        dispatch(setFilterProvider(newfilter));
      } else if (!idEmpresa && isOpLogistico) {
        setTitle('Ações e Suspensões');
        newfilter.empresa = '';
        dispatch(setFilterProvider(newfilter));
      } else if (!(isOpLogistico || hasTorrePlus)) {
        setTitle('Ações e Suspensões');
        navigate('/acoes-suspensoes/empresas');
      }
    }
  }, [idEmpresa, isProvider, isOpLogistico]);

  const { data: clients = [] } = useQuery(
    ['clients-with-faixas'],
    () => fetchClientsWithFaixas(),
    {
      enabled: !isProvider,
      staleTime: Infinity,
    },
  );

  const { data: resData, isFetching } = useQuery(
    ['acoes-suspensoes', query],
    () => query && getAcoesSuspensoes(query),
    {
      refetchOnWindowFocus: false,
      onSettled: () => setResetTable(false),
    },
  );

  // Atualiza cards de acordo com os filtros selecionados
  // Atualiza tabela após cards
  const {
    refetch: fetchCards,
    isLoading: fetchingCards,
    data: resCards,
  } = useQuery(
    [
      'cards-acoes-suspensoes',
      {
        ...filterAcoesSuspensoes,
        card: '',
      },
    ],
    () =>
      getCards({
        ...filterAcoesSuspensoes,
        card: '',
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  // Atualiza total de acordo com os filtros selecionados
  // Atualiza tabela após cards
  const {
    refetch: fetchTotal,
    isLoading: fetchingTotal,
    data: resTotal,
  } = useQuery(
    [
      'total-acoes-suspensoes',
      {
        ...filterAcoesSuspensoes,
      },
    ],
    () =>
      getTotal({
        ...filterAcoesSuspensoes,
      }),
    {
      refetchOnWindowFocus: false,
    },
  );

  const handleChangeTab = tab => {
    setTab(tab);
    dispatch(setFilter({ client: tab }));
    setResetTable(true);
  };

  // Tabs de transportador funcionam como filtro
  const headerTabs = {
    value: tab,
    items: [{ value: '', label: 'Minhas ações' }, ...clients],
    onChange: (_, newValue) => handleChangeTab(newValue),
    disabled: isFetching || fetchingCards,
  };

  // avisa a tabela que houve alteracao nos filtros
  const initial = useRef(true);
  useEffect(() => {
    if (initial.current) {
      initial.current = false;
      return;
    }

    setResetTable(true);
    fetchCards();
    fetchTotal();
  }, [filterAcoesSuspensoes]);
  // -------------------------- CARDS ------------------------------------------//
  const handleClickCard = type => {
    if (!fetchingCards && !isFetching) {
      const cards = resCards || [];
      const card = cards.find(item => item.type === type);
      if (card.value) {
        let _card = '';
        if (type !== filterAcoesSuspensoes.card) _card = type;
        dispatch(
          isProvider
            ? setFilterProvider({ card: _card })
            : setFilter({ card: _card }),
        );
        setResetTable(true);
      }
    }
  };

  // açoes da tabela
  const actions = [
    {
      title: 'Ver detalhe',
      function: (id, item) =>
        item?.id_ultima_acao
          ? navigate(
              `/acoes-suspensoes/${item.id_motorista}?id_acao=${
                item?.id_ultima_acao
              }${
                filterAcoesSuspensoes.client
                  ? `&id_cliente=${filterAcoesSuspensoes.client}`
                  : ''
              }`,
            )
          : toast.warning('Motorista não possui ação'),
    },
  ];

  const sortBy = {
    id: 'data_ultima_acao',
    order: 'DESC',
  };

  // FILTER OPTIONS
  const { data: faixas = [] } = useQuery(
    ['faixas-filter', tab],
    () => getFaixasAcoes(!isProvider ? { client: tab } : {}),
    { refetchOnWindowFocus: false },
  );

  const { data: acoesDisciplinares = [] } = useQuery(
    ['acao-disciplinar-filter'],
    () => getAcaoDisciplinar(),
    { staleTime: Infinity },
  );

  const { data: status = [] } = useQuery(
    ['status-filter'],
    () => getStatusAcaoSuspensao(),
    { staleTime: Infinity },
  );

  const selects = useSelector(state => state.selects);
  const empresasFilter =
    selects?.empresas?.map(i => {
      return { label: i.nome, value: i.id };
    }) || [];
  const { filiais } = useFetchMultipleWithCache();

  let filters = [
    {
      label: 'Faixa',
      options: faixas ?? [],
      multiple: false,
      placeholder: 'Selecione a faixa',
      filterName: 'faixa',
    },
    {
      label: 'Ação disciplinar',
      options: acoesDisciplinares ?? [],
      multiple: false,
      placeholder: 'Selecione a ação disciplinar',
      filterName: 'acaoDisciplinar',
    },
    {
      label: 'Status',
      options: status ?? [],
      multiple: false,
      placeholder: 'Selecione o status',
      filterName: 'status',
    },
  ];

  let filtersProvider = [
    ...filters,
    {
      filterName: 'empresas',
      label: 'Empresas',
      options: empresasFilter || [],
    },
    {
      filterName: 'filial',
      label: 'Filiais',
      options: filiais || [],
    },
  ];

  // -------------------------- EXCEL ------------------------------------------//
  const [openExcelModal, setOpenExcelModal] = useState(false);
  const [typeExcel, setTypeExcel] = useState('');

  // const handleRequestExcel = useCallback(
  //   async type => {
  //     setLoadingExcel(true);
  //     const newQuery = {
  //       ...query,
  //     };
  //     const res =
  //       type === 'motoristas'
  //         ? await requestExcelMotoristas(newQuery)
  //         : await requestExcelAcoes(newQuery);
  //     if (res.link) {
  //       downloadFromLink(res.link);
  //       toast.success(res.message);
  //     } else if (res.message) toast.error(res.message);

  //     setLoadingExcel(false);
  //   },
  //   [query],
  // );

  return (
    <>
      <FiltersGlobal
        persistDate
        hideDate
        hideRefleshButton
        customComponent={<h1>{title}</h1>}
        isFetching={isFetching}
        handleFilters={props => {
          dispatch(
            isProvider
              ? setFilterProvider({ ...props })
              : setFilter({
                  ...props,
                }),
          );
        }}
        handleExport={e => setAnchorEl(e.currentTarget)}
        data={isProvider && hasTorrePlus ? filtersProvider : filters}
      />
      {openExcelModal && (
        <ExcelModal
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          query={query}
          file_name={generateFileName(
            window.location.pathname.split('/').pop(),
            filtersPersist,
          )}
          route={
            typeExcel === 'motoristas'
              ? '/excel/acao_suspensao_motoristas'
              : '/excel/acao_suspensao'
          }
          filtersPersis={filtersPersist}
          collumns={isProvider ? filtersProvider : filters}
        />
      )}
      <Menu
        id="menu-appbar"
        anchorEl={anchorEl}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        open={Boolean(anchorEl)}
        onClose={() => setAnchorEl(null)}
      >
        <MenuItem
          onClick={() => {
            setTypeExcel('motoristas');
            setOpenExcelModal(true);
          }}
        >
          Motoristas
        </MenuItem>
        <MenuItem
          onClick={() => {
            setTypeExcel('acoes');
            setOpenExcelModal(true);
          }}
        >
          Ações
        </MenuItem>
      </Menu>
      {!isProvider && (
        <>
          <Tabs {...headerTabs} />
          <Divider />
        </>
      )}

      {tab ? (
        <InfoCard
          message="As ações disciplinares do cliente são apenas sugestões de ações a serem tomadas para cada faixa de pontuação. A suspensão pode ser obrigatória, de acordo com a configuração do cliente."
          key="info"
        />
      ) : (
        <InfoCard
          message="Motoristas que estiverem suspensos não terão decaimentos de pontos."
          key="info"
        />
      )}

      <Cards
        cards={resCards?.map(card => ({ ...card, disabled: isFetching })) || []}
        selectedCard={filterAcoesSuspensoes.card}
        loadingCards={fetchingCards}
        handleClickCard={handleClickCard}
      />

      <DefaultTable
        data={resData?.data?.data || []}
        columns={
          isProvider && !(isOpLogistico || hasTorrePlus)
            ? columnsProvider
            : columns
        }
        loading={isFetching}
        pageCount={resTotal || 0}
        loadingCounter={fetchingTotal}
        local={false}
        actions={actions}
        reset={resetTable}
        onClickRow={(id, item) => {
          item?.id_ultima_acao
            ? navigate(
                `/acoes-suspensoes/${item?.id_motorista}?id_acao=${
                  item?.id_ultima_acao
                }${
                  filterAcoesSuspensoes.client
                    ? `&id_cliente=${filterAcoesSuspensoes.client}`
                    : ''
                }`,
              )
            : toast.warning('Motorista não possui ação');
        }}
        setQuery={q => {
          setQuery({
            ...filterAcoesSuspensoes,
            ...q,
          });
        }}
        searchEvent={search =>
          trackEvent(user, 'Busca Ações e Suspensões de empresa', null, search)
        }
        sortBy={sortBy}
        placeholder={
          isProvider && !isOpLogistico
            ? 'Buscar Matrícula ou Faixa'
            : 'Buscar Motorista'
        }
        empty={{
          title: 'Ops! Você não tem nenhuma ação ou suspensão disponível.',
          description: 'Verifique os filtros aplicados!',
        }}
      />
    </>
  );
};

export default ListAcoes;
