import styled, { css } from 'styled-components';
import { Modal } from '@mui/material';

export const ModalTurmas = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  width: 100%;
  max-height: 100%;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
`;

export const ContainerModal = styled.div`
  ${({ theme }) => css`
    width: 65%;
    max-height: 96%;
    background-color: ${theme.palette.system.overlay};
    border: 2px solid ${theme.palette.system.border};
    border-radius: 4px;
    padding: 10px 20px;
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
  `}
`;

export const ButtonContianer = styled.div`
  display: flex;
  justify-content: space-between;

  .titleTurma {
    text-transform: capitalize;
  }
`;

export const TableContainer = styled.div`
  ${({ theme }) => css`
    width: 100%;
    position: relative;

    .banner {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: 361px;
      background-color: ${theme.palette.system.overlay};
      border: 2px solid ${theme.palette.system.border};
      border-radius: 4px;
      padding: 30px;
      margin-top: 20px;

      h3 {
        margin-top: 10px;
        margin-bottom: 10px;
        font-weight: 500;
        color: ${theme.palette.words.title.natural};
      }
    }
  `}
`;

export const Paragraph = styled.p`
  ${({ theme, ...props }) => css`
    font-size: ${props.fontsize ? props.fontsize : '16px'};
    color: ${props.color ? props.color : theme.palette.brand.primary.light};
    font-weight: ${props.fontweight ? props.fontweight : 400};
    min-width: ${props.minwidth};

    &.status {
      font-weight: bold;
      color: ${props.color};
      background-color: ${props.bg};
      text-align: center;
      border-radius: 100px;
    }
  `}
`;

export const ColDriver = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    text-transform: capitalize;

    h1 {
      font-size: 14px;
      font-weight: 600;
      color: ${theme.palette.words.title.natural};
    }

    .container-img-name {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    img {
      width: 41px;
      height: 41px;
      border: 1px solid ${theme.palette.system.border};
      border-radius: 100px;
    }
  `}
`;

export const AbsoluteButton = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  margin-top: 30px;
`;
