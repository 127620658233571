import * as S from './styled';
import { useEffect, useState, React } from 'react';
import { toast } from 'react-toastify';
import PreviewConfig from './components/previewConfig';
import Preview from './components/preview';
import { useNavigate, useParams } from 'react-router-dom'; // Add useParams
import GhostButton from 'components/Buttons/Ghost';
import { useTheme } from 'styled-components';
import {
  getTemplateById,
  deleteTemplate,
  updateTemplate,
  createTemplate,
} from './service';
import AlertModal from 'components/AlertModal';
import { useQuery } from 'react-query';
import BorderColorOutlinedIcon from '@mui/icons-material/BorderColorOutlined';
import UnderlinedInput from 'components/Inputs/_withController/UnderlinedInput';
import { useForm } from 'react-hook-form';
import { InputAdornment } from '@mui/material';

const defaultValues = {
  id: null,
  id_empresa: null,
  id_distribuidora: null,
  id_criador: null,
  logo: '',
  cabecalho: '',
  assinatura_1: '',
  assinatura_1_img: '',
  assinatura_1_ativo: false,
  assinatura_2: '',
  assinatura_2_img: '',
  assinatura_2_ativo: false,
  assinatura_3: '',
  assinatura_3_img: '',
  assinatura_3_ativo: false,
  is_default: false,
  exibe_criador: false,
  titulo: '',
};

const DetalheCertificado = ({ edit }) => {
  const { id } = useParams();
  const [titulo, setTitulo] = useState('Novo Certificado');
  const theme = useTheme();
  const navigate = useNavigate();
  const [templateData, setTemplateData] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteError, setDeleteError] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [saveLoading, setSaveLoading] = useState(false);

  const { data: certificadoResponse, isFetching: loading } = useQuery(
    ['certificado', id],
    () => getTemplateById(id),
    {
      onError: error =>
        toast.error(error.message || 'Erro ao carregar template'),
      enabled: !!edit && !!id,
      refetchOnWindowFocus: false,
    },
  );

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
    watch,
  } = useForm({
    defaultValues,
    resetOptions: {
      keepDirtyValues: true,
    },
  });

  useEffect(() => {
    if (certificadoResponse?.success && certificadoResponse?.data && edit) {
      const templateData = certificadoResponse.data;

      const formData = {
        id: templateData.id,
        id_empresa: templateData.id_empresa,
        id_distribuidora: templateData.id_distribuidora,
        id_criador: templateData.id_criador,
        logo: templateData.logo || '',
        cabecalho: templateData.cabecalho || '',
        assinatura_1: templateData.assinatura_1 || '',
        assinatura_1_img: templateData.assinatura_1_img || '',
        assinatura_1_ativo: Boolean(templateData.assinatura_1_ativo),
        assinatura_2: templateData.assinatura_2 || '',
        assinatura_2_img: templateData.assinatura_2_img || '',
        assinatura_2_ativo: Boolean(templateData.assinatura_2_ativo),
        assinatura_3: templateData.assinatura_3 || '',
        assinatura_3_img: templateData.assinatura_3_img || '',
        assinatura_3_ativo: Boolean(templateData.assinatura_3_ativo),
        is_default: Boolean(templateData.is_default),
        exibe_criador: Boolean(templateData.exibe_criador),
        titulo: templateData.titulo || '',
      };

      reset(formData);
    }
  }, [certificadoResponse, edit, reset]);

  const handleConfirmDelete = async () => {
    setIsDeleting(true);
    setDeleteError(null);

    try {
      const response = await deleteTemplate(id);
      if (response.success) {
        toast.success('Template excluído com sucesso!');
        setShowDeleteModal(false);
        navigate(-1);
      } else {
        setDeleteError(response.message || 'Erro ao excluir template');
      }
    } catch (error) {
      console.error('Error deleting template:', error);
      setDeleteError('Erro ao excluir template');
    } finally {
      setIsDeleting(false);
    }
  };

  const handleSaveCertificado = async data => {
    try {
      setSaveLoading(true);
      const req = edit
        ? await updateTemplate(id, data)
        : await createTemplate(data);

      if (req.success) {
        toast.success(req.message || 'Template salvo com sucesso!');
        navigate(-1);
      } else {
        toast.error(req.message || 'Erro ao salvar template');
      }
    } catch (error) {
      console.error('Erro ao salvar:', error);
      toast.error('Erro ao salvar o template');
    } finally {
      setSaveLoading(false);
    }
  };

  const formValues = watch();

  return (
    <S.PageThreeContainer>
      <form id="template-form" onSubmit={handleSubmit(handleSaveCertificado)}>
        <S.ContentContainer>
          <S.NovoCertificadoContainer>
            <S.TitleGroup>
              <S.TitleAndIconContainer>
                <UnderlinedInput
                  control={control}
                  errors={errors}
                  name="titulo"
                  placeholder="Novo Certificado"
                  rules={{
                    required: {
                      value: true,
                      message: 'Campo obrigatório.',
                    },
                  }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <BorderColorOutlinedIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </S.TitleAndIconContainer>
            </S.TitleGroup>
            <S.PreviewEPreviewConfigContainer>
              <PreviewConfig control={control} errors={errors} />
              <Preview
                nome_aluno="Nome do Aluno"
                matricula="123.456.789-10"
                title_conteudo="Título do Curso"
                horas_aula={99}
                inicio="01/01/2025"
                fim="01/02/2025"
                data_validade="01/02/2026"
                nota="9.5"
                autor="Nome do Autor"
                data_atual={new Date().toLocaleDateString()}
                gerente_ssma="Nome do Gerente"
                cnpj="12.345.678/0001-90"
                template={{
                  cabecalho: formValues.cabecalho,
                  exibe_criador: formValues.exibe_criador,
                  logo: formValues.logo,
                  assinatura_1_ativo: formValues.assinatura_1_ativo,
                  assinatura_1: formValues.assinatura_1,
                  assinatura_1_img: formValues.assinatura_1_img,
                  assinatura_2_ativo: formValues.assinatura_2_ativo,
                  assinatura_2: formValues.assinatura_2,
                  assinatura_2_img: formValues.assinatura_2_img,
                  assinatura_3_ativo: formValues.assinatura_3_ativo,
                  assinatura_3: formValues.assinatura_3,
                  assinatura_3_img: formValues.assinatura_3_img,
                }}
              />
            </S.PreviewEPreviewConfigContainer>
            <S.BottomButtonsContainer>
              <S.LeftButtonGroup>
                {id && (
                  <GhostButton
                    children="Excluir template"
                    onClick={() => setShowDeleteModal(true)}
                    customcolor={
                      theme.palette.semantics.feedback.attention.natural
                    }
                  />
                )}
              </S.LeftButtonGroup>

              <S.RightButtonGroup>
                <GhostButton
                  children="Voltar"
                  onClick={() => navigate('/capacitacao/certificados')}
                  customcolor={
                    theme.palette.semantics.feedback.attention.natural
                  }
                />
                <GhostButton
                  type="submit"
                  form="template-form"
                  onClick={() => {}}
                  loading={saveLoading}
                  children="Salvar template"
                />
              </S.RightButtonGroup>
            </S.BottomButtonsContainer>
          </S.NovoCertificadoContainer>
        </S.ContentContainer>
      </form>

      {/* Delete Confirmation Modal */}
      <AlertModal
        open={showDeleteModal}
        handleClose={() => {
          setShowDeleteModal(false);
          setDeleteError(null);
        }}
        onClick={handleConfirmDelete}
        title={`Excluir ${templateData?.titulo || 'template'}?`}
        subtitle={
          deleteError ||
          'Caso esse certificado esteja associado a algum conteúdo, ele voltará ao modelo padrão. Deseja prosseguir?'
        }
        buttonText="Excluir"
        backButtonText="Cancelar"
        loading={isDeleting}
        error={deleteError}
      />
    </S.PageThreeContainer>
  );
};

export default DetalheCertificado;
