import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  border: 1px solid #939aab4d;
  border-radius: 4px;
  background: #fff;
  padding: 1rem;

  @media only screen and (min-width: 900px) {
    flex-direction: row;
    align-items: start;
  }
`;

export const Container = styled.div`
  width: '100%';
  flex: ${({ flex }) => flex ?? 1};
`;

export const Flex = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 1rem;

  &.justify-end {
    justify-content: end;
  }
  &.justify-between {
    justify-content: space-between;
  }
  &.mb-05 {
    margin-bottom: 0.5rem;
  }
  &.mt-05 {
    margin-top: 0.5rem;
  }
`;

export const Title = styled.div`
  width: 100%;
  font-weight: 900;
  font-size: 24px;
  line-height: 30px;
  color: #4b5166;
  &.mb-05 {
    margin-bottom: 0.5rem;
  }
`;
