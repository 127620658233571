import React from 'react';
import * as S from './styled';
import { gerenerateCertificate } from './utils';

const Preview = ({
  nome_aluno = '',
  matricula = '',
  title_conteudo = '',
  horas_aula = 0,
  inicio = '',
  fim = '',
  data_validade = '',
  nota = '',
  autor = '',
  data_atual = '',
  gerente_ssma = '',
  cnpj = '',
  template,
}) => {
  const certificateHTML = gerenerateCertificate(
    nome_aluno,
    matricula,
    title_conteudo,
    horas_aula,
    inicio,
    fim,
    data_validade,
    nota,
    autor,
    data_atual,
    gerente_ssma,
    cnpj,
    template,
  );

  return <S.Preview dangerouslySetInnerHTML={{ __html: certificateHTML }} />;
};

export default Preview;
