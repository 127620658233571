import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { toast } from 'react-toastify';

import { Divider, SvgIcon } from '@mui/material';
import { CheckCircleOutline, DeleteOutline } from '@mui/icons-material';
import { getRouterQueryParams } from 'utils/router-query-params';
import { setFilter } from 'store/modules/filterDesvios/actions';

import { ReactComponent as icMotoristas } from 'images/icons/sidebar/drivers.svg';
import { ReactComponent as icResponsavel } from 'images/icons/sidebar/seguir.svg';

import FiltersGlobal from 'components/FiltersGlobal';
import ExcelModal from 'components/ExcelModalNew';
import AlertModal from 'components/AlertModal';
import Tabs from 'components/Tabs';
import { Modal as SelectMotoristaModal } from 'components/Inputs/SelectMotorista/templates/modal';
import ResponsibleModal from '../components/ResponsibleModal';
import FinishModal from '../components/FinishModal';
import DeleteModal from '../components/DeleteModal';
import Deslocamento from '../components/Deslocamento';
import {
  ButtonApply,
  Header,
  TabsContainer,
  TabsListagem,
  TabsStatus,
  TabsStatusItem,
  TabsUpdated,
} from './styled';

import { ReactComponent as IconAdd } from 'images/icons/components/add-btn.svg';

import {
  aprovarDesvios,
  atribuirResponsavel,
  finalizarDesvios,
  getDeslocamentos,
  tratarDesvios,
  excluirDesvio,
  salvarDesvio,
} from './services';
import { setSortBy } from 'store/modules/filterDesvios/actions';
import { fields, statusTabs, tipoListagemOptions } from './constants';
import DesvioManualModal from '../components/DesvioManualModal';
import { usePlans } from 'hooks/usePlans';
import { getIndicator, indicadorAnexoExclusao } from 'hooks/useParams';

import { trackEvent } from 'utils/mixpanel';
import { generateFileName } from 'utils/generateFileName';

import { format, subDays } from 'date-fns';
import { useFilterOptions } from './filterOptions';

import { ListaAgrupada } from './ListaAgrupada';
import { ListaPreview } from './ListaPreview';

const today = new Date();
const initialDate = subDays(today, 30);
const finalDate = today;

const Desvios = () => {
  // Redux e hooks
  const listaRef = useRef(null);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isOpLogistico, isProvider, hasTorrePlus, hasTorre } = usePlans();
  const user = useSelector(state => state.auth?.user?.user);
  const userLevel = user.nivel;
  const selects = useSelector(state => state.selects);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [loadingLines, setLoadingLines] = useState([]);
  const [isFetching, setIsFetching] = useState(false);
  const [tableData, setTableData] = useState([]);

  const filtersPersist = useSelector(state => {
    return state?.filter?.filters?.desvios;
  });

  const filterDesvios = useSelector(state => state.filterDesvios);

  const [query, setQuery] = useState({
    sortBy: { id: 'data_desvio', order: 'DESC', desc: true },
    pageIndex: 0,
    pageSize: 20,
    initialDate,
    finalDate,
    ...filtersPersist,
    status:
      filterDesvios?.status == 'ABERTO' &&
      isProvider &&
      !(hasTorrePlus || isOpLogistico)
        ? 'PENDENTE'
        : filterDesvios?.status,
  });

  const [alertSelectd, setAlertSelected] = useState([]);
  const [tipoListagem, setTipoListagem] = useState('preview');

  const [selectedInfractions, setSelectedInfractions] = useState([]);
  const [openDesvioManual, setOpenDesvioManual] = useState(false);
  const [responsibleModal, setResponsibleModal] = useState(null);
  const [motoristaModal, setMotoristaModal] = useState({ open: false });
  const [deleteModal, setDeleteModal] = useState(null);
  const [idsFinishModal, setIdsFinishModal] = useState(null);
  const [loadingModal, setLoadingModal] = useState(false);

  const [openExcelModal, setOpenExcelModal] = useState(false);

  const [deslocamentosRisco, setDeslocamentosRisco] = useState([]);

  const filterOptions = useFilterOptions();

  const handleRefetch = () => {
    if (listaRef.current) {
      listaRef.current.refetchCount();
      listaRef.current.refetchLista(); // Chama o refetch exposto pelo filho
    }
  };

  const handleRefetchGeral = () => {
    if (listaRef.current) {
      listaRef.current.refetchGeral();
    }
  };

  const [excelFields, setExcelFields] = useState(
    fields.filter(f => {
      if (isProvider && (hasTorre || hasTorrePlus))
        return f.hasTorre || [1, 2].includes(f.provider);
      if (isProvider) return !f.hasTorre && [1, 2].includes(f.provider);
      return [0, 2].includes(f.provider);
    }),
  );

  // ********************  Deslocamento *****************************
  const { refetch: fetchDeslocamento, isFetching: loadingDeslocamento } =
    useQuery(
      ['deslocamentos', 'ABERTO'],
      () => getDeslocamentos({ tipo: 'ABERTO' }),
      {
        refetchOnWindowFocus: false,
        refetchInterval: hasTorrePlus || hasTorre ? 60000 : false,
        onSuccess: resDataDeslocamento => {
          if (resDataDeslocamento?.data)
            setDeslocamentosRisco(
              resDataDeslocamento?.data
                .filter(item => item.risco !== 'BAIXO')
                .slice(0, 3),
            );
        },
        enabled: hasTorre || hasTorrePlus,
      },
    );

  // Altera tab inicial em caso de url com parametro
  const status = getRouterQueryParams({ location: useLocation }).get('status');

  useEffect(() => {
    statusTabs.map(s => s.value).includes(status) &&
      dispatch(
        setFilter({
          status,
        }),
      );
  }, [statusTabs, status]);

  // Altera tab inicial em caso de Distribuidora
  useEffect(() => {
    if (
      isProvider &&
      !(hasTorrePlus || isOpLogistico) &&
      filterDesvios.status === 'ABERTO'
    )
      dispatch(
        setFilter({
          status: 'PENDENTE',
        }),
      );
  }, []);

  // ********************  ACTIONS / BULKACTIONS *****************************
  // Funções das ações
  const handleAtribuirResponsavel = async (data, approve) => {
    setLoadingModal(true);
    setLoadingLines(data?.ids || []);

    const res = approve
      ? await aprovarDesvios(data)
      : await atribuirResponsavel(data);
    if (res.data?.success) {
      handleRefetch();
      toast.success(res.data?.message);
    } else {
      setLoadingLines([]);
      if (res.data?.message) toast.error(res.data.message);
      if (res.data?.bulkResponse) {
        // TODO: resposta individual
      }
    }
    setLoadingModal(false);
    setResponsibleModal(null);
  };

  const handleDeletar = async (ids, data) => {
    setLoadingModal(true);
    const res = await excluirDesvio(ids, data);
    if (res.data?.success) {
      handleRefetchGeral();
      toast.success(res.data?.message);
    } else {
      setLoadingLines([]);
      if (res.data?.message) toast.error(res.data.message);
    }
    setLoadingModal(false);
    setDeleteModal(null);
  };

  const verifyIdsFinish = (ids, data) => {
    const valid = data
      ?.filter(item => ids.includes(item.id))
      .every(
        item => item.responsavel_nome && item.plano_acao && item.data_previsao,
      );

    if (valid) {
      setIdsFinishModal(ids);
    } else {
      toast.warning(
        'Não é possível finalizar desvios sem usuário responsável, plano ação ou data de previsão.',
      );
    }
  };

  const handleFinalizar = async ids => {
    setLoadingModal(true);
    setLoadingLines(ids || []);

    const res = await finalizarDesvios({ ids });
    if (res.data?.success) {
      handleRefetchGeral();
      toast.success(res.data?.message);
    } else {
      setLoadingLines([]);
      if (res.data?.message) toast.error(res.data.message);
      if (res.data?.bulkResponse) {
        // TODO: resposta individual
      }
    }
    setLoadingModal(false);
    setIdsFinishModal(null);
  };

  const handleAtribuirMotorista = async (id_motorista, ids) => {
    setLoadingModal(true);
    setLoadingLines(ids || []);

    const payload = { id_motorista };
    const res = await salvarDesvio(ids, payload);
    if (res.data?.success) {
      handleRefetchGeral();
      toast.success(res.data?.message);
    } else {
      setLoadingLines([]);
      if (res.data?.message) toast.error(res.data.message);
    }
    setLoadingModal(false);
    setMotoristaModal({ open: false });
  };

  // Troca do tab (filterDesvios.status)
  const handleStatusTab = async (event, newValue) => {
    setQuery({ ...query, status: newValue });
    dispatch(
      setFilter({
        status: newValue,
      }),
    );
  };

  // Configura as tabs da tela
  const headerTabs = {
    value: filterDesvios.status,
    items: statusTabs.filter(
      item =>
        item.value !== 'ABERTO' || !isProvider || isOpLogistico || hasTorrePlus,
    ),
    onChange: handleStatusTab,
    disabled: isFetching,
  };

  // Configura as tabs com icone
  const tipoListagemTabs = {
    value: tipoListagem,
    items: tipoListagemOptions,
    onChange: (_, value) => setTipoListagem(value),
    disabled: isFetching,
  };

  const navigateToDeslocamentos = () => {
    navigate(`/desvios/deslocamentos`);
  };

  const handleTratarDesvio = async data => {
    const res = await tratarDesvios(data);
    if (res.data.success) {
      toast.success(res.data.message);
      fetchDeslocamento();
    }
  };

  useEffect(() => {
    if (alertSelectd.length) {
      for (const alertMsg of alertSelectd) {
        toast.warning(alertMsg);
      }
    }
  }, [alertSelectd]);

  const getAnexoObrigatorio = desvio => {
    const indicadorAnexo = indicadorAnexoExclusao[desvio.criticidade];
    if (!indicadorAnexo) return;
    const anexo = getIndicator(
      indicadorAnexo,
      desvio.id_distribuidora,
      desvio.id_empresa,
      selects.params,
    );
    return anexo;
  };

  // Atualiza bulkActions de acordo com o status selecionado
  // Os itens selecionados podem alterar o a lista de ações
  const getBulkActions = () => {
    let actions = [];
    const data = [...tableData];
    const desviosProprios =
      !isProvider ||
      hasTorrePlus ||
      !data
        .filter(item => selectedInfractions.includes(item.id))
        .some(item => item.id_empresa);

    if (desviosProprios && userLevel < 4) {
      switch (filterDesvios.status) {
        case 'ABERTO':
          // Confere se seleção tem a mesma filial
          const filialIds = [];
          const distIds = [];
          const emRevisao = [];
          const comMotorista = [];
          let anexoObrigatorio = false;
          for (const desvio of data) {
            const filialId = desvio.id_filial;
            const distId = desvio.id_distribuidora;
            const revisao = desvio.aprovado_distribuidora !== null;
            const motorista = !!desvio.id_motorista;
            if (selectedInfractions.includes(desvio.id)) {
              if (!filialIds.includes(filialId)) filialIds.push(filialId);
              if (!distIds.includes(distId)) distIds.push(distId);
              if (revisao) emRevisao.push(desvio.id);
              if (motorista) comMotorista.push(desvio.id);
              const anexo = getAnexoObrigatorio(desvio);
              if (anexo) anexoObrigatorio = true;
            }
          }
          const mesmaFilial = filialIds.length < 2;
          const mesmoCliente = distIds.length < 2;
          const semMotorista = !comMotorista.length;

          let newAlertSelected = [];
          if (!mesmaFilial)
            newAlertSelected.push(
              'Desvios de filiais diferentes não executam a ações em massa: aprovar',
            );
          if (userLevel === 1) {
            if (!mesmoCliente)
              newAlertSelected.push(
                'Desvios de clientes diferentes não executam a ação em massa: excluir',
              );
            if (emRevisao.length)
              newAlertSelected.push(
                'Desvios em revisão não executam a ação em massa: excluir',
              );
          }
          if (newAlertSelected.length != alertSelectd.length)
            setAlertSelected(newAlertSelected);

          actions = [
            semMotorista && {
              icon: <SvgIcon fontSize="small" component={icMotoristas} />,
              title: 'Atribuir motorista',
              function: ids => {
                trackEvent(user, 'ATRIBUIR MOTORISTA NOS DESVIOS');
                setMotoristaModal({ open: true, data: ids });
              },
            },
            mesmaFilial && {
              icon: <SvgIcon fontSize="small" component={icResponsavel} />,
              title: 'Atribuir responsável',
              function: ids => {
                trackEvent(user, 'ATRIBUIR RESPONSAVEL NOS DESVIOS');
                setResponsibleModal({ approve: false, data: ids });
              },
            },
            mesmaFilial && {
              icon: <CheckCircleOutline fontSize="small" />,
              title: 'Aprovar',
              function: ids => {
                trackEvent(user, 'APROVAR TODOS OS DESVIOS');
                setResponsibleModal({ approve: true, data: ids });
              },
            },
            mesmoCliente &&
              userLevel === 1 &&
              !emRevisao.length && {
                icon: <DeleteOutline fontSize="small" />,
                title: 'Excluir',
                variant: 'attention',
                function: ids => {
                  trackEvent(user, 'EXCLUIR TODOS OS DESVIOS');
                  setDeleteModal({ data: ids, anexoObrigatorio });
                },
              },
          ].filter(item => !!item);
          break;

        case 'PENDENTE':
          actions = [
            {
              icon: <CheckCircleOutline fontSize="small" />,
              title: 'Finalizar',
              function: ids => {
                trackEvent(user, 'FINALIZAR TODOS OS DESVIOS');
                verifyIdsFinish(ids, tableData);
              },
            },
          ];
          break;

        default:
          break;
      }
    }
    return actions.filter(Boolean);
  };

  // Atualiza ações de acordo com o status selecionado
  const getActions = () => {
    let actions = [
      {
        title: 'Abrir em nova guia',
        function: handleOpenNewTab,
      },
    ];
    const data = [...tableData];
    if ((!isProvider || isOpLogistico || hasTorrePlus) && userLevel < 4)
      switch (filterDesvios.status) {
        case 'ABERTO':
          actions = [
            ...actions,
            {
              title: 'Aprovar',
              function: id => {
                trackEvent(user, 'APROVAR DESVIOS');
                setResponsibleModal({ approve: true, data: [id] });
              },
            },
            {
              title: 'Excluir',
              function: id => {
                trackEvent(user, 'EXCLUIR DESVIO');
                let anexoObrigatorio = false;
                for (const desvio of data) {
                  if (id === desvio.id) {
                    const anexo = getAnexoObrigatorio(desvio);
                    if (anexo) anexoObrigatorio = true;
                  }
                }
                setDeleteModal({ data: [id], anexoObrigatorio });
              },
            },
          ];
          break;

        case 'PENDENTE':
          actions = [
            ...actions,
            {
              title: 'Finalizar',
              function: id => {
                trackEvent(user, 'FINALIZAR DESVIOS');
                verifyIdsFinish([id], tableData);
              },
            },
          ];
          break;

        default:
          break;
      }
    return actions.filter(Boolean);
  };

  const getIndex = id => {
    const _data = [...tableData];
    dispatch(setSortBy(query.sortBy));

    const dataIndex = _data.findIndex(item => item.id == id);
    if (dataIndex !== -1) {
      return query.pageSize * query.pageIndex + dataIndex;
    }
    return '';
  };

  const handleOpenNewTab = id => {
    return window.open(`/desvios/${id}?index=${getIndex(id)}`);
  };

  return (
    <>
      {(loadingDeslocamento || deslocamentosRisco?.length > 0) && (
        <>
          <div
            style={{
              color: '#4B5166',
              fontWeight: '900',
              fontSize: 24,
              marginBottom: 15,
            }}
          >
            Esses deslocamentos precisam da sua atenção!
          </div>
          <Deslocamento
            data={deslocamentosRisco}
            onSave={handleTratarDesvio}
            loading={loadingDeslocamento}
          />

          <div
            onClick={() => navigateToDeslocamentos()}
            style={{
              textAlign: 'end',
              marginBottom: 30,
              color: ' #ff8040',
              textDecoration: 'underline',
              fontSize: 16,
              cursor: 'pointer',
            }}
          >
            Ver todos os deslocamentos
          </div>
        </>
      )}
      <Header>
        <h1>Lista de desvios</h1>
      </Header>

      <br />
      <FiltersGlobal
        showHours
        persistDate
        customComponent={
          <>
            {(!isProvider || isOpLogistico || hasTorrePlus) &&
              userLevel < 4 && (
                <ButtonApply
                  startIcon={<IconAdd />}
                  onClick={() => {
                    setOpenDesvioManual(true);
                  }}
                >
                  Adicionar desvio manual
                </ButtonApply>
              )}
          </>
        }
        refetch={handleRefetchGeral}
        isFetching={isFetching}
        handleFilters={props => setQuery({ ...query, ...props })}
        handleExport={() => setOpenExcelModal(true)}
        data={filterOptions}
      />
      <br />

      <TabsContainer>
        <TabsStatus>
          <TabsStatusItem>
            <Tabs {...headerTabs} />
            {(hasTorre || hasTorrePlus) && lastUpdated && (
              <TabsUpdated>
                Atualizado ás {format(lastUpdated, "HH'h'mm")}
              </TabsUpdated>
            )}
          </TabsStatusItem>
          <Divider />
        </TabsStatus>
        <TabsListagem>
          <Tabs {...tipoListagemTabs} />
          <Divider />
        </TabsListagem>
      </TabsContainer>

      {tipoListagem === 'preview' && (
        <ListaPreview
          ref={listaRef}
          setTableData={setTableData}
          setSelectedInfractions={setSelectedInfractions}
          loadingLines={loadingLines}
          setLoadingLines={setLoadingLines}
          setIsFetching={setIsFetching}
          setLastUpdated={setLastUpdated}
          getBulkActions={getBulkActions}
          getActions={getActions}
          handleOpenNewTab={handleOpenNewTab}
        />
      )}

      {tipoListagem === 'group' && (
        <ListaAgrupada
          ref={listaRef}
          setTableData={setTableData}
          selectedInfractions={selectedInfractions}
          setSelectedInfractions={setSelectedInfractions}
          loadingLines={loadingLines}
          setLoadingLines={setLoadingLines}
          setIsFetching={setIsFetching}
          setLastUpdated={setLastUpdated}
          getBulkActions={getBulkActions}
          getActions={getActions}
          handleOpenNewTab={handleOpenNewTab}
        />
      )}

      {openExcelModal && (
        <ExcelModal
          open={openExcelModal}
          handleClose={() => setOpenExcelModal(false)}
          excelFields={excelFields}
          query={{
            ...query,
            initialDate: new Date(
              new Date(query.initialDate).setHours(
                new Date(query.initialDate).getHours() - 3,
              ),
            ).toISOString(),
            finalDate: new Date(
              new Date(query.finalDate).setHours(
                new Date(query.finalDate).getHours() - 3,
              ),
            ).toISOString(),
          }}
          file_name={generateFileName(
            `${window.location.pathname.split('/').pop()}${
              tipoListagem !== 'preview' ? '_motoristas' : ''
            }`,
            filtersPersist,
          )}
          route={
            tipoListagem === 'preview'
              ? '/excel/desvios'
              : '/excel/desvios_group'
          }
          filtersPersis={filtersPersist}
          collumns={filterOptions}
        />
      )}

      {responsibleModal && (
        <ResponsibleModal
          approve={responsibleModal?.approve}
          handleConfirm={handleAtribuirResponsavel}
          ids={responsibleModal?.data}
          handleClose={() => setResponsibleModal(null)}
          loading={loadingModal}
        />
      )}

      {deleteModal && (
        <DeleteModal
          handleConfirm={handleDeletar}
          handleClose={() => setDeleteModal(null)}
          ids={deleteModal?.data}
          loading={loadingModal}
          anexoObrigatorio={deleteModal?.anexoObrigatorio}
        />
      )}

      {idsFinishModal && (
        <FinishModal
          handleConfirm={() => handleFinalizar(idsFinishModal)}
          ids={idsFinishModal}
          handleClose={() => setIdsFinishModal(null)}
          loading={loadingModal}
        />
      )}
      {openDesvioManual && (
        <DesvioManualModal
          open={openDesvioManual}
          handleClose={() => setOpenDesvioManual(false)}
          refetchDesvios={handleRefetch}
        />
      )}
      {motoristaModal?.open && (
        <SelectMotoristaModal
          open={!!motoristaModal?.open}
          handleClose={() => setMotoristaModal({ open: false })}
          handleConfirm={(id, motorista) => {
            setMotoristaModal(state => ({
              ...state,
              open: false,
              confirm: true,
              motorista,
            }));
          }}
        />
      )}
      {motoristaModal?.confirm && (
        <AlertModal
          open={!!motoristaModal.confirm}
          handleClose={() => {
            setMotoristaModal(state => ({
              ...state,
              open: true,
              confirm: false,
            }));
          }}
          title="Essa ação é irreversível!"
          subtitle={
            <p>
              Deseja mesmo atribuir o motorista{' '}
              <span>
                {String(motoristaModal?.motorista?.nome || '').toLowerCase()}
              </span>
              ?<br />
              Essa ação não poderá ser desfeita.
            </p>
          }
          buttonText="Continuar"
          onClick={() =>
            handleAtribuirMotorista(
              motoristaModal.motorista.value,
              motoristaModal.data,
            )
          }
        />
      )}
    </>
  );
};

export default Desvios;
