// Styles
import { useTheme } from 'styled-components';

// React
import { useState, useEffect } from 'react';

// Components
import TextInput from 'components/Inputs/TextField';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import GlobalModal from '..';
import Radio from '../Components/Radio';

// Components MUI
import * as S from './styled';

export const ModalAutomaticoGenerico = ({
  open,
  onClose,
  data,
  closeModalItems,
  currentElement,
  section,
}) => {
  const theme = useTheme();
  const [totalContent, setTotalContent] = useState(null);

  // Step 1 (States)
  const [descricao, setDescricao] = useState('');
  const [obrigatorio, setObrigatorio] = useState(false);
  const [hasChanged, setHasChanged] = useState(false);

  // Verify Edit or New
  useEffect(() => {
    if (data) {
      data = JSON.parse(JSON.stringify(data));
      setDescricao(data.descricao);
      setObrigatorio(data.obrigatorio);
    }
  }, [data]);

  const validateStepOne = () => {
    setTotalContent({
      index: data?.index,
      descricao,
      obrigatorio,
    });

    closeModalItems();
    resetFields();
  };

  const resetFields = () => {
    // Reset All fileds
    setDescricao('');
    setObrigatorio(false);
  };

  // -------------------------------------STEP ONE---------------------------------------//
  const stepOne = disabled => {
    const fullDisabled = disabled && data?.id !== undefined;

    return (
      <S.StepOneBox>
        <div className="boxText selection">
          <TextInput
            label={currentElement.nome}
            placeholder="Campo de preenchimento automático"
            value={currentElement.example}
            disabled
          />
          {currentElement.isSelect && (
            <KeyboardArrowDownIcon
              htmlColor={theme.palette.brand.primary.light}
              style={{
                position: 'absolute',
                alignSelf: 'end',
                marginBottom: '-35px',
                marginRight: '10px',
              }}
            />
          )}
        </div>

        <div className="boxText">
          <TextInput
            label="Descrição (opicional)"
            placeholder="Insira uma descrição"
            value={descricao}
            onChange={e => {
              setHasChanged(true);
              setDescricao(e.target.value.slice(0, 150));
            }}
            disabled={fullDisabled}
          />
          <span className="counter">{`${descricao?.length}/150`}</span>
        </div>

        {currentElement.tipo === 'data_hora_auto' && (
          <>
            <S.SelectMoeda>
              <div className="title">Formato da data</div>
              <div className="boxOptions">
                <span className="item">
                  <Radio disabled value />
                  <span>dd/mm/aaaa</span>
                </span>
              </div>
            </S.SelectMoeda>

            <S.SelectMoeda>
              <div className="title">Formato da hora</div>
              <div className="boxOptions">
                <span className="item">
                  <Radio disabled value />
                  <span>24hrs</span>
                </span>
              </div>
            </S.SelectMoeda>
          </>
        )}
      </S.StepOneBox>
    );
  };

  // -------------------------------------RENDER---------------------------------------//
  return (
    <GlobalModal
      stepOne={stepOne}
      validateStepOne={validateStepOne}
      open={open}
      onClose={onClose}
      resetFields={resetFields}
      totalContent={totalContent}
      hasData={!!data}
      obrigatorio={obrigatorio}
      setObrigatorio={setObrigatorio}
      small={currentElement.tipo === 'data_hora_auto' ? '600px' : '410px'}
      currentElement={currentElement}
      section={section}
      hasChanged={hasChanged}
      setHasChanged={setHasChanged}
    />
  );
};
