import { useEffect, useState } from 'react';

// Material UI
import { Collapse, Tooltip } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import WarningAmberRoundedIcon from '@mui/icons-material/WarningAmberRounded';

// Utils
import { useTheme } from 'styled-components';
import { toast } from 'react-toastify';
import * as S from './styled';

// FORMULARIO CRIADO COM BASE NA PAGINA DE DETALHE DA AVALIAÇÃO
// CASO SOFRER ALTERAÇÕES VERIFICAR O FORMULARIO DA MESMA

const FormEvaluation = ({
  sections, // Definição dos headers do Formulario (verificar estrutura caso for reutilizar) **Perguntas estão contidas nesse mesmo objeto
  answers, // Respostas ja respondidas referentes ao formualrio
  desvios, // Caso possua alguma irregularidade que o formulario precisa exibir na linha
  onClickRow, // Click nas opções disponíveis
  setAnswers, // Para alterar a exibição da resposta sem refazer a request completa
  ifNo, // Caso a opção clicada seja "NAO"
  disabled, // Desativar click nas opções
  forbiden, // Mensagem de erro ao clicar na opção bloqueada
  clickOutlier, // Click no desvio da linha
  offCollapse, // Desabilita o click no collapse (usado na pagina de impressão)
  isCreate, // bloqueia o click no icone de direcionar para o desvio
  noNA, // Desabilita a opção "N/A"
  cae,
  info,
  infoLines,
}) => {
  const theme = useTheme();
  const [collapse, setCollapse] = useState([]);

  useEffect(() => {
    const opned = [];
    for (const i in sections) {
      opned.push(true);
    }
    setCollapse(opned);
  }, []);

  const handleCurrentAnswer = id => {
    for (const i in answers) {
      if (answers[i]?.questao?.id === id) {
        return answers[i]?.resposta;
      }
    }
  };

  const getAnswersObject = id => {
    let information = answers.find(answer => answer?.questao?.id === id);

    if (information) {
      return information;
    }
    for (const i in sections) {
      const { questoes } = sections[i];
      for (const i in questoes) {
        if (questoes[i].id === id) {
          return { questao: questoes[i] };
        }
      }
    }
  };

  const handleCollapese = idx => {
    const copyCollapse = [...collapse];
    copyCollapse[idx] = !copyCollapse[idx];
    setCollapse(copyCollapse);
  };

  const countQuestions = id => {
    let count = 0;
    for (const i in answers) {
      if (
        answers[i].questao?.secao.id === id &&
        answers[i].resposta !== undefined
      )
        count++;
    }
    return count;
  };

  const countDesvios = check => {
    let count = 0;
    for (const i in desvios) {
      if (desvios[i].pergunta === check) count++;
    }
    return count;
  };

  const hanldeResponseQuestion = async (id, res) => {
    if (res === 'NAO') {
      ifNo(id, res, getAnswersObject(id));
      return;
    }

    const resp = await onClickRow(id, res, getAnswersObject(id));
    if (resp?.success) {
      const newAnswers = [...answers];
      let currentAnswer = null;

      for (const i in newAnswers) {
        if (newAnswers[i]?.questao?.id === id) {
          currentAnswer = newAnswers[i];
          newAnswers[i].resposta = res;
          setAnswers(newAnswers);
          return;
        }
      }

      if (!currentAnswer) {
        newAnswers.push(resp.resposta);
        setAnswers(newAnswers);
      }
    }
  };

  const disabledMessage = () => {
    toast.warning(forbiden);
  };

  let indexCounter = 1;

  return (
    <S.Container>
      {sections.map((item, idx) => (
        <div key={idx}>
          <S.Header
            onClick={!offCollapse ? () => handleCollapese(idx) : undefined}
          >
            <div className="textHeader">{item.title}</div>
            <div className="rightArea">
              <span className="countArea">
                {countQuestions(item.id)} concluido(s) de um total de{' '}
                {item.questoes.length}
              </span>
              <span className="expandArea">
                {collapse[idx] ? <ExpandLess /> : <ExpandMore />}
              </span>
            </div>
          </S.Header>
          <Collapse in={collapse[idx]} unmountOnExit>
            {item.questoes.map((questao, k) => (
              <S.Row
                value={k % 2}
                resp={handleCurrentAnswer(questao.id)}
                key={k}
              >
                <div className="answerArea">
                  {`${indexCounter++}) `}
                  {questao.texto_da_pergunta}
                  {info && infoLines.includes(questao.id) && (
                    <Tooltip title={info}>
                      <InfoOutlinedIcon />
                    </Tooltip>
                  )}
                </div>
                <div className="responseArea">
                  {countDesvios(questao.pergunta) >= 1 && (
                    <>
                      <div
                        className="anomalyArea"
                        onClick={!isCreate ? () => clickOutlier() : undefined}
                      >
                        <Tooltip
                          title={
                            cae
                              ? 'CAE aberta'
                              : `${countDesvios(
                                  questao.pergunta,
                                )} desvio(s) registrado(s)`
                          }
                        >
                          <WarningAmberRoundedIcon
                            htmlColor={
                              theme.palette.semantics.feedback.attention.natural
                            }
                          />
                        </Tooltip>
                      </div>
                      {!disabled && !cae && (
                        <div
                          className="newInfraction"
                          onClick={() =>
                            hanldeResponseQuestion(questao.id, 'NAO')
                          }
                        >
                          Adicionar desvio
                        </div>
                      )}
                    </>
                  )}

                  {!disabled && (
                    <span className="boxArea">
                      <span
                        className="yes"
                        onClick={() =>
                          hanldeResponseQuestion(questao.id, 'SIM')
                        }
                      >
                        SIM
                      </span>
                      <span
                        className={noNA ? 'nona' : 'no'}
                        onClick={() =>
                          hanldeResponseQuestion(questao.id, 'NAO')
                        }
                      >
                        NÃO
                      </span>
                      {!noNA && (
                        <span
                          className="na"
                          onClick={() =>
                            hanldeResponseQuestion(questao.id, 'N/A')
                          }
                        >
                          N/A
                        </span>
                      )}
                    </span>
                  )}

                  {disabled && (
                    <span className="boxArea disabled">
                      <span className="yes" onClick={() => disabledMessage()}>
                        SIM
                      </span>
                      <span
                        className={noNA ? 'nona' : 'no'}
                        onClick={() => disabledMessage()}
                      >
                        NÃO
                      </span>
                      {!noNA && (
                        <span className="na" onClick={() => disabledMessage()}>
                          N/A
                        </span>
                      )}
                    </span>
                  )}
                </div>
              </S.Row>
            ))}
          </Collapse>
        </div>
      ))}
    </S.Container>
  );
};

export default FormEvaluation;
