/* eslint-disable import/order */

// Styleds
import * as S from './styled';
import colors from 'styles/colors';

// React
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';

// Components
import { ModalAlunos } from './ModalAlunos';
import { ModalDetails } from './ModalDetails';
import { DefaultTable } from 'components/_Table/templates/default';

import { ExportCompaniesPdf } from './Export';
import TableLocal from 'components/TableLocal';
import { trackEvent } from 'utils/mixpanel';

// Components MUI
import {
  CircularProgress,
  Divider,
  Grid,
  Menu,
  MenuItem,
  SvgIcon,
} from '@mui/material';
import { Box } from '@mui/system';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';

// Utils
import { fequencyModule, statusUser } from '../actions';

// Columns
import { columnsSmall } from './columns';

export const Users = ({ printRefPdf, data: reports }) => {
  // Redux
  const { geral } = useSelector(state => state.conteudoProvider);
  const user = useSelector(state => {
    return state.auth?.user?.user;
  });

  const navigate = useNavigate();

  // Data States
  const data = reports?.alunos || [];
  const cards = reports?.cards || [];
  const [currentDetail, setCurrentDetail] = useState(null);

  // Controllers States
  const [openModal, setOpenModal] = useState(false);
  const [currentData, setCurrentData] = useState(null);
  const [anchorDistributor, setAnchorDistributor] = useState(false);
  const [loading, setLoading] = useState(false);

  // -------------------------------------------------FORMAT TABLE--------------------------------------------------//
  const formatTable = arr => {
    const col = columnsSmall.map(item => {
      if (item.id === 'concluido') {
        return {
          header: 'Conclusão',
          align: 'center',
          sort: true,
          width: 300,

          children: (value, item) => (
            <S.ContainerStatus>
              <S.ColDriver>
                <S.Paragraph className="green">
                  {item?.percentual || 0}%
                </S.Paragraph>
              </S.ColDriver>
              <span>
                {item.updated_at &&
                  `Data: ${format(
                    new Date(item.updated_at),
                    'dd/MM/yyyy HH:mm',
                    {
                      timeZone: 'UTC',
                    },
                  )}`}
              </span>
            </S.ContainerStatus>
          ),
        };
      }
      if (item.id === 'status') {
        return {
          header: 'Status',
          align: 'center',
          sort: true,
          children: (value, item) => (
            <S.ColDriver>
              <S.Paragraph className="normal" center>
                {statusUser(item.status)}
              </S.Paragraph>
            </S.ColDriver>
          ),
        };
      }
      return item;
    });
    return col;
  };

  const handleOpenDistributorMenu = e => {
    setAnchorDistributor(e.currentTarget);
  };

  const renderActionMenu = () => {
    let currentOptions = [];

    let tabsFuncs = {
      options: ['visualizar'],
    };

    currentOptions = tabsFuncs.options;

    return (
      <Menu
        open={!!anchorDistributor}
        anchorEl={anchorDistributor}
        onClose={() => setAnchorDistributor(null)}
        onClick={() => setAnchorDistributor(null)}
      >
        <Grid container style={{ display: 'flex', flexDirection: 'column' }}>
          {currentOptions.includes('visualizar') && (
            <MenuItem
              key="visualizar"
              onClick={() => getUserByCompany(anchorDistributor.id)}
            >
              <Grid item md={9}>
                <S.PTable>Ver alunos</S.PTable>
              </Grid>
            </MenuItem>
          )}
        </Grid>
      </Menu>
    );
  };

  // ------------------------------------------------ MODAL VIEWR CONTROLL----------------------------------------//
  const getUserByCompany = id => {
    const currentCompany = data.find(item => item.id === +id);
    setCurrentData(currentCompany);
    setOpenModal(true);
  };

  // ---------------------------------------------TABLE ACTIONS-------------------------------------------//

  const seeProfile = id => {
    const currentItem = data.find(item => item.id === +id);
    navigate(
      `/capacitacao/perfil-do-aluno/info-aluno?id=${currentItem.id_original}&tipo=${currentItem.tipo}`,
    );
  };

  const seeProfileNewTab = id => {
    const currentItem = data.find(item => item.id === +id);
    window.open(
      `/capacitacao/perfil-do-aluno/info-aluno?id=${currentItem.id_original}&tipo=${currentItem.tipo}`,
      '_blank',
    );
  };

  const togglerCurrentDatails = param => {
    if (param?.id) {
      setCurrentDetail(param);
    } else {
      const currentItem = data.find(item => item.id === +param);
      setCurrentDetail(currentItem);
    }
  };

  const actions = [
    {
      title: 'Ver detalhe',
      function: id => togglerCurrentDatails(id),
    },
    {
      title: 'Ver perfil',
      function: id => seeProfile(id),
    },
    {
      title: 'Abir em nova guia',
      function: id => seeProfileNewTab(id),
    },
  ];

  // ---------------------------------------------RENDER-------------------------------------------//
  return (
    <>
      <S.InfoLine>
        <div className="period">{fequencyModule(geral)}</div>
      </S.InfoLine>
      <S.Container>
        <S.BoxInfos>
          <>
            <S.CircleBar>
              <Box
                sx={{
                  position: 'relative',
                  display: 'inline-flex',
                  transform: 'scale(2)',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <CircularProgress
                  variant="determinate"
                  value={100}
                  sx={{
                    color: colors.greyTiny,
                  }}
                />
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <CircularProgress
                    variant="determinate"
                    value={cards?.percentual_conclusao || 0}
                  />
                </Box>
                <Box
                  sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  <FactCheckOutlinedIcon
                    htmlColor={
                      !cards?.percentual_conclusao
                        ? colors.greyTiny
                        : colors.greenMain
                    }
                    style={{ transform: 'scale(0.7)' }}
                  />
                </Box>
              </Box>
              <div className="textFields">
                <div className="text">Concluidos(%)</div>
                <div className="value">{cards?.percentual_conclusao || 0}%</div>
              </div>
            </S.CircleBar>
            <div className="bar" />
            <S.Card>
              <div className="text">Total de Concluidos</div>
              <div className="value">{cards?.n_finalizados || 0}</div>
            </S.Card>
            <div className="bar" />
            <S.Card>
              <div className="text">Em Andamento</div>
              <div className="value">{cards?.n_em_andamento || 0}</div>
            </S.Card>
          </>
        </S.BoxInfos>
        <S.BoxInfos style={{ marginTop: '-6px' }}>
          <>
            <S.Card>
              <div className="text">Reprovados</div>
              <div className="value">{cards?.n_reprovados || 0}</div>
            </S.Card>
            <div className="bar" />
            <S.Card>
              <div className="text">Não Inciados</div>
              <div className="value">{cards?.n_nao_iniciados || 0}</div>
            </S.Card>
            <div className="bar" />
            <S.Card>
              <div className="text">Número de Alunos</div>
              <div className="value">{cards?.n_alunos || 0}</div>
            </S.Card>
          </>
        </S.BoxInfos>

        <S.TableArea id="tableViewContent">
          <DefaultTable
            data={data || []}
            onClickRow={togglerCurrentDatails}
            columns={formatTable()}
            actions={actions}
            searchKeys={['nome']}
            loading={loading}
            placeholder="Buscar aluno"
            searchEvent={search =>
              trackEvent(user, 'Buscar aluno', null, search)
            }
            empty={{
              title: 'Nenhum aluno encontrada',
              description: '',
            }}
          />
        </S.TableArea>

        {renderActionMenu()}
      </S.Container>
      <ModalAlunos
        open={openModal}
        onClose={() => setOpenModal(false)}
        data={currentData}
      />

      <ModalDetails
        open={!!currentDetail}
        handleClose={() => setCurrentDetail(null)}
        currentDetail={currentDetail}
        setCurrentDetail={setCurrentDetail}
      />

      <ExportCompaniesPdf cont={geral} data={reports} printRef={printRefPdf} />
    </>
  );
};
