import { Modal } from '@mui/material';
import styled, { css } from 'styled-components';

export const ModalMUI = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Content = styled.div`
  ${({ theme }) => css`
    width: 850px;
    height: 600px;
    background-color: ${theme.palette.system.overlay};
    border-radius: 5px;
    overflow-x: hidden;
  `}
`;

export const Header = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    .right {
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
    }

    .left {
      display: flex;
      justify-content: center;
      align-items: center;

      .title {
        font-weight: 900;
        color: ${theme.palette.words.title.natural};
        margin-left: 20px;
        font-size: 25px;
      }
    }
  `}
`;

export const TableContainer = styled.div`
  padding: 15px;
  overflow-y: scroll;
  position: relative;

  .visualButton {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 10px;

    .periodo {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0px 12px;
      background: #edf2f9 0% 0% no-repeat padding-box;
      border-radius: 4px;
      opacity: 1;
      margin-right: 15px;

      p {
        margin-left: 8.5px;
        text-align: left;
        font: normal normal medium 16px/23px Texta;
        letter-spacing: 0px;
        color: #939aab;
        opacity: 1;
      }
    }
  }
`;

export const EmptyArea = styled.div`
  ${({ theme }) => css`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    margin-top: -80px;

    img {
      width: 200px;
    }

    .emeptyText {
      margin-left: 20px;
      font-weight: 600;
      color: ${theme.palette.words.title.natural};
      font-size: 25px;
    }
  `}
`;

export const Popup = styled.div`
  ${({ theme }) => css`
    display: flex;
    flex-direction: column;
    width: 120px;

    button {
      width: 100%;
      border: none;
      background-color: ${theme.palette.system.overlay};
      border-radius: none;
      text-align: start;
      padding: 8px 10px;
      font-weight: 600;
      color: ${theme.palette.words.title.natural};
      font-size: 15px;
      transition: all ease 0.2s;

      &:hover {
        background-color: ${theme.palette.system.border};
      }
    }
  `}
`;

export const ColDriver = styled.div`
  text-align: left;
  font: normal normal normal 16px/24px Texta;
  letter-spacing: 0px;
  text-transform: capitalize;
  color: #4b5166;
  opacity: 1;
`;

export const Paragraph = styled.p``;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;
