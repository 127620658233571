import styled, { css } from 'styled-components';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  .minha-equipe-title {
    font: normal normal 900 24px/30px Texta;
    letter-spacing: 0px;
    color: #4b5166;
  }

  .see-more {
    text-decoration: underline;
    font: normal normal 600 16px/24px Texta;
    margin-top: 10px;
    letter-spacing: 0px;
    color: #ff8040;
    cursor: pointer;
  }
  .container-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .title-main {
      text-align: left;
      font: normal normal 900 24px/30px Texta;
      letter-spacing: 0px;
      color: #4b5166;
    }
  }

  .container-tabs {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding-left: 48px;
    padding-bottom: 14px;
  }
  .graph-prazo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border: 1px solid #939aab4d;
    border-radius: 4px;
    background: #ffffff;
    margin-top: 10px;
    padding: 30px;
  }

  .graph-prazo-title {
    text-align: left;
    font: normal normal 900 24px/30px Texta;
    letter-spacing: 0px;
    color: #4b5166;
    padding-bottom: 15px;
    margin-left: 60px;
  }
  .container-movi {
    width: 100%;
  }
  .container-header {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    width: 100%;
    gap: 2rem;
    .first-graph {
      padding: 20px;
      min-width: 17.5%;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #939aab4d;
      overflow: scroll;
      border-radius: 4px;
      .title {
        text-align: left;
        font-size: 24px;
        font-weight: 900;
        font-family: Texta;
        font-style: normal;
        letter-spacing: 0px;
        color: #4b5166;
      }
    }
    .second-graph {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      width: 100%;
      overflow-x: auto;
      .top {
        display: flex;
        align-items: center;
      }
      .top-title {
        text-align: left;
        font-size: 24px;
        font-style: normal;
        font-weight: 900;
        font-family: Texta;
        letter-spacing: 0px;
        color: #4b5166;
        white-space: nowrap;
        width: min-content;
      }
      .middle {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        width: 100%;

        @media (max-width: 1240px) {
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
      }
      .footer {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-bottom: 15px;
        .point {
          margin-right: 5px;
          width: 12px;
          height: 12px;
          border-radius: 100%;
        }
        .text {
          color: #4b5166;
          font-size: 14px;
          font-family: Texta;
          font-weight: normal;
          font-style: normal;
          margin-right: 5%;
        }
      }
    }
    .second-graph::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
  }
  .content {
    display: flex;
    flex-direction: row;
    margin-top: 40px;
    overflow-x: auto;
  }
  .content::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .graphContainer {
    width: 1000px;
    overflow-x: auto;
    overflow-y: hidden;
    position: relative;

    @media (max-width: 1900px) {
      width: 750px;
    }
    @media (max-width: 1280px) {
      width: 500px;
    }
  }
  .barScroll {
    margin-left: 20px;
  }
  .barScroll::-webkit-scrollbar {
    width: 8px;
    height: 8px;
  }
  .card {
    background: #ffffff;
    border: 1px solid #939aab4d;
    border-radius: 4px;
    width: 302px;
    height: 139px;
    margin-right: 20px;
    margin-bottom: 20px;
    padding: 20px;
    overflow: hidden;
  }
  .card:hover {
    border: 1px solid #939aab;
    cursor: pointer;
  }
  .name {
    padding-left: 10px;
    text-align: left;
    font-size: 20px;
    font-weight: 900;
    font-family: Texta;
    font-style: 'normal';
    letter-spacing: 0px;
    color: #4b5166;
  }
  .card-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .footer {
    margin-top: 13px;
    .first-title {
      text-align: left;
      font-size: 15px;
      font-family: Texta;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0px;
      color: #4b5166;
    }
    .secound-title {
      text-align: left;
      font: normal normal medium 16px/24px Texta;
      font-size: 16px;
      font-family: Texta;
      font-style: normal;
      font-weight: 500;
      letter-spacing: 0px;
      color: #4b5166;
    }
  }
  .card-bottom {
    display: flex;
    flex-direction: row;

    border: 1px solid #939aab4d;
    background-color: #ffffff;
    border-radius: 4px;
    margin-top: 61px;
    padding: 20px;
  }
`;

export { Container };
