import styled, { css } from 'styled-components';
import { Button } from '@mui/material';

const Main = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
`;

const Header = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
`;

const Footer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
`;

const SelectsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px 20px;
`;

const SelectWrapper = styled.div`
  margin-bottom: 10px;
`;

const ClearButton = styled.button`
  ${({ theme }) => css`
    border: none;
    background: transparent;
    font: normal 600 14px Texta;
    text-decoration: underline;
    color: ${theme.palette.words.subtitle.natural};
    opacity: 1;
    :hover {
      opacity: 0.6;
    }
  `}
`;

const StyledButton = styled(Button)`
  ${({ theme, ...props }) => css`
    && {
      position: relative;
      text-transform: none;
      color: ${props.textcolor};
      background: ${props.backgroundcolor};
      border: 1px solid ${props.textcolor};
      font: normal normal 400 16px/24px Texta;
      height: ${props.height || '38px'};
      padding: 5px 15px;
      transition: opacity 300ms;

      :hover {
        background: ${props.backgroundcolor};
        opacity: 0.7;
      }
    }
  `}
`;

const Count = styled.p`
  ${({ theme, count }) => css`
    font: normal normal 900 12px/16px Texta;
    color: ${theme.palette.words.text.contrast};
    display: ${count ? 'block' : 'none'};
    background: ${theme.palette.semantics.feedback.attention.natural};
    width: 16px;
    border-radius: 16px;
    position: absolute;
    top: 3px;
    right: 23px;
  `}
`;

export {
  Main,
  Header,
  Footer,
  StyledButton,
  ClearButton,
  SelectWrapper,
  SelectsContainer,
  Count,
};
