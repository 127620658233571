/* eslint-disable prettier/prettier */
/* eslint-disable import/order */

// Styles
import * as S from './styled';

// React
import { toast } from 'react-toastify';
import { useQuery } from 'react-query';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// Components MUI
import CloseIcon from '@mui/icons-material/Close';
import LogoutIcon from '@mui/icons-material/Logout';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import { Modal, Card, Grid, IconButton, Tooltip } from '@mui/material';
import SupportAgentOutlinedIcon from '@mui/icons-material/SupportAgentOutlined';

// Redux
import { signOut } from 'store/modules/auth/saga';
import { setAlert, closeAlert } from 'store/modules/header/actions';

// Utils
import { trackEvent } from 'utils/mixpanel';

// Assets
import { ReactComponent as AttentionIcon } from 'images/Grupo 21585.svg';

// Services
import { expires } from './services';

export const Expire = ({ open, handleClose }) => {
  const dispatch = useDispatch();
  const user = useSelector(state => state.auth?.user?.user);

  const [showExpireModal, setShowExpiredModal] = useState(false);

  const contract = user?.provider
    ? user?.distribuidora?.contrato
    : user?.filial?.empresa?.contrato;

  const { data } = useQuery(
    ['expirationPlan'],
    () =>
      user?.provider
        ? expires(user?.id_da_distribuidora, 'distribuidora')
        : expires(user?.filial?.id_da_empresa, 'company'),
    { refetchOnWindowFocus: false },
  );

  const title = data?.status
    ? data?.demonstracao
      ? 'Seu período de demonstração está chegando ao fim'
      : 'Seu plano está expirando'
    : data?.demonstracao
    ? 'Seu período de demonstração acabou'
    : 'Seu plano expirou';

  useEffect(() => {
    if ((data && data.status && data.expiracao) || !contract.isActive) {
      dispatch(
        setAlert({
          message: (
            <>
              {data?.demonstracao
                ? 'Seu período de demonstração está chegando ao fim.'
                : 'Seu plano está expirando.'}{' '}
              Para continuar tendo acesso, entre em contato com nosso{' '}
              <S.Link onClick={() => setShowExpiredModal(true)}>
                time comercial
              </S.Link>
              .
            </>
          ),
        }),
      );
    }

    // UMA MSG QUALQUER PODE SER COLOCADA AQUI NESSE FORMATO
    // if (data) {
    //   dispatch(
    //     setAlert({
    //       message: (
    //         <>
    //           Nos ajude a melhorar:{' '}
    //           <S.Link
    //             href="https://forms.office.com/Pages/ResponsePage.aspx?id=2-CSzB8A00uEvRvhSUojr1UNl2KvIn5Nm78CyF5asRJUQ0ZUOUFGOVoyWVgwOTE4N1hWM0pHQ0lFNi4u"
    //             target="_blank"
    //             rel="noopener noreferrer"
    //             onClick={() => dispatch(closeAlert())}
    //           >
    //             Clique aqui
    //           </S.Link>{' '}
    //           e responda nossa pesquisa de NPS. Leva apenas 3 minutos!
    //         </>
    //       ),
    //     }),
    //   );
    // }
  }, [data]);

  const handleClickClose = () => {
    setShowExpiredModal(false);
    handleClose();
  };

  return (
    <Modal open={!!open || !contract.isActive || showExpireModal}>
      <S.Content>
        <Card>
          <S.CardContent>
            <S.CardHeader>
              <AttentionIcon width="1.5rem" />
              <h2 style={{ flex: 1 }}>{title}</h2>
              {contract?.isActive ? (
                <Tooltip title="Fechar">
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => handleClickClose()}
                  >
                    <CloseIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              ) : (
                <Tooltip title="Sair">
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={() => {
                      trackEvent(user, 'Logout');
                      dispatch({ type: '@DESTROY_SESSION' });
                      signOut();
                    }}
                  >
                    <LogoutIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </S.CardHeader>
            <p>
              Para efetivar seu acesso, entre em contato com nosso time
              comercial.
            </p>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <S.Contact
                  onClick={() => {
                    navigator.clipboard.writeText('+55 31 99210-8028');
                    toast.success('Telefone Copiado');
                  }}
                >
                  <S.IconCircle>
                    <SupportAgentOutlinedIcon />
                  </S.IconCircle>
                  <p>Fale com consultor por telefone</p>
                  <S.Link href="tel:+5531992108028">+55 31 99210-8028</S.Link>
                </S.Contact>
              </Grid>
              <Grid item xs={6}>
                <S.Contact
                  onClick={() => {
                    navigator.clipboard.writeText('upgrade@onisys.com.br');
                    toast.success('Email Copiado');
                  }}
                >
                  <S.IconCircle>
                    <MailOutlineIcon />
                  </S.IconCircle>
                  <p>Envie um email para nosso comercial</p>
                  <S.Link href="mailto:upgrade@onisys.com.br">
                    upgrade@onisys.com.br
                  </S.Link>
                </S.Contact>
              </Grid>
            </Grid>
            {/* <S.CardFooter>
              <S.Link
                href="https://onisys.zendesk.com/hc/pt-br"
                target="_blank"
                rel="noreferrer"
              >
                Acessar Base de Conhecimento
              </S.Link>
            </S.CardFooter> */}
          </S.CardContent>
        </Card>
      </S.Content>
    </Modal>
  );
};
